import React, { useEffect, useState, useCallback } from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import LightTooltip from '../../../components/LightTooltip';
import { RiEditLine, RiTimeLine, RiCheckboxCircleLine, RiPauseCircleLine } from "react-icons/ri";
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { useSnackbar } from 'notistack';

import { getLanguage, roundNumber, getDataGridConfig, setDataGridConfig, dateFormat } from '../../../utils/utils';
import { EditRowContainer } from '../styles';
import ModalEdit from './ModalEdit';
import ModalEditTime from './ModalEditTime';
import { useMutation } from '@apollo/client';
import { SET_REPLACE_JOB_RUNTIME, SET_JOB_RUNTIME_START_END } from '../mutations';
import { Box, IconButton } from '@mui/material';

import CustomDetailPanelToggle from './CustomDetailPanelToggle';
import CustomToolbar from './CustomToolbar';
import DetailPanelContent from './DetailPanelContent';

import moment from 'moment'

function PoTable({ data, update }) {
  const language = getLanguage();
  const [rows, setRows] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [EditTime, setEditTime] = useState({ open: false, row: {}, start: '', end: '' });
  const [dataModel, setDataModel] = useState([]);
  const [jobChanged, setJobChanged] = useState({});
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const dataGridConfig = getDataGridConfig('po');

  const [replaceJobRuntime] = useMutation(SET_REPLACE_JOB_RUNTIME, {
    onCompleted: (data) => {
      const id_changed = jobChanged?.id_order ?? '';
      let successMsg = language?.po_has_changed || "Po job_start has been modified to job_end";
      successMsg = successMsg.replace("job_start", dataModel?.row?.id_order?.toString());
      successMsg = successMsg.replace("job_end", id_changed?.toString());
      enqueueSnackbar(successMsg, { variant: 'success' });
      update();
    },
    onError: () => {
      enqueueSnackbar(`${language?.an_error_occured || "Ops! Something went wrong."}`, { variant: 'error' });
    }
  });

  const [editStarEnd] = useMutation(SET_JOB_RUNTIME_START_END, {
    onCompleted: (data) => {
      enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
      update();
    },
    onError: () => {
      enqueueSnackbar(`${language?.an_error_occured || "Ops! Something went wrong."}`, { variant: 'error' });
    }
  });


  const handleChangeJob = (params) => {
    setJobChanged(params);
    replaceJobRuntime({
      variables: {
        id_production_order: dataModel?.row?.id_production_order,
        new_id: params?.id_production_order
      }
    });

    setEdit(false);
    setOpenEdit(false);
  }

  const handleEditStartEnd = (params) => {
    editStarEnd({
      variables: {
        id_production_order: dataModel?.row?.id_production_order,
        timeRange: `[${params}]`
      }
    });
    handleCloseEditTime();
  }

  function getOpStatus(jobStatus) {
    let status = "";
    let statusText = "";
    switch (jobStatus) {
      case 1:
        status = <PlayCircleOutlineOutlinedIcon sx={{ color: '#318F29', fontSize: 22 }} />
        statusText = `${language?.available || 'Available'}`;
        break;
      case 2:
        status = <RiTimeLine color="#407CCC" size={22} />;
        statusText = `${language?.running || 'Running'}`;
        break;
      case 3:
        status = <RiCheckboxCircleLine color='#4D4D4D' size={22} />;
        statusText = `${language?.finished || 'Finished'}`;
        break;
      case 4:
        status = <RiPauseCircleLine color='#FD9729' size={22} />;
        statusText = `${language?.paused || 'Paused'}`;
        break;
    }
    return {
      status, statusText
    }
  }

  const handleClickOpenEdit = (params) => {
    setDataModel(params);
    setOpenEdit(true);
  };

  const handleClickEditTime = (params) => {
    const { ts_start, ts_end } = params?.row;
    setEditTime({ open: true, start: moment(ts_start), end: moment(ts_end), row: params?.row });
    setDataModel(params);
  }


  const handleCloseEditTime = () => {
    setEditTime({ open: false, start: '', end: '', row: {} });
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  function formatDate(date) {
    if (date) {
      return dateFormat(date, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'CLIENT' });
    }
    return '-';
  }

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerName: `${language?.details || 'Details'}`,
      disableColumnMenu: true,
      filterable: false,
      hideable: false,
      disableReorder: true,
      hideSortIcons: true,
      width: 80,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: 'status', headerName: `${language?.status || 'Status'}`,
      flex: 1,
      minWidth: 90,
      valueGetter: ({ value }) => value && getOpStatus(value).statusText?.toString(),
      renderCell: (params) => (
        <LightTooltip title={getOpStatus(params?.row?.status).statusText} placement='top'>
          <IconButton sx={{ cursor: 'unset' }}>
            {getOpStatus(params?.row?.status).status}
          </IconButton>
        </LightTooltip>
      )
    },
    {
      field: 'id_order',
      headerName: `${language?.job || 'Job'}`,
      editable: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        (isEdit && params?.row?.ts_end !== '-') ?
          <EditRowContainer onClick={() => handleClickOpenEdit(params)}>
            <div> <span>{params?.row?.id_order}</span> <RiEditLine color='#828282' size={15} /> </div>
          </EditRowContainer> : <span>{params?.row?.id_order}</span>
      )
    }, {
      field: 'nm_client',
      headerName: `${language?.client || 'Client'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
    }, {
      field: 'nm_product',
      headerName: `${language?.product || 'Product'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.txt_product} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.nm_product}</span>
        </LightTooltip>
      )
    }, {
      field: 'production_ordered',
      headerName: `${language?.order_size || 'Order Size'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <span>{roundNumber(params?.row?.production_ordered)}</span>
    },
    {
      field: 'production_final',
      headerName: `${language?.production_count || 'Production Count'}`,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <span>{params?.row?.production_final ? roundNumber(params?.row?.production_final) : "-"}</span>
    },
    {
      field: 'net_production',
      headerName: `${language?.production_collected || 'Production Collected'}`,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <span>{params?.row?.net_production ? roundNumber(params?.row?.net_production) : "-"}</span>
    },
    {
      field: 'scrap',
      headerName: `${language?.scrap || 'Scrap'}`,
      editable: false,
      flex: 1,
      minWidth: 120,
    }, {
      field: 'nm_equipment',
      headerName: `${language?.line || 'Line'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.nm_equipment} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.nm_equipment}</span>
        </LightTooltip>
      )
    }, {
      field: 'ts_start',
      headerName: `${language?.start || 'Start'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        (isEdit && params?.row?.ts_end !== null) ?
          <EditRowContainer onClick={() => handleClickEditTime(params)}>
            <div> <span>{formatDate(params?.row?.ts_start)}</span> <RiEditLine color='#828282' size={15} /> </div>
          </EditRowContainer> : <span>{formatDate(params?.row?.ts_start)}</span>
      )
    }
    , {
      field: 'ts_end',
      headerName: `${language?.end || 'End'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        (isEdit && params?.row?.ts_end !== null) ?
          <EditRowContainer onClick={() => handleClickEditTime(params)}>
            <div> <span>{formatDate(params?.row?.ts_end)}</span> <RiEditLine color='#828282' size={15} /> </div>
          </EditRowContainer> : <span>{formatDate(params?.row?.ts_end)}</span>
      )
    }, {
      field: 'total',
      headerName: `${language?.duration || 'Duration'}`,
      editable: false,
      flex: 1,
      minWidth: 150,
    },

  ];

  useEffect(() => {

    if (data?.length) {

      const filteredData = data?.filter((item) => item?.status !== 1);
      setRows(filteredData);
    }

  }, [data])

  const handleClickEdit = (prevState) => {
    setEdit(!prevState);
  }

  const getDetailPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, [],);
  const getDetailPanelHeight = useCallback(() => 250, []);

  return (
    <>
      <Box sx={{ height: 740, width: '100%', }}>
        <ModalEdit params={dataModel} isOpen={openEdit} handleChange={handleChangeJob} handleClose={handleCloseEdit} />
        <ModalEditTime params={EditTime} isOpen={EditTime?.open} handleClose={handleCloseEditTime} handleChange={handleEditStartEnd} />
        <DataGridPro
          sx={{ border: 'none' }}
          components={{ Toolbar: CustomToolbar }}
          rows={rows}
          columns={columns}
          onColumnVisibilityModelChange={(newModel) =>
            setDataGridConfig('po', { filterName: 'columns', value: newModel })
          }
          initialState={{
            pagination: {
              pageSize: dataGridConfig?.rowsPerPage
            },
            filter: {
              filterModel: {
                items: dataGridConfig?.filter,
              },
            },
            columns: {
              columnVisibilityModel: dataGridConfig?.columns
            }
          }}
          rowsPerPageOptions={[5, 10, 15, 50, 100]}
          onPageSizeChange={(newPageSize) => setDataGridConfig('po', { filterName: 'rowsPerPage', value: newPageSize })}
          onFilterModelChange={(e) => setDataGridConfig('po', { filterName: 'filter', value: e.items })}
          pagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          apiRef={apiRef}
          componentsProps={{
            toolbar: {
              data: data,
              handleEditMode: () => handleClickEdit(isEdit),
            },
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          localeText={{
            columnsPanelTextFieldLabel: `${language?.find_column || 'Find column'} `,
            columnsPanelTextFieldPlaceholder: `${language?.column_title || 'Column title'}`,
            columnsPanelShowAllButton: `${language?.show_all || 'Show all'}`,
            columnsPanelHideAllButton: `${language?.hide_all || 'Hide all'}`,
            toolbarColumns: `${language?.columns || 'Columns'}`,
          }}
        />
      </Box>
    </>
  );
}

export default PoTable;
