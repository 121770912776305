//import moment from 'moment';
import React, { useContext } from 'react';
import { LineChart, CartesianGrid, YAxis, XAxis, Tooltip, Line, Legend, ResponsiveContainer } from 'recharts';
import ChartLegend from '../../../components/ChartLegend';
import StaticTooltip from './staticTooltip';
import CustomTooltip from './Tooltip';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import { getLanguage, sortArrayOfObjectsBy } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, } from '@mui/material';

function Chart({ width, height, data, dateRange, target }) {
  const { setTs_start, setTs_end, setRange, typeOfView: test } = useContext(VariablesContext);
  const language = getLanguage();
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });
  let typeOfView = (dateRange === 'day' || dateRange === 'today') ? 'GENERAL': test;
  let isHour = (dateRange === 'day' || dateRange === 'today') ? true : false;

  let chartSize = 550;
  if (window.innerWidth < 1537) chartSize = 490;
  let targetData = [];
  
  target?.reduce((acc, current) => {  targetData.push(acc + current?.target); return acc + current?.target;}, 0);

  let legendKeys = [];
  const colors = ['#407CCC', '#2F3A8F', '#FE7E6D', '#FFB830'];

  let chartData = data?.map((item, index) => (
    {
      gross_production: item.gross_production_acc,
      net_production: item.net_production_acc,
      scrap: item?.scrap,
      target: targetData?.[index],
      trend_line: item?.trendline1,
      ts: item?.ts,
      togoal: item?.togoal,
      period_production: item?.net_production_incr,
      scrap_acc: item?.scrap_acc,
      shifts: item?.shift_net_prod,
      target_period: item?.target_period
    }

  ));
  chartData?.unshift({
    gross_production: 0,
    net_production: 0,
    scrap: 0,
    target: 0,
    trend_line: 0,
    ts: moment(data?.[0]?.ts).subtract(1, 'day').toISOString(),
    togoal: 0,
    period_production: 0,
    scrap_acc: 0,
    shifts: [],
    target_period: 0
  });

  chartData = chartData?.map((item) => {
    if (isHour && moment(item.ts).utc().isBefore(moment(), 'hour')) item.acumTotal = item.net_production;
    if (!isHour && moment(item?.ts).utc().isBefore(moment(), 'D')) item.acumTotal = item.net_production;
    if (moment(item?.ts).utc().isBefore(moment())) {
      item.trend_line = item.net_production;
    } 
    return item;
  });


  // Creates list of shifts
  let shiftOrTeamList = [];
  let groupBy = '';
  if (typeOfView != 'GENERAL') {
    switch (typeOfView) {
      case 'SHIFTS':
        groupBy = `shift`;
        break;
      case 'TEAMS':
        groupBy = `team`;
        break;
    }
    chartData?.map(
      (item, index) => {
        item?.shifts?.map((shiftOrTeam) => {
          if (shiftOrTeam?.[groupBy] && !shiftOrTeamList.includes(shiftOrTeam?.[groupBy])) {
            shiftOrTeamList.push(shiftOrTeam?.[groupBy]);
            console.log(shiftOrTeam);
            legendKeys.push({ label: `${shiftOrTeam?.[groupBy]}`, color: colors[shiftOrTeamList.indexOf(shiftOrTeam?.[groupBy])] })
          }
        }
        );
      }
    );
    chartData?.map((ts_value_production, ts_value_production_index) => {
      if (ts_value_production?.shifts?.length != shiftOrTeamList?.length) {
        const ts_value_production_shifts = ts_value_production?.shifts?.map((shiftOrTeam) => shiftOrTeam?.[groupBy]);
        shiftOrTeamList?.map((shiftOrTeam) => {
          if (!ts_value_production_shifts?.includes(shiftOrTeam)) {
            let ts_value_production_current_shifts = Array.from(chartData[ts_value_production_index].shifts);
            if (groupBy == `shift`) {
              ts_value_production_current_shifts?.push({ "shift": shiftOrTeam, net: 0, scrap: null });
            } else {
              ts_value_production_current_shifts?.push({ "team": shiftOrTeam, net: 0, scrap: null });
            }

          }
        }
        )
      }
      chartData[ts_value_production_index].shifts = chartData[ts_value_production_index].shifts?.sort((a, b) => sortArrayOfObjectsBy(a, b, groupBy));
    })
  }

  let mainShifts = null;

  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = (isMobile ? 5 : 1);

  if ((typeOfView == 'SHIFTS' || typeOfView == 'TEAMS') && chartData?.length) {

    if (typeOfView == 'TEAMS') {
      element = 'team';
    }
    console.log(shiftOrTeamList)

    mainShifts = shiftOrTeamList?.map((item, index) => {
      const lineColor = colors[index];
      if (item) {
        return (
          <Line
            key={item}
            isAnimationActive={false}
            type="linear"
            dataKey={`shifts[${index}]net`}
            stroke={lineColor}
            connectNulls={true}
            strokeWidth="4"
            dot={false}
          />
        );
      }
    }
    );
  } else {
    legendKeys = [{
      label: language?.quantity_produced || 'Quantity Produced',
      color: '#407CCC'
    }, {
      label: language?.trend_line || 'Trend Line',
      dashed: true,
      color: '#828282'
    }, {
      label: language?.production_goal || 'Production Goal',
      color: '#318F29',
      dashed: true,
    }];
  }

  function setDay(e) {
    if (isMobile) return;
    if (e?.activeLabel === undefined) return;
    if (dateRange !== 'today' && dateRange != 'day') {
      let start = e?.activeLabel
      start = moment(start).utc().format('YYYY-MM-DD HH:mm');
      let end = moment(start).utc().format('YYYY-MM-DD HH:mm');
      setTs_start(start);
      setTs_end(end);
      setRange('day');
    }
  }
  const activeDotStyle = { stroke: '#FFF', fill: '#407CCC', strokeWidth: 3, r: 9 };

  let currentDayLine = (
    <Line
      type="linear"
      dataKey="net_production"
      stroke="#7fb5fa"
      strokeWidth="4"
      dot={false}
      activeDot={activeDotStyle}
    />
  );


  return (
    <Box sx={{ position: 'relative', }}>
      {isMobile && <StaticTooltip chartData={chartData} dateRange={dateRange} />}
      <ResponsiveContainer width="100%" height={isMobile ? 290 : chartSize} className={"recharts-responsive-container"}>
        <LineChart
          width={width} height={height} data={chartData}
          margin={!isMobile ? { top: 15, right: 54, left: 10, bottom: 0 } : { top: 0, right: 25, left: 0, bottom: 0 }}
          onClick={(e) => setDay(e)}
        >
          <CartesianGrid strokeDasharray="3" vertical={false}  />
          <XAxis
            dataKey="ts"
            height={60}
            interval={intervalData}
            tick={<XTick dateRange={dateRange} hideFirstTick />}
          // tickCount={5} 
          />

          <YAxis
            tick={<Ytick />}
            label={{ position: 'insideLeft' }}
            axisLine={false}
            // interval={'preserveStart'} 
            domain={[0, 'dataMax']}
          />

          {!isMobile &&
            <Tooltip content={<CustomTooltip chartData={chartData} dateRange={dateRange} isShift={(typeOfView == 'SHIFTS' || typeOfView == 'TEAMS')} />} />
          }

          <Legend content={<ChartLegend />} legendKeys={legendKeys} />
          {
            (typeOfView == 'SHIFTS' || typeOfView == 'TEAMS')
              ? mainShifts
              : <>
                {currentDayLine}
                <Line animationDuration={4400}
                  animationEasing="ease-out" type="linear" strokeWidth="2" connectNulls={true}
                  dot={false} strokeDasharray="3 2" dataKey="trend_line" stroke="#828282" />
                  
                <Line animationDuration={3100} dot={false}
                  animationEasing="ease-out" type="linear" strokeWidth="4"
                  connectNulls={true} dataKey="acumTotal" stroke="#407CCC" activeDot={false} />

                <Line animationDuration={2400}
                  animationEasing="ease-out" type="linear" strokeWidth="2"
                  dot={false} dataKey="target" strokeDasharray="3 2" stroke="#318F29" />

              </>
          }

        </LineChart>
      </ResponsiveContainer>

      {(dateRange != 'custom' && dateRange != 'day' && typeOfView != 'SHIFTS' && !typeOfView != 'TEAMS' && !isMobile)
        && <StaticTooltip chartData={chartData} dateRange={dateRange} />}

    </Box>
  );
}

export default Chart;