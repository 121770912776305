import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography as Typography2, } from '@mui/material';
import { Typography, } from '@material-ui/core';

export default function CardTime({ translate, data }) {
  const size = 2000;
  const sizeMobile = 2;

  const allTime = data?.h_piot_get_downtimes_resumo?.[0]?.available_time;
  const stopTime = data?.h_piot_get_downtimes_resumo?.[0]?.duration_total;
  const runningTime = allTime - stopTime;

  const timeForm = (num) => {
    const dia = num / 86400;
    const restoDia = num % 86400;

    const hora = restoDia / 3600;
    const restoHora = restoDia % 3600;

    const minutos = restoHora / 60;

    const restoMinutos = restoHora % 60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "")
      // (Math.trunc(restoMinutos) !== 0 ? Math.trunc(restoMinutos) + "seg" : "")
    )
  };

  const porcentRunning = (runningTime * 100) / allTime
  const porcentDowntime = (stopTime * 100) / allTime

  return (
    <Card >
      <Grid container
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateAreas:
          {
            xs:
              `"title title title title"
              "graphMobile total total total"
              "graphMobile porcent porcent porcent"`,
            sm:
              `"title title title title"
              "total total porcent porcent"
              "last last last last"`
          },
        }} >

        <CardContent sx={{ gridArea: 'title', display: 'flex', mb: { xs: -2, lg: -2 }, justifyContent: { xs: 'center', sm: 'start' } }}>
          <Typography variant="h4"> {translate.total_time} </Typography>
          <Box sx={{ ml: 2, mt: 1 }}>
            <Typography variant="body1"> {allTime ? timeForm(allTime) : "-"} </Typography>
          </Box>
        </CardContent>

        <CardContent sx={{ gridArea: 'total', display: { xs: 'flex', sm: 'grid' }, justifyContent: { xs: 'start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant="h3"> {runningTime ? timeForm(runningTime) : "-"} </Typography>
          <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Typography2 variant="subtitle2" color="#828282" > {porcentRunning.toFixed(1)}% </Typography2>
            <Typography2 sx={{ display: { xs: 'none', sm: 'block' }}} variant="subtitle2" color="#828282"> &nbsp; - &nbsp; </Typography2>
            <Typography2 variant="subtitle2" color="#828282" > {translate.running_time ?? "Running time"}  </Typography2>
          </Box>
        </CardContent>

        <CardContent sx={{ gridArea: 'porcent', display: { xs: 'flex', sm: 'grid' }, justifyContent: { xs: 'start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant="h3"> {stopTime ? timeForm(stopTime) : "-"}</Typography>
          <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Typography2 variant="subtitle2" color="#828282" > {porcentDowntime.toFixed(1)}% </Typography2>
            <Typography2 sx={{ display: { xs: 'none', sm: 'block' }}} variant="subtitle2" color="#828282"> &nbsp; - &nbsp; </Typography2>
            <Typography2 variant="subtitle2" color="#828282" > {translate.stopped_time ?? downtimes} </Typography2>
          </Box>
        </CardContent>

        <CardContent sx={{ gridArea: 'last', mt: -2, display: { xs: 'none', sm: 'block' }, }}>
          <Box sx={{ maxWidth: size, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'grid',
              width: isNaN(porcentRunning) || Math.round(porcentRunning) === 0
                ? 0 : Math.round(porcentRunning) <= 5
                  ? 10 * size : Math.round(porcentRunning) * size,
            }} >
              <Box sx={{
                height: 16, display: 'flex', alignItems: 'center',
                justifyContent: 'center', backgroundColor: '#0984E3',
                borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
              }}> </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              width: isNaN(porcentDowntime) || Math.round(porcentDowntime) === 0
                ? 0 : Math.round(porcentDowntime) <= 5
                  ? 10 * size : Math.round(porcentDowntime) * size,
            }} >
              <Box
                sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor: '#EB5757',
                  borderBottomRightRadius: 10, borderTopRightRadius: 10,
                  borderTopLeftRadius: Math.round(porcentDowntime) === 0 ? 10 : 0,
                  borderBottomLeftRadius: Math.round(porcentDowntime) === 0 ? 10 : 0,
                }}> </Box>
            </Box>
          </Box>
        </CardContent>

        <CardContent sx={{ gridArea: 'graphMobile', display: { xs: 'block', sm: 'none' }, }}>
          <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'center', }}>
            <Box sx={{
              display: 'flex',
              height: isNaN(porcentRunning) || Math.round(porcentRunning) === 0
                ? 0 : Math.round(porcentRunning) <= 5
                  ? 10 * sizeMobile : Math.round(porcentRunning) * sizeMobile,
            }} >
              <Box sx={{
                width: 16, display: 'flex', alignItems: 'center',
                justifyContent: 'center', backgroundColor: '#0984E3',
                borderTopLeftRadius: 10, borderTopRightRadius: 10,
                borderBottomRightRadius: Math.round(porcentDowntime) === 0 ? 10 : 0,
                borderBottomLeftRadius: Math.round(porcentDowntime) === 0 ? 10 : 0,
              }}> </Box>
            </Box>

            <Box sx={{
              display: 'flex',
              height: isNaN(porcentDowntime) || Math.round(porcentDowntime) === 0
                ? 0 : Math.round(porcentDowntime) <= 5
                  ? 10 * sizeMobile : Math.round(porcentDowntime) * sizeMobile,
            }} >
              <Box
                sx={{
                  width: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor: '#EB5757',
                  borderTopLeftRadius: Math.round(porcentRunning) === 0 ? 10 : 0,
                  borderTopRightRadius: Math.round(porcentRunning) === 0 ? 10 : 0,
                  borderBottomRightRadius: 10, borderBottomLeftRadius: 10,
                }}> </Box>
            </Box>
          </Box>
        </CardContent>

      </Grid>
    </Card>
  );
}
