import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const CardSkeleton = styled.div`
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  animation: 1.5s ease-in-out 0.5s infinite animation-skeleton-dwt;
  @keyframes animation-skeleton-dwt {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

function DowntimeSkeleton() {
  return (
    <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 2, mb: 2,
                gridTemplateRows: 'auto',
                gridTemplateAreas:
                { 
                  xs:
                  `"main main main main"
                  "class class class class"
                  "re re re re"`,
                  lg:
                  `"main main class class class class"
                  "re re re re re re"`
                }
                  ,
              }}>

              <Box sx={{ gridArea: 'main', }}>
                <CardSkeleton />
              </Box>
              
              <Box sx={{ gridArea: 'class',}}>
                <CardSkeleton />
              </Box>

              <Box sx={{ gridArea: 're',}}>
                <CardSkeleton />
              </Box>
            </Box>

  );
}

export default DowntimeSkeleton;