import React from 'react';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Card, Box, Typography as Typography2 } from '@mui/material';
import { Typography } from '@material-ui/core';
import moment from 'moment';

export default function InfoUpWithoutOP({ data, equipment }) {
  const size = 1100
  const language = getLanguage();
  const dataJob = data?.uns_equipment_current_job?.[0]

  const ordem = data?.uns_equipment_current_job?.[0]?.target
  const prod = data?.uns_equipment_current_job?.[0]?.net_production


  const timeForm = (num) => {
    const dia = num / 86400;
    const restoDia = num % 86400;

    const hora = restoDia / 3600;
    const restoHora = restoDia % 3600;

    const minutos = restoHora / 60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "")
    )
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, }}>

      <Card sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: 190, width: '15%' }} elevation={0}>
        <Typography variant="h2" >
          {equipment?.equipments?.[0]?.nm_equipment}
        </Typography>
        {
          dataJob?.setup_end_time && <>
            <Typography variant="h3" style={{ color: '#407CCC', margin: 'auto' }} >
              {language?.last_setup || 'last_setup'}
            </Typography>
            <Typography variant="h3" style={{ margin: "auto" }}>
              {timeForm(moment(dataJob?.setup_end_time).diff(moment(dataJob?.setup_begin_time), "seconds"))}
            </Typography>
          </>
        }
      </Card>

      <Card sx={{ display: 'flex', gap: 2, width: '85%', height: 190 }} elevation={0}>
        <Typography variant="h1" style={{ margin: 'auto' }} >
          {language?.no_op_running || 'No OP running'}
        </Typography>
      </Card>

    </Box>
  );
}
