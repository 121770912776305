import React, { useContext, useEffect, useState } from 'react'
import api from '../../../../../services/api';
import { getLanguage } from '../../../../../utils/utils';
import { AuthContext } from "../../../../../Context/AuthContext"
import { VariablesContext } from '../../../../../Context/VariablesContext';

import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, IconButton, MenuItem, FormControl, Select, InputLabel } from '@mui/material';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';

import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { useSnackbar } from 'notistack';

import Unthorized from '../../../../../pages/Unthorized';

export const StyledDiv = styled.div.attrs({
  className: "dropzone"
})`
  border: 1px dashed #407CCC;
  background-color: #f7f9ff;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

export default function DowntimeReasonsUpload({ isOpen, toggleCsvUploadModal, selectedLine, setSelectedLine }) {
  const language = getLanguage();
  const [fileList, setFileList] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const { userPermissions, lineOptions, sectorOptions } = useContext(VariablesContext);

  const settingsPermissions = userPermissions?.screen?.filter((page) => { return page?.code == 24 });
  const canViewSettings = settingsPermissions?.length > 0;
  const canEditSettings = canViewSettings ? settingsPermissions?.[0]?.write : false;

  const handleClose = () => {
    toggleCsvUploadModal();
    setFileList();
    setError();
    setSuccess();
  };

  async function handleUploadCsv() {
    const data = new FormData();
    data.append("file", fileList?.[0]?.file);
    data.append("id_equipment", selectedLine);
    let successfull = false;

    if (selectedLine) {
      try {
        const response = await api.post('/api/admin/downtime-reasons/upload', data, { headers: { "Content-type": "multipart/form-data" } });
        successfull = true;
        enqueueSnackbar(language?.success || 'Success', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(language?.error || 'Error', { variant: 'error' });
        setError(language?.error || "Error")
      }
      if (successfull) {
        setSuccess(true)
        await new Promise(res => setTimeout(res, 3000));
        handleClose()
      }
    }
    else {
      enqueueSnackbar('Line must be selected', { variant: 'error' });
    }

  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
    onDrop: acceptedFiles => {
      const fileOk = acceptedFiles.map(file => ({
        file,
        progress: 0,
        uploaded: false,
        error: null
      }))
      setFileList(fileOk);
      setError();
    }
  });

  const fileDescription = (filee) => (<>
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateAreas:
        `"img name name name"
          "img below below below "
          "low low low low"`, mt: 5,
      color: error !== undefined ? '#C13939' : success === true ? '#318F29' : '#407CCC',
    }}>
      <DescriptionOutlinedIcon sx={{ gridArea: "img", fontSize: 35 }} />
      <Typography variant="h6" sx={{ fontFamily: "Source Sans Pro", gridArea: "name", fontSize: 16, ml: -11 }}>
        {filee?.[0]?.file?.path}
      </Typography>
      <Box sx={{
        maxWidth: 500, height: 3, borderRadius: 10, gridArea: "below", ml: -11,
        backgroundColor: error !== undefined ? '#C13939' : success === true ? '#318F29' : '#407CCC',
      }}></Box>
      <Typography variant="h6" sx={{ fontFamily: "Source Sans Pro", fontSize: 16, gridArea: "low", mt: 1, ml: 1 }}>
        {error}
        {success ? language?.this_file || 'The file was successfully uploaded!' : <></>}
      </Typography>
    </Box>
  </>
  );

  return (
    <div>
      <Dialog open={true} onClose={handleClose} fullWidth={true}>
        {canEditSettings
          ? <>
            <DialogTitle>
              {language?.import_downtime_reasons || "Import Downtime Reasons"}
              <IconButton
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}
                onClick={handleClose} >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers sx={{ pb: 13 }}>
              <Typography variant="h6" sx={{ fontFamily: "Source Sans Pro", gridArea: "name", fontSize: 24, fontWeight: 1000, mb: 3, mt: 1 }}>
                {language?.select_the_equipment_you_want_to_change || "Select the equipment you want to change:"}
              </Typography>
              <Box >
                <FormControl >
                  <InputLabel id="demo-simple-select-label">Equipment</InputLabel>
                  <Select
                    sx={{ width: '550px', }}
                    // autoWidth={true}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLine}
                    label="Equipment"
                    onChange={(event) => setSelectedLine(event.target.value)}
                  >
                    {sectorOptions?.filter((item) => item?.require_downtime_reason === true)?.map((line) => <MenuItem value={line.id_equipment}>{line.nm_equipment}</MenuItem>)}
                    {lineOptions?.filter((item) => item?.require_downtime_reason === true)?.map((line) => <MenuItem value={line.id_equipment}>{line.nm_equipment}</MenuItem>)} 
                  </Select>
                </FormControl>
              </Box>
              <Typography variant="h6" sx={{ fontFamily: "Source Sans Pro", gridArea: "name", fontSize: 24, fontWeight: 1000, mb: 3, mt: 1 }}>
                {language?.upload_your_csv_file_here || "Upload your CSV file here:"}
              </Typography>

              <StyledDiv className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <Grid container direction="column" alignItems="center" sx={{ mb: 4, mt: 2 }}>
                    <FolderIcon sx={{ fontSize: 100, color: '#407CCC', alignContent: 'center', }} />
                    <p>{language?.drag_drop || "Drag & drop here, or Click to Select."}</p>
                  </Grid>
                </div>
              </StyledDiv>

              <aside>
                <Box sx={{}}> {fileList ? fileDescription(fileList) : <></>} </Box>
              </aside>

            </DialogContent>

            <DialogActions>
              <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={handleClose}>{language?.close || 'Close'}</Button>
              <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={handleUploadCsv} disabled={fileList ? false : true}>{language?.save || 'Save'} </Button>
            </DialogActions>
          </>
          : <Unthorized />
        }
      </Dialog>
    </div>
  );
}
