import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton,
  Box, Button
} from '@mui/material';
import { roundNumber } from '../../../../../utils/utils';
import { getLanguage } from '../../../../../utils/utils';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useMutation } from '@apollo/client';
import { REMOVE_CUSTOM_TARGET_DAY, REMOVE_CUSTOM_TARGET_WEEK, REMOVE_CUSTOM_TARGET_MONTH } from '../mutations';
import { useSnackbar } from 'notistack';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 1 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}


function CustomTargetTable({ data, period, update, id }) {
  const language = getLanguage();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    const [removeTargetDay] = useMutation(REMOVE_CUSTOM_TARGET_DAY, {
      onCompleted: () => {
        update()
        enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
      },
      onError: (error) => {
        console.log(error);
        enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
      }
    });
  
    const [removeTargetWeek] = useMutation(REMOVE_CUSTOM_TARGET_WEEK, {
      onCompleted: () => {
        update()
        enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
      },
      onError: (error) => {
        console.log(error);
        enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
      }
    });
  
    const [removeTargetMonth] = useMutation(REMOVE_CUSTOM_TARGET_MONTH, {
      onCompleted: () => {
        update()
        enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
      },
      onError: (error) => {
        console.log(error);
        enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
      }
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function deleteTarget(item) {
    setTarget(item);
    setOpen(true);
    
  }

  const handleSubmit = () => {
    if (period === 'DAY') {
      removeTargetDay({
        variables: {
          id_equipment: id,
          ts_value: target?.ts_value
        }
      })
    }

    if (period === 'WEEK') {
      removeTargetWeek({
        variables: {
          id_equipment: id,
          ts_value: target?.ts_value
        }
      })
    }

    if (period === 'MONTH') {
      removeTargetMonth({
        variables: {
          id_equipment: id,
          ts_value: target?.ts_value
        }
      })
    }
  }

  function getPeriodLanguage(period) {
    let periodLanguage = period;
    switch(period) {
      case 'DAY':
        periodLanguage = language?.daily || 'Daily';
        break;
      case 'WEEK':
        periodLanguage = language?.weekly || 'Weekly';
        break;
      case 'MONTH':
        periodLanguage = language?.monthly || 'Monthly';
        break;
    }
    return periodLanguage;
    
  }
  const rows = data?.map((item, index) => (
    {
      id: item?.ts_value,
      line: item?.equipment?.nm_equipment,
      period: getPeriodLanguage(period),
      target: item?.target,
      ts_value: item?.ts_value,
      id_equipment: id,
      delete: (item) => deleteTarget(item)
    }
  ));

  function confirmMessage() {
    let successMsg = "Are you sure you want to delete?";
    return successMsg;
  }
  

  return (
    <TableContainer sx={{ mt: '25px' }}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> {language?.line || 'Line'}</TableCell>
            <TableCell align="center">{language?.period || 'Period'}</TableCell>
            <TableCell align="center">{language?.date || 'Date'}</TableCell>
            <TableCell align="center">{language?.goal || 'Goal'}</TableCell>
            <TableCell align="center">{language?.delete || 'Delete'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.line}
                </TableCell>
                <TableCell align="center">{row.period}</TableCell>
                <TableCell align="center">{row.ts_value}</TableCell>
                <TableCell align="center">{roundNumber(row.target)}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => row.delete(row)}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, { label: 'All', value: -1 }]}
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page', },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </Table>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', fontWeight: 400, fontSize: '18px', color: '#4D4D4D' }} id="alert-dialog-description">
            {confirmMessage()}
          </DialogContentText>

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => {
              handleClose();
              handleSubmit();
            }} autoFocus
              variant="contained"
              sx={{ width: '250px', mt: '24px' }}
            >
              {language?.delete || 'Delete'}
            </Button>
            <Button sx={{ mt: '16px', width: '150px' }} variant='outlined' onClick={handleClose}>{language?.cancel || 'Cancel'}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
}

export default CustomTargetTable;