import styled from 'styled-components';

export const EditRowContainer = styled.button`
    background: #DEE8F5;
    width: 100%;
    height: 33px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;

    div {
        margin-left: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        margin-right: 3px;
    }
`;

export const ButtonTab = styled.button`
    height: 42px;
    outline: none;
    margin-right: 32px;
    border: none;
    background: none;
    border-bottom: ${(props) =>  (props.active) ? '4px solid #407CCC' : 'none'};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #4D4D4D;
    cursor: pointer;

    @media (max-width: 475px) {
      font-size: 16px;
    }
`;