import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import { Nav, NavItem } from './styles';
import { getLanguage, getTotalElapsedTime } from '../../utils/utils';
const language = getLanguage();

function eventType(type) {
  let icon = <QueryBuilderOutlinedIcon />;
  switch (type) {
    case 1:   //System Downtime
      icon = <QueryBuilderOutlinedIcon />
      break;
    case 2:   //Manual Downtime
      icon = <ReportOutlinedIcon />
      break;
    case 3:   //Low Speed
      icon = <SpeedOutlinedIcon />
      break;
    case 4:   //PO Start
      icon = <PlayCircleOutlineIcon />
      break;
    case 5:   //PO End
      icon = <CheckCircleOutlineIcon />
      break;
    case 6:   //Shift End
      icon = <ChangeCircleOutlinedIcon />
      break;
    default:
      break;
  }
  return icon;
}

function eventContent(event) {
  switch (event.event_type) {
    //case 1: Default   //System Downtime
    case 2:
      return <>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Typography sx={{
            color: '#407CCC',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px'
          }}>
            {language?.manual_event || 'Manual Event'}
            {event?.cd_machine ? ` - ${event?.cd_machine} - ` : ''}
            {' ('}
            {event?.cd_category
              ?
              `${event.cd_category} ${event?.cd_subcategory ? ' - ' : ''} ${event?.cd_subcategory}`
              : `${language?.not_justify || 'Not justified'}`}
            {') '}
          </Typography>
          &nbsp;
          <Typography sx={{
            color: '#407CCC',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px'
          }}>
            {moment(event?.ts_event).format('MM/DD HH:mm')} {' - '} {(event?.ts_end) ? moment(event?.ts_end).format('MM/DD HH:mm') : ''}
            {' '} [{(event?.ts_end) ? getTotalElapsedTime(moment(event.ts_end).diff(event?.ts_event, 'seconds'), 'seconds') : ''}]
          </Typography>
        </Box>
        <Typography sx={{ mt: '5px', mb: '5px', color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
        {event?.txt_downtime_notes &&
          <Box sx={{ width: '100%', p: 1, borderRadius: '8px', background: '#EAEAED', display: 'flex' }}>
            <Typography>
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{language?.notes || 'Notes'}: </span>
              {event?.txt_downtime_notes}
            </Typography>
          </Box>}
      </>;
    case 3:   //Low Speed
      return <>
        <Typography sx={{
          color: '#407CCC',
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px'
        }}>
          {language?.low_speed || 'Low Speed'}
        </Typography>
        <Typography sx={{ color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
        {event?.txt_downtime_notes &&
          <Box sx={{ width: '100%', p: 1, borderRadius: '8px', background: '#EAEAED', display: 'flex' }}>
            <Typography>
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{language?.notes || 'Notes'}: </span>
              {event?.txt_downtime_notes}
            </Typography>
          </Box>}
      </>;
    case 4:   //PO Start
      return <>
        <Typography sx={{
          color: '#407CCC',
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px'
        }}>
          {language?.po_started || 'Po Started'}: {event?.id_order} - {event?.nm_client}
        </Typography>
        <Typography sx={{ color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
      </>;
    case 5:   //PO End
      return <>
        <Typography sx={{
          color: '#407CCC',
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px'
        }}>
          {language?.po_finished || 'PO Finished'}: {event?.id_order} - {event?.nm_client}
        </Typography>
        <Typography sx={{ color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
      </>;
    case 6:   //Shift End
      return <>
        <Typography sx={{
          color: '#407CCC',
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px'
        }}>
          {language?.shift_changed || 'Shift Changed'}
        </Typography>
        <Typography sx={{ color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
      </>
    default:
      let end = event?.ts_end ?? moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      let duration = moment(end).diff(event?.ts_event, 'seconds');
      return <>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Typography sx={{
            color: '#407CCC',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px'
          }}>
            {language?.downtime || 'Downtime'}
            {event?.cd_machine ? ` - ${event?.cd_machine} - ` : ''}
            {' ('}
            {event?.cd_category
              ?
              `${event.cd_category} ${event?.cd_subcategory ? ' - ' : ''} ${event?.cd_subcategory}`
              : `${language?.not_justify || 'Not justified'}`}
            {') '}
          </Typography>
          &nbsp;
          <Typography sx={{
            color: '#407CCC',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px'
          }}>
            {moment(event?.ts_event).format('MM/DD HH:mm')} {' - '} {(event?.ts_end) ? moment(event?.ts_end).format('MM/DD HH:mm') : ''}
            {' '} [{getTotalElapsedTime(duration, 'seconds')}]
          </Typography>
        </Box>
        <Typography sx={{ mt: '5px', mb: '5px', color: '#4D4D4D', fontSize: '14px', }} >
          {event?.nm_site} {'>'} {event?.nm_area} {'>'} {event?.nm_equipment}
        </Typography>
        {event?.txt_downtime_notes &&
          <Box sx={{ width: '100%', p: 1, borderRadius: '8px', background: '#EAEAED', display: 'flex' }}>
            <Typography>
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{language?.notes || 'Notes'}: </span>
              {event?.txt_downtime_notes}
            </Typography>
          </Box>}
      </>;
  }
}

function TimeLine({ data }) {
  moment.locale('en');
  const userLanguage = localStorage.getItem('user_language')?.toLowerCase();
  const [activeItem, setActive] = useState(0);

  let events = [];

  const eventsPerDay = data?.map((item) => (
    {
      cd_category: item?.cd_category,
      cd_machine: item?.cd_machine,
      cd_subcategory: item?.cd_subcategory,
      change_over: item?.change_over,
      duration: item?.duration,
      id_equipment: item?.id_equipment,
      status: item?.status,
      ts_event: item?.ts_event,
      ts_end: item?.ts_end,
      txt_downtime_notes: item?.txt_downtime_notes,
      ts_index: moment(item.ts_timeline).format('YYYY-MM-DD'),
      event_type: item?.event_type,
      id_order: item?.id_order,
      nm_client: item?.nm_client,
      ts_timeline: item?.ts_timeline,
      nm_site: item?.nm_site,
      nm_area: item?.nm_area,
      nm_equipment: item?.nm_equipment,
    }
  ));

  const eventsDate = eventsPerDay?.reduce((objeto, elementoAtual) => {
    const grupo = elementoAtual['ts_index'];
    if (!objeto.hasOwnProperty(grupo)) {
      objeto[grupo] = [];
    }
    objeto[grupo].push(elementoAtual);
    return objeto;
  }, {});


  if (eventsDate) {
    events = Object.keys(eventsDate).map((key) => (
      {
        ts_value: key,
        events: eventsDate[key]
      }
    ));
  }


  return (
    <Grid container>
      <Grid item xs={12} md={4} sx={{ height: { xs: '120px', md: 'calc(100vh - 322px)' } }}>
        <Nav>
          {
            events?.map((item, index) => (
              <NavItem key={index} href={`#ts-${index}`} active={activeItem === index} onClick={() => setActive(index)} >
                <span>{moment(item?.ts_value).locale(userLanguage).format('MMMM DD, YYYY')}</span>
                <p>{moment(item?.ts_value).locale(userLanguage).format('dddd')}</p>
              </NavItem>
            ))
          }
        </Nav>

      </Grid>


      <Grid item xs={12} md={8} sx={{ maxHeight: 'calc(100vh - 322px)', overflowY: 'scroll', pb: '45px' }}>

        {
          events?.map((item, index) => (
            <div key={index} id={index}>
              <Box key={index} id={`ts-${index}`} sx={{ display: 'flex', marginLeft: '19px', alignItems: 'center' }}>
                <Typography sx={{ marginRight: 2, fontWeight: 'bold', color: '#BDBDBD', fontSize: '16px' }}> {moment(item.ts_value).locale(userLanguage).format('MMMM DD')} </Typography>
                <Box sx={{ width: '70%', height: 0, border: '1px dashed #D8D7DD' }} />
              </Box>
              {
                item?.events?.map((event, index) => (
                  <Timeline key={index} sx={{ '.MuiTimelineItem-missingOppositeContent:before': { content: 'none' } }} position='right'>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                          {eventType(event.event_type)}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 1, }}>
                        <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                          {moment(event?.ts_timeline).format('HH:mm:ss')} {' - '} {moment(event?.ts_timeline).locale(userLanguage).fromNow()}
                        </Typography>
                        {eventContent(event)}
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>

                ))
              }
            </div>

          ))
        }
      </Grid>

    </Grid>
  );
}

export default TimeLine;