import { gql } from '@apollo/client';

export const MissionControlInitialQuery = gql`
query GetMissionControlData($in_ids_sites: String, $in_ids_equipments: String, $in_ids_areas: String, $in_id_enterprise: Int) {
  h_piot_get_mission_control_uns_3(args: {in_id_enterprise: $in_id_enterprise, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_sites: $in_ids_sites}, order_by: {id_site: asc, nm_area: asc, nm_line: asc}) {
    change_over_duration
    change_over_duration_percent
    curr_shift_name
    currshift_oee
    curshift_grosprod
    curshift_netprod
    curshift_scrap
    duration
    expected_time
    id_area
    id_line
    id_order
    id_site
    job_remaining_time
    nm_area
    nm_client
    nm_line
    planned_downtime
    planned_duration_percent
    po_net_production
    prev1_shift_name
    prev1_target
    prev1shift_netprod
    prev2_shift_name
    prev2_target
    prev2shift_netprod
    production_programmed
    proportional_target
    speed
    status
    status_24h
    status_time
    stopped_time
    unplanned_duration
    unplanned_duration_perc
  }
  h_piot_get_mission_control_area_uns_2(args: {in_id_enterprise: $in_id_enterprise, in_id_areas: $in_ids_areas, in_id_sites: $in_ids_sites}) {
    gross_production
    id_area
    net_production
    nm_area
    oee
    projected_production
    scrap
    target
    vl_shift
  }
}
  `;

