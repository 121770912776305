import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Grid, TextField, Select, 
  InputLabel, InputAdornment, FormHelperText, OutlinedInput, IconButton, FormControl,
   MenuItem, 
   DialogTitle} from '@mui/material';
import { Typography } from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';

import { getLanguage } from '../../../../../../utils/utils';
import { useSnackbar } from 'notistack';
import { VariablesContext } from '../../../../../../Context/VariablesContext';
import api from '../../../../../../services/api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ModalEditRole({ isOpen, pages, params, update, handleClose}) {
  const language = getLanguage();
  const [roleName, setRoleName] = useState('');
  const [availablePages, setAvailablePages] = useState([]);
  const [lines, setLines] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { lineOptions } = useContext(VariablesContext);
  
  useEffect(() => {
    const permissionLines = params?.permissions?.desktop?.line?.map((item) => item);
    setLines(permissionLines);
    setRoleName(params?.nm_user_role);
    const pagesFiltered = pages?.map((item) => {
      let id = item?.id_page;
      let findPage = params?.permissions?.desktop?.screen?.find((item) => item?.code === id);

      let permissionType = 'disabled'

      if (findPage) {
        if (findPage?.write) {
          permissionType = 'edit'
        } else {
          permissionType = 'view'
        }
      }

      return {
        id_page: id,
        nm_page: item?.nm_page,
        permissionType
      }

    } );
    setAvailablePages(pagesFiltered)
  }, [params])

  const handleNameChange = (event) => {
    setRoleName(event.target.value);
  }

  const handlePermissionChange = (event, page) => {
    const permissionType = event.target.value;
    let permissionIndex = availablePages.findIndex((item) => item.id_page === page?.id_page);
    let permissionCopy = [...availablePages];

    permissionCopy[permissionIndex].permissionType = permissionType;
    setAvailablePages(permissionCopy);

  }

  const handleSubmit = async () => {
    if (roleName === '') {
      enqueueSnackbar(`${language?.role_name_empty || "Role's Name cannot be empty" }`, { variant: 'warning' });
      return;
    }
    if (lines.length === 0) {
      enqueueSnackbar(`${language?.select_at_least_1_line || "Select at least one line"}`, { variant: 'warning' });
      return;
    }
    const screens = [];
    availablePages?.map((item) => {
      switch (item?.permissionType) {
        case 'view':
            screens.push({
              code: item?.id_page,
              write: false
            });
          break;

          case 'edit':
            screens.push({
              code: item?.id_page,
              write: true
            });
          break;
      
        default:
          break;
      }
    })

    const role_info = {
      role_name: roleName,
      id_user_role: params?.id,
      permissions: {
        desktop: {
          line: lines,
          screen: screens
        }
      }
    }
    try {
      
     const response = await api.put('/api/admin/user-roles', role_info);
      if (response) {
        enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
        update();
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
    
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLines(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  


  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'}>

      <DialogTitle>
        <Typography variant="h4" style={{ color: '#4D4D4D' }}> {language?.edit_role || "Edit Role"} </Typography>
      </DialogTitle>

      <DialogContent sx={{ pr: 3, pl: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

        <Grid container spacing={2}>

          <Grid item xs={12}>

            <TextField
              sx={{ width: '100%', mt: '5px', background: '#fff' }}
              type="text"
              id="create-user-name" 
              label={language?.role_name || "Role's Name"} variant="outlined"
              value={roleName}
              onChange={(e) => handleNameChange(e)}
            />

          </Grid>

          <Grid item xs={12}>

            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="create-user-role-label">{language?.lines || 'Lines'}</InputLabel>
              <Select
                labelId="create-user-role-label"
                id="create-user-role-select"
                multiple
                value={lines}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={language?.lines || 'Lines'} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const lineName = lineOptions?.find((item) => item?.id_equipment === value);
                      return <Chip key={value} label={lineName?.nm_equipment} />
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {lineOptions?.map((item) => (
                  <MenuItem
                    key={item?.id_equipment}
                    value={item?.id_equipment}
                  >
                    {item?.nm_equipment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={12}>
            <TableContainer sx={{ height: '170px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'> {language?.page || 'Page'}</TableCell>
                    <TableCell align='right'>{language?.permission || 'Permission'}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    availablePages?.map((item) => (
                      <TableRow key={item?.id_page}>
                        <TableCell align='left'>{item?.nm_page}</TableCell>
                        <TableCell align='right'>
                          <Select
                            variant='standard'
                            id={`select-screen-permission-${item?.id_page}`}
                            defaultValue={item?.permissionType}
                            onChange={(e) => handlePermissionChange(e, item)}
                          >
                            <MenuItem value={'view'}> {language?.view || 'View'}</MenuItem>
                            <MenuItem value={'edit'}> {language?.edit || 'Edit'}</MenuItem>
                            <MenuItem value={'disabled'}> {language?.disabled || 'Disabled'}</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>

              </Table>
            </TableContainer>
            

          </Grid>

        </Grid>

      </DialogContent>

      <DialogActions sx={{ p: 3, borderTop: '1px solid #E3E2E7' }}>
        <Button
          sx={{ width: 132, height: 42, textTransform: 'capitalize', borderRadius: 2, border: '2px solid #407CCC', color: '#000', background: '#FFF', '&:hover': { background: '#EAEAED' } }}
          variant="contained" disableElevation onClick={handleClose}
          size="small"
        >
          <Typography variant='body1'> {language?.cancel || 'Cancel'} </Typography>
        </Button>

        <Button sx={{ borderRadius: 2, textTransform: 'capitalize', width: 132, height: 42 }}
          size="small" variant="contained" onClick={() => handleSubmit()}
        >
          <Typography variant='body1'> {language?.edit || 'Edit'} </Typography>
        </Button>

      </DialogActions>


    </Dialog>
  );
}