import React from 'react';

import { getLanguage, roundNumber } from '../../../utils/utils';
import { Box, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { scrapCalc } from '../../../configurations/scrap';

function DetailPanelContent({ row: rowProp }) {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');

  function translateDuration(duration) {
    let translate = duration;
    let day = language?.day ?? 'day';
    let days = language?.days ?? 'days';
    if (translate?.includes('days')) {
      translate = translate.replace('days', days);
    }
    if (translate?.includes('day')) {
      translate = translate.replace('day', day);
    }
    return translate;
  }
  
  return(
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', background: '#F1F3F5'}}>
      <Box sx={{ ml: '100px', width: 900, display: 'flex', flexDirection: 'column', border: '1px solid #4F4F4F', borderRadius: '4px'}}>
        <Typography sx={{ ml: '16px', mt: '10px', color: '#4F4F4F', fontSize: '16px', fontWeight: 700, textTransform: 'uppercase' }}> {language?.job || 'PO'} {rowProp?.id_order}  </Typography>
        <TableContainer sx={{ mt: '5px', mb: '15px', ml: '10px' }}>
        <Table sx={{ width: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">{language?.start || 'Start'}</TableCell>
              <TableCell align="left">{language?.end || 'End'}</TableCell>
              <TableCell align="left">{language?.duration || 'Duration'}</TableCell>
              <TableCell align="left">{language?.produced || 'Produced'}</TableCell>
              <TableCell align="left">{language?.scrap || 'Scrap'}</TableCell>
              <TableCell> {language?.line || 'Line'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowProp?.runtimes?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.ts_start}</TableCell>
                <TableCell align="left">{row.ts_end}</TableCell>
                <TableCell align="left">{translateDuration(row.duration)}</TableCell>
                <TableCell align="left">{roundNumber(row.production_final)}</TableCell>
                <TableCell align="left">{scrapCalc( scrapType, row?.gross, row?.net )}%</TableCell>
                <TableCell align="left">{row.nm_equipment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    </Box>
  )
}

export default DetailPanelContent;