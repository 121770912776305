import React from 'react';
import { Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';

export default function Segurança() {

  return (
    <Paper sx={{ m: 1 }}>
      <Grid container spacing={1} sx={{ m: 1, pb: 2 }}>
        <Grid item xs={12} >
          <Typography variant="h4"> Segurança </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex' }} >
          <Typography variant="body1" style={{ color: '#828282' }}> TEMA DDS </Typography>
          &nbsp;
          &nbsp;
          <Typography variant="body2"> Segurança </Typography>
        </Grid>
      </Grid >
    </Paper >
  );
}
