import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import Chart from './components/Chart';
import { useLazyQuery } from '@apollo/client';
import { TOTAL_PRODUCTION_EQUIPMENT, OEE_TOTAL, GET_TARGETS } from './queries';
import './style.css';

import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage, userLocale } from '../../utils/utils';
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilters from '../../components/EntityFilters';
import BarChart from './components/BarChart';
import LoadingTotalProduction from './components/LoadingTotalProduction';
import TableMobile from './components/TableMobile';
import GrainSelector from '../../components/GrainSelector';

import { Grid, Box, } from '@mui/material';
import { Typography, } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery';
import TypeOfViewSelector from '../../components/TypeOfViewSelector';
import SelectASite from '../../components/SelectASite';

function TotalProductionContainer() {
  const locale = userLocale();
  moment.locale(locale.tag);
  let hideShift = false;
  const { ts_start, ts_end, enterprise, site, area, line, filterShift, dateRange, grain, filterSite, filterArea, filterLine, filterTeam, typeOfView } = useContext(VariablesContext);
  const [isShift, setShift] = useState(false);
  const [isTeam, setTeam] = useState(false);
  const [getTotalProductionEquipment, { data, loading }] = useLazyQuery(TOTAL_PRODUCTION_EQUIPMENT, { fetchPolicy: 'no-cache' });
  const [getOee, { data: oeeData, loading: oeeLoad }] = useLazyQuery(OEE_TOTAL, { fetchPolicy: 'no-cache' });
  const [getTarget, { data:targetData, loading: targetLoading }] = useLazyQuery(GET_TARGETS, { fetchPolicy: 'no-cache' });

  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });

  const language = getLanguage();

  useEffect(() => {
    //let grain = 'DAY';
    let start = ts_start;
    let end = ts_end;
    let shifts = filterShift?.join();
    let teams = filterTeam?.join();
    let nav_level = 'EQUIPMENT';
    if (shifts) {
      setShift(true);
    } else {
      shifts = '';
      setShift(false);
    }

    if (teams) {
      setTeam(true);
    } else {
      teams = '';
      setTeam(false);
    }

  /*   if (dateRange === 'today' || dateRange === 'day') {
      grain = 'HOUR';
    } */
    let equipment = null;
    if (line !== 'null') equipment = line;

    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];

    if (site) {
      if (area) {
        if (line) {
          nav_level = 'EQUIPMENT';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          nav_level = 'AREA';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        nav_level = 'SITE';
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    } else {
      nav_level = 'SITE';
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

    getTotalProductionEquipment({
      variables: {
        id_enterprise: enterprise,
        grain: grain,
        shifts: `${shifts}`,
        teams: `{${teams}}`,
        id_site: `{${in_id_sites.toString()}}`,
        id_area: `{${in_id_areas.toString()}}`,
        id_equipment: `{${in_id_equipments.toString()}}`,
        ts_start: start,
        ts_end: end,
        partitionby: (dateRange === 'day' || dateRange === 'today') ? 'GENERAL': typeOfView,
      }
    });

    getOee({
      variables: {
        in_id_enterprise: enterprise,
        in_ids_shifts: `${shifts}`,
        in_id_sites: `{${in_id_sites.toString()}}`,
        in_id_equipments: `{${in_id_equipments.toString()}}`,
        in_id_areas: `{${in_id_areas.toString()}}`,
        in_begin_time: start,
        in_end_time: end,
        is_shift_filtered: false,
        time_grain: grain,
        nav_level,
      }
    });

    getTarget({
      variables: {
        id_enterprise: enterprise,
        in_id_shifts: `${shifts}`,
        in_id_teams: `{${teams}}`,
        in_id_sites: `{${in_id_sites.toString()}}`,
        in_id_equipments: `{${in_id_equipments.toString()}}`,
        in_id_areas: `{${in_id_areas.toString()}}`,
        ts_start: start,
        ts_end: end,
        time_grain: grain,
        nav_level,
        group: 'SHIFTS'
      }
    })

  }, [enterprise, site, area, line, ts_end, ts_start, filterShift, grain, filterSite, filterArea, filterLine, typeOfView, filterTeam]);

  let scrapData = [];
  data?.h_piot_total_production_teams_2?.forEach((item, index) => {
    if (item.net_production_acc !== null) {
      scrapData = data.h_piot_total_production_teams_2[index]
    }
  });

  if (dateRange === 'day' || dateRange === 'today') hideShift = true;

  return (
    <Box sx={{ m: { xs: 0, sm: 0, md: 1, xl: 2 } }}>
      <Breadcrumb />

      <Box sx={{
        display: 'flex', justifyContent: isMobile ? 'space-evenly' : 'space-between',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignItems: 'center',
      }}>
        <Box sx={{ m: 2, ml: 4, display: 'flex' }}>
          <Typography variant="h2"> {language?.total_production || 'Total Production'} </Typography>
          {site ? <EntityFilters hideShift={hideShift} hideSector={true} /> : <></>}
        </Box>
        <Box>
          {!isMobile && site && !hideShift && <TypeOfViewSelector />}
          {!isMobile && <GrainSelector />}
        </Box>
      </Box>

      <Box sx={{ m: { xs: 0, sm: 2 }, }}>
        {
          site ?
            (loading || targetLoading)
              ? <LoadingTotalProduction />
              : <>
                <Chart
                  width={isMobile ? 350 : 1700}
                  height={isMobile ? 290 : 400}
                  dateRange={dateRange}
                  data={data?.h_piot_total_production_teams_2}
                  target={targetData?.h_piot_get_targets}
                />

                <Box sx={{ mt: 15 }} />

                {isMobile &&
                  <>
                    <TableMobile data={data?.h_piot_total_production_teams_2} />
                    <Box sx={{ mt: 5 }} />
                  </>}

                {(dateRange !== 'custom') &&
                  <Grid container >
                    <Grid sx={{ justifyContent: 'center', alignItems: 'center', mx: { xs: 2, sm: 0 } }} item xs={12} sm={6}>
                      <BarChart data={scrapData} title={language?.quality || 'Quality'}
                        legend={[
                          {
                            title: `${language?.good_parts || 'Good Parts'}`,
                            color: '#407CCC'
                          }, {
                            title: `${language?.scrap || 'Scrap'}`,
                            color: '#C13939'
                          },]}
                      />
                    </Grid>

                    <Grid sx={{ justifyContent: 'center', alignItems: 'center', mx: { xs: 2, sm: 0 } }} item xs={12} sm={6}>
                      <BarChart data={oeeData?.h_piot_oee_score_full_3} loading={oeeLoad} type='oee' title="OEE"
                        legend={[{ title: `${language?.oee_total || 'OEE Total'}`, color: '#407CCC' }]}
                      />
                    </Grid>
                  </Grid>
                }
              </>
            : <SelectASite />
        }
      </Box>
    </Box>
  );
}

export default TotalProductionContainer;