import React, { useContext } from 'react';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput, 
}  from '@mui/material';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function SiteFilter() {
  const { siteOptions, filterSite, setFilterSite } = useContext(VariablesContext);
  const language = getLanguage();

  const handleChange = (event) => {
    const arrayValues = event?.target?.value?.map(x => x);
    setFilterSite(arrayValues)
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150, }}>
        <InputLabel sx={{  m: filterSite?.length == 0 ? -1 : 0 }}>
          {filterSite?.length == 0 ? <FilterAltOutlinedIcon /> : "" }
          { language?.filter_site || 'Filter Site' } 
        </InputLabel>
        <Select
          multiple 
          value={ filterSite }
          onChange={handleChange}
          input={
            <OutlinedInput
              sx={{ maxHeight:40, }} 
              label={ language?.filter_site || 'Filter Site' }/>
          }
          renderValue={
            (selected) => (
              siteOptions?.length +" "+ language?.available || 'avaliable'
              
              // selected.length == 1 
              // ? siteOptions.filter(sites => sites.id_site == selected)?.[0]?.nm_site
              // : selected.length + " " + language?.selected || 'selected'
            )
          }>

          {siteOptions?.length > 0 
          ? siteOptions?.map((site, index) => (
            <MenuItem key={site.id_site} value={site.id_site}>
              <Checkbox sx={{m:-1, mr:.3}} checked={filterSite.includes(site.id_site)} />
              <Typography variant="body2"> {site.nm_site } </Typography>
            </MenuItem>
          ))
          : language?.no_sites_available || 'No sites available' }
          
        </Select>
      </FormControl>
    </>
  );
}
