import React from 'react';

// In devellopment
import OverviewSuzano from './pages/OverviewSuzano';

// Production
import Home from './pages/Home';
import Downtimes from './pages/Downtimes';
import OEE from './pages/OEE/index';
import ProductionOrders from './pages/ProductionOrders';
import ReportsPowerBI from './pages/ReportsPowerBi';
import TotalProductionUNS from './pages/TotalProductionUNS';
import MissionControlMui from './pages/_MissionControl';
import OverviewGranado from './pages/OverviewGranado';
import Overview from './pages/Overview';
import ProductionFlow from './pages/ProductionFlow';
import NotFound from './pages/NotFound';
import Settings from './pages/Settings';
import SinglePeriod from './pages/SinglePeriod';
import ScrapPeriod from './pages/ScrapPeriod';
import MachineSpeed from './pages/MachineSpeed';

// Custom pages
import MCViniCamargo from './pages/CustomPages/C35/C35MissionControl/pageMoved.js'

import Targets from './pages/Settings/Pages/Targets';
import UsersAndPermissions from './pages/Settings/Pages/UsersAndPermissions';
import ProductionOrdersSettings from './pages/Settings/Pages/ProductionOrders';
import DowntimeReasonsSettings from './pages/Settings/Pages/DowntimeReasons2';
import PageModel from './components/PageModel'
import Login from './pages/Login';
import AuthGuard from './components/AuthGuard';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PdfSuzano from './pages/PdfSuzano';

const routes = [
  { path: 'login', element: <Login /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'reset-password/*', element: <ResetPassword /> },
  { path: 'public-report/:dataset/:reportId', element: <ReportsPowerBI /> },
  { path: 'suzano', element: <PdfSuzano /> },
  {
    path: '/',
    element: (
      <AuthGuard>
        <PageModel />
      </AuthGuard>
    ),
    children: [
      { path: 'home', element: <Home /> },
      { path: 'MissionControl', element: <MissionControlMui /> },
      { path: 'mission-control', element: <MissionControlMui /> },
      { path: 'NewMissionControl', element: <MissionControlMui /> },
      { path: 'MissionControl35', element: <MCViniCamargo /> },
      { path: 'downtimes', element: <Downtimes /> },
      { path: 'OEE', element: <OEE /> },
      { path: 'production-orders', element: <ProductionOrders /> },
      { path: 'total-production', element: <TotalProductionUNS /> },
      { path: 'single-period', element: <SinglePeriod /> },
      { path: 'scrap-period', element: <ScrapPeriod /> },
      { path: 'machine-speed', element: <MachineSpeed /> },
      { path: 'report', element: <ReportsPowerBI /> },
      { path: 'production-flow', element: <ProductionFlow /> },

      { path: 'overview/v1/:lineId', element: <Overview /> },
      { path: 'overview/v2/:lineId', element: <OverviewGranado /> },
      { path: 'Overview/:lineId', element: <Overview /> },
      { path: 'OverviewGranado/:lineId', element: <OverviewGranado /> },

      { path: 'overviewsuzano', element: <OverviewSuzano /> },

      // {/* //PowerBi */}
      { path: 'report/:dataset/:reportId', element: <ReportsPowerBI /> },
      { path: 'reports', element: <ReportsPowerBI /> },
      { path: '*', element: <NotFound /> },
    ]
  },
  {
    path: '/settings',
    element: (
      <AuthGuard>
        <Settings />
      </AuthGuard>
    ),
    children: [
      { path: 'targets', element: <Targets /> },
      { path: 'users-and-permissions', element: <UsersAndPermissions /> },
      { path: 'production-orders', element: <ProductionOrdersSettings /> },
      { path: 'downtime-reasons', element: <DowntimeReasonsSettings /> },
    ]
  },
];

export default routes;
