import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import FormControl from '@mui/material/FormControl';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
//import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import InputLabel from '@mui/material/InputLabel';
//import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { VariablesContext } from '../../Context/VariablesContext';
import { useLazyQuery } from '@apollo/client';
import { GET_JOBS } from './queries';
import LightTooltip from '../LightTooltip';


const FilterButton = styled.button`
  outline: none;
  width: 47px;
  height: 33px;
  background: ${(props) => props.active ? '#407CCC' : '#EAEAED'};
  color: ${(props) => props.active ? '#ffff' : '#828282'};
  border-radius: 6px;
  border: none;
  margin: 10px;
  cursor: pointer;
`;

function Filters({ update, language }) {
  // event type 2 manual
  // 1 normal
  // 3 low speed
  // 4 job start
  // 5 end job
  // 6 shift change 
  const [filter_event_types, setFilter] = useState([1, 2, 3, 4, 5, 6])
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [jobSelected, setJobSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [lineSelected, setLineSelected] = useState(null);
  const [areaDisabled, setAreaDisabled] = useState(true);
  const [lineDisabled, setLineDisabled] = useState(true);
  const [jobDisabled, setJobDisabled] = useState(true);
  const { siteOptions, areaOptions, lineOptions, enterprise, ts_start, ts_end  } = useContext(VariablesContext);

  useEffect(() => {
    update({ siteSelected, areaSelected, lineSelected, enterprise, filter_event_types, job: jobSelected })
  }, [filter_event_types, siteSelected, areaSelected, lineSelected, jobSelected, enterprise, ts_start, ts_end ]);

  const [getJobs] = useLazyQuery(GET_JOBS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const jobOptions = data.production_orders?.map((item) => (
        {
          value: item.id_production_order,
          label: item.id_order
        }
      ));
      setJobs(jobOptions);
    }
  });


  useEffect(() => {
    const siteOpt = siteOptions?.map((item) => ({
      value: item?.id_site,
      label: item?.nm_site
    }));
    setSites(siteOpt);
  }, [siteOptions]);

  const handleSiteChange = (event) => {
    const availableAreas = areaOptions?.filter((item) => item?.id_site === event?.target?.value);
    if (availableAreas) {
      const areaOpt = availableAreas.map((item) => ({
        value: item?.id_area,
        label: item?.nm_area
      }));
      setAreaDisabled(false);
      setLineDisabled(true);
      setAreaSelected(null);
      setLineSelected(null);
      setJobSelected(null);
      setSiteSelected(event?.target?.value);
      setAreas(areaOpt);
    }
  };

  const handleAreaChange = (event) => {
    const availableLines = lineOptions?.filter((item) => item?.id_area === event?.target?.value);
    if (availableLines) {
      const lineOpt = availableLines?.map((item) => ({
        value: item?.id_equipment,
        label: item?.nm_equipment
      }));
      setLineDisabled(false);
      setAreaSelected(event?.target?.value);
      setLineSelected(null);
      setJobSelected(null);
      setLines(lineOpt);
    }
  }

  const handleLineChange = (event) => {
    setJobDisabled(false);
    setLineSelected(event?.target?.value);
    setJobSelected(null);
    getJobs({ variables: { line: event?.target?.value } });
  }

  const handleJobChange = (event) => {
    setJobSelected(event?.target?.value);
  }

  const updateFilter = (newItem) => {
    if (filter_event_types.indexOf(newItem) < 0) {
      setFilter([...filter_event_types, newItem]);
    } else {
      setFilter(filter_event_types.filter((element) => { return element !== newItem }));
    }
  }


  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ textAlign: 'start' }} id="site-select-label">{language?.site || 'Site'}</InputLabel>
            <Select
              value={siteSelected}
              label={language?.site || 'Site'}
              labelId="site-select-label"
              onChange={(e) => handleSiteChange(e)}
            >
              {sites?.map((item) => {
                return <MenuItem value={item?.value}>{item?.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ textAlign: 'start' }} id="area-select-label">{language?.area || 'Area'}</InputLabel>
            <Select
              value={areaSelected}
              label={language?.area || 'Area'}
              labelId="area-select-label"
              disabled={areaDisabled}
              onChange={(e) => handleAreaChange(e)}
            >
              {areas?.map((item) => {
                return <MenuItem value={item?.value}>{item?.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ textAlign: 'start' }} id="line-select-label">{language?.line || 'Line'}</InputLabel>
            <Select
              value={lineSelected}
              label={language?.line || 'Line'}
              labelId="line-select-label"
              disabled={lineDisabled}
              onChange={(e) => handleLineChange(e)}
            >
              {lines?.map((item) => {
                return <MenuItem value={item?.value}>{item?.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ textAlign: 'start' }} id="po-select-label">{language?.job || 'Production order'}</InputLabel>
            <Select
              value={jobSelected}
              label={language?.job || 'Production order'}
              labelId="po-select-label"
              disabled={jobDisabled}
              onChange={(e) => handleJobChange(e)}
            >
              {jobs?.map((item) => {
                return <MenuItem value={item?.value}>{item?.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, flexWrap: 'wrap' }}>
        <LightTooltip title={`${language?.po_started || 'Job Started'}`} placement='top'>
          <FilterButton onClick={() => updateFilter(4)} active={filter_event_types.indexOf(4) >= 0} > <PlayCircleOutlineIcon /> </FilterButton>
        </LightTooltip>

        <LightTooltip title={`${language?.po_finished || 'Job Finished'}`} placement="top">
          <FilterButton onClick={() => updateFilter(5)} active={filter_event_types.indexOf(5) >= 0}> <CheckCircleOutlineIcon /> </FilterButton>
        </LightTooltip>

        {/* <LightTooltip title="Possible issue on sensor" placement="top">
            <FilterButton onClick={() => updateFilter('issue')} active={filter_event_types.indexOf('issue')>=0}> <ErrorOutlineIcon /> </FilterButton>
          </LightTooltip> */}

        <LightTooltip title={`${language?.shift_changed || 'Job Changed'}`} placement="top">
          <FilterButton onClick={() => updateFilter(6)} active={filter_event_types.indexOf(6) >= 0}> <ChangeCircleOutlinedIcon /> </FilterButton>
        </LightTooltip>

        <LightTooltip title={`${language?.low_speed || 'Low Speed'}`} placement="top">
          <FilterButton onClick={() => updateFilter(3)} active={filter_event_types.indexOf(3) >= 0}> <SpeedOutlinedIcon /> </FilterButton>
        </LightTooltip>

        {/* <LightTooltip title="High scrap" placement="top">
            <FilterButton onClick={() => updateFilter('high_scrap')} active={filter_event_types.indexOf('high_scrap')>=0}> <DeleteOutlinedIcon /> </FilterButton>  
          </LightTooltip> */}


        <LightTooltip title={`${language?.downtime || 'Downtime'}`} placement="top">
          <FilterButton onClick={() => { updateFilter(1); }} active={filter_event_types.indexOf(1) >= 0}> <QueryBuilderOutlinedIcon /> </FilterButton>
        </LightTooltip>

        <LightTooltip title={`${language?.manual_event || 'Manual Event'}`} >
          <FilterButton onClick={() => updateFilter(2)} active={filter_event_types.indexOf(2) >= 0}> <ReportOutlinedIcon /> </FilterButton>
        </LightTooltip>

      </Box>

    </Box>
  );
}

export default Filters;