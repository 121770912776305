import React, { useContext, useState, useEffect } from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import { VariablesContext } from '../../../../../Context/VariablesContext'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import api from '../../../../../services/api';

import { Box, IconButton, } from '@mui/material';
import { Typography } from '@material-ui/core'
import { getLanguage, JSONToCSVConvertor } from '../../../../../utils/utils';
import { useSnackbar } from 'notistack';


function CustomToolbar({ selectedLine, setSelectedLine, toggleCsvUploadModal }) {
  const { lineOptions, sectorOptions } = useContext(VariablesContext)
  const language = getLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const handleDowntimeDownload = (selectedLine) => {
    if (selectedLine) {
      api.get(`/api/admin/downtime-reasons/download/${selectedLine}`).then((response) => {
        JSONToCSVConvertor(response.data, null, 'downtime-reasons', true);
      });
    } else {
      enqueueSnackbar(`${language?.line_must_be_selected || 'Line must be selected!'}`, { variant: 'error' });
    }
  };

  return (
    <GridToolbarContainer >
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mb: '24px' }}>
        <Box sx={{ width: 400, display: 'flex', alignItems: 'center' }}>
          <Typography variant='h3'> {language?.downtime_reasons || 'Downtime Reasons'} </Typography>
        </Box>

        <Box sx={{ mt: { xs: '20px', sm: 0 }, display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'center' }, width: { xs: '100%', sm: 'auto' } }}>
          <Box sx={{ ml: '34px', display: 'flex', justifyContent: 'center' }}>
            <IconButton aria-label="download" sx={{ mr: 2, height: '40px' }}>
              <DownloadOutlinedIcon fontSize='large' onClick={() => handleDowntimeDownload(selectedLine)} />
            </IconButton>
          </Box>

          <Box>
            <GridToolbarColumnsButton sx={{ bgcolor: '#EAEAED', width: '140px', height: '40px', borderRadius: '8px', color: '#333333' }} />
          </Box>

          <Box sx={{ ml: '34px', height: '40px', display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ px: 4 }} onClick={() => toggleCsvUploadModal()} startIcon={<AddCircleOutline />} variant="contained">{`${language?.upload_csv || 'Upload CSV'}`}</Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <FormControl size="large">
          <InputLabel id="demo-simple-select-label"> {language?.line || "Equipment"} </InputLabel>
          <Select
            sx={{ minWidth: '260px', }}
            autoWidth={true}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={selectedLine}
            value={selectedLine}
            label="Equipment"
            onChange={(event) => setSelectedLine(event.target.value)}
          >
            {sectorOptions?.filter((item) => item?.require_downtime_reason === true)?.map((line) => <MenuItem value={line.id_equipment}>{line.nm_equipment}</MenuItem>)}
            {lineOptions?.filter((item) => item?.require_downtime_reason === true)?.map((line) => <MenuItem value={line.id_equipment}>{line.nm_equipment}</MenuItem>)}
          </Select>
        </FormControl>
      </Box>

    </GridToolbarContainer>
  );
}

export default CustomToolbar;