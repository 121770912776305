import { split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";
import { auth } from '../firebase';

function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

const httpLink = new HttpLink({
  uri: `https://gqlpiot.packiot.com/v1/graphql`
  // uri: 'https://strong-cicada-30.hasura.app/v1/graphql'
});

const retryLink = new RetryLink();

const wsLink = new WebSocketLink({
  uri: `ws://gqlpiot.packiot.com/v1/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      let token = localStorage.getItem('@packiot4:token')
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

// const request = async (operation) => {
//   // let token = await auth.currentUser.getIdToken();

//   let token = 
//   //  localStorage.getItem('@packiot4:token')

//   operation.setContext({
//     headers: {
//       authorization: token ? `Bearer ${token}` : ''
//     }
//   });
// };

const authMiddleware = new ApolloLink(async (operation, forward) => {
  // add the authorization to the headers
  if (!auth?.currentUser?.getIdToken()) await delay(2500);
  let token = await auth?.currentUser?.getIdToken().then((token) => token);
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token || localStorage.getItem('@packiot4:token')}`
      }
    }));
  }
  return forward(operation);
})
// const requestLink = new ApolloLink((operation, forward) =>
//   new Observable((observer) => {
//     let handle;
//     Promise.resolve(operation)
//     .then((oper) => request(oper))
//     .then(() => {
//       handle = forward(operation).subscribe({
//         next: observer.next.bind(observer),
//         error: observer.error.bind(observer),
//         complete: observer.complete.bind(observer),
//       });
//     })
//     .catch(observer.error.bind(observer));

//     return () => {
//       if (handle) handle.unsubscribe();
//     };
//   })
// );

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'ignore',
  },
  query: {
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  link: concat(
    authMiddleware,
    httpLink,
    retryLink,
    errorLink,
  ),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});
