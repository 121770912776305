import React from 'react';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Card, Box, Typography as Typography2 } from '@mui/material';
import { Typography } from '@material-ui/core';
import moment from 'moment';

export default function InfoUpWithOP({ data, equipment, enterprise }) {
  const size = 1100
  const language = getLanguage();
  const dataJob = data?.uns_equipment_current_job?.[0]

  let tempoFalta = data?.uns_equipment_current_job?.[0]?.current_expected_time;

  const colorStatus = () => {
    let color = "#407CCC";
    data?.uns_equipment_current_job?.[0]?.current_expected_time <= 7200 ? color = "#EB5757" : color = "#407CCC";
    return color;
  }
  const ordem = data?.uns_equipment_current_job?.[0]?.target
  const prod = data?.uns_equipment_current_job?.[0]?.net_production

  const nameStatus = (tempoFalta) => {
    if (((prod * 100) / ordem) > 100) {
      return (language?.overproduction || "OVERPRODUCTION");
    } else if (tempoFalta <= 7200) {
      return (language?.prepar_for_setup || "PREPARE FOR SETUP");
    } else {
      return "";
    }
  }

  const timeForm = (num) => {
    const dia = num / 86400;
    const restoDia = num % 86400;

    const hora = restoDia / 3600;
    const restoHora = restoDia % 3600;

    const minutos = restoHora / 60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "")
    )
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, }}>

      <Card sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: 190, width: '15%' }} elevation={0}>
        <Typography variant="h2" >
          {equipment?.equipments?.[0]?.nm_equipment}
        </Typography>
        {
          dataJob?.setup_end_time && <>
            <Typography variant="h3" style={{ color: '#407CCC', margin: 'auto' }} >
              {language?.last_setup || 'last_setup'}
            </Typography>
            <Typography variant="h3" style={{ margin: "auto" }}>
              {timeForm(moment(dataJob?.setup_end_time).diff(moment(dataJob?.setup_begin_time), "seconds"))}
            </Typography>
          </>
        }
      </Card>

      <Card sx={{ display: 'flex', gap: 2, width: '85%', height: 190 }} elevation={0}>

        <Box sx={{ ml: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 1, mt: 1.5 }}>
            <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, mt: 1 }} >
              {language?.job || 'Job'}
            </Typography2>
            <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 40, color: '#407CCC' }} >
              {data?.uns_equipment_current_job?.[0]?.id_order}
            </Typography2>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mt: 3.5, mb: -2 }}>
            <Box sx={{ ml: 1 }}>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 24 }} >
                {language?.client || 'Client'}
              </Typography2>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 40, color: '#407CCC' }} >
                {data?.uns_equipment_current_job?.[0]?.nm_client}
              </Typography2>
            </Box>

            <Box sx={{ color: 'black', height: 70, width: '1px', backgroundColor: '#acaeb0', ml: 1, mr: 1 }} />

            <Box sx={{ mr: 7 }}>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 24 }} >
                {language?.product || 'Product'}
              </Typography2>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 36, color: '#407CCC', mt: .5 }} >
                {data?.uns_equipment_current_job?.[0]?.nm_product?.length >= 10 ?
                  data?.uns_equipment_current_job?.[0]?.nm_product?.slice(0, 10) + "." :
                  data?.uns_equipment_current_job?.[0]?.nm_product}
              </Typography2>
            </Box>
          </Box>
        </Box>

        <Box >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, m: 1, }}>
            <Box>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 24 }} >
                {language?.order_size || 'Order Size'}
              </Typography2>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 48, color: '#407CCC' }} >
                {
                  enterprise != 37 ?
                    roundNumber(data?.uns_equipment_current_job?.[0]?.target)
                    : data?.uns_equipment_current_job?.[0]?.target
                }
              </Typography2>
            </Box>
            <Box>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 24 }} >
                {language?.produced || 'Produced'}
              </Typography2>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 48, color: '#407CCC' }} >
                {
                  enterprise != 37 ?
                    roundNumber(data?.uns_equipment_current_job?.[0]?.net_production)
                    : data?.uns_equipment_current_job?.[0]?.net_production
                } {" | "}
                {(data?.uns_equipment_current_job?.[0]?.net_production / data?.uns_equipment_current_job?.[0]?.target * 100).toFixed(1)}
                %
              </Typography2>
            </Box>
            <Box>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 24 }} >
                {language?.remaining || 'Remaining'}
              </Typography2>
              <Typography2 variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 48, color: colorStatus }} >
                {
                  enterprise != 37 ?
                    roundNumber((data?.uns_equipment_current_job?.[0]?.target - data?.uns_equipment_current_job?.[0]?.net_production).toFixed())
                    : (data?.uns_equipment_current_job?.[0]?.target - data?.uns_equipment_current_job?.[0]?.net_production).toFixed()
                }
                <AccessTimeOutlinedIcon sx={{ mx: 1 }} />
                {timeForm(data?.uns_equipment_current_job?.[0]?.current_expected_time)}
              </Typography2>
            </Box>
          </Box>

          <Box sx={{ maxWidth: 1100, minHeight: 40, display: 'flex', }}>
            {/* já produzido */}
            <Typography2 sx={{
              width: ((prod * 100) / ordem) * size,
              height: 42, fontSize: 24,
              backgroundColor: colorStatus,
              borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
              borderTopRightRadius: Math.round(((prod * 100) / ordem)) >= 100 ? 5 : 0,
              borderBottomRightRadius: Math.round(((prod * 100) / ordem)) >= 100 ? 5 : 0,
              display: 'flex', justifyContent: 'center',
            }} variant="h6" color="#FFFFFF" >
              {nameStatus(data?.uns_equipment_current_job?.[0]?.current_expected_time)}
            </Typography2>

            {/* faltando produzir */}
            <Typography2
              sx={{
                width: Math.round((prod * 100) / ordem) <= 100 ? (100 - ((prod * 100) / ordem)) * size : 0, height: 42, opacity: 0.6,
                backgroundColor: colorStatus,
                borderBottomRightRadius: 5, borderTopRightRadius: 5,
              }} variant="h6" color={colorStatus} opacity={0.6} > {" "} </Typography2>
          </Box>

        </Box>

      </Card>

    </Box>
  );
}
