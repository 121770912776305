import React, { useContext } from 'react';
import { numbertoString, secondsToDurationString } from '../../../utils/utils';
import { getLanguage } from '../../../utils/utils';
import {
  CardContent, Grid, Box, Divider, Typography, Card,
} from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from "@mui/material/styles";
import { ReactComponent as Q } from '../../../assets/Q.svg'
import { ReactComponent as P } from '../../../assets/P.svg'
import { ReactComponent as D } from '../../../assets/D.svg'
import { VariablesContext } from '../../../Context/VariablesContext';


export default function OeeScore({ equipment }) {
  const language = getLanguage();
  const size = 200000;

  const { typeOfView } = useContext(VariablesContext);

  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });
  // let size = isMobile === true ? 'row' : 'column';

  const mainColorStatus = (number) => {
    let color = 'warning.main';

    if (number >= 85) {
      color = '#318f29';
      // color = 'success.main';
    } else if (number >= 70) {
      color = '#ecbc13';
      // color = 'attention_2.main';
    } else if (number >= 50) {
      color = '#FD9729';
      // color = 'attention.main';
    } else if (number < 50) {
      color = '#C13939';
      // color = 'warning.main';
    }

    return color;
  }
  const secondColorStatus = (number) => {
    let color = "#FAEEC4";

    if (number >= 85) {
      color = "#C1DEBF";
    } else if (number >= 70) {
      color = "#FAEEC4";
    } else if (number >= 50) {
      color = "#FEE5C9";
    } else if (number < 50) {
      color = "#E5C4C6";
    }
    return color;
  }

  const Graph = (number, type) => {
    return (
      <Box sx={{
        width: !type ? 240 : 123,
        bgcolor: secondColorStatus(number),
        height: 16, mt: 1,
        borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
        borderTopRightRadius: 5, borderBottomRightRadius: 5,
      }}>
        <Box sx={{
          width: number >= 100 ? number : `${number}%`,
          height: 16,
          bgcolor: mainColorStatus(number),
          borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
          borderTopRightRadius: 5, borderBottomRightRadius: 5,
        }} />
      </Box>
    )
  }

  const Box1 = styled(Box)(({ theme }) => ({
    transform: `${isMobile ? 'rotate(0deg)' : 'rotate(270deg)'}`
  }));

  return (
    <Grid item lg={6} md={12} sm={12} >
      <Card elevation={16} style={{ borderRadius: 8 }} >
        <CardContent>
          <Typography variant="h3" > {equipment.nav_name ? equipment.nav_name : ""} </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 3 }}>
            <Typography variant="subtitle1" > Total OEE Score </Typography>
            <Typography variant="h2" >{equipment.oee_componentes.oee ? (equipment.oee_componentes.oee * 100).toFixed(1) : 0} % </Typography>
            {Graph((equipment?.oee_componentes.oee * 100)?.toFixed(0))}
          </Box>

          <Grid container columns={12} spacing={2}>

            <Grid item xs={12} sm={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 1 }}>
              <Box sx={{ display: isMobile ? 'flex' : 'block', justifyContent: 'center', }}>
                <Typography variant="body1"  > {language?.availability || 'Availability'} </Typography>
              </Box>

              {isMobile ? <></> :
                <Box1 sx={{ mt: { sm: 13, md: 14, lg: 14 }, }}> {Graph((equipment?.oee_componentes.oee_a * 100)?.toFixed(0), !isMobile)} </Box1>
              }
            </Grid>

            <Grid item md={3} sm={12} xs={12}>
              {isMobile ? <></> : <Box sx={{ mb: 3.5 }}></Box>}

              <Box sx={{ display: { xs: 'none', md: 'block' }, justifyContent: 'center', }}>
                <Typography variant="h4"> {equipment.oee_componentes.oee_a ? (equipment.oee_componentes.oee_a * 100).toFixed(1) : 0}%  </Typography>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', mt: -2 }}>
                <Typography variant="h3"> {equipment.oee_componentes.oee_a ? (equipment.oee_componentes.oee_a * 100).toFixed(1) : 0}%  </Typography>
              </Box>

              <Grid container columns={12} >
                <Grid item md={12} sm={6} xs={6} >
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.possible || 'Possible'}: </Typography>
                    <Typography variant="body2">  {secondsToDurationString(equipment.oee_info.available_time ? (equipment.oee_info.available_time) : 0)} </Typography>
                  </Box>
                </Grid>

                <Grid item md={12} sm={6} xs={6}>
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.producing || 'Producing'}:  </Typography>
                    <Typography variant="body2"> {secondsToDurationString(equipment.oee_info.running_time ? (equipment.oee_info.running_time) : 0)} </Typography>
                  </Box>
                </Grid>
              </Grid>

              {isMobile &&
                <Box1 sx={{ display: 'flex', justifyContent: 'center', }}>
                  {Graph((equipment?.oee_componentes.oee_a * 100)?.toFixed(0), !isMobile)}
                </Box1>}
            </Grid>



            <Grid item md={1} sm={12} xs={12}>
              <Box sx={{ display: isMobile ? 'flex' : 'block', justifyContent: 'center', }}>
                <Typography variant="body1"> {language?.performance || 'Performance'} </Typography>
              </Box>

              {!isMobile &&
                <Box1 sx={{ mt: { sm: 13, md: 14, lg: 14 }, }}> {Graph((equipment?.oee_componentes.oee_p * 100)?.toFixed(0), !isMobile)} </Box1>
              }
            </Grid>

            <Grid item md={3} sm={12} xs={12}>
              {isMobile ? <></> : <Box sx={{ mb: 3.5 }}></Box>}

              <Box sx={{ display: { xs: 'none', md: 'block' }, justifyContent: 'center', }}>
                <Typography variant="h4">{equipment.oee_componentes.oee_p ? (equipment.oee_componentes.oee_p * 100).toFixed(1) : 0}% </Typography>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', mt: -2 }}>
                <Typography variant="h3">{equipment.oee_componentes.oee_p ? (equipment.oee_componentes.oee_p * 100).toFixed(1) : 0}% </Typography>
              </Box>

              <Grid container columns={12} >
                <Grid item md={12} sm={6} xs={6}>
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.nominal || 'Nominal'}: </Typography>
                    <Typography variant="body2"> {equipment.oee_info.ideal_speed.toFixed(0) || 0} un/min </Typography>
                  </Box>
                </Grid>

                <Grid item md={12} sm={6} xs={6}>
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.producing || 'Producing'}:  </Typography>
                    <Typography variant="body2"> {equipment?.oee_info.avg_speed.toFixed(0) || 0} un/min </Typography>
                  </Box>
                </Grid>
              </Grid>

              {isMobile &&
                <Box1 sx={{
                  display: 'flex', justifyContent: 'center',
                  // maxWidth:240,
                }}>
                  {Graph((equipment?.oee_componentes.oee_p * 100)?.toFixed(0), !isMobile)}
                </Box1>
              }
            </Grid>




            <Grid item md={1} sm={12} xs={12}>
              <Box sx={{ display: isMobile ? 'flex' : 'block', justifyContent: 'center', }}>
                <Typography variant="body1"> {language?.quality || 'Quality'} </Typography>
              </Box>

              {isMobile ? <></> :
                <Box1 sx={{ mt: { sm: 13, md: 14, lg: 14 }, }}> {Graph((equipment?.oee_componentes.oee_q * 100)?.toFixed(0), !isMobile)} </Box1>
              }
            </Grid>

            <Grid item md={3} sm={12} xs={12}>
              {isMobile ? <></> : <Box sx={{ mb: 3.5 }}></Box>}

              <Box sx={{ display: { xs: 'none', md: 'block' }, justifyContent: 'center', }}>
                <Typography variant="h4">{equipment.oee_componentes.oee_q ? (equipment.oee_componentes.oee_q * 100).toFixed(1) : 0}%</Typography>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', mt: -2 }}>
                <Typography variant="h3">{equipment.oee_componentes.oee_q ? (equipment.oee_componentes.oee_q * 100).toFixed(1) : 0}% </Typography>
              </Box>

              <Grid container columns={12} >
                <Grid item md={12} sm={6} xs={6}>
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.total_produced || 'Total Produced'}: </Typography>
                    <Typography variant="body2"> {numbertoString(equipment.oee_info.total_prod || 0)}  </Typography>
                  </Box>
                </Grid>

                <Grid item md={12} sm={6} xs={6}>
                  <Box sx={{ display: isMobile ? 'grid' : 'block', justifyContent: 'center', }}>
                    <Typography variant="body1"> {language?.scrap || 'Scrap'}: </Typography>
                    <Typography variant="body2"> {numbertoString(equipment.oee_info.scrap || 0)} </Typography>
                  </Box>
                </Grid>
              </Grid>

              {isMobile &&
                <Box1 sx={{ display: 'flex', justifyContent: 'center', }}>
                  {Graph((equipment?.oee_componentes.oee_q * 100)?.toFixed(0), !isMobile)}
                </Box1>
              }

              <Box sx={{ mt: 1.8, height: 1, width: 1, }}></Box>
            </Grid>

          </Grid>


          {/* Card by shifts */}
          {typeOfView == 'SHIFTS' &&
            <Grid container columns={12} spacing={1} >
              {equipment?.shifts?.map((item, index) =>
                <>
                  {index % 2 == 0
                    ? <Grid item xs={11}>
                      <Box sx={{ bgcolor: '#4D4D4D', opacity: 0.5, height: 1, width: '108%', ml: .5 }}></Box>
                    </Grid>
                    : <Box></Box>}

                  <Grid item xs={12} md={5}>
                    <Box>
                      <Box sx={{ display: 'flex', ml: 1, }}>
                        <Typography variant="h4"> {language?.shift || 'Shift'} {" "} {item?.shift} </Typography>
                        <Box sx={{ bgcolor: '#4D4D4D', height: 20, width: 1, display: 'grid', mt: 1, mx: 2 }}></Box>

                        <Box color={mainColorStatus((item?.oee_componentes?.oee * 100)?.toFixed(0))} >
                          <Typography variant="h4" > {(item?.oee_componentes?.oee * 100)?.toFixed(2)}% </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, ml: 1, mr: { xs: 0, sm: -3 } }}>
                        <D fill={mainColorStatus((item?.oee_componentes?.oee_a * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_a * 100)?.toFixed(1)}% </Typography>
                        <Box sx={{ mx: 1 }}></Box>
                        <P fill={mainColorStatus((item?.oee_componentes?.oee_p * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_p * 100)?.toFixed(1)}% </Typography>
                        <Box sx={{ mx: 1 }}></Box>
                        <Q fill={mainColorStatus((item?.oee_componentes?.oee_q * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_q * 100)?.toFixed(1)}% </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {index % 2 == 0
                    ? <Grid item xs={12} md={1}>
                      <Box sx={{ bgcolor: '#4D4D4D', opacity: 0.5, height: { xs: .1, md: '71px' }, width: { xs: '100%', md: '.5px' }, ml: isMobile ? 0 : 4, mt: 1, }}></Box>
                    </Grid>
                    : <Box></Box>}

                </>
              )}
            </Grid>
          }

          {typeOfView == 'TEAMS' &&
            <Grid container columns={12} spacing={1} >
              {equipment?.teams?.filter((team) => {
                // Eliminate Data Without Team
                return team?.team
              })?.map((item, index) =>
                <>
                  {index % 2 == 0
                    ? <Grid item xs={11}>
                      <Box sx={{ bgcolor: '#4D4D4D', opacity: 0.5, height: 1, width: '108%', ml: .5 }}></Box>
                    </Grid>
                    : <Box></Box>}

                  <Grid item xs={12} md={5}>
                    <Box>
                      <Box sx={{ display: 'flex', ml: 1, }}>
                        <Typography variant="h4"> {language?.team || 'Team'} {" "} {item?.team} </Typography>
                        <Box sx={{ bgcolor: '#4D4D4D', height: 20, width: 1, display: 'grid', mt: 1, mx: 2 }}></Box>

                        <Box color={mainColorStatus((item?.oee_componentes?.oee * 100)?.toFixed(0))} >
                          <Typography variant="h4" > {(item?.oee_componentes?.oee * 100)?.toFixed(2)}% </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, ml: 1, mr: { xs: 0, sm: -3 } }}>
                        <D fill={mainColorStatus((item?.oee_componentes?.oee_a * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_a * 100)?.toFixed(1)}% </Typography>
                        <Box sx={{ mx: 1 }}></Box>
                        <P fill={mainColorStatus((item?.oee_componentes?.oee_p * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_p * 100)?.toFixed(1)}% </Typography>
                        <Box sx={{ mx: 1 }}></Box>
                        <Q fill={mainColorStatus((item?.oee_componentes?.oee_q * 100)?.toFixed(0))} />
                        <Typography variant="body1"> {(item?.oee_componentes?.oee_q * 100)?.toFixed(1)}% </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {index % 2 == 0
                    ? <Grid item xs={12} md={1}>
                      <Box sx={{ bgcolor: '#4D4D4D', opacity: 0.5, height: { xs: .1, md: '71px' }, width: { xs: '100%', md: '.5px' }, ml: isMobile ? 0 : 4, mt: 1, }}></Box>
                    </Grid>
                    : <Box></Box>}

                </>

              )}

            </Grid>
          }

        </CardContent>

      </Card>
    </Grid >
  );
}