import React, { useState, useEffect, useContext } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import SiteFilter from './components/SiteFilter';
import AreaFilter from './components/AreaFilter';
import EquipmentFilter from './components/EquipmentFilter';
import ShiftFilter from './components/ShiftFilter';
import SectorFilter from './components/SectorFilter'
import TeamFilter from './components/TeamFilter'

import { Typography, Box, } from '@material-ui/core';

function EntityFilter(props) {
  const { enterprise, site, area, line, visualizationLevel } = useContext(VariablesContext);

  const styleHidden = { display: 'none' };
  const styleVisible = {};

  return (
    <>
      <Box sx={{ display:{xs:'none',lg:'flex'}, ml:3, justifyContent:'space-between' }}>
        <Box style={site ? styleHidden : styleVisible}><SiteFilter /></Box>
        <Box style={area ? styleHidden : styleVisible}><AreaFilter /></Box>
        <Box style={line ? styleHidden : styleVisible}><EquipmentFilter /></Box>
        <Box style={props?.hideSector ? styleHidden : styleVisible}><SectorFilter /></Box>
        <Box style={props?.hideShift ? styleHidden : styleVisible}><ShiftFilter /></Box>
        <Box style={props?.hideTeam ? styleHidden : styleVisible}><TeamFilter /></Box>
      </Box>

      <Box sx={{display:'flex', }}>

      </Box>
    </>
  );
}

export default EntityFilter;
