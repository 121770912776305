import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 263px;
  height: 220px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  background: rgba(0, 0, 0, 0.11);
  animation: 1.5s ease-in-out 0.5s infinite animation-skeleton;

  @media (min-width: 1440px) {
    margin-left: 0;
    width: 99%;
  }

  @keyframes animation-skeleton {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;


const AreaCardSkeleton = () => <Container />;

export default AreaCardSkeleton;