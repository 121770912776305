import React, { useState, useContext } from 'react'
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { VariablesContext } from '../../Context/VariablesContext';
import Unthorized from '../Unthorized';

import { Box, } from '@mui/material';
import { Typography } from '@material-ui/core';

// components
import Header from './components/Header';
import SubHeader from './components/SubHeader';
import Footer from '../../components/Footer';
import { NavItem, SideNavigation } from './styles';

// utils functions
import { getLanguage } from '../../utils/utils';

function Settings() {
  const language = getLanguage();
  const [active, setActive] = useState(0);

  const { userPermissions } = useContext(VariablesContext);

  const settingsPermissions = userPermissions?.screen?.filter((page) => { return page?.code == 24 });
  const canViewSettings = settingsPermissions?.length > 0;
  const canEditSettings = canViewSettings ? settingsPermissions?.[0]?.write : false;

  const routes = [
     {
       name: `${language?.targets || 'Targets'}`,
       path: `targets`,
     },
    {
      name: `${language?.user_and_permission || 'User and Permission'}`,
      path: `users-and-permissions`,
    },
    {
      name: `${language?.production_orders || 'Production Orders'}`,
      path: `production-orders`,
    }
    , {
      name: `${language?.downtime_reasons || 'Downtime Reasons'}`,
      path: `downtime-reasons`,
    }
  ];

  return (
    canViewSettings ?
      <Box sx={{
        backgroundColor: '#F1F3F5', minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: '50px 64px auto 100px',
        gridTemplateAreas:
          `"h h h h h h h h h h h h"
        "t t t t t t t t t t t t"
        "m m c c c c c c c c c c"
        "m m f f f f f f f f f f"`,
      }}>

        <Box sx={{ gridArea: 'h', }}>
          <Header />
        </Box>

        <Box sx={{ gridArea: 't', }}>
          <SubHeader />
        </Box>

        <Box sx={{ gridArea: 'm', }}>
          <SideNavigation>
            <ul>
              {routes.map((item, index) => (
                <NavItem key={index} active={active === index}>
                  <Link className='custom-link' to={item.path} onClick={() => setActive(index)}>
                    <Typography variant='body1'> {item.name} </Typography>
                  </Link>
                </NavItem>
              ))}
            </ul>
          </SideNavigation>
        </Box>

        <Box sx={{ gridArea: 'c', background: '#F9F9F9' }}>
          <Outlet />
        </Box>

        <Box sx={{ gridArea: 'f', display: 'flex', alignItems: 'flex-end', background: '#F9F9F9', justifyContent: 'center' }}>
          <Footer />
        </Box>

      </Box>
      : <Unthorized />
  )

}

export default Settings;
