import React, { useState, useContext, useEffect } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function EquipmentFilter() {
  const { lineOptions, filterLine, setFilterLine, filterArea, site, area } = useContext(VariablesContext);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const language = getLanguage();

  useEffect(() => {
    if (lineOptions) {
      let temp = [...lineOptions];
      temp = temp?.filter(item => {
        return (filterArea.includes(item.id_area) && (area ? area == item.id_area : true) && (site ? site == item.id_site : true));
      });
      let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      let sortItems = temp.sort((a, b) => collator.compare(a.nm_equipment, b.nm_equipment));
      setItemsToDisplay(sortItems);
    }
  }, [lineOptions, filterArea, area, site]);

  const handleChangee = (event) => {
    const arrayValues = event?.target?.value?.map(x => x);
    setFilterLine(arrayValues)
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150, }}>
        <InputLabel sx={{ m: filterLine?.length == 0 ? -1 : 0 }}>
          {filterLine?.length == 0 ? <FilterAltOutlinedIcon /> : ""}
          {language?.filter_line || 'Filter Line'}
        </InputLabel>
        <Select
          multiple
          value={filterLine}
          onChange={handleChangee}
          input={
            <OutlinedInput
              sx={{ maxHeight: 40, }}
              label={language?.filter_line || 'Filter Line'} />
          }
          renderValue={
            (selected) => (
              itemsToDisplay?.length + " " + language?.available || 'avaliable'
              // 'disponiveis'
              // ( site || area ? (itemsToDisplay.length) : ( selected.length) )+ " " + 'disponiveis'
            )
          }>

          {itemsToDisplay?.length > 0
            ? itemsToDisplay?.map((line, index) => (
              <MenuItem key={line.id_equipment} value={line.id_equipment}>
                <Checkbox sx={{ m: -1, mr: .3 }} checked={filterLine.includes(line.id_equipment)} />
                <Typography variant="body2"> {line.nm_equipment} </Typography>
              </MenuItem>
            ))
            : language?.no_lines_available || 'No lines available'}

        </Select>
      </FormControl>
    </>
  );
};