import React, { useState, useContext } from 'react';
import moment from 'moment';
import { numbertoString, getLanguage } from '../../../utils/utils';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton
} from '@mui/material';
import { Box, Typography, } from '@material-ui/core';
import { VariablesContext } from '../../../Context/VariablesContext';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 1 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

export default function TableMobile({ data }) {
  const language = getLanguage();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { dateRange } = useContext(VariablesContext);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer sx={{ textAlign: '-webkit-center' }} >
      <Table sx={{ maxWidth: 352, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> Time </TableCell>
            <TableCell align="right"> P. Prod </TableCell>
            <TableCell align="right"> T.Prod </TableCell>
            <TableCell align="right"> Scrap </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          )?.map((row) => (
            <TableRow key={row?.scrap} sx={{ ' &:last-child th': { border: 0 } }} >
              <TableCell scope="center">
                {dateRange == "day"
                    ? moment(row?.ts).utc().format('HH:mm')
                    : moment(row?.ts).utc().format('MM/DD HH:mm')
                }
              </TableCell>
              <TableCell align="right">{numbertoString(row?.net_production_incr)}</TableCell>
              <TableCell align="right">{numbertoString(row?.net_production_acc)}</TableCell>
              <TableCell align="right">{numbertoString(row?.scrap)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page', },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </Table>
    </TableContainer>
  );
}
