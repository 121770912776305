import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

export function LoadingOeeScore() {
  return (
    <Box sx={{ display:'flex' }}>
      <Grid item xs={6} container sx={{ mt: 3 }} >
        <Skeleton variant='rectangular' width={'95%'} height={400}/>
      </Grid>

      <Grid item xs={6} container sx={{ mt: 3 }} >
        <Skeleton variant='rectangular' width={'100%'} height={400}/>
      </Grid>
    </Box>
  );
}
