import React from 'react';
import { Box, Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
//import { roundNumber, getLanguage } from '../../../utils/utils.js';

export default function ConsumoRefugo({ tags }) {
  //const language = getLanguage();
  //const scrapType = localStorage.getItem('scrap_calc_type');

  function getTag(type, line) {
    let tag = { value: 0 };
    let tagLine1 = tags?.[0] ?? [];
    let tagLine2 = tags?.[1] ?? [];

    if (line === 'TISSUE/L1') {
      tag = tagLine1?.find(item => item?.line === line && item?.type === type)
    } else {
      tag = tagLine2?.find(item => item?.line === line && item?.type === type)
    }

    return tag?.value ?? '-';
  }

  return (
    <Paper sx={{ m: 1 }}>
      <Grid container spacing={1} sx={{ m: 1, pb: 2 }}>
        <Grid item xs={12} >
          <Typography variant="h4"> Consumo e Refugo </Typography>
        </Grid>

        <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          &nbsp;
          <Typography variant="body1" style={{ marginBottom: 6 }}> Consumo Filme e Prim. </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Consumo Filme e Secun. </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Consumo Maculatura </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Consumo Papel </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Manta </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Log </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Refile/Rolinho </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Maculatura </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Filme Primario </Typography>
          <Typography variant="body1" style={{ marginBottom: 6 }}> Refugo Filme Secun. </Typography>
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography variant="body1" style={{ fontSize: 16, color: '#828282' }}> LINHA 1 </Typography>

          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('PRIMÁRIO', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('SECUNDÁRIO', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('MACULATURA', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('PAPEL', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO MANTA', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO LOG', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO REFILE', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO MACULATURA', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO PRIMÁRIO', 'TISSUE/L1')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO SECUNDÁRIO', 'TISSUE/L1')} KG </Typography>

        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography variant="body1" style={{ fontSize: 16, color: '#828282' }}> LINHA 2 </Typography>

          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('PRIMÁRIO', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('SECUNDÁRIO', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('MACULATURA', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('PAPEL', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO MANTA', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO LOG', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO REFILE', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO MACULATURA', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO PRIMÁRIO', 'TISSUE/L2')} KG </Typography>
          <Typography style={{ lineHeight: 1.85 }} variant="body2"> {getTag('REFUGO SECUNDÁRIO', 'TISSUE/L2')} KG </Typography>
        </Grid>

      </Grid >
    </Paper >
  );
}
