import styled from 'styled-components';

export const SideNavigation = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid #BDBDBD;

  ul {
      list-style: none;
      width: 100%;
      padding-left: 0;
  }

`;

export const NavItem = styled.li`
    width: 100%;
    height: 77px;
    display: flex;
    align-items: center;
    ${(props) => props.active ? 'background-color: #EAEAED;' : ''}

    .custom-link {
        margin-left: 42px;
        font-size: 16px;
        color: #4D4D4D;
        font-weight: 600;
        width: 100%;
        text-decoration: none;
    }
`;