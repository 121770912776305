import moment from 'moment-timezone';
import React, {useContext} from 'react';
import { roundNumber, isInvalidNumber, userLocale } from '../../../utils/utils';
import { getLanguage } from '../../../utils/utils';
import { Card, CardContent, Box, Divider, Typography, } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery';
import { VariablesContext } from '../../../Context/VariablesContext';

const StaticTooltip = ({ chartData, dateRange }) => {
  const language = getLanguage();
  const locale = userLocale();
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });
  let displayTitle = '';
  const { grain, timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  if (!chartData) return null;
  function validateNumber(number) {
    let validNumber = number
    if (isInvalidNumber(number)) validNumber = 0;
    if (number > 100) validNumber = 100;

    return validNumber;
  }

  let lastDot = chartData?.[chartData?.length - 1];
  let currentDot = {};
  chartData.forEach((item) => {
    if (item.net_production !== null) {
      currentDot = item;
    }
  });

  let toGoal = (currentDot?.net_production) / lastDot?.target * 100;
  //let scrapPercent = ((currentDot?.scrap / (currentDot?.net_production + currentDot?.scrap)) * 100);
  let scrapPercent = (currentDot?.scrap_acc) * 100 / currentDot?.net_production;
  let toGoalColor = 'value-default';
  let dateRangeGoalTitle = '';
  let dateRangeGoal = lastDot?.target;
  let projected = lastDot?.trend_line;
  let projectedGoal = (lastDot?.trend_line) / lastDot?.target * 100;
  let projectedGoalColor = 'value-dafault';
  let toGoalText = language?.of_goal || 'of goal';
  let projectedGoalText = language?.of_goal || 'of goal';

  toGoal = validateNumber(toGoal);
  scrapPercent = validateNumber(scrapPercent);
  projectedGoal = validateNumber(projectedGoal);

  let toGoalFormat = '';
  let projectedFormat = '';

  if (toGoal >= 100) {
    toGoalColor = '#318f29';
    toGoalFormat = toGoal.toFixed() + '%';
  } else {
    toGoalColor = '#C13939';
    toGoalFormat = toGoal.toFixed() + '%';
  }

  if (projectedGoal >= 100) {
    projectedGoalColor = 'value-success';
    projectedFormat = projectedGoal.toFixed() + '%';
  } else {
    projectedGoalColor = 'value-danger';
    projectedFormat = projectedGoal.toFixed() + '%';

  }

  if (dateRange === 'month') {
    displayTitle = language?.this_month || `This Month`;
    dateRangeGoalTitle = language?.monthly_goal || 'Monthly Goal';
  }
  if (dateRange === 'last month') {
    displayTitle = language?.last_month || 'Last Month';
    dateRangeGoalTitle = language?.monthly_goal || 'Monthly Goal'
  }

  if (dateRange === 'today') {
    displayTitle = `${language?.today || 'Today'} - ${moment().format(locale.hour)}${(!locale.isAmPm) ? 'h' : ''}`;
    dateRangeGoalTitle = language?.daily_goal || 'Daily Goal'
  }

  if (dateRange === 'week') {
    displayTitle = language?.this_week || `This Week`;
    dateRangeGoalTitle = language?.weekly_goal || 'Weekly Goal'
  }


  return (
    <>
      {!isMobile
        ? <div className="static-tooltip">
          <Card elevation={16} style={{ margin: 24 }}  >
            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <Box sx={{ display: { xs: 'flex', sm: 'block' }, justifyContent: 'center', alignItems: 'center', mb: 1, ml: 2, }}>
                <Typography variant="h4">  {displayTitle} </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body2" > {language?.total_production || 'Total Production'} </Typography>
                  <Typography variant="h4">{roundNumber(currentDot?.net_production)}</Typography>
                  <Box sx={{ color: toGoalColor }}>
                    <Typography variant="body1">{toGoalFormat} {toGoalText}</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body2"> {language?.scrap || 'Scrap'}: </Typography>
                  <Typography variant="h4">{scrapPercent?.toFixed()}%</Typography>
                  <Typography variant="body1">({roundNumber(currentDot?.scrap_acc)})</Typography>
                </Box>

                <Box sx={{ bgcolor: '#828282', height: 80, width: 1 }} />

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body2">{dateRangeGoalTitle}</Typography>
                  <Typography variant="h4"> {roundNumber(dateRangeGoal)}</Typography>
                  &nbsp;
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body2"> {language?.projected || 'Projected'}</Typography>
                  <Typography variant="h4"> {roundNumber(projected)} </Typography>
                  <Box sx={{ color: toGoalColor }}>
                    <Typography variant="body1"> {projectedFormat} {projectedGoalText}</Typography>
                  </Box>
                </Box>

              </Box>
            </CardContent>
          </Card>
        </div>
        :
        <Card elevation={16} style={{ margin: 24 }}  >
          <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
              <Typography variant="h4"> {displayTitle} </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="body2" > {language?.total_production || 'Total Production'} </Typography>
                <Typography variant="subtitle2">{roundNumber(currentDot?.net_production)}</Typography>
                <Box sx={{ color: toGoalColor }}>
                  <Typography variant="overline" color={toGoalColor}>{toGoalFormat} {toGoalText}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="body2"> {language?.scrap || 'Scrap'}: </Typography>
                <Typography variant="subtitle2">{scrapPercent?.toFixed()}%</Typography>
                <Typography variant="overline">({roundNumber(currentDot?.scrap_acc)})</Typography>
              </Box>
            </Box>

            <Divider style={{ marginTop: 10, marginBottom: 10, marginRight: 20, marginLeft: 20, }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="body2">{dateRangeGoalTitle}</Typography>
                <Typography variant="subtitle2"> {roundNumber(dateRangeGoal)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="body2"> {language?.projected || 'Projected'}</Typography>
                <Typography variant="subtitle2"> {roundNumber(projected)} </Typography>
                <Box sx={{ color: toGoalColor }}>
                  <Typography variant="overline"> {projectedFormat} {projectedGoalText}</Typography>
                </Box>
              </Box>
            </Box>

          </CardContent>
        </Card>
      }
    </>
  )
};
export default StaticTooltip;
