import { gql } from "@apollo/client";

export const EQP_RUNTIME_1DAY = gql`
query EQP_RUNTIME_1DAY($_eq: [Int!] = [404,411], $_gte: date = "2023-01-26", $_lt: date = "2023-02-01") {
  equipment_runtime_1day(where: {id_equipment: {_in: $_eq}, ts_value: {_gte: $_gte, _lt: $_lt}}) {
    available_time
    changeover_time
    downtime
    gross
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net
    planned_downtime
    proportional_target
    recalc_needed
    running_time
    scrap
    speed
    stopped_time
    target
    target_customized
    ts_value
    equipment {
      nm_equipment
      id_equipment
    }
  }
}
`;

export const EQP_RUNTIME_1MONTH = gql`
query EQP_RUNTIME_1MONTH($_in: [Int!] = [404,411], $_gte: date = "2023-01-01", $_lt: date = "2023-02-01") {
  equipment_runtime_1month(where: {id_equipment: {_in: $_in}, ts_value: {_gte: $_gte, _lt: $_lt}}) {
    available_time
    changeover_time
    downtime
    gross
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    proportional_target
    recalc_needed
    running_time
    scrap
    speed
    stopped_time
    target
    target_customized
    equipment {
      nm_equipment
      id_equipment
    }
  }
}
`;

export const EQP_RUNTIME_SHIFT = gql`
query EQP_RUNTIME_SHIFT($_eq1: [Int!] = 404, $_eq2: date = "2023-01-30") {
  equipment_runtime_shift(where: {id_equipment: {_in: $_eq1}, ts_value_production: {_eq: $_eq2},cd_shift: {_is_null: false}}) {
    available_time
    cd_shift
    changeover_time
    downtime
    duration
    gross
    id_shift
    id_shift_hour
    id_team
    ideal_production
    ideal_speed
    idle_blocked
    idle_starved
    idle_time
    manually_customized
    net
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    proportional_target
    recalc_needed
    running_time
    scrap
    speed
    stopped_time
    target
    target_customized
    ts_end
    ts_range
    ts_value_production
    id_equipment
    ts_value
    equipment {
      id_equipment
      nm_equipment
    }
  }
}
`;

export const UNS_EQUIPMENT_CURRENT_JOB = gql`
query UNS_EQUIPMENT_CURRENT_JOB($_in: [Int!] ) {
  uns_equipment_current_job(where: {id_equipment: {_in: $_in}}) {
    available_time
    begin_time
    cd_setup
    current_expected_time
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    id_order
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    last_setup_duration
    net_production
    net_production_exec_mode
    nm_client
    nm_product
    nm_product_family
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    production_ordered
    production_programmed
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    setup_begin_time
    setup_end_time
    setup_speed
    setup_target_duration
    speed
    stopped_time
    target
    equipment {
      id_equipment
      nm_equipment
    }
  }
}`;


export const ENTERPRISE = gql`
query enterprises($eq: Int) {
  enterprises {
    logo_url
    nm_enterprise
  }
}`;
