import React, { useContext } from 'react';
import { ComposedChart, Bar, CartesianGrid, YAxis, XAxis, Tooltip, Cell, Legend, ReferenceLine, Line, ResponsiveContainer } from 'recharts';
import moment from 'moment-timezone';
import SinglePeriodTooltip from './Tooltip';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import { VariablesContext } from '../../../Context/VariablesContext';

function SinglePeriodChart({ data, dateRange, target }) {
  let chartData = data ?? [];
  const { setTs_start, setTs_end, setRange, setEventTabOpen } = useContext(VariablesContext);

  chartData = chartData?.map((item, index) => {
    item.target = target?.[index]?.target;
    return item;
  });

  // average line: sum of net from chartData array
  const average = chartData.reduce((acc, curr) => {
    return acc + curr.net;
  }, 0) / chartData.length;


  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

  function setDay(e) {
    console.log(e);
    console.log(dateRange);
    
    if (dateRange !== 'today' && dateRange != 'day') {
      let start = e?.ts_value_production;
      start = moment(start).utc().format('YYYY-MM-DD HH:mm');
      let end = moment(start).utc().endOf('day').format('YYYY-MM-DD HH:mm');
      setTs_start(start);
      setTs_end(end);
      setRange('day');
      setEventTabOpen();
    }
  }

  return (
    <ResponsiveContainer minWidth={1140} height={490}>
      <ComposedChart height={470} margin={{ top: 40, right: 50, left: 16, bottom: 0 }} data={chartData}>
        <CartesianGrid strokeDasharray="1 1" />

        <XAxis dataKey="ts_value_production" tick={<XTick dateRange={dateRange} />} height={60} interval={intervalData} />

        <YAxis tick={<Ytick />} label={{ position: 'insideLeft' }} axisLine={false} />

        <Tooltip content={<SinglePeriodTooltip chartData={chartData} dateRange={dateRange} />} />

        <Bar dataKey='net' radius={[5, 5, 0, 0]} isAnimationActive animationDuration={2400}
        onClick={(e) => setDay(e)}
        >
          {chartData.map((entry, index) => {
            if (moment(entry.ts_value_production).minutes() !== 0 || moment(entry.ts_value_production).seconds() !== 0) {
              return (
                <Cell
                  key={`cell-${index}`}
                  stroke='#407CCC' fill='#C9D8FB'
                  strokeWidth="3" strokeDasharray="5 3"
                  style={{ borderRadius: '10px' }}
                />
              );
            } else {
              return (<Cell key={`cell-${index}`} fill='#407CCC' />);
            }
          })
          }
        </Bar>

        <Line
          isAnimationActive animationDuration={2400}
          animationEasing="ease-out" type="linear" strokeWidth="2"
          dot={false} dataKey="target" stroke="#27AE60"
        />

        <ReferenceLine y={average} stroke="#828282" isFront strokeWidth={2} strokeDasharray='5 3' />

      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SinglePeriodChart;