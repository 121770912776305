import React from 'react';
import { Typography, Box } from '@mui/material';
import { Typography as Typography2 } from '@material-ui/core';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { scrapCalc } from '../../../configurations/scrap';

export default function Card1({ dataShift, enterprise, dataDay }) {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');

  const howGood = (number, target) => {
    let quanto = (number * 100) / target;

    if (quanto == 99 || quanto == 101 || target === null || target === 0) {
      return <></>
    } else if (quanto > 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowUpwardRoundedIcon fontSize='large' sx={{ color: '#318F29', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#318F29', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"+" + (quanto - 100).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    } else if (quanto < 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowDownwardRoundedIcon fontSize='large' sx={{ color: '#EB5757', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#EB5757', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"-" + (100 - quanto).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    }
  };

  // ---------- Net do Turno
  let netEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.net_production;
  let netEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.net_production;
  let netAll = netEqp1 + netEqp2

  // -- Porcentagem
  let targetShiftEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.target;
  let targetShiftEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.target;
  let allTarget = targetShiftEqp1 + targetShiftEqp2

  //CUSTOM: duração do turno é igual para ambas
  let durationShiftEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.duration;
  let targetPorSegundo = allTarget / durationShiftEqp1
  let elapsedTime1Shift = dataShift?.uns_equipment_current_shift?.[0]?.elapsed_time
  let proportionalTargetAll = targetPorSegundo * elapsedTime1Shift

  // ----------Scrap
  let scrapEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.scrap
  let scrapEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.scrap
  let scrapTotal = scrapEqp1 + scrapEqp2

  let grossShiftEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.gross_production;
  let netShiftEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.net_production;
  let grossShiftEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.gross_production;
  let netShiftEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.net_production;

  let scrapPorcetEqp1 = parseFloat(scrapCalc(scrapType, grossShiftEqp1, netShiftEqp1), 16)
  let scrapPorcetEqp2 = parseFloat(scrapCalc(scrapType, grossShiftEqp2, netShiftEqp2), 16)
  let scrapPorcetAllEqp = (scrapPorcetEqp1 + scrapPorcetEqp2) / 2

  // ----------Projetado para o final do dia
  let secondsOfaDay = 86400 // tem que mutiplicar por 2 pq são duas maquinhas

  let allElapsedTimeDay = dataDay?.uns_equipment_current_day?.[0]?.elapsed_time + dataDay?.uns_equipment_current_day?.[1]?.elapsed_time
  let allNet = dataDay?.uns_equipment_current_day?.[0]?.net_production + dataDay?.uns_equipment_current_day?.[1]?.net_production
  let projectedDay = ((secondsOfaDay * 2) * allNet) / allElapsedTimeDay

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, m: 1, height: 430, width: 430 }} >

      <Box >
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, }} >
          {language?.shift_production || 'SHIFT Production'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {enterprise != 37 ? roundNumber(netAll) : netAll}
          </Typography>

          {howGood(netAll, proportionalTargetAll.toFixed(0))}
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, mt: 1 }} >
          {language?.shift_scrap || 'SHIFT Scrap'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {scrapPorcetAllEqp.toFixed(1) ?? "-"} %
          </Typography>

          {/* <Box sx={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'row',
            color: '#EB5757', ml: 1, mt: -4
          }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {enterprise != 37 ? roundNumber(scrapTotal) : scrapTotal}
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, mt: 2, ml: 1 }} >
              {enterprise != 37 ? 'units' : 'm'}
            </Typography>
          </Box> */}
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, mt: 1 }} >
          Projetado para o final do dia
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {enterprise != 37 ? roundNumber(projectedDay.toFixed(0)) : projectedDay.toFixed(0)}
          </Typography>

          {/* VER daonde que vai ser retirado os dados */}
          {/* { howGood(dataShift?.uns_equipment_current_shift?.[0]?.net_production, dataShift?.uns_equipment_current_shift?.[0]?.target) } */}

        </Box>
      </Box>


    </Box>
  );
}
