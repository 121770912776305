import { gql } from '@apollo/client';


export const OEE_SCORE = gql`
  query OeeScore($time_grain: String, $nav_level: String, $is_shift_filtered: Boolean = true, $in_ids_shifts: String, $in_ids_teams: String, $in_id_sites: String, $in_id_equipments: String, $in_id_enterprise: Int, $in_id_areas: String , $in_end_time: timestamptz, $in_begin_time: timestamptz) {
    h_piot_oee_score_with_teams(args: {in_begin_time: $in_begin_time, in_end_time: $in_end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_ids_shifts, in_ids_teams: $in_ids_teams, is_shift_filtered: $is_shift_filtered, nav_level: $nav_level, time_grain: $time_grain}) {
      id_enterprise
      nav_name
      oee_componentes
      oee_info
      shifts
      childs
      teams
    }
  }
`;

export const OEE_PROGRESS = gql`
  query OeeProgress($begin_time: timestamptz, $end_time: timestamptz, $in_id_areas: String, $in_id_enterprise: Int, $time_grain: String, $in_id_shifts: String, $in_id_sites: String, $in_id_equipments: String, $is_shift: Boolean, $in_ids_shifts: String, $in_ids_teams: String, $is_team: Boolean, $nav_level: String) {
    h_piot_oee_progress_new2(args: {in_begin_time: $begin_time, in_end_time: $end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_id_shifts, is_shift_filtered: $is_shift, in_ids_teams: $in_ids_teams, is_team_filtered: $is_team, nav_level: $nav_level, time_grain: $time_grain}) {
    id_enterprise
    nm_entity
    oee_progress
  }
}
`;
