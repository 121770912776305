import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 199px;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 0;
  background: rgba(0, 0, 0, 0.11);
  animation: 1.5s ease-in-out 0.5s infinite animation-skeleton;

  @media (min-width: 768px) {
    width: 330px;
    margin-right: 35px;
  }

  @media (min-width: 1200px) {
    margin-left: 20px;
  }

  @media (min-width: 1440px) {
    width: 840px;
    height: 400px;
    margin-left: 20px;
    margin-top: 8px;
  }

  @media (min-width: 1530px) {
    width: 47%;
    height: 400px;
    margin-left: 10px;
    margin-top: 8px;
    margin-right: 0;
  }

  @keyframes animation-skeleton {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;


const CardSkeleton = () => <Container />;

export default CardSkeleton;