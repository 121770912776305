import { useState, useContext, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Box, Button, TextField, FormControl, FormHelperText } from '@mui/material';
import { Typography } from '@material-ui/core';
import { AuthContext } from '../../../Context/AuthContext.js';

export default function EnterpriseModal() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const enterpriseName = useRef();
  const { othersAuth } = useContext(AuthContext)

  const handleLoginWithMicrosoft = (event) => {
    event.preventDefault();
    if (enterpriseName.current.value.toUpperCase() === 'SUZANO') {
      setError(false);
      othersAuth();
    } else {
      setError(true);
    }
  }

  return (
    <Box>
      <Button
        sx={{ width: '100%', height: '50px', mt: '40px', backgroundColor: '#407CCC', mb: { xs: '10px', md: '30px', lg: '50px' }, }}
        variant='contained' onClick={() => { setOpen(true); }}
      >
        Login with Microsoft
      </Button>

      <Dialog
        open={open}
        onClose={() => { setOpen(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <IconButton onClick={() => { setOpen(false); }} disableRipple={true} sx={{ justifyContent: 'flex-end' }}>
          <CloseIcon />
        </IconButton>

        <Box component='form' sx={{
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          m: 0, pb: 1
        }} >
          <Typography variant='h3'> Write your company name: </Typography>

          <FormControl error={error} fullWidth variant="outlined" sx={{ my: 5, display: 'flex', }}>

            <TextField
              sx={{ width: '75%', alignSelf: 'center' }}
              id="outlined-basic"
              label="Company" variant="outlined"
              inputRef={enterpriseName} />

            {error && <FormHelperText sx={{ ml: 10, }}> Invalid company. Contact your manager.</FormHelperText>}

          </FormControl>

          <Button
            type='submit' variant='contained'
            sx={{ width: '75%', height: '50px', mx: 10, mb: 5 }}
            onClick={handleLoginWithMicrosoft}
          >
            Submit
          </Button>
        </Box>

      </Dialog >
    </Box >
  );
}
