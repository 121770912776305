import { gql } from '@apollo/client';

export const EDIT_EVENT = gql`
mutation EditEvent($id_event: bigint, $category: String, $subcategory: String, $machine: String, $txt_category: String, $txt_subcategory: String, $notes: String, $idle: String = "", $planned_downtime: Boolean = false, $change_over: Boolean = false) {
  update_equipment_events(where: {id_equipment_event: {_eq: $id_event}}, _set: {cd_category: $category, cd_machine: $machine, cd_subcategory: $subcategory, desc_category: $txt_category, desc_subcategory: $txt_subcategory, txt_downtime_notes: $notes, change_over: $change_over, idle: $idle, planned_downtime: $planned_downtime}) {
    affected_rows
  }
}
`;

export const DOWNTIME_SPLIT = gql`
mutation MyMutation($_id_equipment_event: bigint = "", $_planned_downtime: Boolean = false, $_notes: String = "", $_idle: String = "", $_change_over: Boolean = false, $_cd_subcategory: String = "", $_cd_machine: String = "", $_cd_category: String = "", $_begin_time: timestamptz = "") {
  piot_split_downtime(args: {_id_equipment_event: $_id_equipment_event, _change_over: $_change_over, _cd_subcategory: $_cd_subcategory, _cd_machine: $_cd_machine, _cd_category: $_cd_category, _begin_time: $_begin_time, _idle: $_idle, _notes: $_notes, _planned_downtime: $_planned_downtime}) {
    cd_category
    cd_machine
    cd_subcategory
    change_over
    duration
    id_enterprise
    id_equipment
    idle
    planned_downtime
    status
    ts_end
    ts_event
    txt_downtime_notes
  }
}
`;