import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { Grid, Box, Typography, FormHelperText, FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton, Button } from '@mui/material';
import { ReactComponent as PackiotLogo } from "../../assets/packiot-dark.svg";

import LoginImage from "../../assets/LoginImage.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SuccessAnimation from './SuccessAnimation';
import { useSnackbar } from 'notistack';


const SuportLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  
  &:hover {
    color: #fff;
  }
`;

function ResetPassword() {
  const [passError, setPassError] = useState({ error: false, message: '' });
  const [password, setPassword] = useState('');
  const [confirmPassError, setConfirmPassError] = useState({ error: false, message: '' });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //const [btnDisabled, setDisable] = useState(true);
  const [upper, setUpper] = useState(false);
  const [lower, setLower] = useState(false);
  const [number, setNumber] = useState(false);
  const [char, setChar] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { verifyCode, updatePassword } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  let location = useLocation();

  const year = new Date().getFullYear();

  useEffect(() => {
    if (updateSuccess) {
      setTimeout(() => {
        history('/login')
      }, 3500)
    }
  }, [updateSuccess])

  useEffect(() => {
    let pass = password;
    let upperLetter = new RegExp(/[A-Z]/gm);
    let lowerLetter = new RegExp(/[a-z]/g);
    let number = new RegExp(/(?=.*\d)/g);


    if (upperLetter.test(pass)) {
      setUpper(true);
    } else {
      setUpper(false);
    }

    if (lowerLetter.test(pass)) {
      setLower(true);
    } else {
      setLower(false);
    }

    if (number.test(pass)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (pass.length >= 8) {
      setChar(true)
    } else {
      setChar(false);
    }

  }, [password]);

  const handlePasswordChange = (event) => {
    if (passError.error) setPassError({ error: false, message: '' });
    setPassword(event.target.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (event) => {
    if (confirmPassError.error) setConfirmPassError({ error: false, message: '' });
    setConfirmPassword(event.target.value);

  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (confirmPassword === password) {
      let rules = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/gm);
      if (rules.test(confirmPassword)) {
        let code = location.search.substr(location.search.indexOf('&oobCode=') + 9);
        if (code.indexOf("&") != -1) code = code.substr(0, code.indexOf("&"));
        let isValidCode = await verifyCode(code);
        if (isValidCode) {
          let response = await updatePassword(code, confirmPassword);
          if (response) {
            setUpdateSuccess(true);
          } else {
            enqueueSnackbar('Oops! an error occured, try again later', { variant: 'error' });  
          }
        } else {
          enqueueSnackbar('Your verification code has been expired!', { variant: 'error' });
        }

      } else {
        setConfirmPassError({ error: true, message: 'Invalid password!' })
      }
    } else {
      setConfirmPassError({ error: true, message: 'Password does not match!' })
    }
  }


  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >

        <Box sx={
          {
            height: { xs: 'calc(100vh - 65px)', lg: 'calc(100vh - 165px)' },
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            pl: { xs: '30px', lg: '0' },
            pr: { xs: '30px', lg: '0' },
          }
        }
        >
          {
            updateSuccess ? <SuccessAnimation /> :
              <Box component='form'>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                  <PackiotLogo style={{ marginTop: '30px' }} />
                  <Typography variant="h1" sx={{ fontWeight: 500, color: '#333333', fontSize: { xs: '36px', md: '64px' }, mt: { xs: '20px', md: '40px' }, textAlign: 'center' }}> Reset Password </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#333333', fontSize: '25px' }}> Choose here your new password! </Typography>
                </Box>

                <FormControl
                  error={passError.error}
                  fullWidth variant="outlined"
                  sx={{ mb: '20px', mt: '30px' }}
                >
                  <InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password*"
                  />
                  {
                    passError.error &&
                    <FormHelperText sx={{ ml: 0 }}>
                      {passError.message}
                    </FormHelperText>
                  }
                </FormControl>

                <Box sx={{
                  width: '100%',
                  height: '115px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {upper ? <CheckCircleOutlineIcon sx={{ color: '#318F29' }} /> :
                      <HighlightOffIcon sx={{ color: '#C13939', mr: '8px' }} />
                    }
                    <Typography sx={{ color: `${upper ? '#318F29' : '#C13939'}` }}>At least one uppercase letter </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {lower ? <CheckCircleOutlineIcon sx={{ color: '#318F29' }} /> :
                      <HighlightOffIcon sx={{ color: '#C13939', mr: '8px' }} />
                    }
                    <Typography sx={{ color: `${lower ? '#318F29' : '#C13939'}` }}>At least one lowercase letter </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {number ? <CheckCircleOutlineIcon sx={{ color: '#318F29' }} /> :
                      <HighlightOffIcon sx={{ color: '#C13939', mr: '8px' }} />
                    }
                    <Typography sx={{ color: `${number ? '#318F29' : '#C13939'}` }}>At least one number </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {char ? <CheckCircleOutlineIcon sx={{ color: '#318F29' }} /> :
                      <HighlightOffIcon sx={{ color: '#C13939', mr: '8px' }} />
                    }
                    <Typography sx={{ color: `${char ? '#318F29' : '#C13939'}` }}>At least 8 characters </Typography>
                  </Box>

                </Box>

                <FormControl
                  error={confirmPassError.error}
                  fullWidth variant="outlined"
                  sx={{ mb: '20px', mt: '10px' }}
                >
                  <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm password*</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm password*"
                  />
                  {
                    confirmPassError.error &&
                    <FormHelperText sx={{ ml: 0 }}>
                      {confirmPassError.message}
                    </FormHelperText>
                  }
                </FormControl>



                <Box sx={{
                  mt: 1, width: '100%', display: 'flex',
                  flexDirection: { xs: 'column' },
                  justifyContent: { xs: 'center' },
                  alignItems: 'center'
                }}>

                  <Button
                    type='submit'
                    sx={
                      {
                        width: '100%', height: '50px', backgroundColor: '#407CCC',
                        mb: { xs: '10px', md: '30px', lg: '50px' },
                      }
                    }
                    variant='contained' onClick={handleSubmit}
                  >
                    Confirm
                  </Button>

                </Box>

              </Box>

          }



        </Box>

        <Box sx={{
          width: '100%',
          height: '65px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: { xs: '#fff', lg: '#BDBDBD' },
          background: { xs: '#1F608A', lg: 'transparent' },
        }}>
          <p>&nbsp;</p>
          <Typography>&copy; {year} - All rights reserved</Typography>
        </Box>

      </Grid>

      <Grid item lg={7}
        sx={
          {
            display: { xs: 'none', lg: 'flex' }, height: '100vh', background: "#1F608A",
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
          }}
      >
        <Box sx={{ width: '180px', display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '40px', right: '100px' }}>
          <MailOutlineIcon sx={{ color: '#fff' }} />
          <SuportLink href='mailto:suport@packiot.com'> suport@packiot.com </SuportLink>
        </Box>
        <img style={{ width: '50%', height: 'auto' }} src={LoginImage} className="image" />
      </Grid>
    </Grid>
  );
}

export default ResetPassword;