import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/client';
import { sortArrayOfObjectsBy } from '../utils/utils.js'

import { GET_VARIABLES_CONTEXT } from './Query';

var default_start = moment().startOf('month').format('YYYY-MM-DD HH:mm')
var default_end = moment().endOf('month').format('YYYY-MM-DD HH:mm');

const VariablesContext = createContext();

function VariablesProvider({ children }) {
  const [enterprise, setEnterprise] = useState();
  const [nameEnterprise, setNameEnterprise] = useState("");
  const [site, setSite] = useState();
  const [area, setArea] = useState();
  const [line, setLine] = useState();
  const [timezone, setTimezone] = useState();
  const [filterArea, setFilterArea] = useState([]);
  const [filterSite, setFilterSite] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [filterLine, setFilterLine] = useState([]);
  const [lineOptions, setLineOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [filterShift, setFilterShift] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [filterSector, setFilterSector] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [shiftSelected, setShiftSelected] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [ts_start, setTs_start] = useState(default_start);
  const [ts_end, setTs_end] = useState(default_end);
  const [visualizationLevel, setVisualizationLevel] = useState("SITE");
  const [grain, setGrain] = useState("DAY");
  const [typeOfView, setTypeOfView] = useState("GENERAL");
  const [menu, setMenu] = useState([]);
  const [scrapCalcType, setScrapCalcType] = useState(1);
  const [logoUrl, setLogoUrl] = useState(1);
  const [eventTabsOpen, setEventTabOpen] = useState(false);

  const [dateRange, setRange] = useState(null);
  let temp = [];

  const [getData, { data: data1 }] = useLazyQuery(GET_VARIABLES_CONTEXT,
    {
      onCompleted: () => {

        setUserPermissions(data1?.user_roles?.[0]?.permissions?.desktop);

        let res = data1?.v_entities_per_user_role?.[0];

        localStorage.setItem("language_packs", JSON.stringify(data1?.users?.[0]?.language?.language_pack_desktop));
        // localStorage.setItem("language_packs", JSON.stringify(data1?.language_packs?.[0]?.language_pack_desktop);

        localStorage.setItem("piot_username", data1?.users?.[0]?.user_name);
        localStorage.setItem("user_language", data1?.users?.[0]?.languages);

        setEnterprise(res?.enterprise?.id_enterprise);
        localStorage.setItem("id_enterprise", res?.enterprise?.id_enterprise)

        setScrapCalcType(data1?.enterprises?.[0]?.scrap_calc_type);
        localStorage.setItem("scrap_calc_type", data1?.enterprises?.[0]?.scrap_calc_type)

        setLogoUrl(data1?.enterprises?.[0]?.logo_url);
        localStorage.setItem("logoUrl", data1?.enterprises?.[0]?.logo_url)

        setNameEnterprise(res?.enterprise?.nm_enterprise);
        localStorage.setItem("enterpriseName", res?.enterprise?.nm_enterprise)

        setMenu(data1?.v_menu_per_user_role?.[0]?.menu);
        localStorage.setItem("menu", JSON.stringify(data1?.v_menu_per_user_role?.[0]?.menu));

        // SITES
        res?.sites?.map((site) => {
          temp.push(site?.id_site);
        });
        setFilterSite(temp);
        setSiteOptions(res?.sites);

        // AREA
        temp = [];
        res?.areas?.map((area) => {
          temp.push(area?.id_area);
        });
        setFilterArea(temp);
        setAreaOptions(res?.areas);

        // EQUIPMENTS
        temp = [];
        res?.equipments?.map((equip) => {
          temp.push(equip?.id_equipment);
        });
        setFilterLine(temp);
        // setLineOptions(res?.equipments ? res?.equipments?.sort((a,b)=>sortArrayOfObjectsBy((a, b, 'nm_equipment'))) :[]);
        setLineOptions(res?.equipments);


        // SHIFTS
        temp = [];
        /* res?.shifts?.map((shift) => {
          temp.push(shift?.cd_shift);
        }); */
        /* data1?.shifts?.map((item) => {
          temp.push(item?.cd_shift);
        }) */


        //setFilterShift(temp);
        //res?.shifts ? setShiftOptions(res?.shifts) : null;
        data1?.shifts ? setShiftOptions(data1?.shifts) : null;

        // SECTORS
        temp = [];
        res?.sectors?.map((sector) => {
          temp.push(sector?.id_equipment);
        });

        setFilterSector(temp);
        setSectorOptions(res?.sectors);

        // TEAMS
        temp = [];
        res?.teams?.map((team) => {
          temp.push(team?.id_team);
        });

        setFilterTeam(temp);
        res?.teams ? setTeamOptions(res?.teams) : null;


      },
      fetchPolicy: 'no-cache',
    }
  );



  const initialValues = function () {
    getData();
    const start = localStorage.getItem("ts_start");
    if (start && start !== "") {
      setTs_start(moment(start).format('YYYY-MM-DD HH:mm'));
      localStorage.setItem("ts_start", moment(start).format('YYYY-MM-DD HH:mm'));
    }
    else {
      setTs_start(default_start);
      localStorage.setItem("ts_start", default_start);
    }

    const end = localStorage.getItem("ts_end");
    if (end && end !== "") {
      setTs_end(moment(end).format('YYYY-MM-DD HH:mm'));
      localStorage.setItem("ts_end", moment(end).format('YYYY-MM-DD HH:mm'));
    }
    else {
      setTs_end(default_end);
      localStorage.setItem("ts_end", default_end);
    }

    const enterprise_local = localStorage.getItem("enterprise");
    enterprise_local != "null" ? setEnterprise(enterprise_local) : null;
    enterprise_local != "null" ? localStorage.setItem("enterprise", enterprise_local) : null;

    const site_local = localStorage.getItem("site");
    site_local != "null" ? setSite(site_local) : null;
    site_local != "null" ? localStorage.setItem("site", site_local) : null;

    const line = localStorage.getItem("line");
    line != "null" ? setLine(line) : null;
    line != "null" ? localStorage.setItem("line", line) : null;

    const area = localStorage.getItem("area");
    area != "null" ? setArea(area) : null;
    area != "null" ? localStorage.setItem("area", area) : null;

    const visualizationLevel = localStorage.getItem("visualizationLevel");
    setVisualizationLevel(visualizationLevel);
    localStorage.setItem("visualizationLevel", visualizationLevel);

    const savedTimezone = localStorage.getItem("timezone");
    savedTimezone != "null" ? setTimezone(savedTimezone) : null;
    savedTimezone != "null" ? localStorage.setItem("timezone", savedTimezone) : null;

    let range = localStorage.getItem("range");
    if (!range) range = 'month';
    if (range === 'day' || range === 'today') setGrain('hour');
    setRange(range)

    localStorage.setItem("range", range);

    let menu = localStorage.getItem("menu");
    //menu !== "null" ? setMenu(JSON.parse(menu)) : null;

  }

  useEffect(() => {
    setTimeout(() => {
      initialValues();
    }, 1500)

  }, [])

  function changeSite(prop) {
    setSite(prop);
    setArea();
    setLine();
    localStorage.setItem("site", prop);
    localStorage.setItem("area", "");
    localStorage.setItem("line", "");
    setVisualizationLevel("SITE");
    localStorage.setItem("visualizationLevel", "SITE");

    const selectedSite = siteOptions.find((site) => site.id_site == prop);
    selectedSite?.timezone ? setTimezone(selectedSite?.timezone) : null;
    selectedSite?.timezone ? localStorage.setItem("timezone", selectedSite?.timezone) : null;
  };
  function changeArea(prop) {
    setArea(prop);
    setLine();
    localStorage.setItem("area", prop);
    localStorage.setItem("line", "");
    setVisualizationLevel("AREA");
    localStorage.setItem("visualizationLevel", "AREA");
  };
  function changeLine(prop) {
    setLine(prop);
    localStorage.setItem("line", prop);
    setVisualizationLevel("EQUIPMENT");
    localStorage.setItem("visualizationLevel", "EQUIPMENT");
  };

  function changeTs_start(prop) {
    setTs_start(prop);
    localStorage.setItem("ts_start", prop);
  };
  function changeTs_end(prop) {
    setTs_end(prop);
    localStorage.setItem("ts_end", prop);
  };
  function changeEnterprise(prop) {
    setEnterprise(prop);
  };

  function changeRange(prop) {
    if (prop === 'day' || prop === 'today') setGrain('HOUR')
    setRange(prop);
    localStorage.setItem("range", prop);
  }

  const toggleEventsTab = () => setEventTabOpen(!eventTabsOpen);

  function clearContext() {
    localStorage.clear();
    setRange(null);
    setEnterprise(null);
    setSite(null);
    setArea(null);
    setLine(null);
    setFilterArea([]);
    setAreaOptions([]);
    setFilterSite([]);
    setSiteOptions([]);
    setFilterLine([]);
    setLineOptions([]);
    setFilterSector([]);
    setSectorOptions([]);
    setFilterShift([]);
    setShiftOptions([]);
    setTs_start(default_start);
    setTs_end(default_end);
    setVisualizationLevel(null);
    setMenu(null);
  }

  return (
    <VariablesContext.Provider
      value={{
        enterprise,
        setEnterprise: changeEnterprise,
        nm_enterprise: nameEnterprise,
        site,
        setSite: changeSite,
        area,
        setArea: changeArea,
        line,
        setLine: changeLine,
        filterArea,
        setFilterArea,
        filterSite,
        setFilterSite,
        filterLine,
        setFilterLine,
        filterSector,
        setFilterSector,
        filterShift,
        setFilterShift,
        shiftSelected,
        setShiftSelected,
        filterTeam,
        setFilterTeam,
        ts_start,
        setTs_start: changeTs_start,
        ts_end,
        setTs_end: changeTs_end,
        shiftOptions,
        teamOptions,
        lineOptions,
        areaOptions,
        siteOptions,
        sectorOptions,
        visualizationLevel,
        dateRange,
        setRange: changeRange,
        grain,
        setGrain,
        typeOfView,
        setTypeOfView,
        clearContext,
        initialValues,
        userPermissions,
        menu,
        scrapCalcType,
        logoUrl,
        timezone,
        eventTabsOpen,
        setEventTabOpen: toggleEventsTab
      }}>
      {children}
    </VariablesContext.Provider>
  );
}


export { VariablesContext, VariablesProvider };
