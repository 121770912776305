import { gql } from '@apollo/client';

export const SET_PRODUCTION_TARGET = gql`
mutation setProductionTarget(
  $id_enterprise: Int!
  $id_equipment: Int!
  $vl_hour: Int!
  $vl_day: Int!
  $vl_week: Int!
  $vl_month: Int!
) {
  h_piot_set_production_target(
    args: {
      in_id_enterprise: $id_enterprise
      in_id_equipment: $id_equipment
      in_target_hour: $vl_hour
      in_target_day: $vl_day
      in_target_week: $vl_week
      in_target_month: $vl_month
      proportional: false
    }
  ) {
    id_equipment
    vl_hour
    vl_day
    vl_month
    vl_week
  }
}
`;

export const SET_SCRAP_TARGET = gql`
mutation setScrapTarget(
  $id_enterprise: Int!
  $id_equipment: Int!
  $vl_day: Int
) {
  h_piot_set_scrap_target(
    args: {
      in_id_enterprise: $id_enterprise
      in_id_equipment: $id_equipment
      in_target_day: $vl_day
      proportional: true
    }
  ) {
    vl_month
    vl_day
    vl_week
    vl_hour
    vl_shift
    id_equipment
  }
}
`
export const SET_CUSTOM_TARGET_DAY = gql`
mutation SetCustomTargetDay($target: float8, $ts_value: date, $id_equipment: Int) {
  insert_equipment_runtime_1day(
    objects: {
      target: $target
      target_customized: true
      ts_value: $ts_value
      id_equipment: $id_equipment
    }
    on_conflict: {
      constraint: equipment_runtime_1day_pk
      update_columns: [target, target_customized]
    }
  ) {
    affected_rows
  }
}

`;

export const SET_CUSTOM_TARGET_WEEK = gql`
mutation SetCustomTargetWeek($target: float8, $ts_value: date, $id_equipment: Int) {
  insert_equipment_runtime_1week(
    objects: {
      target: $target
      target_customized: true
      ts_value: $ts_value
      id_equipment: $id_equipment
    }
    on_conflict: {
      constraint: equipment_runtime_1week_pk
      update_columns: [target, target_customized]
    }
  ) {
    affected_rows
  }
}

`;

export const SET_CUSTOM_TARGET_MONTH = gql`
mutation SetCustomTargetMonth($target: float8, $ts_value: date, $id_equipment: Int) {
  insert_equipment_runtime_1month(
    objects: {
      target: $target
      target_customized: true
      ts_value: $ts_value
      id_equipment: $id_equipment
    }
    on_conflict: {
      constraint: equipment_runtime_1month_pk
      update_columns: [target, target_customized]
    }
  ) {
    affected_rows
  }
}

`;

export const REMOVE_CUSTOM_TARGET_DAY = gql`
mutation RemoveCustomTargetDay($id_equipment: Int, $ts_value: date) {
  update_equipment_runtime_1day(where: {id_equipment: {_eq: $id_equipment}, ts_value: {_eq: $ts_value}}, _set: {target_customized: false, recalc_needed: true}) {
    affected_rows
  }
}
`;

export const REMOVE_CUSTOM_TARGET_WEEK = gql`
mutation RemoveCustomTargetWeek($id_equipment: Int, $ts_value: date) {
  update_equipment_runtime_1week(where: {id_equipment: {_eq: $id_equipment}, ts_value: {_eq: $ts_value}}, _set: {target_customized: false, recalc_needed: true}) {
    affected_rows
  }
}
`;

export const REMOVE_CUSTOM_TARGET_MONTH = gql`
mutation RemoveCustomTargetMonth($id_equipment: Int, $ts_value: date) {
  update_equipment_runtime_1month(where: {id_equipment: {_eq: $id_equipment}, ts_value: {_eq: $ts_value}}, _set: {target_customized: false, recalc_needed: true}) {
    affected_rows
  }
}
`;