import React, { useState, useEffect, useRef } from 'react';
import { Button, Dialog, DialogContent, Box, } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useMutation, useLazyQuery } from '@apollo/client';
import { DELETE_PO } from '../queries'
import { getLanguage } from '../../../../../utils/utils';
import { useSnackbar } from 'notistack';

export default function DialogDelete({ isOpen, handleClose, selectedIDs, update }) {
  const language = getLanguage();
  const { enqueueSnackbar } = useSnackbar();

  let confirmMsg = language?.delete_po || "You're about to delete the PO id_order. Are you sure about this?";
  // confirmMsg = confirmMsg.replace("id_order", selectedIDs?.toString());
  confirmMsg = confirmMsg.replace("id_order", (
    selectedIDs.length == 1
      ? selectedIDs?.[0]?.id?.toString()
      : selectedIDs.length + ' Production Orders'
  ));
  confirmMsg = confirmMsg.replace("PO", (selectedIDs.length == 1 ? "PO" : " "));

  const [deleteEvent, { data: dataInfo, loading, error }] = useMutation(DELETE_PO, {
    onCompleted: () => {
      handleClose();
      console.log(error);
      enqueueSnackbar(`PO ${language?.deleted || 'Deleted'}`, { variant: 'success' });
      update();
    },
    onError: () => {
      handleClose();
      enqueueSnackbar(`${language?.an_error_occured || 'Something went wrong!'}`, { variant: 'error' });
    }
  })

  const handleDeleteButton = () => {
    const listOfIds = selectedIDs.map(e => e?.id_production_order);

    deleteEvent({
      variables: {
        id_production_order: listOfIds,
      }
    })

  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'}>

      <DialogContent sx={{ pr: 3, pl: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

        <Box sx={{ textAlign: 'center', width: '360px', mb: '24px' }}>
          <Typography variant='subtitle1'> {confirmMsg} </Typography>
        </Box>
        <Button sx={{ borderRadius: 2, px: 4, width: 251, height: 45, }}
          color='error' size="small" variant="contained"
          onClick={handleDeleteButton} >
          {language?.delete || 'Delete'}
        </Button>

        <Button
          sx={{ width: 157, mt: '16px', height: 45, borderRadius: 2, px: 3, border: '2px solid #C13939', color: '#000', background: '#FFF', '&:hover': { background: '#EAEAED' } }}
          variant="contained" disableElevation size="small"
          onClick={handleClose}
        >
          {language?.cancel || 'Cancel'}
        </Button>

      </DialogContent>

    </Dialog>
  );
}
