import styled from 'styled-components';

export const Nav = styled.nav`
  width: 100%;
  height: 110px;
  display: flex;
  overflow-x: scroll;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (min-width: 900px) {
    height: 100%;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
`;

export const NavItem = styled.a`
  width: 200px;
  height: 87px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-decoration: none;
  margin-top: 5px;
  color: #333333;

  ${(props) => props.active ? 'background: #EAEAED;' : ''}
  ${(prosps) => prosps.active ? 'border-bottom: 5px solid #407CCC;' : ''}
  
  &:hover {
    color: #333333;
  }

  span {
    margin-left: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
  }
  p {
    margin-left: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
  }
  @media (min-width: 900px) {
    width: 100%;
    ${(props) => props.active ? 'background: #EAEAED;' : ''}
    ${(prosps) => prosps.active ? 'border-left: 5px solid #407CCC;' : ''}
    ${(prosps) => prosps.active ? 'border-bottom: none;' : ''}
  }
  &:last-child {
    margin-bottom: 50px;
  }
  
`;

export const NavSkeleton = styled.div`
  width: 85%;
  margin-left: 21px;
  height: 60px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 5px;

  animation: 1.5s ease-in-out 0.5s infinite animation-skeleton;

  @keyframes animation-skeleton {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

