import React, { useState, useContext, useEffect } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput,
} from '@mui/material';


export default function ShiftFilter() {
  const { shiftOptions, filterShift, shiftSelected, setShiftSelected, site, setFilterShift } = useContext(VariablesContext);
  const [displayShifts, setDisplayShifts] = useState([]);
  const language = getLanguage();

  const handleChangee = (event) => {

    const arrayValues = event?.target?.value?.map(x => x);
    const shiftsFiltered = [];

    for (let shift of arrayValues) {
      let item = displayShifts?.findIndex((item) => item.id_shift === shift);
      shiftsFiltered.push(displayShifts[item]?.cd_shift);
    }
    setShiftSelected(arrayValues);
    setFilterShift(shiftsFiltered);
  };


  useEffect(() => {
    if (site && shiftOptions) {

      let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

      let sortItems = shiftOptions?.sort((a, b) => collator.compare(a?.name_site?.nm_site, b?.name_site?.nm_site));
      sortItems = sortItems?.filter((item) => item?.name_site?.id_site == site);

      let selectedIds = sortItems?.map((item) => item?.id_shift);
      let selectedCodes = sortItems?.map((item) => item?.cd_shift);

      setFilterShift(selectedCodes);
      setShiftSelected(selectedIds);

      setDisplayShifts(sortItems);
    }

  }, [shiftOptions, site])


  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 170, }}>
        <InputLabel sx={{ m: shiftSelected?.length == 0 ? -1 : 0 }}>
          {shiftSelected?.length == 0 ? <FilterAltOutlinedIcon /> : ""}
          {language?.filter_shift || 'Filter Shift'}
        </InputLabel>
        <Select
          multiple
          value={shiftSelected}
          onChange={handleChangee}
          input={
            <OutlinedInput
              sx={{ maxHeight: 40, }}
              label={language?.filter_area || 'Filter Site'} />
          }
          renderValue={
            (selected) => (
              shiftSelected?.length >= 1
                ? shiftSelected?.length + " " + `${language.selected || 'Selected'}`
                : shiftOptions.filter(sites => sites.cd_shift == selected)?.[0]?.cd_shift
            )
          }>

          {displayShifts?.length > 0
            ? displayShifts?.map((shift, index) => (
              <MenuItem key={shift.id_shift} value={shift.id_shift}>
                <Checkbox sx={{ m: -1, mr: .3 }} checked={shiftSelected.includes(shift.id_shift)} />
                <Typography variant="body2"> {shift?.name_site?.nm_site} - {shift.cd_shift} </Typography>
              </MenuItem>
            ))
            : language?.no_shifts_available || 'No shifts available'}

        </Select>
      </FormControl>
    </>
  );
};