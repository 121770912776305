import React, { useCallback } from 'react';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getLanguage, getDataGridConfig, setDataGridConfig } from '../../../../../utils/utils';
import { Box } from '@mui/material';
import { Typography } from '@material-ui/core';

import CustomDetailPanelToggle from './CustomDetailPanelToggle';
import CustomToolbar from './CustomToolbar';
import DetailPanelContent from './CategoriesTable';

function DwtReasonsTable({ data, selectedLine, setSelectedLine, toggleCsvUploadModal }) {
  const language = getLanguage();
  const dataGridConfig = getDataGridConfig('dw-settings');

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerName: `${language?.details || 'Details'}`,
      disableColumnMenu: true,
      filterable: false,
      hideable: false,
      disableReorder: true,
      hideSortIcons: true,
      width: 80,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: 'code', headerName: `${language?.code || 'Code'}`,
      flex: 1,
      minWidth: 90
    },
    {
      field: 'machine',
      headerName: `${language?.machine || 'Machine'}`,
      editable: false,
      flex: 1,
      minWidth: 110
    },
    {
      field: 'idle',
      headerName: `${language?.idle || 'IDLE'}`,
      editable: false,
      flex: 1,
      minWidth: 110
    },
    {
      field: 'name',
      headerName: `${language?.name || 'Name'}`,
      editable: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params) => <span>{params?.row?.name?.['en-US']}</span>
    },
    {
      field: 'change_over',
      headerName: `${language?.change_over || 'Setup'}`,
      editable: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params) => <span>{params?.row?.change_over ? `${language?.yes || 'Yes'}` : `${language?.no || 'No'}`} </span>
    },
    {
      field: 'planned_downtime',
      headerName: `${language?.planned || 'Planned'}`,
      editable: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params) => <span>{params?.row?.planned_downtime ? `${language?.yes || 'Yes'}` : `${language?.no || 'No'}`} </span>
    },
    {
      field: 'description',
      headerName: `${language?.description || 'Description'}`,
      editable: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params) => <span>{params?.row?.description?.['en-US']}</span>
    }
  ];

  const getDetailPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, [],);

  return (
    <>
      <Box sx={{ height: 740, width: '100%', }}>
        <DataGridPro
          sx={{ border: 'none' }}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => (
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CancelOutlinedIcon sx={{ color: '#828282', fontSize: '32px' }} />
                <Typography variant='h3' style={{ color: '#828282' }}>
                  {language?.downtime_reason_select_line_plese || 'Please, select a Equipment to see its Downtime Reasons'}
                </Typography>
              </Box>
            )
          }}
          rows={data || []}
          columns={columns}
          getRowId={(row) => ('' + row?.machine + row?.code)}
          onColumnVisibilityModelChange={(newModel) =>
            setDataGridConfig('dw-settings', { filterName: 'columns', value: newModel })
          }
          initialState={ {
            pagination: {
              pageSize: dataGridConfig?.rowsPerPage
            },
            filter: {
              filterModel: {
                items: dataGridConfig?.filter,
              },
            },
            columns: {
              columnVisibilityModel: dataGridConfig?.columns
            }
          }}
          rowsPerPageOptions={[5, 10, 15, 50, 100]}
          onPageSizeChange={(newPageSize) => setDataGridConfig('dw-settings', { filterName: 'rowsPerPage', value: newPageSize }) }
          onFilterModelChange={(e) => setDataGridConfig('dw-settings', {filterName: 'filter', value: e.items}) }
          pagination
          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              data: data,
              selectedLine,
              setSelectedLine,
              toggleCsvUploadModal
            },
          }}
          getDetailPanelContent={getDetailPanelContent}
          localeText={{
            columnsPanelTextFieldLabel: `${language?.find_column || 'Find column'} `,
            columnsPanelTextFieldPlaceholder: `${language?.column_title || 'Column title'}`,
            columnsPanelShowAllButton: `${language?.show_all || 'Show all'}`,
            columnsPanelHideAllButton: `${language?.hide_all || 'Hide all'}`,
            toolbarColumns: `${language?.columns || 'Columns'}`,
          }}
        />
      </Box>
    </>
  );
}

export default DwtReasonsTable;
