import * as React from 'react';
import { Dialog, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as InfoEnBig } from '../../../assets/MissionControl/InfoCardMissionControlEn.svg';
import { ReactComponent as InfoPtBig } from '../../../assets/MissionControl/InfoCardMissionControlPt.svg';
import { ReactComponent as InfoPlBig } from '../../../assets/MissionControl/InfoCardMissionControlPl.svg';

import { ReactComponent as InfoEnMob } from '../../../assets/MissionControl/InfoCardMissionControlEnMob.svg';
import { ReactComponent as InfoPtMob } from '../../../assets/MissionControl/InfoCardMissionControlPtMob.svg';
import { ReactComponent as InfoPlMob } from '../../../assets/MissionControl/InfoCardMissionControlPlMob.svg';

export default function InfoDialog() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:1200px)', { noSsr: true });
  let where = window.localStorage.getItem('user_language');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let optionsDesktop = {
    'pt-BR': (<InfoPtBig />),
    'en-US': (<InfoEnBig />),
    'pl-PL': (<InfoPlBig />),
  };

  let optionsMobile = {
    'pt-BR': (<InfoPtMob />),
    'en-US': (<InfoEnMob />),
    'pl-PL': (<InfoPlMob />),
  }

  function InfoImage(language) {
    if (isMobile) {
      return optionsMobile[language];
    }
    return optionsDesktop[language];
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} >
        <InfoOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xl'}
      >
        {InfoImage(where)}

      </Dialog>
    </div>
  );
}
