import { gql } from "@apollo/client";

export const GET_SINGLE_PERIOD_DATA = gql`
query GetSinglePeriodProduction(
    $ts_start: timestamptz
    $ts_end: timestamptz
    $areas: String
    $equipments: String
    $id_enterprise: Int
    $shifts: String
    $teams: String
    $sites: String
    $grain: String
    $group_by_element: String = "GENERAL"
  ) {
    h_piot_single_period_with_teams_3(
      args: {
        in_begin_time: $ts_start
        in_end_time: $ts_end
        in_id_areas: $areas
        in_id_enterprise: $id_enterprise
        in_id_equipments: $equipments
        in_id_shifts: $shifts
        in_id_teams: $teams
        in_id_sites: $sites
        time_grain: $grain
        group_by_element: $group_by_element
      }
    ) {
      gross
      net
      scrap
      array_agg
      target
      ts_value_production
    }
  }
  
`;

export const GET_TARGETS = gql`
query GetTarget(
  $group: String
  $ts_start: timestamptz
  $ts_end: timestamptz
  $in_id_areas: String
  $id_enterprise: Int
  $in_id_equipments: String
  $in_id_shifts: String
  $in_id_sites: String
  $in_id_teams: String
  $nav_level: String
  $time_grain: String
) {
  h_piot_get_targets(
    args: {
      group_by_element: $group
      in_begin_time: $ts_start
      in_end_time: $ts_end
      in_id_areas: $in_id_areas
      in_id_enterprise: $id_enterprise
      in_id_equipments: $in_id_equipments
      in_id_shifts: $in_id_shifts
      in_id_sites: $in_id_sites
      in_id_teams: $in_id_teams
      nav_level: $nav_level
      time_grain: $time_grain
    }
  ) {
    array_agg
    id_enterprise
    target
    ts_value_production
  }
}

`;