import { gql } from "@apollo/client";

export const GET_SINGLE_PERIOD_DATA = gql`
query GetSinglePeriodProduction(
    $ts_start: timestamptz
    $ts_end: timestamptz
    $areas: String
    $equipments: String
    $id_enterprise: Int
    $shifts: String
    $teams: String
    $sites: String
    $grain: String
    $group_by_element: String = "GENERAL"
  ) {
    h_piot_single_period_with_teams_4(
      args: {
        in_begin_time: $ts_start
        in_end_time: $ts_end
        in_id_areas: $areas
        in_id_enterprise: $id_enterprise
        in_id_equipments: $equipments
        in_id_shifts: $shifts
        in_id_teams: $teams
        in_id_sites: $sites
        time_grain: $grain
        group_by_element: $group_by_element
      }
    ) {
      gross
      net
      scrap
      array_agg
      target
      ts_value_production
      scrap_targets
      scrap_percentage
      }
  }
  
`;