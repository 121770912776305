import React from 'react';
import { Box, Typography } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

import { getLanguage } from '../../../utils/utils';
import LightTooltip from '../../../components/LightTooltip';
import NoData from '../../../components/NoData';

const calculatePercentegeStatus = (array) => {
  let result = array.reduce(
    function (prev, cur) {
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {});

  for (let status in result) {
    result[status] /= (array.length / 100);
  }
  return result;
}

function StatusHistory({ data }) {
  const language = getLanguage();
  let statusPercentage = data?.status_24h ? calculatePercentegeStatus(data?.status_24h?.filter((item) => item !== null)) : {};
  let plannedPercent = data?.stopped_time ? data.planned_duration_percent ? data.planned_duration_percent * 100 : 0 : 100 / 3;
  let unplannedPercent = data?.stopped_time ? data.unplanned_duration_perc ? data.unplanned_duration_perc * 100 : 0 : 100 / 3;
  let changeOverPercent = data?.stopped_time ? data.change_over_duration_percent ? data.change_over_duration_percent * 100 : 0 : 100 / 3;
  plannedPercent = plannedPercent.toFixed(0);
  unplannedPercent = unplannedPercent.toFixed(0);
  changeOverPercent = changeOverPercent.toFixed(0);
  const showStatusGraph = data?.status_24h?.some(element => element !== null);




  return (
    <Box
      sx={{
        width: '100%', height: '100px', display: 'flex', alignItems: 'center', mt: '10px',
        justifyContent: 'space-between', mt: '10px', borderTop: '1px solid #E3E2E7',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: { lg: '336px', xl: '48%' } }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#BDBDBD', textTransform: 'uppercase' }}> {language?.running_time || 'Running Time'} </Typography>
        {
          showStatusGraph ?
            <Box sx={{ display: 'flex', width: { lg: '336px', xl: '100%' }, mt: '10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${statusPercentage?.["running"] || 0}%`, }}>
                <LightTooltip title={`${language?.running || 'Running'}: ${statusPercentage?.["running"]?.toFixed(0) || 0}%`} placement='top'>
                  <Box sx={{ width: '100%', height: '12px', background: '#318F29', borderRadius: '12px 0px 0px 12px', mb: '5px' }} />
                </LightTooltip>
                {statusPercentage?.["running"] > 0 && <PlayCircleFilledWhiteOutlinedIcon sx={{ color: '#BDBDBD' }} />}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${((statusPercentage?.["stopped"] || 0) + (statusPercentage?.["changeOver"] || 0))?.toFixed(0) || 0}%` }}>
                <LightTooltip title={`${language?.stopped || 'Stopped'}: ${((statusPercentage?.["stopped"] || 0) + (statusPercentage?.["changeOver"] || 0))?.toFixed(0) || 0}%`} placement='top'>
                  <Box sx={{ width: '100%', height: '12px', background: '#C13939', borderRadius: '0px', mb: '5px' }} />
                </LightTooltip>
                {statusPercentage?.["stopped"] > 0 && <PauseCircleOutlineOutlinedIcon sx={{ color: '#BDBDBD' }} />}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${statusPercentage?.["lowSpeed"] || 0}%`, }}>
                <LightTooltip title={`${language?.low_speed || 'Low Speed'}: ${statusPercentage?.["lowSpeed"]?.toFixed(0) || 0}%`} placement='top'>
                  <Box sx={{ width: '100%', height: '12px', background: '#ECBC13', borderRadius: '0px 12px 12px 0px', mb: '5px' }} />
                </LightTooltip>
                {statusPercentage?.["lowSpeed"] > 0 && <SpeedOutlinedIcon sx={{ color: '#BDBDBD' }} />}
              </Box>

            </Box> : <Box sx={{ display: 'flex', width: { lg: '336px', xl: '280px' }, mt: '10px' }}> <NoData variant='body1' message={language?.arent_runtime || "There aren't any runtimes"} /> </Box>
        }
      </Box>

      <Box sx={{ width: '1px', height: '50px', background: '#E3E2E7' }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', width: { lg: '336px', xl: '48%' } }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#BDBDBD', textTransform: 'uppercase' }}> {language?.downtime_reasons || "DOWNTIME REASONS"}  </Typography>
        <Box sx={{ display: 'flex', width: { lg: '336px', xl: '100%' }, mt: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${plannedPercent}%`, }}>
            <LightTooltip title={`${language?.planned || 'Planned'}: ${plannedPercent}%`} placement='top'>
              <Box sx={{ width: '100%', height: '12px', background: '#407CCC', borderRadius: '12px 0px 0px 12px', mb: '5px' }} />
            </LightTooltip>
            {plannedPercent > 0 && <CheckCircleOutlineOutlinedIcon sx={{ color: '#BDBDBD' }} />}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${unplannedPercent}%`, }}>
            <LightTooltip title={`${language?.unplanned || 'Unplanned'}: ${unplannedPercent}%`} placement='top'>
              <Box sx={{ width: '100%', height: '12px', background: '#C13939', borderRadius: '0px', mb: '5px' }} />
            </LightTooltip>
            {unplannedPercent > 0 && <CancelOutlinedIcon sx={{ color: '#BDBDBD' }} />}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: `${changeOverPercent}%`, }}>
            <LightTooltip title={`${language?.change_over || 'Change Over'}: ${changeOverPercent}%`} placement='top'>
              <Box sx={{ width: '100%', height: '12px', background: '#ECBC13', borderRadius: '0px 12px 12px 0px', mb: '5px' }} />
            </LightTooltip>
            {changeOverPercent > 0 && <ChangeCircleOutlinedIcon sx={{ color: '#BDBDBD' }} />}
          </Box>

        </Box>

      </Box>

    </Box>
  );
}

export default StatusHistory;