import React, { useEffect, useState } from 'react';
import { getDataGridConfig, setDataGridConfig, escapeRegExp } from '../../../utils/utils';
import {
  DataGridPro, GridToolbarColumnsButton,
  useGridApiRef, GridActionsCellItem
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import LightTooltip from '../../../components/LightTooltip';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { getLanguage, dateFormat as DateFormats } from '../../../utils/utils';
import { Typography, } from '@material-ui/core';
import DialogEdit from './DialogEdit';
import DialogTrim from './DialogTrim';

function CustomToolbar() {
  const language = getLanguage();
  return (
    // <GridToolbarContainer >
    <Box sx={{ display: { xs: 'flex', sm: 'flex' }, justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
      <Typography variant="h3" id="tableTitle" component="div"> {language?.downtime_details || 'Downtime Details'} </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GridToolbarColumnsButton sx={{ bgcolor: '#EAEAED', width: '140px', borderRadius: '8px', color: '#333333', my: { xs: 1, sm: 0 } }} />
        {/* <QuickSearchToolbar /> */}
      </Box>
    </Box>
    // </GridToolbarContainer>
  );
}

function DowntimeTable(props) {
  const { data, update, isSectorView } = props;
  const [dataModel, setDataModel] = useState();
  const language = getLanguage();
  const [searchText, setSearchText] = useState('');
  const [openTrim, setOpenTrim] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [rows, setRows] = React.useState([]);
  const dataGridConfig = getDataGridConfig('downtimes');

  const apiRef = useGridApiRef();

  const handleClickOpenTrim = (id) => {
    setDataModel(rows[id]);
    setOpenTrim(true);
  };

  const handleCloseTrim = () => {
    setOpenTrim(false);
  };


  const handleClickOpenEdit = (id) => {
    setDataModel(rows[id]);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const columns = [
    { field: 'id_order', headerName: `${language?.job || 'Job'}`, width: 120 },
    {
      field: 'nm_equipment',
      headerName: `${language?.line || 'Line'}`,
      width: 170,
      editable: false,
    },
    {
      field: 'sector',
      headerName: `${language?.sector || 'Sector'}`,
      width: 160,
      editable: false,
      hide: !isSectorView,
    }, {
      field: 'cd_shift',
      headerName: `${language?.shift || 'Shift'}`,
      width: 120,
      editable: false,
    }, {
      field: 'ts_event',
      headerName: `${language?.start || 'Start'}`,
      width: 160,
      editable: false,
      renderCell: (el) => DateFormats(el.value, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    }, {
      field: 'ts_end',
      headerName: `${language?.end || 'End'}`,
      width: 160,
      editable: false,
      renderCell: (el) => DateFormats(el.value, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    }, {
      field: 'total',
      headerName: `${language?.total_time || 'Total Time'}`,
      width: 130,
      editable: false,
    }, {
      field: 'cd_machine',
      headerName: 'Machine',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.cd_machine} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_machine}</span>
        </LightTooltip>
      )
    }, {
      field: 'cd_category',
      headerName: `${language?.category || 'Category'}`,
      width: 120,
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.txt_category} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_category}</span>
        </LightTooltip>
      )
    }, {
      field: 'cd_subcategory',
      headerName: `${language?.sub_category || 'Sub category'}`,
      width: 120,
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.txt_subcategory} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_subcategory}</span>
        </LightTooltip>
      )
    }, {
      field: 'txt_downtime_notes',
      headerName: `${language?.notes || 'Notes'}`,
      width: 260,
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.txt_downtime_notes} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.txt_downtime_notes}</span>
        </LightTooltip>
      )
    },
    {
      field: 'manual_event',
      headerName: `${language?.manual_event || 'Manual event'}`,
      width: 120,
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params?.row?.manual_event ? language?.manual_event || "Manual event" : 'Event detected by PackIoT'} placement='top'>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params?.row?.manual_event ? <TouchAppOutlinedIcon /> : ' '}
          </span>
        </LightTooltip>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: `${language?.actions || 'Actions'}`,
      cellClassName: 'actions',
      getActions: ({ id }) => {

        return [
          <LightTooltip title={language?.edit || 'Edit'} placement='top'>
            <GridActionsCellItem
              icon={<ModeEditOutlineOutlinedIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleClickOpenEdit(id)}
              color="inherit"
            />
          </LightTooltip>
          ,
          <LightTooltip title={language?.split || 'Split'} placement='top'>
            <GridActionsCellItem
              icon={<CallSplitIcon />}
              label="Cut"
              onClick={() => handleClickOpenTrim(id)}
              color="inherit"
            />
          </ LightTooltip>
          ,
        ];
      },
    },

  ];

  useEffect(() => setRows(data), [data]);


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <>
      <DialogTrim update={update} isSectorView={isSectorView} isOpen={openTrim} handleClose={handleCloseTrim} data={dataModel} />

      <DialogEdit update={update} isSectorView={isSectorView} isOpen={openEdit} handleClose={handleCloseEdit} data={dataModel} />

      <Box sx={{
        // height: 700,
        height: { xs: 520, sm: 470 },
        width: '100%', '& .super-app-theme--Open': {
          bgcolor: '#FFF4DE',
          fontWeight: 500,
          '&:hover': { bgcolor: '#FFF4DE' }
        },
        '& .super-app-theme--not-finished': {
          bgcolor: '#E8CDCF',
          fontWeight: 500,
          '&:hover': { bgcolor: '#E8CDCF' }
        }
      }}>
        <DataGridPro
          sx={{ border: 'none' }}
          apiRef={apiRef}
          components={{ Toolbar: CustomToolbar }}
          rows={rows}
          columns={columns}
          getRowClassName={(params) => {
            if (params?.row?.ts_end === '-') return 'super-app-theme--not-finished';
            if (!params?.row?.cd_category) return 'super-app-theme--Open';
          }}
          onColumnVisibilityModelChange={(newModel) =>
            setDataGridConfig('downtimes', { filterName: 'columns', value: newModel })
          }
          initialState={{
            pagination: {
              pageSize: dataGridConfig?.rowsPerPage
            },
            filter: {
              filterModel: {
                items: dataGridConfig?.filter,
              },
            },
            columns: {
              columnVisibilityModel: dataGridConfig?.columns
            }
          }}
          // disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
              apiRef
            },
          }}
          onPageSizeChange={(newPageSize) => setDataGridConfig('downtimes', { filterName: 'rowsPerPage', value: newPageSize })}
          onFilterModelChange={(e) => {
            setDataGridConfig('downtimes', { filterName: 'filter', value: e.items });
          }}
          rowsPerPageOptions={[5, 10, 15, 50, 100]}
          pagination
          {...data}
        />
      </Box>

    </>
  );
}


export default DowntimeTable;