import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getLanguage, roundNumber } from '../../../utils/utils';
import { scrapCalc } from '../../../configurations/scrap';

function AreaCard({ data, handleOpen }) {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');
  let scrap = (data?.scrap != null || data?.gross_production != null) ? scrapCalc(scrapType,data?.gross_production, data?.net_production ) : "";
  
  // (data?.scrap != null || data?.gross_production != null) ? (data?.gross_production == 0 ? (0).toFixed(0) : ((data?.scrap || 0) * 100 / data?.gross_production).toFixed(1)) : "";


  let scrapUnits = (data?.scrap != null || data?.gross_production != null) ? roundNumber(data?.scrap) : "";
  let toGoal = (data?.net_production - data?.target) / data?.target * 100;
  let toGoalColor = '#BDBDBD';

  if (toGoal > 0) {
    toGoal = "+" + Math.round(toGoal) + "%";
    toGoalColor = '#318F29';
  }
  else if (toGoal < 0) { 
    toGoal = Math.round(toGoal) + "%";
    toGoalColor = '#D32F2F';
  } else {
    toGoal = "0%";
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        width: {xs: '263px', lg: '99%'},
        height: '260px',
        background: '#FFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        mt: 1,
        mb: 1,
        ml: {xs: 3, lg: 0},
        ':last-child': { marginRight: {xs: 3, md: '150px', lg: 0} }
      }}
    >
      <Box sx={{ width: '100%', height: '55px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: {xs: '17px', sm: '19px'}, fontWeight: 600, ml: 2 }}> { data?.nm_area } </Typography>
        <IconButton onClick={() => handleOpen(data?.id_area)} color='primary'>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>{language?.total_production || "Total Production"}  [{data?.unit_of_measurement || "u"}]</Typography>
        <Typography sx={{ fontSize: '30px', fontWeight: 700}}>{roundNumber(data?.net_production)}</Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: toGoalColor }}> {toGoal} {language?.of_goal || 'of goal'} </Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'  }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mr: '30px' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>{language?.scrap || "Scrap"}</Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 700}}>{scrap}%</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}> ({scrapUnits}) </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>OEE</Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 700}}> {(data?.oee != null) ? (data?.oee * 100).toFixed(1) : "0"}%</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, visibility: 'hidden'}}>438,75K</Typography>
        </Box>

      </Box>

    </Box>
  );
}

export default AreaCard;