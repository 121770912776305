import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import {  RiEditLine  } from "react-icons/ri"; 


import ExportCsv from './ExportCsv';
import { Box, IconButton, Typography } from '@mui/material';
import { getLanguage } from '../../../utils/utils';


function CustomToolbar(props) {
  const { data, handleEditMode } = props;
  const language = getLanguage();
  return (
    <GridToolbarContainer sx={{ mb: 1 }}>
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between',  }}>
        <Box sx={{ width: 400, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: '#4D4D4D', fontSize: '32px' }}>
            {language?.production_orders || 'Production Orders'}
          </Typography>
        </Box>

        <Box sx={{mt: { xs: '20px', sm: 0 }, display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'center' }, width: { xs: '100%', sm: 'auto' } }}>
          <Box>
            <GridToolbarColumnsButton sx={{ bgcolor: '#EAEAED', width: '140px', borderRadius: '8px', color: '#333333' }} />
          </Box>

          <Box sx={{ ml: '34px', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ mr: { xs: '40px', sm: '34px' } }}>
              <ExportCsv data={data} />
            </Box>
            <IconButton  onClick={handleEditMode}>
              <RiEditLine />
            </IconButton>
          </Box>
        </Box>
      </Box>
    
    </GridToolbarContainer>
  );
}

export default CustomToolbar;