import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { Grid, Box, Typography, FormHelperText, FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton, Button } from '@mui/material';
import { ReactComponent as PackiotLogo } from "../../assets/packiot-dark.svg";

import LoginImage from "../../assets/LoginImage.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';


const SuportLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  
  &:hover {
    color: #fff;
  }
`;

function ForgotPassword() {
  const [emailError, setEmailError] = useState({ error: false, message: '' });
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const { resetPassword, sendEmail } = useContext(AuthContext);
  const history = useNavigate();

  const year = new Date().getFullYear();

  const handleEmailChange = (event) => {
    if (emailError.error) setEmailError({ error: false, message: '' });
    if (emailSuccess) setEmailSuccess(false);
    setEmail(event.target.value);
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === '') {
      setEmailError({ error: true, message: 'Email is required' });
      return;
    } else if (!email.includes('@')) {
      setEmailError({ error: true, message: 'Email is not valid' });
      return;
    } else {
      resetPassword(email);
    }
  }

  useEffect(() => {
    if (sendEmail === 'error') {
      setEmailError({ error: true, message: 'Email not found!' });
    }
    if (sendEmail === 'sent') {
      setEmailSuccess(true);
    }
  },[sendEmail])


  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >

        <Box sx={
          {
            height: { xs: 'calc(100vh - 65px)', lg: 'calc(100vh - 165px)' },
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            pl: { xs: '30px', lg: '0' },
            pr: { xs: '30px', lg: '0' },
          }
        }
        >

          <Box component='form'>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
              <PackiotLogo style={{ marginTop: '30px' }} />
              <Typography variant="h1" sx={{ fontWeight: 500, color: '#333333', fontSize: { xs: '36px', md: '64px' }, mt: { xs: '20px', md: '40px' }, textAlign: 'center' }}> Forgot Password? </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#333333', fontSize: '25px' }}> We will help you to reset your password </Typography>
            </Box>

            <FormControl
              error={emailError.error} fullWidth variant='outlined'
              color={emailSuccess ? 'success' : ''}
              focused={emailSuccess}
              sx={{ mb: '20px', mt: '48px' }}
            >
              <InputLabel htmlFor="outlined-adornment-email">Enter email *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                label="Enter email *"
                value={email}
                onChange={handleEmailChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={`${emailError.error ? 'email error' : 'email success'}`}
                      edge="end"
                      onClick={() => {
                        if (emailError.error) {
                          setEmailError({ error: false, message: '' });
                          setEmail('');
                        }
                      }}
                    >
                      {
                        emailError.error ? <HighlightOffIcon sx={{ color: '#C13939' }} />
                          : <CheckCircleOutlineIcon sx={{ color: '#318F29', visibility: (!emailSuccess) ? 'hidden' : 'visible' }} />
                      }

                    </IconButton>
                  </InputAdornment>
                }

              />
              {
                emailError.error &&
                <FormHelperText sx={{ ml: 0 }}>
                  {emailError.message}
                </FormHelperText>
              }

{
                emailSuccess &&
                <FormHelperText sx={{ ml: 0 }}>
                  A link to reset your password was sent to your email!
                </FormHelperText>
              }

            </FormControl>



            <Box sx={{ 
              mt: 1, width: '100%', display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: 'center' 
            }}>


            <Button
                sx={
                  {
                    width: '219px', height: '50px', mt: '10px', backgroundColor: '#fff',
                    color: '#000',
                    border: '3px solid #407CCC',
                    mb: { xs: '10px', md: '30px', lg: '50px' },
                    ':hover': { backgroundColor: '#fff' }
                  }
                }
                variant='contained' onClick={() => history('/login')}
              >
                Cancel
              </Button>

              <Button
                type='submit'
                sx={
                  {
                    width: '244px', height: '50px', mt: '10px', backgroundColor: '#407CCC',
                    mb: { xs: '10px', md: '30px', lg: '50px' },
                  }
                }
                variant='contained' onClick={handleSubmit}
              >
                Send Email
              </Button>

            </Box>

          </Box>

        </Box>

        <Box sx={{
          width: '100%',
          height: '65px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: { xs: '#fff', lg: '#BDBDBD' },
          background: { xs: '#1F608A', lg: 'transparent' },
        }}>
          <p>&nbsp;</p>
          <Typography>&copy; {year} - All rights reserved</Typography>
        </Box>

      </Grid>

      <Grid item lg={7}
        sx={
          {
            display: { xs: 'none', lg: 'flex' }, height: '100vh', background: "#1F608A",
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
          }}
      >
        <Box sx={{ width: '180px', display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '40px', right: '100px' }}>
          <MailOutlineIcon sx={{ color: '#fff' }} />
          <SuportLink href='mailto:suport@packiot.com'> suport@packiot.com </SuportLink>
        </Box>
        <img style={{ width: '50%', height: 'auto' }} src={LoginImage} className="image" />
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;