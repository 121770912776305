import React, { useContext } from 'react';
import { Typography, Box, Card } from '@mui/material';
import moment from 'moment-timezone';
import { getLanguage, dateFormat } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';

const ShiftOrTeamTooltip = ({ active, label, chartData, dateRange }) => {
  const language = getLanguage();
  const { timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  const colors = ['rgba(64, 124, 204)', 'rgb(31, 96, 138)', 'rgb(123, 132, 200)', 'rgb(47, 58, 143)'];

  let currentDot = chartData.info?.filter((item) => item?.ts_value === label);
  if (!active) return null;
  currentDot = currentDot?.[0] || {};
  if (currentDot?.net === null) return null;//???
  if (moment(currentDot?.ts_value).isAfter(moment(new Date()))) return null;

  let displayDate = '';

  if (dateRange === 'day' || dateRange === 'today') {
    displayDate = dateFormat(label, {hour: "2-digit", timeZone: 'CLIENT'});
  } else {
   displayDate = dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC'});
  }
  // console.log(chartData);
  // console.log(label)
  // console.log(currentDot);

  return (
    <Card sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center',
      height: '120%', width: '110%', px:2, py:1
    }}>
      <Typography sx={{ color: '#4D4D4D', fontWeight: 'bold', fontSize: '16px',textTransform: 'capitalize' }}>
        {displayDate}
      </Typography>
      {
        currentDot?.info_per_shift_or_team?.map((item, index) => {
          if (item?.speed != null) {
            return (
              <>
                <Typography sx={{ color: '#4D4D4D', fontWeight: 'bold', fontSize: '14px' }}> {item?.cd_shift} : </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: colors[index] }}>
                  {language?.avg || 'Avg'}: {item?.speed?.toFixed(1)} un/min
                </Typography>
              </>
            )
          } else { return null }
        })
      }
    </Card>
  )

};
export default ShiftOrTeamTooltip;
