import React from 'react';
import {
  Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material'

import { getLanguage, dateFormat } from '../../../utils/utils';
import moment from 'moment';

export default function TableEvent({ list }) {
  const language = getLanguage();

  let data = list?.h_piot_overview_i_get_events_3

  let type = { fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 20 };
  // let treatData = data?.slice(0,5) ;

  function getColor(row) {
    let color = "#333333";
    //nÃo justificada
    if (row === "notJustified") color = "#FD9729";
    //está parado
    if (row === "runningStop") color = "#C13939";
    return color;
  }

  return (
    <Card sx={{ mx: 1, display: 'flex', maxHeight: 350 }} elevation={0} >

      <CardContent >
        <Typography variant="h5"
          sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 29, ml: -7, mr: -7, mt: 15, transform: 'rotate(270deg)' }} >
          {language?.last_events || 'Last Events'} {/* Last Events */}
        </Typography>
      </CardContent>

      <CardContent>
        <Table sx={{ minWidth: 1750 }} size={'small'} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={type}> {language?.start || 'Start'} </TableCell>
              <TableCell align="left" sx={type}> {language?.duration || 'Duration'} </TableCell>
              <TableCell align="left" sx={type}> {language?.category || 'Category'}  </TableCell>
              <TableCell align="left" sx={type}> {language?.sub_category || 'Sub-Category'}</TableCell>
              <TableCell align="left" sx={type}> {language?.machine || 'Machine'} </TableCell>
              {
                data?.[0]?.cd_sector &&
                <TableCell align="left" sx={type}> {language?.sector || 'Sector'} </TableCell>
              }
              <TableCell align="left" sx={type}> {language?.notes || 'Notes'} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {data?.map((row) => (
              <TableRow key={row.duration} sx={{ color: getColor(row?.colorcolumn), '&:last-child td, &:last-child th': { border: 0 }, fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} >
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} component="th" scope="row"
                  width={170}>
                  { dateFormat(row?.start, {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'}) }
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                  width={130}>
                  {row?.duration}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                  width={220}>
                  {row?.reason}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                  width={220}>
                  {row?.sub_category}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                  width={200}>
                  {row?.machine}
                </TableCell>

                {row?.cd_sector &&
                  <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                    width={200}>
                    {row?.cd_sector}
                  </TableCell>
                }

                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left"
                  width={500}>
                  {row?.notes}
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </CardContent>

    </Card>
  )
};