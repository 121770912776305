import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { userLocale, getLanguage, dateFormat } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';

const XTick = (props) => {
  const language = getLanguage();
  const { grain, timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;
  if (props.index === 0 && props.hideFirstTick) return null;
  const isDay = (props?.dateRange === 'today' || props?.dateRange === 'day') ? true : false;
  const isWeek = grain === 'WEEK' ? true : false; 
  

  if (isDay) {
    let hour = dateFormat(props.payload.value, { hour: "2-digit", timeZone: 'CLIENT' }); //moment(props.payload.value).format(locale.hour);
    return (
      <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
        <tspan style={{ fontWeight: 'bold' }} x={props.x} dy="15" textAnchor="middle" >
          {hour?.toLowerCase()}
        </tspan>
      </text>
    );
  }

 

  if (isWeek) {
    return (
      <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
        <tspan x={props.x} dy="15" textAnchor="middle">
          {language?.week || 'Week'} { moment(props.payload.value).format('w')}
        </tspan>
        <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
          {dateFormat(props.payload.value, { day: "2-digit", month: '2-digit', timeZone: 'UTC' })}
        </tspan>
      </text>
    );
  }

  return (
    <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
      <tspan x={props.x} dy="15" textAnchor="middle">
        {dateFormat(props.payload.value, { weekday: "short", timeZone: 'UTC' })?.toUpperCase()}
      </tspan>
      <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
        {dateFormat(props.payload.value, { day: "2-digit", month: '2-digit', timeZone: 'UTC' })}
      </tspan>
    </text>
  );

};

export default XTick;
