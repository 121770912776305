import React from 'react';
import { getLanguage } from '../../../../utils/utils';

import { Button, ButtonGroup } from '@mui/material';

function TypeOfEventSelector({ setTypeOfEvent, typeOfEvent }) {
  const language = getLanguage();

  return (
    <ButtonGroup variant="outlined" sx={{ px: 3, height: 36, mr: { sm: 3 } }}>
      <Button
        onClick={() => setTypeOfEvent('All events')}
        sx={{
          backgroundColor: typeOfEvent == 'All events' ? '#407CCC' : '',
          color: typeOfEvent == 'All events' ? 'white' : '',
          borderRadius: 5,
        }}>
        {language?.all_events || 'All events'}
      </Button>

      <Button
        onClick={() => setTypeOfEvent('Detected events')}
        sx={{
          backgroundColor: typeOfEvent == 'Detected events' ? '#407CCC' : '',
          color: typeOfEvent == 'Detected events' ? 'white' : '',
          borderRadius: 5,
        }}>
        {language?.detected_events || 'Detected events'}
      </Button>

      <Button
        onClick={() => setTypeOfEvent('Manual events')}
        sx={{
          backgroundColor: typeOfEvent == 'Manual events' ? '#407CCC' : '',
          color: typeOfEvent == 'Manual events' ? 'white' : '',
          borderRadius: 5,
        }}>
        {language?.manual_events || 'Manual events'}
      </Button>

    </ButtonGroup>
  );
}

export default TypeOfEventSelector;
