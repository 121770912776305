import React, { useState, useEffect } from 'react';
import { TooltipDowntimeReasons as Tooltip } from './TooltipDowntimeReasons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Card, CardContent, Typography as Typography2, Chip, } from '@mui/material';
import { Typography, } from '@material-ui/core';
import { secondsToTimePassed } from '../../../utils/utils';

export default function DowntimeReason({ data, translate }) {
  const [value, setValue] = useState(1);
  const [tasks, setTasks] = useState([1, 2, 3, 4, 5]);
  const [objts, setObjts] = useState([]);
  const [lista1, setLista1] = useState([]);
  const [lista2, setLista2] = useState([]);
  const [lista3, setLista3] = useState([]);
  const [lista4, setLista4] = useState([]);

  const [outrosTotalTime, setOutrosTotalTime] = useState(0);
  const [outrosOcc, setOutrosOcc] = useState(0);
  const [outrosAvg, setOutrosAvg] = useState(0);

  const [microstopsDetails, setMicrostopsDetails] = useState({});

  const size = 2000

  let all = [];
  let totalTime = 0;
  let topFourTime = 0;

  useEffect(() => {

    let totalTime = data?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);

    //SELECIONANDO VALORES RESPECTIVOS
    if (value == 1) {

      data?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category?.forEach(element => all.push(element));

      all?.sort(function (a, b) {
        return (a?.duration_total) < (b?.duration_total) ? 1 : (a?.duration_total) > (b?.duration_total) ? -1 : 0;
      });

    } else if (value == 2) {
      // PLANNED

      let plan = data?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category?.filter(item => (item?.planned_downtime === true && item?.cd_category))
      plan?.forEach(element => all.push(element))

      all?.sort(function (a, b) {
        return (a?.duration_total) < (b?.duration_total) ? 1 : (a?.duration_total) > (b?.duration_total) ? -1 : 0;
      });

    } else {
      // UNPLANNED

      let unplan = data?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category?.filter(item => (item?.planned_downtime === false && item?.cd_category))
      unplan?.forEach(element => all.push(element))

      all?.sort(function (a, b) {
        return (a?.duration_total) < (b?.duration_total) ? 1 : (a?.duration_total) > (b?.duration_total) ? -1 : 0;
      });

    }

    // LISTA SEM REPETIÇÃO DE DADOS
    function groupBy(array, f) {
      let groups = {};
      array?.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      })
    }

    let listClean = [];
    var listaCleanAgrupada = groupBy(all, function (item) {
      return [item.cd_category];
    }).map((data) => {
      return {
        duration_total: data.reduce((total, valor) => total + valor.duration_total, 0),
        num_occurence: data.reduce((total, valor) => total + valor.num_occurence, 0),
        avg_time: data.reduce((total, valor) => total + valor.avg_time, 0),
        itens: data
      };
    });
    for (var i in listaCleanAgrupada) {
      let listaDown = {
        cd_category: listaCleanAgrupada[i].itens[0].cd_category,
        cd_machine: listaCleanAgrupada[i].itens[0].cd_machine,
        nm_equipment: listaCleanAgrupada[i].itens[0].nm_equipment,
        duration_total: listaCleanAgrupada[i].duration_total,
        num_occurence: listaCleanAgrupada[i].num_occurence,
        avg_time: listaCleanAgrupada[i].avg_time,
      }
      listClean.push(listaDown);
    }

    // listClean?.sort(function(a,b) {
    //   return (a?.duration_total) < (b?.duration_total) ? 1 : (a?.duration_total) > (b?.duration_total) ? -1 : 0;
    // });

    // let selectData = area === undefined ? all : listClean;
    let selectData = listClean;

    let microstops = selectData?.filter((e) => { return e?.cd_category === "Microstops" });
    let microstopsTotalTime = microstops?.[0]?.duration_total;
    let microstopsPorcent = microstopsTotalTime * 100 / totalTime;
    setMicrostopsDetails({ "microstopsTotalTime": microstopsTotalTime, "microstopsPorcent": microstopsPorcent });

    //TRATANDO VALORES PARA MOSTRAR
    topFourTime = selectData[0]?.duration_total + selectData[1]?.duration_total + selectData[2]?.duration_total + selectData[3]?.duration_total

    let task1 = selectData[0]?.duration_total * 100 / totalTime;
    let task2 = selectData[1]?.duration_total * 100 / totalTime;
    let task3 = selectData[2]?.duration_total * 100 / totalTime;
    let task4 = selectData[3]?.duration_total * 100 / totalTime;
    let task5 = (totalTime - topFourTime) * 100 / totalTime;

    setTasks([task1, task2, task3, task4, task5])

    //SETANDO OUTROS
    setOutrosTotalTime(selectData.slice(4)?.map(item => item?.duration_total).reduce((prev, curr) => prev + curr, 0))
    setOutrosOcc(selectData.slice(4)?.map(item => item?.num_occurence).reduce((prev, curr) => prev + curr, 0))
    setOutrosAvg(selectData.slice(4)?.map(item => item?.duration_total).reduce((prev, curr) => prev + curr, 0) / selectData.slice(4)?.map(item => item?.num_occurence).reduce((prev, curr) => prev + curr, 0))

    setObjts([selectData[0], selectData[1], selectData[2], selectData[3], selectData[4]])

    setLista1(all?.filter(item => item?.cd_category === selectData[0]?.cd_category))
    setLista2(all.filter(item => item?.cd_category === selectData[1]?.cd_category))
    setLista3(all.filter(item => item?.cd_category === selectData[2]?.cd_category))
    setLista4(all.filter(item => item?.cd_category === selectData[3]?.cd_category))

  }, [value, data])

  const handleClick = (value) => {
    setValue(value)
  };

  return (
    <Card>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          <Typography variant="h4"> {translate?.downtime_reasons || "Downtimes Reasons"} </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, }}>
            {value == 1 ? <Chip sx={{ mx: 1 }} label={translate?.all || "All"} color="primary"
              clickable={true} variant="filled" /> : <Chip sx={{ mx: 1 }} label={translate?.all || "All"} color="primary"
                onClick={() => handleClick(1)} clickable={true} variant="outlined" />}
            {value == 2 ? <Chip sx={{ mx: 1 }} label={translate?.planned || "Planned"} color="primary"
              clickable={true} variant="filled" /> : <Chip sx={{ mx: 1 }} label={translate?.planned || "Planned"} color="primary"
                onClick={() => handleClick(2)} clickable={true} variant="outlined" />}
            {value == 3 ? <Chip sx={{ mx: 1 }} label={translate?.unplanned || "Unplanned"} color="primary"
              clickable={true} variant="filled" /> : <Chip sx={{ mx: 1 }} label={translate?.unplanned || "Unplanned"} color="primary"
                onClick={() => handleClick(3)} clickable={true} variant="outlined" />}
          </Box>
        </Box>

        <Box sx={{ display: { sm: 'grid', md: 'flex' }, alignItems: 'flex-end' }}>
          <Typography variant="h4"> {translate?.microstops || "Microstops "} </Typography>
          <Typography variant="subtitle2" style={{ marginLeft: 10 }}> {secondsToTimePassed(microstopsDetails?.microstopsTotalTime)}  </Typography>
          <Typography variant="subtitle2" style={{ marginLeft: 10 }}> ({microstopsDetails?.microstopsPorcent?.toFixed(1)}%)</Typography>
        </Box>
      </CardContent>

      {isNaN(tasks[0]) ?
        <CardContent sx={{ maxWidth: size, }}>
          <Typography sx={{ color: '#757575', display: 'flex', alignItems: 'center', justifyContent: "center" }} variant="h6" >
            <InfoOutlinedIcon sx={{ mr: 1 }} />
            {translate?.There_aren_t_any_planned_downtimes || "There aren't any planned Downtimes."}
          </Typography>
        </CardContent>
        :
        <>
          <CardContent sx={{ mt: -1.3 }} >
            <Box sx={{ maxWidth: size, minHeight: 43, display: 'flex', alignItems: 'center' }}>

              {Math.round(tasks[0]) !== 0 ?
                <Tooltip
                  translate={translate}
                  name={objts[0]?.cd_category}
                  totalTime={objts[0]?.duration_total}
                  occ={objts[0]?.num_occurence}
                  avg={objts[0]?.duration_total / objts[0]?.num_occurence}
                  // avg={objts[0]?.avg_time}
                  lista={lista1}
                >
                  <Box sx={{
                    display: 'grid',
                    width: isNaN(tasks[0]) ? 0 : Math.round(tasks[0]) <= 5 ? 7 * size : Math.round(tasks[0]) * size,
                  }}>
                    <Box sx={{ mx: 'auto' }} >
                      <Typography variant="subtitle2" >
                        {isNaN(tasks[0]) || Math.round(tasks[0]) === 0 ? "" : `${tasks[0].toFixed(1)}%`}
                      </Typography>
                    </Box>
                    <Box sx={{
                      height: 16, display: 'flex', alignItems: 'center',
                      justifyContent: 'center', backgroundColor: '#1B2361',
                      borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
                      borderBottomRightRadius: tasks[1] == 0 || isNaN(tasks[1]) ? 10 : 0,
                      borderTopRightRadius: tasks[1] == 0 || isNaN(tasks[1]) ? 10 : 0,
                    }}> </Box>
                  </Box>
                </Tooltip >
                : <></>}

              {Math.round(tasks[1]) !== 0 ?
                <Tooltip
                  translate={translate}
                  name={objts[1]?.cd_category}
                  totalTime={objts[1]?.duration_total}
                  occ={objts[1]?.num_occurence}
                  // avg={objts[1]?.avg_time}
                  avg={objts[1]?.duration_total / objts[1]?.num_occurence}
                  lista={lista2}
                >
                  <Box sx={{
                    display: 'grid',
                    width: isNaN(tasks[1]) ? 0 : Math.round(tasks[1]) <= 5 ? 7 * size : Math.round(tasks[1]) * size,
                  }}>
                    <Box sx={{ mx: 'auto' }} >
                      <Typography variant="subtitle2" >
                        {isNaN(tasks[1]) === true || Math.round(tasks[1]) === 0 ? "" : `${tasks[1].toFixed(1)}%`}
                      </Typography>
                    </Box>
                    <Box sx={{
                      height: 16, display: 'flex', alignItems: 'center',
                      justifyContent: 'center', backgroundColor: '#7B84C8',
                      borderTopRightRadius: tasks[2] == 0 || isNaN(tasks[2]) ? 10 : 0,
                      borderBottomRightRadius: tasks[2] == 0 || isNaN(tasks[2]) ? 10 : 0,
                    }}> </Box>
                  </Box>
                </Tooltip>
                : <></>}


              <Tooltip
                translate={translate}
                name={objts[2]?.cd_category}
                totalTime={objts[2]?.duration_total}
                occ={objts[2]?.num_occurence}
                // avg={objts[2]?.avg_time}
                avg={objts[2]?.duration_total / objts[2]?.num_occurence}
                lista={lista3}
              >
                <Box sx={{
                  display: 'grid',
                  width: isNaN(tasks[2]) || Math.round(tasks[2]) === 0 ? 0 : Math.round(tasks[2]) <= 5 ? 7 * size : Math.round(tasks[2]) * size,
                }} >
                  <Box sx={{ mx: 'auto' }} >
                    <Typography variant="subtitle2" >
                      {isNaN(tasks[2]) === true || Math.round(tasks[2]) === 0 ? "" : `${tasks[2].toFixed(1)}%`}
                    </Typography>
                  </Box>
                  <Box sx={{
                    height: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center', backgroundColor: '#FFB830',
                    borderTopRightRadius: tasks[3] === 0 || isNaN(tasks[3]) ? 10 : 0,
                    borderBottomRightRadius: tasks[3] === 0 || isNaN(tasks[3]) ? 10 : 0,
                  }}> </Box>
                </Box>
              </Tooltip>

              <Tooltip
                translate={translate}
                name={objts[3]?.cd_category}
                totalTime={objts[3]?.duration_total}
                occ={objts[3]?.num_occurence}
                // avg={objts[3]?.avg_time}
                avg={objts[3]?.duration_total / objts[3]?.num_occurence}
                lista={lista4}
              >
                <Box sx={{
                  display: 'grid',
                  width: isNaN(tasks[3]) || tasks[3] === 0
                    ? 0 : Math.round(tasks[3]) <= 5
                      ? 7 * size : Math.round(tasks[3]) * size,
                }} >
                  <Box sx={{ mx: 'auto' }} >
                    <Typography variant="subtitle2" >
                      {isNaN(tasks[3]) === true || tasks[3] === 0 ? "" : `${tasks[3].toFixed(1)}%`}
                    </Typography>
                  </Box>
                  <Box sx={{
                    height: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center', backgroundColor: '#FE7E6D',
                    borderTopRightRadius: tasks[4] == 0 || isNaN(tasks[4]) ? 10 : 0,
                    borderBottomRightRadius: tasks[4] == 0 || isNaN(tasks[4]) ? 10 : 0,
                  }}> </Box>
                </Box>
              </Tooltip>

              <Tooltip
                translate={translate}
                name={translate?.others || "Others"}
                totalTime={outrosTotalTime}
                occ={outrosOcc}
                avg={outrosAvg}
                // avg={outrosTotalTime/outrosOcc}
                lista={[{
                  cd_category: translate?.others || "Others",
                  duration_total: outrosTotalTime,
                  num_occurence: outrosOcc,
                  avg_time: outrosAvg,
                  nm_equipment: translate?.others || "Others",
                  cd_machine: translate?.others || "Others",
                  cd_machine: translate?.others || "Others",
                }]} >
                <Box sx={{
                  display: 'grid',
                  width: isNaN(tasks[4]) ? 0 : Math.round(tasks[4]) > 0 && Math.round(tasks[4]) <= 4 ? 6 * size : Math.round(tasks[4]) * size,
                }} >
                  <Box sx={{ mx: 'auto' }} >
                    <Typography variant="subtitle2" >
                      {isNaN(tasks[3]) || Math.round(tasks[4]) === 0 ? "" : `${tasks[4].toFixed(1)}%`}
                    </Typography>
                  </Box>
                  <Box sx={{
                    height: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center', backgroundColor: '#A6A6A6',
                    borderTopRightRadius: 10, borderBottomRightRadius: 10,
                  }}> </Box>
                </Box>
              </Tooltip>

            </Box>
          </CardContent>

          <CardContent sx={{ display: 'flex', justifyContent: { xs: 'space-around', sm: 'center' }, mb: -2, flexWrap: 'wrap' }}>

            {objts[0] !== undefined && Math.round(objts[0]) !== 0 ?
              <Box sx={{ display: 'flex', mr: 1 }} >
                <CircleIcon sx={{ color: '#1B2361', mr: 1 }} />
                <Typography color="text.secondary" variant='body2'> {objts[0]?.cd_category} </Typography>
              </Box>
              : <></>}

            {objts[1] !== undefined && Math.round(tasks[1]) !== 0 ?
              <Box sx={{ display: 'flex', mr: 1 }} >
                <CircleIcon sx={{ color: '#7B84C8', mr: 1 }} />
                <Typography color="text.secondary" variant='body2'> {objts[1]?.cd_category} </Typography>
              </Box>
              : <></>}

            {objts[2] !== undefined && Math.round(objts[2]) !== 0 ?
              <Box sx={{ display: 'flex', mr: 1 }} >
                <CircleIcon sx={{ color: '#FFB830', mr: 1 }} />
                <Typography color="text.secondary" variant='body2'> {objts[2]?.cd_category} </Typography>
              </Box>
              : <></>}

            {objts[3] !== undefined && Math.round(objts[3]) !== 0 ?
              <Box sx={{ display: 'flex', mr: 1 }} >
                <CircleIcon sx={{ color: '#FE7E6D', mr: 1 }} />
                <Typography color="text.secondary" variant='body2'> {objts[3]?.cd_category} </Typography>
              </Box>
              : <></>}

            {tasks[4] !== 0 && Math.round(tasks[4]) ?
              <Box sx={{ display: 'flex', mr: 1 }} >
                <CircleIcon sx={{ color: '#A6A6A6', mr: 1 }} />
                <Typography color="text.secondary" variant='body2'> {isNaN(tasks[4]) ? objts[4]?.cd_category : translate?.others || "Others"} </Typography>
              </Box>
              : <></>}

          </CardContent>
        </>
      }

    </Card>
  );
}
