import React, { useState } from 'react';

import { getLanguage, sortArrayOfObjectsBy } from '../../../../../utils/utils';
import { Box, Typography, TableSortLabel } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function CategoriesTable({ row: rowProp }) {
  const language = getLanguage();

  const columns = [
    {
      field: 'code',
      align: "left",
      languagePackCode: 'code',
      defaultLabel: 'Code'
    },
    {
      field: 'idle',
      align: "left",
      languagePackidle: 'idle',
      defaultLabel: 'IDLE'
    },
    {
      field: 'name',
      align: "left",
      languagePackname: 'name',
      defaultLabel: 'Name'
    },
    {
      field: 'change_over',
      align: "left",
      languagePackCode: 'change_over',
      defaultLabel: 'Setup'
    },
    {
      field: 'planned',
      align: "left",
      languagePackname: 'planned',
      defaultLabel: 'Planned'
    },
    {
      field: 'description',
      languagePackCode: 'description',
      defaultLabel: 'Description'
    },
  ];

  return (
    <Box sx={{
      py: 2,
      background: '#F9F9F9',
      display: 'block',
      height: '-webkit-fill-available',
      overflow: "auto"
    }}>
      <Box sx={{ ml: '70px', width: '90%', display: 'flex', flexDirection: 'column', border: '1px solid #4F4F4F', borderRadius: '4px' }}>
        <Typography sx={{ ml: '16px', mt: '10px', color: '#4F4F4F', fontSize: '16px', fontWeight: 700, textTransform: 'uppercase' }}> {rowProp?.code}  </Typography>
        <TableContainer sx={{ mt: '5px', mb: '15px', ml: '10px' }}>
          <Table sx={{ width: 1, }}>
            <TableHead>
              <TableRow>

                {columns?.map((column, index) =>
                  <TableCell align={column?.align}>
                    {language?.[column?.languagePackCode] || column?.defaultLabel}
                  </TableCell>
                )}

              </TableRow>
            </TableHead>
            <TableBody>
              {rowProp?.subcategories?.map((row) => (
                <TableRow
                  key={row?.code}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width={100} align="left">{row?.code}</TableCell>
                  <TableCell width={100} align="left">{row?.idle}</TableCell>
                  <TableCell width={100} align="left">{row?.name?.['en-US']}</TableCell>
                  <TableCell width={100} align="left">{row?.change_over ? `${language?.yes || 'Yes'}` : `${language?.no || 'No'}`}</TableCell>
                  <TableCell width={100} align="left">{row?.planned_downtime ? `${language?.yes || 'Yes'}` : `${language?.no || 'No'}`}</TableCell>
                  <TableCell width={200} align="left">{row?.description?.['en-US']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default CategoriesTable;