import React from 'react';
import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import { Typography } from '@material-ui/core';

import animationData from '../../assets/lotties/97240-success.json';

function SuccessAnimation() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <Box sx={{ width: '360px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Lottie options={defaultOptions} height={260} width={260} />
      <Typography color='primary' variant='h4'>
        Your password has been created!
      </Typography>

      <Typography variant='subtitle1'>
        You will be redirected to login.
      </Typography>
    </Box>
  );
}

export default SuccessAnimation;