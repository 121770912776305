import React from 'react';
import Tooltipp from './Tooltipp';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import { getLanguage } from '../../../utils/utils';
import { AreaChart, Area, YAxis, XAxis, Tooltip, ReferenceLine, ResponsiveContainer, CartesianGrid, } from 'recharts';
import { Box, } from '@mui/material';
import { Typography, } from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CircleIcon from '@mui/icons-material/Circle';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Chart({ data, dateRange }) {
  const language = getLanguage();
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });

  let chartData = data?.info?.map((item, index) => (
    {
      speed: item?.info_per_period?.speed,
      time: item?.ts_value,
      speed_target: item?.info_per_period?.speed_target
    }
  ));

  const average = chartData.reduce((acc, curr) => {
    return acc + curr.speed;
  }, 0) / chartData?.length;

  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = (isMobile ? 5 : 1);

  return (
    <Box sx={{ width: 1 }}>
      <Typography variant="h4" style={{ marginLeft: 30, marginTop: 15, marginBottom: 5 }}>
        {data?.nm_equipment}
      </Typography>

      <ResponsiveContainer width="100%" height={400}>
        <AreaChart height={470} margin={{ top: 20, right: 50, left: 20, bottom: 0 }} data={chartData}>
          <CartesianGrid strokeDasharray=" 2" />

          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="00%" stopColor="#407CCC" stopOpacity={0.9} />
              <stop offset="80%" stopColor="#407CCC" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color2" />
          </defs>

          <XAxis dataKey="time" tick={<XTick dateRange={dateRange} />} height={60} interval={intervalData} />

          <YAxis tick={<Ytick removeLastLetter={false} />} label={{ position: 'insideLeft' }} axisLine={false} />

          <Tooltip content={<Tooltipp chartData={chartData} dateRange={dateRange} />} />

          <Area type="linear" dataKey="speed" stroke="#8884d8" fillOpacity={1} fill="url(#color1)" />

          <Area type="linear" dataKey="speed_target" strokeWidth={2} stroke="#27AE60" fill="url(#color2)" isFront />

          <ReferenceLine y={average} stroke="#828282" isFront strokeWidth={2} strokeDasharray='5 3' />

        </AreaChart>
      </ResponsiveContainer>

      <Box sx={{ display: { xs: 'grid', sm: 'flex', md: 'flex', lg: 'flex' }, flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', mt: 2, }}>
        <Box sx={{ display: 'flex', mx: { xs: 'auto', sm: 0, md: 0, lg: 0, }, ml: 1 }}>
          <CircleIcon sx={{ color: '#407CCC', fontSize: 20, mr: 1 }} />
          <Typography variant="body1"> {language?.speed || 'Speed'} </Typography>

          <MoreHorizIcon sx={{ mr: 1, ml: 1 }} />
          <Typography variant="body1"> {language?.average || 'Average'} </Typography>
        </Box>

        <Box sx={{ display: { xs: 'flex', alignItens: 'center' }, ml: 1 }} >
          <HorizontalRuleIcon sx={{ color: '#318F29', fontSize: 30, mr: 1, }} />
          <Typography variant="body1" > {language?.ratedSpeed || 'Rated Speed'} </Typography>
        </Box>
      </Box>

    </Box>
  );
}
