import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import DowntimeTable from './components/Table';
import { VariablesContext } from '../../Context/VariablesContext';
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilter from '../../components/EntityFilters'
import { getLanguage, convertToDaysHoursNoSeconds, convertToDaysHours, dateFormat } from '../../utils/utils';
import DownloadCsv from './components/DownloadCsv';
import CardTime from './components/CardTime';
import TypeOfEventSelector from './components/TypeOfEventSelector';
import Resume from './components/Resume';
import DowntimeReason from './components/DowntimeReason';
import { DOWNTIME_QUERIES, SHOULD_DISPLAY_EVENT_COLLUMN } from './queries';
import moment from 'moment'

import { Typography, Box, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import DowntimeSkeleton from './components/DowntimeSkeleton';

function totalTime(start, end, viewMicrostops) {
  if (end) {
    const value = moment(end).diff(start, 'seconds')
    const total = viewMicrostops ? convertToDaysHours(`${value}`, 'seconds') : convertToDaysHoursNoSeconds(`${value}`, 'seconds')
    return total;
  }
  return '-'
}

export default function Downtimes() {
  const [dataa, setDataa] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [viewMicrostops, setViewMicrostops] = useState(false);
  const [downtimeDetails, setDowntimeDetails] = useState(null);
  const [downtimeDetailstoCSV, setDowntimeDetailstoCSV] = useState([]);
  const language = getLanguage();
  const [isSectorView, setIsSectorView] = useState(true);
  const [typeOfEvents, setTypeOfEvents] = useState('All events');

  const { data: dataShouldEventCollumnDisplayed, loading: loadingShouldEventCollumnDisplayed } = useQuery(SHOULD_DISPLAY_EVENT_COLLUMN,
    {
      onCompleted: () => {
        setIsSectorView(dataShouldEventCollumnDisplayed?.equipments_aggregate?.aggregate?.count > 0);
      }
    }
  );

  const [getDowntimeDataEquip, { data: dataDowntimeQueryEquip, loading: loadingDowntimeQueryEquip }] = useLazyQuery(DOWNTIME_QUERIES,
    {
      onCompleted: () => {

        setDowntimeDetails(dataDowntimeQueryEquip);

        const tableData = dataDowntimeQueryEquip?.h_piot_get_downtimes_events?.map((item, index) => (
          {
            id: index,
            id_order: item?.id_order,
            nm_equipment: item?.nm_equipment,
            sector: item?.sector,
            id_sector: item?.id_sector,
            cd_shift: item?.cd_shift,
            ts_event: item?.ts_event,
            ts_end: item?.ts_end,
            total: totalTime(item?.ts_event, item?.ts_end, viewMicrostops),
            cd_category: item?.cd_category,
            txt_category: item?.txt_category,
            cd_subcategory: item?.cd_subcategory,
            txt_subcategory: item?.txt_subcategory,
            cd_machine: item?.cd_machine,
            txt_downtime_notes: item?.txt_downtime_notes,
            id_equipment_event: item?.id_equipment_event,
            id_equipment: item?.id_equipment,
            id_shift: item?.id_shift,
            manual_event: item?.manual_event || false,
          }
        ))
        setDataa(tableData);

        let temp = dataDowntimeQueryEquip?.h_piot_get_downtimes_events
        setCsvData(temp);

        // setCsvData(tableData);

        let temp1 = dataDowntimeQueryEquip?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category
        if (temp1 !== undefined) {
          temp1.forEach(element => { element.ts_start = ts_start; element.ts_end = ts_end; });
          setDowntimeDetailstoCSV(temp1);
        }
      },
      fetchPolicy: 'no-cache'
    }
  );

  const { enterprise, site, area, line, filterShift, ts_start, ts_end, visualizationLevel, filterArea, filterLine, filterSector } = useContext(VariablesContext);

  const updateData = () => {
    if (site) {
      if (area) {
        if (line) {
          // Shift Filter
          getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `${filterShift.toString()}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: '{' + (line ? line : "") + '}', in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: '{' + (area ? area : "") + '}', _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView, microstops_view: viewMicrostops } });
        }
        else {
          // Lines and Shift Filters
          getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `${filterShift.toString()}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: '{' + (area ? area : "") + '}', _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView, microstops_view: viewMicrostops } });
        }
      }
      else {
        // Areas, Lines and Shift Filters
        getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `${filterShift.toString()}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: `{${filterArea.toString()}}`, _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView, microstops_view: viewMicrostops } });
      }
    } else {
      getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `${filterShift.toString()}`, in_ids_sites: '{}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: `{${filterArea.toString()}}`, _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView, microstops_view: viewMicrostops } });
    }
  }

  useEffect(() => {
    updateData();
  }, [ts_end, ts_start, line, filterShift, filterArea, filterLine, filterSector, site, area, isSectorView, visualizationLevel, viewMicrostops]);

  useEffect(() => {
    if (downtimeDetailstoCSV?.length > 0) {
      let totalTime = downtimeDetailstoCSV[downtimeDetailstoCSV?.length - 1].duration_total
      downtimeDetailstoCSV[downtimeDetailstoCSV?.length - 1].duration_justified = totalTime
    }

  }, [downtimeDetailstoCSV]);

  const columns_csv = [
    { id: 'ts_start', displayName: 'Start' },
    { id: 'ts_end', displayName: 'End' },
    { id: 'cd_category', displayName: 'Category name' },
    { id: 'txt_category', displayName: 'Category Description' },
    { id: 'duration_justified', displayName: 'Duration seconds' },
    { id: 'planned_downtime', displayName: 'Planned' },
  ];

  return (
    <Box sx={{ m: { xs: 1, sm: 2 } }}>
      <Breadcrumb />

      <Box sx={{ m: 3 }}>
        <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: { xs: '', md: 'space-between' }, mb: 2 }}>
          <Box sx={{ display: 'flex', }}>
            <Typography variant="h2" sx={{ mr: 1 }}> {language?.downtimes || 'Downtimes'} </Typography>
            <EntityFilter hideSector={!isSectorView} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DownloadCsv data={csvData.filter((item) => {
              switch (typeOfEvents) {
                case 'Manual events':
                  return item?.manual_event;
                case 'All events':
                  return true;
                case 'Detected events':
                  return item?.manual_event == false;
              }
            })} />
            <FormGroup>
              <FormControlLabel control={<Switch size="small" onChange={(event) => { setViewMicrostops(event.target.checked) }} color='primary' />} label="Microstops" />
            </FormGroup>
            <FormGroup sx={{ width: '100%' }} noWrap>
              <Box sx={{ display: 'flex', }}>
                <TypeOfEventSelector 
                  setTypeOfEvent={(e) => { setTypeOfEvents(e); }}
                  typeOfEvent={typeOfEvents} />
              </Box>
            </FormGroup>
          </Box>
        </Box>

        {loadingDowntimeQueryEquip
          ? <DowntimeSkeleton />
          : <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 20, mb: 2,
                gridTemplateRows: 'auto',
                gridTemplateAreas:
                {
                  xs:
                    `"main main main main"
                  "class class class class"
                  "re re re re"`,
                  lg:
                    `"main main class class class class"
                  "re re re re re re"`
                }
                ,
              }}>

              <Box sx={{ gridArea: 'main', }}>
                {downtimeDetails &&
                  <CardTime
                    translate={language}
                    data={downtimeDetails}
                  />
                }
              </Box>

              <Box sx={{ gridArea: 'class', }}>
                {downtimeDetails &&
                  <Resume
                    translate={language}
                    data={downtimeDetails ? downtimeDetails : ""}
                  />
                }
              </Box>

              <Box sx={{ gridArea: 're', }}>
                {downtimeDetails &&
                  <DowntimeReason
                    translate={language}
                    data={downtimeDetails ? downtimeDetails : ""}
                  />
                }
              </Box>
            </Box>

            <DowntimeTable
              isSectorView={isSectorView}
              update={updateData}
              data={
                dataa
                  ?.filter((dwnt) => {
                    if (filterShift?.length > 0) {
                      return filterShift?.includes(dwnt?.cd_shift)
                    } else return true
                  })
                  ?.filter((item) => {
                    switch (typeOfEvents) {
                      case 'Manual events':
                        return item?.manual_event;
                      case 'All events':
                        return true;
                      case 'Detected events':
                        return item?.manual_event == false;
                    }
                  })
                }
            />
          </>
        }

      </Box>
    </Box>
  );
}
