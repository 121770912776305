import { gql } from "@apollo/client";

export const GET_MACHINE_SPEED_DATA= gql`
query h_piot_machine_speed($group_by_element: String = "", $in_begin_time: timestamptz = "", $in_end_time: timestamptz = "", $in_id_areas: String = "", $in_id_enterprise: Int , $in_id_equipments: String = "", $in_id_shifts: String = "", $in_id_sites: String = "", $in_id_teams: String = "", $time_grain: String = "") {
  h_piot_machine_speed(args: {group_by_element: $group_by_element, in_begin_time: $in_begin_time, in_end_time: $in_end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_shifts: $in_id_shifts, in_id_sites: $in_id_sites, in_id_teams: $in_id_teams, time_grain: $time_grain}) {
    id_enterprise
    id_equipment
    info
    nm_equipment
  }
}
`;

