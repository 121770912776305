import React, { useContext } from "react";
import { VariablesContext } from '../../Context/VariablesContext';
import { useNavigate, useLocation } from "react-router-dom";
import DateTimePicker from '../DateTimePicker';
import Line from './components/line';
import Area from './components/area';
import Site from './components/site';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as More } from '../../assets/More.svg';
import { Box, Divider, Button, IconButton } from '@mui/material';
import { Typography, } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Breadcrumb() {
  const { nm_enterprise, setLine, area, site, setSite, setArea } = useContext(VariablesContext);
  const isTablet = useMediaQuery('(max-width:1100px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width:700px)', { noSsr: true });

  const navigate = useNavigate();
  let location = useLocation();

  return (
    <>
      <Box sx={{ display: isMobile ? 'grid' : 'flex', justifyContent: isMobile ? 'space-around' : 'space-between', mx: { xs: 2, lg: 4 }, my: 1, mt: 2, alignItems: 'center' }}>

        <Box color="#407CCC" sx={{
          display: 'flex', mt: 1,
          alignItems: isTablet & isMobile ? 'center' : isTablet & !isMobile ? 'start' : 'center',
          flexDirection: isTablet ? 'column' : 'row',
        }}>

          <Box sx={{ display: "flex", alignItems: "center", flexDirection: 'row' }}>
            <IconButton onClick={() => {
              setArea();
              navigate('/home');
            }} >
              <HomeOutlinedIcon htmlColor="#407CCC" />
            </IconButton>
            <ChevronRightIcon sx={{ mx: .5 }} />

            <Typography variant="body1" > {nm_enterprise} </Typography>
            <ChevronRightIcon sx={{ mx: .5 }} />

            <Site />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", flexDirection: 'row' }}>
            {(site == undefined || site == "") ? <div />
              : <>
                <ChevronRightIcon sx={{ mx: .5 }} />
                <Area />
              </>}

            {(area == undefined || area == "") ? <div />
              : <>
                <ChevronRightIcon sx={{ mx: .5 }} />
                <Line />
              </>}
          </Box>

        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 } }}>
          {
            location.pathname.includes('MissionControl')
              || location.pathname.includes('NewMissionControl')
              || location.pathname.includes('mission-control')
              ? <div /> : <DateTimePicker />
          }
        </Box>

      </Box>

      <Divider sx={{ mx: 2, mt: 2 }} />
    </>
  );
}