import React from 'react';
import { Box, Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { dateFormat } from '../../../utils/utils.js';

export default function RelatorioTurno({ runtimeShift }) {
  const date = new Date();

  const shifts = runtimeShift?.equipment_runtime_shift

  const initialTime = new Date(shifts?.at(-1)?.ts_range?.slice(2, 24));
  const endTime = new Date(shifts?.at(-1)?.ts_range?.slice(27, 49));

  return (
    <Paper sx={{ m: 1 }}>

      <Grid container spacing={1} sx={{ m: 1, pb: 2 }}>
        <Grid item xs={12} >
          <Typography variant="h4"> Relatório de Turno Conversão </Typography>
        </Grid>
        <Grid item xs={3} >
          <Typography variant="body1" style={{ color: '#828282' }}> DATA </Typography>
          <Typography variant="body2"> {new Intl.DateTimeFormat('pt-BR').format(date)} </Typography>
        </Grid>
        <Grid item xs={3} >
          <Typography variant="body1" style={{ color: '#828282' }}> TURNO </Typography>
          <Typography variant="body2" > {shifts?.at(-1)?.cd_shift} </Typography>
        </Grid>
        <Grid item xs={3} >
          <Typography variant="body1" style={{ color: '#828282' }}> TURMA </Typography>
          {/* <Typography variant="body2" > A </Typography> */}
        </Grid>
        <Grid item xs={3} >
          <Typography variant="body1" style={{ color: '#828282' }}> HORÁRIO </Typography>
          <Typography variant="body2">
            {
              dateFormat(initialTime, { hour: '2-digit', minute: '2-digit', timeZone: 'CLIENT' }) + ' - ' +
              dateFormat(endTime, { hour: '2-digit', minute: '2-digit', timeZone: 'CLIENT' })
            }
          </Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6} >
          <Typography variant="body1" style={{ color: '#828282' }}> RESPONSÁVEL LINHA 1</Typography>
          {/* <Typography variant="body2"> Cris P. Bacon </Typography> */}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" style={{ color: '#828282' }}> RESPONSÁVEL LINHA 1</Typography>
          {/* <Typography variant="body2"> John Doe </Typography> */}
        </Grid>
      </Grid >

    </Paper >
  );
}
