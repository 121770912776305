import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Context/AuthContext'
import { BrowserRouter, } from 'react-router-dom';
import { VariablesProvider } from './Context/VariablesContext';
import { ApolloProvider} from '@apollo/client';
import { client } from "./services/graphqlConnection";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter >
      <AuthProvider>
        <ApolloProvider client={client}>
          <VariablesProvider>
            <App />
          </VariablesProvider>
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
