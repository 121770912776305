import React, { useContext } from 'react';
import { CartesianGrid, YAxis, XAxis, Tooltip, Line, ResponsiveContainer, LineChart } from 'recharts';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import { getLanguage, sortArrayOfObjectsBy } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';
import { Box, } from '@mui/material';
import { Typography, } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircleIcon from '@mui/icons-material/Circle';
import ShiftOrTeamTooltip from './ShiftOrTeamTooltip';

export default function ChartShift({ data, dateRange }) {
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });
  const { typeOfView } = useContext(VariablesContext);
  const language = getLanguage();
  let chartData = data ?? [];
  let legendKeys = [];
  let shiftOrTeamList = new Array();

  const colors = ['rgba(64, 124, 204)', 'rgb(31, 96, 138)', 'rgb(123, 132, 200)', 'rgb(47, 58, 143)'];

  if (chartData) {
    let groupBy = '';
    switch (typeOfView) {
      case 'SHIFTS':
        groupBy = `cd_shift`;
        break;
      case 'TEAMS':
        groupBy = `cd_team`;
        break;
    }

    // Creates list of teams/shifts and create legend
    chartData?.info?.map((period) => {
      period?.info_per_shift_or_team?.map((shift) => {
        if (shift?.[groupBy] && shiftOrTeamList?.indexOf(shift?.[groupBy]) < 0) {
          legendKeys.push({
            label: `${shift?.[groupBy]}`, color: colors[shiftOrTeamList?.length], circle: true
          });
          shiftOrTeamList.push(shift?.[groupBy]);
        }
      })
    });

    chartData?.info?.map((ts_value_production, ts_value_production_index) => {
      if (ts_value_production?.info?.length != shiftOrTeamList?.length) {
        const ts_value_production_shifts = ts_value_production?.info_per_shift_or_team?.map((shiftOrTeam) => shiftOrTeam?.[groupBy]);
        shiftOrTeamList?.map((shiftOrTeam) => {
          if (!ts_value_production_shifts?.includes(shiftOrTeam)) {
            let ts_value_production_current_shifts = Array.from(chartData?.info?.[ts_value_production_index].info_per_shift_or_team);
            if (groupBy == `cd_shift`) {
              ts_value_production_current_shifts?.push({ net: null, scrap: null, target: null, gross: null, cd_shift: shiftOrTeam, id_shift: true, cd_team: null, id_team: null });
            } else {
              ts_value_production_current_shifts?.push({ net: null, scrap: null, target: null, gross: null, cd_shift: null, id_shift: null, cd_team: shiftOrTeam, id_team: true });
            }
            // chartData.info[ts_value_production_index].info_per_shift_or_team = ts_value_production_current_shifts?.sort((a, b) => sortArrayOfObjectsBy(a, b, groupBy));
            // console.log(ts_value_production)

          }
        }
        )
      }
    });

  }

  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = (isMobile ? 5 : 1);
  // console.log(chartData)
  // console.log(chartData?.info)
  // console.log(shiftOrTeamList)

  return (
    <Box sx={{ width: 1 }}>
      <Typography variant="h4" style={{ marginLeft: 30, marginTop: 15, marginBottom: 5 }}> {data?.nm_equipment} </Typography>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart margin={{ top: 40, right: 50, left: 16, bottom: 0 }} data={chartData?.info} height={470} >
          <CartesianGrid strokeDasharray="1 1" />

          <XAxis dataKey="ts_value" tick={<XTick dateRange={dateRange} />} height={60} interval={intervalData} />
          <YAxis tick={<Ytick removeLastLetter={false} />} label={{ position: 'insideLeft' }} axisLine={false} />

          <Tooltip
            content={<ShiftOrTeamTooltip chartData={chartData} dateRange={dateRange} />}
          />

          {
            shiftOrTeamList.map((e, i) => {
              console.log(i)
              console.log(e)
              return (
                <Line connectNulls dot={false} strokeWidth={2} dataKey={`info_per_shift_or_team[${i}].speed`} stroke={colors[i]} type="linear" />
              )
            })
          }
        </LineChart>

      </ResponsiveContainer>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', mt: 2, }}>
        {legendKeys?.map((item, index) => {
          return (
            <>
              <CircleIcon sx={{ color: colors[index], fontSize: 20, mr: 1, ml: 2 }} />
              <Typography variant="body1"> {item?.label} </Typography>
            </>
          )
        })}
      </Box>

    </Box>
  );
}

