import React, { useState, useContext, useEffect } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function SectorFilter() {
  const { sectorOptions, filterSector, setFilterSector, filterLine, site, area, line } = useContext(VariablesContext);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const language = getLanguage();

  useEffect(() => {
    if (sectorOptions) {
      let temp = [...sectorOptions];

      temp = temp?.filter(item => {
        return (filterLine.includes(item.id_parentequipment) && (area ? area == item.id_area : true) && (site ? site == item.id_site : true) && (line ? line == item.id_parentequipment : true));
      });
      let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      let sortItems = temp.sort((a, b) => collator.compare(a.nm_equipment, b.nm_equipment));
      setItemsToDisplay(sortItems);
    }

  }, [sectorOptions, filterLine, area, site, line]);

  const handleChange = (event) => {
    const arrayValues = event?.target?.value?.map(x => x);
    setFilterSector(arrayValues)
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150, }}>
        <InputLabel sx={{ m: filterSector?.length == 0 ? -1 : 0 }}>
          {filterSector?.length == 0 ? <FilterAltOutlinedIcon /> : ""}
          {language?.filter_sector || 'Filter Sector'}
        </InputLabel>
        <Select
          multiple
          value={filterSector}
          onChange={handleChange}
          input={
            <OutlinedInput
              sx={{ maxHeight: 40, }}
              label={language?.filter_sector || 'Filter Sector'} />
          }
          renderValue={
            (selected) => (
              itemsToDisplay?.length + " " + language?.available || 'avaliable'
              // 'disponiveis'
              // selected.length == 1 
              // ? itemsToDisplay.filter(sites => sites.id_site == selected)?.[0]?.nm_site
              // : selected.length + " " + language?.selected || 'selected'
            )
          }>

          {itemsToDisplay?.length > 0
            ? itemsToDisplay?.map((site, index) => (
              <MenuItem key={site.id_equipment} value={site.id_equipment}>
                <Checkbox sx={{ m: -1, mr: .3 }} checked={filterSector.includes(site.id_equipment)} />
                <Typography variant="body2"> {site.nm_equipment} </Typography>
              </MenuItem>
            ))
            : language?.no_sectors_available || 'No sectors available'}

        </Select>
      </FormControl>
    </>
  );
};