import React, { useState, useEffect, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { ReactComponent as Logo } from '../../assets/white-logo.svg'
import { ReactComponent as BackMenu } from "../../assets/backMenu.svg"
import { ReactComponent as BackMenuClose } from "../../assets/backMenuClose.svg"
import CompanyLogo from "../../assets/CompanyLogo.svg"

import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@mui/material/useMediaQuery';

import ListaSideBar from './components/ListaSideBar';
import User from './components/User'
import Configuration from './components/Configuration'
import { AppBar, Box, Toolbar, IconButton } from '@mui/material';
import { VariablesContext } from '../../Context/VariablesContext';

const drawerWidth = 240;

const AppBarPer = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#E3E2E7',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  // width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down('md')]: {
    width: 0
  },
  backgroundColor: '#E3E2E7',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Header() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { menu, logoUrl } = useContext(VariablesContext);
  
  const handleChangeSideBar = (childdata) => {
    setOpen(childdata);
  }

  return (
    <Box sx={{ flexGrow: 1, }}>
      <AppBarPer position="fixed" open={open} sx={{ height: 48, backgroundColor: '#131822' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <IconButton color="inherit" aria-label="open drawer" edge="start"
            onClick={() => setOpen(!open)}
            sx={{ left: '4px', mb: 1.5 }} >
            {open === true ? <BackMenu /> : <BackMenuClose />}
          </IconButton>

          <Box sx={{ zoom: .25, mb: {xs:4,md:8} }}>
            <Logo />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
            <Configuration />
            <User />
          </Box>

        </Toolbar>
      </AppBarPer>

      <Drawer variant="permanent" open={open} >
        <DrawerHeader>
          <IconButton onClick={() => setOpen(!open)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Box sx={{ mt: -.8 }}>
          {menu?.map((item, index) => (
            item?.hidden !== true && item?.menu_group!==-1
              ? <ListaSideBar key={index} menuData={item} handleChangeSideBar={handleChangeSideBar} isSideBarOpen={open} />
              : <></>))}
        </Box>

        <Box sx={{ position: 'relative', ml: 3, width: 1, mt: 'auto', mb: 2 }}>
          <Box sx={{ zoom: .7, display: open ? 'block' : 'none', }}>
            <img alt="" src={ logoUrl || CompanyLogo} />
          </Box>
        </Box>

      </Drawer>

      {/* Looks like this componnent keeps the page content in position... When I remove it the mission control breadcrumb disapears */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      </Box>

    </Box>
  );
}
