import React, { useEffect, useContext } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';
import Spacer from '../../components/Spacer';
import Flow from './components/Flow';
import FlowLoading from './components/FlowLoading';
import { getLanguage, roundNumber } from '../../utils/utils';

import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTION_FLOW } from './queries';

import { VariablesContext } from '../../Context/VariablesContext';
import NoData from '../../components/NoData';
import { ContainerError } from './styles';

function ProductionFlow() {
  const language = getLanguage();
  const { ts_start, ts_end, enterprise, site, area, line, dateRange } = useContext(VariablesContext);
  const [getProductionFlow, { data, loading, error }] = useLazyQuery(GET_PRODUCTION_FLOW, { fetchPolicy: 'no-cache' });

  let message = '';

  const totalProduced = data?.h_piot_production_flow?.[0]?.production_flow?.length >= 1 ? data?.h_piot_production_flow?.[0]?.production_flow?.[data?.h_piot_production_flow?.[0]?.production_flow?.length - 1]?.net || 0 : 0;

  const totalScrap = data?.h_piot_production_flow?.[0]?.production_flow?.reduce((previous, current) => {
    return previous + current?.scrap;

  }, 0);

  useEffect(() => {
    let grain = 'DAY';
    let start = ts_start;
    let end = ts_end;

    if (dateRange === 'today' || dateRange === 'day') {
      grain = 'HOUR';
    }

    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];
    if (line && enterprise) {
      in_id_sites = site;
      in_id_areas = area;
      in_id_equipments = line;
      getProductionFlow({
        variables: {
          id_enterprise: enterprise,
          shifts: ``,
          id_site: `{${in_id_sites.toString()}}`,
          id_equipment: `{${in_id_equipments.toString()}}`,
          id_area: `{${in_id_areas.toString()}}`,
          ts_start: start,
          ts_end: end,
          grain,
          teams: '{}',
        }
      });
    }

  }, [line, ts_end, enterprise]);

  if (!line) message = `${language?.select_line_to_view || 'Please select a line to see the production flow.'}`;
  if (error) message = `${language?.an_error_occurred || 'Oops! An error occurred.'}`;

  return (
    <Box sx={{ mr: 3, ml: 3 }}>
      <Breadcrumb />
      {/** Header */}
      <Box sx={{ m: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ lineHeight: 1, fontSize: 32, fontWeight: 'bold' }}>{language?.production_flow || 'Production Flow'}</Typography>
          <Spacer size={20} />

        </Box>
      </Box>

      {/** Content */}
      <Box sx={{ m: 3 }}>
        {
          !line || error ? <ContainerError> <NoData message={message} /> </ContainerError> :
            <>
              {
                loading ? <FlowLoading /> :
                  <>
                    <Flow data={data?.h_piot_production_flow?.[0]?.production_flow} language={language} />
                    <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                      <Box sx={{ mr: 2 }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{language?.total_produced || 'Total Produced'}</Typography>
                        <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: '#407CCC' }} > {roundNumber(totalProduced)} </Typography>
                      </Box>
                      <Divider orientation="vertical" sx={{ height: '60px' }} />
                      <Box sx={{ ml: 2 }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{language?.scrap || 'Scrap'}</Typography>
                        <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: '#C12727' }} > {roundNumber(totalScrap)} </Typography>
                      </Box>
                    </Box>
                  </>
              }
            </>
        }

      </Box>

    </Box>
  );
}

export default ProductionFlow;