import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilter from '../../components/EntityFilters';
import NoData from '../../components/NoData';

import { getLanguage } from '../../utils/utils';
import GrainSelector from '../../components/GrainSelector';
import TypeOfViewSelector from '../../components/TypeOfViewSelector';
import Chart from './components/Chart';
import SinglePeriodShiftChart from './components/ChartShift';
import { VariablesContext } from '../../Context/VariablesContext';
import { GET_SINGLE_PERIOD_DATA } from './queries';
import { Box, Skeleton, Card, FormGroup, FormControlLabel } from '@mui/material';
import { Typography, Switch, } from '@material-ui/core'

import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SelectASite from '../../components/SelectASite';

export default function ScrapPeriod() {
  const language = getLanguage();
  const [isShift, setShift] = useState(false);
  const [isTeam, setTeam] = useState(false);
  const [porcentage, setPorcentage] = useState(true);
  const { ts_start, ts_end, enterprise, site, area, line, filterShift, grain, dateRange, filterSite, filterArea, filterLine, filterTeam, typeOfView } = useContext(VariablesContext);
  const [getProductionData, { data, error, loading }] = useLazyQuery(GET_SINGLE_PERIOD_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    let shifts = filterShift?.join();
    let teams = filterTeam?.join();
    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];
    let grainSize = grain;
    if (dateRange === 'day' || dateRange === 'today') {
      grainSize = 'HOUR';
    }
    if (dateRange === 'week') {
      grainSize = 'DAY';
    }

    if (shifts) {
      setShift(true);
    } else {
      shifts = '';
      setShift(false);
    }

    if (teams) {
      setTeam(true);
    } else {
      teams = '';
      setTeam(false);
    }

    if (site) {
      if (area) {
        if (line) {
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    } else {
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

    getProductionData({
      variables: {
        id_enterprise: enterprise,
        shifts: `${shifts}`,
        sites: `{${in_id_sites && in_id_sites.toString()}}`,
        equipments: `{${in_id_equipments.toString()}}`,
        areas: `{${in_id_areas.toString()}}`,
        teams: `{${teams}}`,
        ts_start,
        ts_end,
        grain: grainSize,
        group_by_element: typeOfView
      }
    });

  }, [enterprise, site, area, line, ts_start, ts_end, filterShift, filterSite, filterArea, filterLine, grain, filterTeam, typeOfView]);

  return (
    <Box sx={{ pr: 3, pl: 3 }}>
      <Breadcrumb />

      <Box sx={{ m: { xs: 0, sm: 3 }, display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='h2'> {language?.scrap_period || 'Scrap Period'} </Typography>
          {site ? <EntityFilter hideSector hideTeam /> : <></>}
          {site ?
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none', lg: 'none' } }}>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={() => { setPorcentage(!porcentage) }} color='primary' />} label="Units" size="small" />
              </FormGroup>
            </Box>
            : <></>
          }
        </Box>
        {site ?
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-evenly', mt: { xs: 2, lg: 0 } }}>
            <TypeOfViewSelector />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: { xs: 4, sm: 0 } }}>
              {(dateRange === 'month' || dateRange === 'last month') && <GrainSelector />}
              <Box sx={{ width: 10, display: { xs: 'block', sm: 'none' } }}></Box>
              <Box sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
                <FormGroup>
                  <FormControlLabel control={<Switch onChange={() => { setPorcentage(!porcentage) }} color='primary' />} label="Units" size="small" />
                </FormGroup>
              </Box>
            </Box>
          </Box>
          : <></>
        }
      </Box>


      {
        site ?
          loading ? <Skeleton variant='rect' height={600} /> :
            <Card sx={{
              mb: 2, mt: 4, display: 'flex',
              flexDirection: 'column',
              overflowX: { xs: 'scroll', md: 'visible' },
              height: '600px', width: '100%'
            }} elevation={16} >

              {
                (data?.h_piot_single_period_with_teams_4?.length) ?

                  (typeOfView != 'GENERAL'
                    ? <SinglePeriodShiftChart data={data?.h_piot_single_period_with_teams_4} dateRange={dateRange} porcentage={porcentage} />
                    : <Chart data={data?.h_piot_single_period_with_teams_4} dateRange={dateRange} porcentage={porcentage} />)

                  : <Box sx={{ width: '300px', mt: 10 }}>
                    <NoData message={language?.no_data || 'No data'} />
                  </Box>
              }

              {typeOfView == 'GENERAL' &&
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  /* justifyContent: { xs: 'space-around', sm: 'center' },
                  alignItems: 'center', flexWrap: 'wrap',
                  mt: 2,
                  mt: '520px'
                  ml: { xs: 3, sm: '220px', md: '320px', lg: '520px', },
                  position: 'absolute', */
                  
                }}>
                  <Box sx={{ display: 'flex', }}>
                    <CircleIcon sx={{ color: '#C13939', fontSize: 20, mr: 1 }} />
                    <Typography variant="body2"> {language?.scrap || 'Scrap'} </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', mx: 2 }}>
                    <MoreHorizIcon sx={{ mr: 1 }} />
                    <Typography variant="body2"> {language?.average || 'Average'} </Typography>
                  </Box>
                </Box>}
            </Card>
          :<SelectASite/>  
          }

    </Box>
  );
}
