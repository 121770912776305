import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Header from './components/Header';
import InfoUp from './components/InfoUp';
import InfoSide from './components/InfoSide';
import Chart from './components/Chart';
import TableEvent from './components/Table';
import { VariablesContext } from '../../Context/VariablesContext';
// import Loading from '../../components/Loading';

import { Box, } from '@mui/material';

import { GET_SHIFT_PROD, GET_DAY_PROD, GET_MONTH_PROD, GET_PRODUCTION_CHART, GET_EVENTS, GET_JOB_INFO, GET_TARGET, PROJ_MONTH, STATUS_EQUIPMENT, AREA_NAME, OEE_OBJ_MONTH, RECORD_12H, SCRAP_TARGET } from './components/queries';

export default function OverviewGranado() {
  let { lineId } = useParams();
  const { enterprise, site, area, line } = useContext(VariablesContext);
  // const { data: dayData  } = useQuery(GET_DAY_PROD, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'DAY' });

  //recarrega a pagina a cada um dia
  setTimeout(() => {
    window.location.reload()
  }, 86400000);


  //BARRA DE CIMA
  const { data: jobData, startPolling: s0 } = useQuery(GET_JOB_INFO, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: statusData, startPolling: s1 } = useQuery(STATUS_EQUIPMENT, { variables: { in_id_enterprise: enterprise, in_ids_sites: `{${site ? site : ""}}`, in_ids_equipments: `{${lineId}}`, in_ids_areas: `{${""}}` }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Status' });
  const { data: areaName, startPolling: s2 } = useQuery(AREA_NAME, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  //PROD SIDEBAR
  const { data: recorde12h, startPolling: s3 } = useQuery(RECORD_12H, { variables: { _eq: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'recorde12h' });
  const { data: objetivos, startPolling: s4 } = useQuery(GET_TARGET, { variables: { _eq1: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'objetivos' });
  const { data: shiftData, startPolling: s5 } = useQuery(GET_SHIFT_PROD, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Shift' });
  const { data: monthProj, startPolling: s6 } = useQuery(PROJ_MONTH, { variables: { _eq: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Month' });
  const { data: oeeObjMes, startPolling: s7 } = useQuery(OEE_OBJ_MONTH, { variables: { _eq: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Month' });

  //TABELA
  const { data: eventsData, startPolling: s8 } = useQuery(GET_EVENTS, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Events' });

  //GRAFICO
  const { data: prodData, startPolling: s9 } = useQuery(GET_PRODUCTION_CHART, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache' });

  let arr12H = prodData?.h_piot_overview_i_production_chart?.map(e => e.production);
  let max12H = Math.max.apply(null, arr12H);


  useEffect(() => {
    s0(55000); s1(55000); s2(55000); s3(55000); s4(55000); s5(55000); s6(55000); s7(55000); s8(55000); s9(55000);
  }, [])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F3F5 ',
          flexGrow: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 2,
          gridTemplateRows: 'auto',
          gridTemplateAreas:
            `"title title title title"
            "main main main main"
            "info graph graph graph"
            "info graph graph graph"
            "info re re re"`,
        }}>

        <Box sx={{ gridArea: 'title', }}>
          <Header />
        </Box>

        <Box sx={{ gridArea: 'main', }}>
          <InfoUp
            dataProd={shiftData}
            info={jobData}
            dataObjetivos={objetivos}
            areaName={areaName}
            restTime={jobData?.h_piot_overview_i_get_job_info?.[0]?.remaining_time}
            status={statusData}
          />
        </Box>

        <Box sx={{ gridArea: 'info', mb: 4 }}>
          <InfoSide
            dataProd={shiftData}
            dataMax12H={max12H}
            dataObjetivos={objetivos}
            dataProjMonth={monthProj}
            dataObjMonth={oeeObjMes}
            recorde12h={recorde12h}
          />
        </Box>

        <Box sx={{ gridArea: 'graph', }}>
          <Chart
            data={prodData?.h_piot_overview_i_production_chart}
            dataArr12H={arr12H}
            target={objetivos} />
        </Box>

        <Box sx={{ gridArea: 're', mb: 4 }}>
          <TableEvent data={eventsData?.h_piot_overview_i_get_events} />
        </Box>

      </Box>

    </>
  )
}

