import { Skeleton, Box } from '@mui/material';
import React from 'react';

function FlowLoading() {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Skeleton variant='rectangular' width='100%' height='400px' />
      <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center', mt: '20px' }}>
        <Box sx={{ mr: '15px' }}>
          <Skeleton variant='text' width='120px' height='30px' />
          <Skeleton variant='text' width='120px' height='50px' />
        </Box>

        <Box>
          <Skeleton variant='text' width='120px' height='30px' />
          <Skeleton variant='text' width='120px' height='50px' />
        </Box>
      </Box>
    </Box>
  );
}

export default FlowLoading;