import React from 'react';
import { Box, Typography } from '@mui/material';

import LightTooltip from '../../../components/LightTooltip';
import { AreaSpan } from '../styles';

import { getLanguage, convertToDaysHoursNoSeconds } from '../../../utils/utils';

const CardHeader = ({ data }) => {
  const language = getLanguage();
  const colors = {
    running: '#318F29',
    lowSpeed: '#ECBC13',
    stopped: '#C13939',
    default: '#808080'
  }
  let currentStatus = data?.status ?? "default";
  let statusLanguage = language?.unknown || 'Unknown';
  if (data?.status === 'running') statusLanguage = language?.running || 'Running';
  if (data?.status === 'lowSpeed') statusLanguage = language?.low_speed || 'Low Speed';
  if (data?.status === 'stopped') statusLanguage = language?.stopped || 'Stopped';

  let timeInCurrentStatus = 0;
  let index = data.status_24h?.length > 0 ? data.status_24h?.length - 1 : -1;
  if (data?.status != 'running') {
    while (index >= 0) {
      if (data.status_24h?.[index] == data.status) {
        timeInCurrentStatus++;
        index--;
      } else {
        index = -1
      }
    }
    data.timeInCurrentStatus = timeInCurrentStatus;
  }

  return (
    <Box sx={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography noWrap={true} sx={{ fontSize: { xs: '18px', sm: '20px' }, fontWeight: 700, color: '#4D4D4D' }}> {data?.nm_line} | <AreaSpan> {data?.nm_area} </AreaSpan></Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='body1' sx={{ mr: '10px' }} >{data?.timeInCurrentStatus ? convertToDaysHoursNoSeconds(data?.timeInCurrentStatus * 60 ?? 0, 'seconds') : ''}</Typography>
        <LightTooltip title={`${statusLanguage}`} placement='top'>
          <Box sx={{ width: '20px', height: '20px', borderRadius: '100%', background: `${colors[currentStatus]}` }} />
        </LightTooltip>

      </Box>
    </Box>
  );
}

export default CardHeader;