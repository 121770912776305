import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Table from './components/Table';
import Breadcrumb from '../../components/Breadcrumb';
import { VariablesContext } from '../../Context/VariablesContext';
//import EntityFilter from '../../components/EntityFilters';
import { Box, Skeleton } from '@mui/material';

import { PRODUCTION_ORDERS_NEW } from './queries'
import { convertToDaysHoursNoSeconds, dateFormat } from '../../utils/utils';
import Spacer from '../../components/Spacer';
import moment from 'moment';
import SkeletonTable from './components/SkeletonTable';
import { scrapCalc } from '../../configurations/scrap';

function ProductionOrders() {
  const { enterprise, site, area, line, filterShift, ts_start, ts_end, visualizationLevel, filterSite, filterArea, filterLine } = useContext(VariablesContext);
  const [tableData, setTableData] = useState([]);
  const scrapType = localStorage.getItem('scrap_calc_type');

  function totalTime(start, end) {
    if (end) {
      const value = moment(end).diff(start, 'seconds')
      const total = convertToDaysHoursNoSeconds(`${value}`, 'seconds')
      return total;
    }
    return '-'
  }

  function formatDate(date) {
    if (date) {
      return dateFormat(date, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'CLIENT' });
    }
    return '-';
  }

  const [getProductionOrders, { loading }] = useLazyQuery(PRODUCTION_ORDERS_NEW, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let table = data?.h_piot_production_orders_with_runtimes4?.map((item, index) => ({
        id: index,
        gross_production: item?.gross_production,
        id_equipment: item?.id_equipment,
        id_order: item?.id_order,
        id_production_order: item?.id_production_order,
        net_production: item?.net_production,
        nm_client: item?.nm_client,
        nm_equipment: item?.nm_equipment,
        nm_product: item?.nm_product,
        txt_product: item?.txt_product,
        production_ordered: item?.production_ordered,
        production_final: item?.production_final,
        runtimes: item?.runtimes?.map((item, index) => ({
          id: index,
          nm_equipment: item?.nm_equipment,
          production_final: item?.production_final,
          net: item?.net,
          gross: item?.gross,
          ts_start: formatDate(item?.ts_start),
          ts_end: formatDate(item?.ts_end),
          duration: item?.duration,
          scrap_percentage: item?.scrap_percentage,
        })),
        status: item?.status,
        ts_end: item?.ts_end,
        ts_start: item?.ts_start,
        scrap: scrapCalc(scrapType, item?.gross_production, item?.net_production) + " %",
        total: totalTime(item?.ts_start, item?.ts_end)
      }));
      setTableData(table);
    }
  });


  useEffect(() => {
    updateData();
  }, [ts_end, ts_start, line, filterShift, filterArea, filterLine, site, area, filterSite]);

  const updateData = () => {
    if (site) {
      if (area) {
        if (line) {
          // Shift Filter
          getProductionOrders({ variables: { id_enterprise: enterprise, shifts: `${filterShift.toString()}`, sites: '{' + (site ? site : "") + '}', equipments: '{' + (line ? line : "") + '}', areas: '{' + (area ? area : "") + '}', ts_start: ts_start, ts_end: ts_end, teams: "{}" } });
        }
        else {
          // Lines and Shift Filters
          getProductionOrders({ variables: { id_enterprise: enterprise, shifts: `${filterShift.toString()}`, sites: '{' + (site ? site : "") + '}', equipments: `{${filterLine.toString()}}`, areas: '{' + (area ? area : "") + '}', ts_start: ts_start, ts_end: ts_end, teams: "{}" } });

        }
      }
      else {
        // Areas, Lines and Shift Filters
        getProductionOrders({ variables: { id_enterprise: enterprise, shifts: `${filterShift.toString()}`, sites: '{' + (site ? site : "") + '}', equipments: `{${filterLine.toString()}}`, areas: `{${filterArea.toString()}}`, ts_start: ts_start, ts_end: ts_end, teams: "{}" } });

      }
    }
    else {
      getProductionOrders({ variables: { id_enterprise: enterprise, shifts: `${filterShift.toString()}`, sites: `{${filterSite.toString()}}`, equipments: `{${filterLine.toString()}}`, areas: `{${filterArea.toString()}}`, ts_start: ts_start, ts_end: ts_end, teams: "{}" } });

    }
  }

  return (
    <Box>
      <Breadcrumb />

      <Box sx={{ m: 3 }}>
        {
          (loading) ?
            <Box sx={{ width: '100%', height: 700, display: 'flex', flexDirection: 'column' }}>
              <Spacer isVertical size={10} />
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton width='400px' height='85px' />
                <Box sx={{ width: '400px', display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant='text' width='100px' height='50px' />
                  <Skeleton variant='text' width='100px' height='50px' />
                  <Skeleton variant='text' width='100px' height='50px' />
                </Box>

              </Box>
              <Spacer isVertical size={20} />
              <Skeleton variant='rectangular' width='100%' height='40px' />
              <SkeletonTable />


            </Box>
            : <Table data={tableData} update={updateData} />
        }

      </Box>

    </Box>
  );
}

export default ProductionOrders;
