import React, { useState, useEffect } from 'react';
import { Typography, Box, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Divider, Button, } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getLanguage, getTotalElapsedTime } from '../../../utils/utils';


function ModalEditTime({ params, isOpen, handleClose, handleChange }) {
  const language = getLanguage();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [minDateStart, setMinDateStart] = useState(null);
  const [maxDateStart, setMaxDateStart] = useState(null);
  const [minDateEnd, setMinDateEnd] = useState(null);
  const [maxDateEnd, setMaxDateEnd] = useState(null);
  const [duration, setDuration] = useState('0');
  const [dateFormat, setDateFormat] = useState('DD/MM/YYYY HH:mm');
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    const dateRange = [moment(dateStart).format(), moment(dateEnd).format()];
    if (moment(dateStart).format() != 'Invalid date' && moment(dateEnd).format() != 'Invalid date') {
      handleChange(dateRange);

    } else {
      enqueueSnackbar(`${language?.an_error_occured || "Ops! Something went wrong."}`, { variant: 'error' });
    }
  }

  useEffect(() => {
    let language = localStorage.getItem('user_language');
    let format = language === 'en-US' ? 'MM/DD/YYYY hh:mma' : 'DD/MM/YYYY HH:mm';

    setDateFormat(format);
    setDateStart(params?.start);
    setMinDateStart(null);
    setMaxDateStart(params?.end);

    setDateEnd(params?.end);
    setMinDateEnd(params?.start);
    setMaxDateEnd(moment(new Date()));


  }, [params]);

  useEffect(() => {
    if (dateStart && dateEnd) {
      let duration = moment(dateEnd).diff(dateStart, 'seconds');
      let totalDuration = getTotalElapsedTime(duration, 'seconds');
      setDuration(totalDuration);
    }
  }, [dateStart, dateEnd]);

  const handleDateStartChange = (date) => {
    setDateStart(date);
    setMinDateEnd(moment(date, 'DD-MM-YYYY HH:mm'));
    setMaxDateEnd(moment(new Date(), 'DD-MM-YYYY HH:mm'));
  }

  const handleDateEndChange = (date) => {
    setDateEnd(date);
    setMaxDateStart(moment(date, 'DD-MM-YYYY HH:mm'));
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ pl: 2, pt: 3 }}>
        <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: '22px', color: '#4D4D4D', width: '80%' }}>
          {language?.edit_runtime || 'Edit Runtime'} {'-'} {language?.job || 'Job'} {params?.row?.id_order}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pr: 2, pl: 2, width: { xs: '100%', sm: 492 }, height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >

        <DateTimePicker
          value={dateStart}
          inputFormat={dateFormat}
          ampm={dateFormat === 'MM/DD/YYYY hh:mma' ? true : false}
          ampmInClock={true}
          onChange={(newValue) => handleDateStartChange(newValue)}
          label={language?.time_start || 'Start Time'}
          renderInput={(params) => <TextField fullWidth {...params} />}
          minDateTime={minDateStart}
          maxDateTime={maxDateStart}
        />
        <Box sx={{ height: '24px' }} />

        <DateTimePicker
          value={dateEnd}
          inputFormat={dateFormat}
          ampm={dateFormat === 'MM/DD/YYYY hh:mma' ? true : false}
          ampmInClock={true}
          onChange={(newValue) => handleDateEndChange(newValue)}
          label={language?.time_end || 'End Time'}
          renderInput={(params) => <TextField fullWidth {...params} />}
          minDateTime={minDateEnd}
          maxDateTime={maxDateEnd}
        />

        <Box sx={{ mt: '24px', display: 'flex' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#4D4D4D' }}>{language?.total_duration || 'Total Duration'}: {' '} </Typography>
          <Typography sx={{ fontSize: '20px', ml: '5px', color: '#4D4D4D' }}> {duration} </Typography>
        </Box>


      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 2, justifyContent: { xs: 'center', sm: 'flex-end' } }}>
        <Button
          sx={{ mr: { xs: 0, sm: '24px' }, borderRadius: 2, px: 3, border: '2px solid #407CCC', color: '#000', background: '#FFF', '&:hover': { background: '#EAEAED' } }}
          variant="contained" disableElevation onClick={handleClose}
          size="small"
        >
          {language?.cancel || 'Cancel'}
        </Button>

        <Button sx={{ borderRadius: 2, px: 4 }} size="small" variant="contained" onClick={() => handleSave()}>
          {language?.edit || 'Edit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEditTime;