import { gql } from '@apollo/client';

export const GET_VARIABLES_CONTEXT = gql`
 query GET_VARIABLES_CONTEXT {
  v_entities_per_user_role {
    areas
    enterprise
    equipments
    sectors
    id_enterprise
    id_user_role
    nm_user_role
    shifts
    sites
    teams
  }
  enterprises {
    scrap_calc_type
    logo_url
    id_enterprise
  }
  user_roles {
    permissions
  }
  users {
    user_name
    languages
    language {
      language_pack_desktop
    }
  }
  v_menu_per_user_role {
    id_user_role
    menu
  }
  shifts {
    cd_shift
    id_shift
    name_site {
      nm_site
      id_site
    }
    area {
      id_area
      nm_area
    }
  }
}`;
