import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput, 
}  from '@mui/material';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function AreaFilter() {
  const { areaOptions, filterArea, setFilterArea, site } = useContext(VariablesContext);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const language = getLanguage();

  useEffect(() => {
      if(areaOptions){let temp = [...areaOptions];
      temp = temp.filter(item => {
          return (site? site == item.id_site : true);
      });
      setItemsToDisplay(temp);
  }
  }, [areaOptions, site]);

  const handleChange = (event) => {
    const arrayValues = event?.target?.value?.map(x => x);
    setFilterArea(arrayValues)
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150, }}>
        <InputLabel sx={{  m: filterArea?.length == 0 ? -1 : 0 }}>
          {filterArea?.length == 0 ? <FilterAltOutlinedIcon /> : "" }
          { language?.filter_area || 'Filter Area' } 
        </InputLabel>
        <Select
          multiple 
          value={ filterArea }
          onChange={handleChange}
          input={
            <OutlinedInput
              sx={{ maxHeight:40, }} 
              label={ language?.filter_area || 'Filter Area' }/>
          }
          renderValue={
            (selected) => ( 
              itemsToDisplay?.length +" "+ language?.available || 'avaliable'
              // 'disponiveis'
              // ( site ? (itemsToDisplay.length) : ( selected.length) ) + " " + 'disponiveis'
              )
          }>

          {itemsToDisplay?.length > 0 
          ? itemsToDisplay?.map((area, index) => (
            <MenuItem key={area.id_area} value={area.id_area}>
              <Checkbox sx={{m:-1, mr:.3}} checked={filterArea.includes(area.id_area)} />
              <Typography variant="body2"> {area.nm_area } </Typography>
            </MenuItem>
          ))
          : language?.no_areas_available || 'No areas available'}
          
        </Select>
      </FormControl>
    </>
  );
};