import { gql } from '@apollo/client';

export const TIMELINE = gql`
query GET_EVENTS_TIMELINE(
  $job: Int
  $enterprise: Int
  $area: String
  $site: String
  $line: String
  $filter_event_types: String
  $ts_start: timestamp
  $ts_end: timestamp
) {
  h_piot_get_events_timeline_full_with_filter_3(
    args: {
      _id_production_order: $job
      in_id_enterprise: $enterprise
      in_ids_areas: $area
      in_ids_equipments: $line
      in_event_types: $filter_event_types
      in_ids_sites: $site
      _tsstart: $ts_start
      _tsend: $ts_end
    }
    limit: 120
  ) {
    cd_category
    cd_machine
    cd_subcategory
    change_over
    duration
    event_type
    id_equipment
    id_order
    nm_area
    nm_client
    nm_equipment
    nm_site
    status
    ts_end
    ts_event
    ts_timeline
    txt_downtime_notes
  }
}

`;

export const GET_JOBS = gql`
query GET_JOBS($line: Int) {
  production_orders(where: {id_equipment: {_eq: $line}, _and: {status: {_neq: 1}}}) {
    id_order
    id_production_order
  }
}
`