import React from 'react';
import {
  Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material'
import { Typography } from '@material-ui/core';
import { getLanguage } from '../../../utils/utils';
import moment from 'moment';

export default function TableEvent({ list, line }) {
  const language = getLanguage();

  let data = list
  let treatData = data?.slice(0, 6);

  let lingua = localStorage.getItem('user_language')
  let type = { fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 20 };

  function getColor(row) {
    let color = "#333333";
    //nÃo justificada
    if (row === "notJustified") color = "#FD9729";
    //está parado
    if (row === "runningStop") color = "#C13939";
    return color;
  }

  return (
    <Card sx={{ mx: 1, display: 'flex', width: 1 }} elevation={0} >
      <CardContent >
        <Typography variant="h3" style={{ marginLeft: 10, marginRight: -15 }} > {line} </Typography>
      </CardContent>

      <CardContent sx={{ width: 1, }} >
        <Table sx={{ width: 1, }} size={'small'} aria-label="a dense table" >
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={type}> {language?.start || 'Start'} </TableCell>
              <TableCell align="left" sx={type}> {language?.duration || 'Duration'} </TableCell>
              <TableCell align="left" sx={type}> {language?.category || 'Category'}  </TableCell>
              <TableCell align="left" sx={type}> {language?.sub_category || 'Sub-Category'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {treatData?.map((row, index) => (
              <TableRow key={index} sx={{ color: getColor(row?.colorcolumn), '&:last-child td, &:last-child th': { border: 0 }, fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} >
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} component="th" scope="row">
                  {lingua == 'en-US' ? moment(row?.start).format('MM/DD hh:mm a') : moment(row?.start).format('MM/DD HH:mm ')}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} align="left">
                  {row?.duration}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} align="left">
                  {row?.reason}
                </TableCell>
                <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20, }} align="left">
                  {row?.sub_category}
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </CardContent>

    </Card >
  )
};