import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment-timezone';
import { roundNumber, isInvalidNumber, getLanguage, userLocale } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';

import { TooltipContainer, TooltipColumn, TooltipDivider, TooltipItems, TooltipTitle, TooltipResponsive } from '../styles';

const ShiftOrTeamTooltip = ({ active, payload, label, chartData, dateRange }) => {
  const language = getLanguage();
  const locale = userLocale();
  const { typeOfView, timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  let currentDot = chartData?.filter((item) => item?.ts_value_production === label);
  if (!active) return null;
  currentDot = currentDot?.[0] || {};
  if (currentDot?.net === null) return null;
  if (moment(currentDot?.ts_value_production).isAfter(moment(new Date()))) return null;
  const shiftsOrTeams = currentDot?.array_agg || [];
  let tooltipSize = 240;
  if (shiftsOrTeams.length > 1) {
    tooltipSize = 440;
  }

  let showIncomplete = false;
  let format = 'dddd DD/MM';
  let showHour = false;
  let displayDate = moment(label).utc().format(format);
  if (dateRange === 'day' || dateRange === 'today') {
    format = locale.hour;
    if (!locale.isAmPm) showHour = true;
    displayDate = moment(label).format(format);
  }

  if (moment(currentDot.ts_value_production).minutes() !== 0 || moment(currentDot.ts_value_production).seconds() !== 0) {
    showIncomplete = true;
  }


  return (
    <TooltipResponsive size={tooltipSize}>
      <Box sx={{ display: 'flex', height: '50px', width: '100%' }}>
        <TooltipTitle>
          {displayDate}{(showHour) ? 'h' : ''}
          <span> {showIncomplete ? `(${language?.inprogress || 'In progress'})` : ''} </span>
        </TooltipTitle>

      </Box>

      <TooltipColumn>
        {
          shiftsOrTeams
            ?.filter((shiftOrTeam) => {
              // Eliminate Data Without Team
              if (typeOfView == 'TEAMS') {
                return shiftOrTeam?.cd_team
              } else if (typeOfView == 'SHIFTS') {
                return shiftOrTeam?.cd_shift
              } else {
                return true
              }
            })
            ?.filter((item) => item?.net != null)?.map((shiftOrTeam, index) => (
              <TooltipItems key={index}>
                {
                  typeOfView == 'TEAMS' ?
                    <Typography sx={{ color: '#4D4D4D' }} >{shiftOrTeam?.cd_team}</Typography>
                    : <Typography sx={{ color: '#4D4D4D' }} > {shiftOrTeam?.cd_shift}</Typography>
                }
                <Typography sx={{ fontWeight: 'bold', fontSize: '26px' }}>
                  {roundNumber(shiftOrTeam?.scrap_percentage?.toFixed(1))} %
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#4D4D4D', opacity: 0.5 }}>
                  ({roundNumber(shiftOrTeam?.net)})
                </Typography>
              </TooltipItems>
            ))
        }

      </TooltipColumn>

    </TooltipResponsive>
  )

};
export default ShiftOrTeamTooltip;
