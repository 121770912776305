import React from 'react';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import { Card, Box, Typography as Typography2 } from '@mui/material';
import { Typography } from '@material-ui/core';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function InfoLines({ data, unsShift }) {
  const size = 9000
  const language = getLanguage();

  let eqp1 = unsShift?.uns_equipment_current_shift?.[0]?.oee
  let eqp2 = unsShift?.uns_equipment_current_shift?.[1]?.oee
  let avgOeeEqpsPorcent = ((eqp1 + eqp2) / 2) * 100;

  const ordem1 = data?.uns_equipment_current_job?.[0]?.target
  const prod1 = data?.uns_equipment_current_job?.[0]?.net_production

  const ordem2 = data?.uns_equipment_current_job?.[1]?.target
  const prod2 = data?.uns_equipment_current_job?.[1]?.net_production

  const colorStatus1 = () => {
    let color = "#407CCC";
    data?.uns_equipment_current_job?.[0]?.current_expected_time <= 7200 ? color = "#EB5757" : color = "#407CCC";
    return color;
  }
  const colorStatus2 = () => {
    let color = "#407CCC";
    data?.uns_equipment_current_job?.[1]?.current_expected_time <= 7200 ? color = "#EB5757" : color = "#407CCC";
    return color;
  }

  const timeForm = (num) => {
    const dia = num / 86400;
    const restoDia = num % 86400;

    const hora = restoDia / 3600;
    const restoHora = restoDia % 3600;

    const minutos = restoHora / 60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "")
    )
  };

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 190, width: 1 }} elevation={0}>
      {/* <Typography variant="h1" > OEE: &nbsp; {avgOeeEqpsPorcent?.toFixed(2)}% </Typography> */}

      <Box sx={{ width: '50%' }}>
        <Box sx={{ display: 'flex', }} >
          <Typography variant="h2">
            L1 - {data?.uns_equipment_current_job?.[0]?.nm_product}
          </Typography>
          <Box color={'#407CCC'} sx={{ display: 'flex', alignItems: 'center' }} >
            <AccessTimeOutlinedIcon sx={{ mx: 1, }} />
            <Typography variant="h2" color='#407CCC' sx={{ backgroudColor: '#407CCC' }}>
              {timeForm(data?.uns_equipment_current_job?.[0]?.current_expected_time)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ maxWidth: 900, minHeight: 40, display: 'flex', }}>
          {/* já produzido */}
          <Typography2 sx={{
            width: ((prod1 * 100) / ordem1) * size,
            height: 22, fontSize: 24,
            backgroundColor: colorStatus1,
            borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
            borderTopRightRadius: Math.round(((prod1 * 100) / ordem1)) >= 100 ? 5 : 0,
            borderBottomRightRadius: Math.round(((prod1 * 100) / ordem1)) >= 100 ? 5 : 0,
            display: 'flex', justifyContent: 'center',
          }} variant="h6" color="#FFFFFF" > </Typography2>

          {/* faltando prod1uzir */}
          <Typography2
            sx={{
              width: Math.round((prod1 * 100) / ordem1) <= 100 ? (100 - ((prod1 * 100) / ordem1)) * size : 0, height: 22, opacity: 0.6,
              backgroundColor: colorStatus1,
              borderBottomRightRadius: 5, borderTopRightRadius: 5,
            }} variant="h6" color={colorStatus1} opacity={0.6} > </Typography2>
        </Box>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', }} >
          <Typography variant="h2">
            L2 - {data?.uns_equipment_current_job?.[1]?.nm_product}
          </Typography>
          <Box color={'#407CCC'} sx={{ display: 'flex', alignItems: 'center' }} >
            <AccessTimeOutlinedIcon sx={{ mx: 1, }} />
            <Typography variant="h2" color='#407CCC' sx={{ backgroudColor: '#407CCC' }}>
              {timeForm(data?.uns_equipment_current_job?.[1]?.current_expected_time)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ maxWidth: 1000, minHeight: 40, display: 'flex', }}>
          {/* já produzido */}
          <Typography2 sx={{
            width: ((prod2 * 100) / ordem2) * size,
            height: 22, fontSize: 24,
            backgroundColor: colorStatus2,
            borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
            borderTopRightRadius: Math.round(((prod2 * 100) / ordem2)) >= 100 ? 5 : 0,
            borderBottomRightRadius: Math.round(((prod2 * 100) / ordem2)) >= 100 ? 5 : 0,
            display: 'flex', justifyContent: 'center',
          }} variant="h6" color="#FFFFFF" > </Typography2>
          {/* faltando prod2uzir */}
          <Typography2
            sx={{
              width: Math.round((prod2 * 100) / ordem2) <= 100 ? (100 - ((prod2 * 100) / ordem2)) * size : 0, height: 22, opacity: 0.6,
              backgroundColor: colorStatus2,
              borderBottomRightRadius: 5, borderTopRightRadius: 5,
            }} variant="h6" color={colorStatus2} opacity={0.6} > </Typography2>
        </Box>
      </Box>

    </Card>
  );
}
