import React, { useContext, useState, useEffect } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, Box, Typography, FormHelperText, FormControl, InputAdornment, InputLabel, OutlinedInput, Button, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as PackiotLogo } from "../../assets/packiot-dark.svg";
import LoginImage from "../../assets/LoginImage.png"
import { SuportLink } from './styles';
import { AuthContext } from '../../Context/AuthContext'
import { VariablesContext } from '../../Context/VariablesContext';
import EnterpriseModal from './components/enterpriseModal.js';

function Login() {
  const { initialValues } = useContext(VariablesContext)
  const { login, userError, passError: wrongPassword, } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState({ error: false, message: '' });
  const [emailError, setEmailError] = useState({ error: false, message: '' });
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [counter, setCounter] = useState(0);
  const year = new Date().getFullYear();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    if (emailError.error) setEmailError({ error: false, message: '' });
    if (emailSuccess) setEmailSuccess(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    if (passError.error) setPassError({ error: false, message: '' });
    setPassword(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === '') {
      setEmailError({ error: true, message: 'Email is required' });
      return;
    } else if (!email.includes('@')) {
      setEmailError({ error: true, message: 'Email is not valid' });
      return;
    }
    if (password === '') {
      setPassError({ error: true, message: 'Password is required' });
      return;
    } else {
      login(email, password)
    }
    initialValues();
  }

  useEffect(() => {
    if (wrongPassword) {
      setPassError({ error: true, message: 'Please, enter the correct password.' });
    }
    if (userError) {
      setEmailError({ error: true, message: 'Please enter a valid email address.' });
    } else if (userError === false) {
      setEmailError({ error: false, message: '' });
      setEmailSuccess(true);
    }
  }, [wrongPassword, userError])


  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >

        <Box sx={{ height: { xs: 'calc(100vh - 65px)', lg: 'calc(100vh - 165px)' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: { xs: '30px', lg: '0' }, }}>

          <Box component='form'>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
              <PackiotLogo style={{ marginTop: '30px' }} />
              <Typography variant="h1" sx={{ fontWeight: 500, color: '#333333', fontSize: { xs: '36px', md: '64px' }, mt: { xs: '20px', md: '40px' }, textAlign: 'center' }}> Welcome Back! </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#333333', fontSize: '25px' }}> Log in to see your growth. </Typography>
            </Box>

            <FormControl
              error={emailError.error} fullWidth variant='outlined'
              color={emailSuccess ? 'success' : ''}
              focused={emailSuccess}
              sx={{ mb: '20px', mt: '48px' }}
            >
              <InputLabel htmlFor="outlined-adornment-email">Enter email *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                label="Enter email *"
                value={email}
                onChange={handleEmailChange}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label={`${emailError.error ? 'email error' : 'email success'}`}
              //       edge="end"
              //       onClick={() => {
              //         if (emailError.error) {
              //           setEmailError({ error: false, message: '' });
              //           setEmail('');
              //         }
              //       }}
              //     >
              //       {
              //         emailError.error ? <HighlightOffIcon sx={{ color: '#C13939' }} /> 
              //         : <CheckCircleOutlineIcon sx={{ color: '#318F29', visibility: (!emailSuccess) ? 'hidden' : 'visible' }} />
              //       }

              //     </IconButton>
              //   </InputAdornment>
              // }

              />
              {emailError.error && <FormHelperText sx={{ ml: 0 }}> {emailError.message} </FormHelperText>}

            </FormControl>

            <FormControl error={passError.error} fullWidth variant="outlined" >
              <InputLabel htmlFor="outlined-adornment-password">Enter your password*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                // endAdornment={
                // <InputAdornment position="end">
                //   <IconButton
                //     aria-label="toggle password visibility"
                //     // onClick={handleClickShowPassword}
                //     onMouseDown={handleMouseDownPassword}
                //     edge="end"
                //   >
                //     {showPassword ? <VisibilityOff /> : <Visibility />}
                //   </IconButton>
                // </InputAdornment>
                // }
                label="Enter your password"
              />
              {passError.error && <FormHelperText sx={{ ml: 0 }}> {passError.message} </FormHelperText>}
            </FormControl>

            <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'space-between' }, alignItems: 'center' }}>
              {/*<FormControlLabel sx={{ display: { xs: 'none', md: 'block' }, color: '#828282', fontWeight: 'bold' }} 
                control={<Checkbox sx={{color:'#BDBDBD'}} />} label="Remember me" 
              /> */}
              <Link style={{ fontWeight: 'bold', textDecoration: 'none' }} to="/forgot-password">Forgot your password?</Link>
            </Box>

            <Button
              type='submit'
              sx={{ width: '100%', height: '50px', mt: '40px', backgroundColor: '#407CCC', mb: { xs: '10px', md: '30px', lg: '50px' }, }}
              variant='contained' onClick={handleSubmit}
            >
              Login
            </Button>

            <EnterpriseModal />
          </Box>

        </Box>

        <Box sx={{
          width: '100%', height: '65px', display: 'flex',
          justifyContent: 'center', alignItems: 'center',
          color: { xs: '#fff', lg: '#BDBDBD' },
          background: { xs: '#1F608A', lg: 'transparent' },
        }}>
          <p>&nbsp;</p>
          <Typography>&copy; {year} - All rights reserved</Typography>
        </Box>

      </Grid>

      <Grid item lg={7}
        sx={{ display: { xs: 'none', lg: 'flex' }, height: '100vh', background: "#1F608A", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
        <Box sx={{ width: '180px', display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '40px', right: '100px' }}>
          <MailOutlineIcon sx={{ color: '#fff' }} />
          <SuportLink href='mailto:suport@packiot.com'> suport@packiot.com </SuportLink>
        </Box>
        <img style={{ width: '50%', height: 'auto' }} src={LoginImage} className="image"
          onClick={
            () => {
              if (counter > 10) {
                handleClickShowPassword();
              } else {
                setCounter(counter + 1)
              }
            }
          }
        />
      </Grid>
    </Grid>
  );
}

export default Login;