import React from 'react';
import { Dialog, DialogContent, Button, Box,  } from '@mui/material';
import { Typography } from '@material-ui/core';

import { getLanguage } from '../../../../../../utils/utils';
import api from '../../../../../../services/api';
import { useSnackbar } from 'notistack';

export default function ModalDeleteUser({ isOpen, params, handleClose, update}) {
  const language = getLanguage();
  const { enqueueSnackbar } = useSnackbar();
  let name = params?.nm_user_role;  

  let confirmMsg = language?.delete_role || "You're about to delete the role name. Are you sure about this?";
  confirmMsg = confirmMsg.replace("name", name?.toString());

  
  const handleDelete = async () => {
    try {
      const response = await api.delete('/api/admin/user-roles', {
        data: {
          id_user_role: params?.id
        }
      });
      if (response) {
        enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
        update();
        handleClose()
      }
    } catch (error) {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'}>

      <DialogContent sx={{ pr: 3, pl: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

        <Box sx={{ textAlign: 'center', width: '360px', mb: '24px' }}>
          <Typography variant='subtitle1'> {confirmMsg} </Typography>
        </Box>
        <Button sx={{ borderRadius: 2, textTransform: 'capitalize', px: 4, width: 251, height: 45 }} size="small" variant="contained" onClick={handleDelete}> 
         <Typography variant='body1'> {language?.delete || 'Delete'} </Typography>
        </Button>

        <Button
          sx={{ width: 157, mt: '16px', textTransform: 'capitalize', height: 45, borderRadius: 2, px: 3, border: '2px solid #407CCC', color: '#000', background: '#FFF', '&:hover': { background: '#EAEAED' } }}
          variant="contained" disableElevation onClick={handleClose}
          size="small"
        >
          <Typography variant='body1'> {language?.cancel || 'Cancel'} </Typography>
        </Button>

      </DialogContent>

    </Dialog>
  );
}