import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const FirebaseConfig = {
  apiKey: 'AIzaSyCRK02fBbgho-VSQrjt5bIZzzVdoIgpRGo',
  authDomain: 'fbpackiot.firebaseapp.com',
  databaseURL: 'https://fbpackiot.firebaseio.com',
  projectId: 'fbpackiot',
  storageBucket: 'fbpackiot.appspot.com',
  messagingSenderId: '969023787798'
};

const app = initializeApp(FirebaseConfig);

export const auth = getAuth(app);