import React, { useState, useEffect } from 'react';
import { IconButton, Box } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { getLanguage, JSONToCSVConvertor } from '../../../../../utils/utils';

function ExportCsv({ dataDownload }) {
  const year = new Date().getFullYear().toString();
  const month = new Date().getMonth().toString();
  const day = new Date().getDate().toString();
  const hour = new Date().getHours().toString();
  const fileName = day + month + year + "-" + hour + 'export.csv';

  const columns = {
    'id_order': 'order id',
    //'nm_product': 'product name',
    //'txt_product': 'product description',
    'cd_product': 'product code',
    'production_programmed': 'production programmed',
    'order_size': 'production ordered',
    'nm_client': 'client',
    //'product_family': 'product family',
    'nm_equipment': 'line',
    'start_date': 'order date',
    'txt_production_order_description': 'order description',
    'equipment_setup': 'setup',
    'scrap_target': 'scrap target',
  };


  const handleDowntimeDownload = () => {
    console.log(dataDownload)
    JSONToCSVConvertor(dataDownload, columns, 'PO-details', true);
  };

  return (
    <IconButton aria-label="download" sx={{ mr: 2 }} onClick={() => handleDowntimeDownload()}>
      <DownloadOutlinedIcon fontSize='large' />
    </IconButton>
  );
}

export default ExportCsv;