import React from 'react';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function NoData({ variant, message }) {
  return (
    <Typography  sx={{color:'#757575', display: 'flex', alignItems: 'center', justifyContent: "center" }} variant={variant} >
        <InfoOutlinedIcon sx={{ mr:1 }} />
        {message}
    </Typography>
  );
}

export default NoData;