import * as React from 'react';
import { useContext, useState, useEffect } from "react";
import moment from 'moment';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { Box, Chip, TextField } from '@mui/material';
import { Typography } from '@material-ui/core';

import useMediaQuery from '@mui/material/useMediaQuery';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { dateRange } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

export default function Filter() {
  const language = getLanguage();
  const todayStart = moment().startOf('day').format('YYYY-MM-DD HH:mm');
  const todayEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm');
  const weekStart = moment().startOf('week').format('YYYY-MM-DD HH:mm');
  const weekEnd = moment().endOf('week').startOf('day').format('YYYY-MM-DD HH:mm');
  const monthStart = moment().startOf('month').format('YYYY-MM-DD HH:mm')
  const monthEnd = moment().endOf('month').startOf('day').format('YYYY-MM-DD HH:mm')
  const isTablet = useMediaQuery('(max-width:1300px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width:700px)', { noSsr: true });

  const lang = localStorage.getItem('user_language');
  if (lang && lang !== 'null') {
    moment.locale(lang.toLowerCase());
  } else {
    moment.locale('en-us');
  }

  const { ts_start, ts_end, setTs_start, setTs_end, setGrain, setRange, dateRange: currentRange } = useContext(VariablesContext);

  const [value, setValue] = useState([ts_start, ts_end]);

  useEffect(() => {
    setValue([ts_start, ts_end]);
  }, [ts_start, ts_end]);

  const handleDatePickerChange = (newValue, grain = 'DAY') => {
    let diff =  moment(newValue[1])?.diff(moment(newValue[0]), 'days');
    setValue(newValue);
    const range = dateRange(moment(newValue[0]).format('YYYY-MM-DD HH:mm'), diff);
    setRange(range);
    setTs_start(moment(newValue[0]).format('YYYY-MM-DD HH:mm'));
    setTs_end(moment(newValue[1]).endOf('day').format('YYYY-MM-DD HH:mm'));
    if (diff === 0) {
      setGrain('HOUR');
    }else {
      setGrain(grain);
    }
  }

  const CssTextField = styled(TextField)({
    '& .MuiInput-underline:after': {
      borderBottomColor: 'blue',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'blue',
    }
  });

  return (
    <Box sx={{ display: 'flex' }}>

      <Box sx={{
        display: !isTablet ? 'flex' : 'none',
        flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center'
      }}>
        <Chip
          sx={{ mr: 2, bgcolor: `${currentRange == 'today' ? '#407CCC' : 'white'}`, color: `${currentRange == 'today' ? 'white' : 'black'}`, border: "solid 1px grey" }}
          label={<Typography variant='body2'> {language?.today || 'Today'} </Typography>}
          onClick={() => handleDatePickerChange([todayStart, todayEnd], 'HOUR')}
        />
        <Chip
          sx={{ mr: 2, bgcolor: `${currentRange == 'week' ? '#407CCC' : 'white'}`, color: `${currentRange == 'week' ? 'white' : 'black'}`, border: "solid 1px grey" }}
          label={<Typography variant='body2'> {language?.this_week || 'This Week'} </Typography>}
          onClick={() => handleDatePickerChange([weekStart, weekEnd], 'DAY')}
        />
        <Chip
          sx={{ mr: 4, bgcolor: `${currentRange == 'month' ? '#407CCC' : 'white'}`, color: `${currentRange == 'month' ? 'white' : 'black'}`, border: "solid 1px grey" }}
          label={<Typography variant='body2'> {language?.this_month || 'This Month'} </Typography>}
          onClick={() => handleDatePickerChange([monthStart, monthEnd], 'DAY')}
        />
      </Box>

      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            startText={language?.from_date || 'From'}
            endText={language?.to_date || 'To'}
            value={value}
            maxDate={moment().endOf('month')}
            onChange={handleDatePickerChange}
            renderInput={(startProps, endProps) => (
              <>
                <CssTextField
                  variant="standard"
                  InputLabelProps={{
                    style: { fontWeight: 'bold' },
                    shrink: true
                  }}
                  {...startProps}
                />
                <Box sx={{ mx: 2, color: '#407CCC' }}> <ChevronRightIcon /> </Box>
                <CssTextField
                  variant="standard"
                  InputLabelProps={{
                    style: { fontWeight: 'bold' },
                    shrink: true
                  }}
                  {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
