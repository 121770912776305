import React, { useState, useEffect, useContext } from "react";
import { useQuery, useLazyQuery } from '@apollo/client';
import { getLanguage } from '../../utils/utils';
import { Grid, Box, Typography, Tab, Tabs, } from '@material-ui/core'
import { Button, ButtonGroup } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import TypeOfViewSelector from '../../components/TypeOfViewSelector';

import { OEE_PROGRESS, OEE_SCORE } from './Query';

import Breadcrumb from '../../components/Breadcrumb';
import OeeScore from './components/OeeScore';
import OeeProgress from "./components/OeeProgress";
import { VariablesContext } from "../../Context/VariablesContext";
import { LoadingOeeScore } from './components/LoadingOeeScore';

export default function OEE() {
  const language = getLanguage();
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [getOeeScore, { loading: oeeScoreLoading, error: statusError, data: oeeScoreData }] = useLazyQuery(OEE_SCORE, { fetchPolicy: 'no-cache' });

  const [getOeeProgress, { loading: oeeProgressLoading, error: progressError, data: progressData }] = useLazyQuery(OEE_PROGRESS, { fetchPolicy: 'no-cache' });

  const { ts_start, ts_end, area, line, site, filterShift, filterSite, filterArea, filterLine, enterprise, visualizationLevel, filterTeam, teamOptions, typeOfView } = useContext(VariablesContext);

  let time_grain = 'DAY';
  let nav_level = 'EQUIPMENT';
  let in_id_areas = area || [].toString();
  let in_id_equipments = line || [].toString();
  let in_id_sites = site || [].toString();
  let in_id_teams = [].toString();
  let in_id_shifts = [].toString();

  if (site) {
    if (area) {
      if (line) {
        nav_level = 'EQUIPMENT';
      } else {
        nav_level = 'AREA';
      }
    } else {
      nav_level = 'SITE';
    }
  } else {
    nav_level = 'SITE';
  }

  // This commented code uses the filters
  // if (site) {
  //   if (area) {
  //     if (line) {
  //       nav_level = 'EQUIPMENT';
  //       in_id_sites = site;
  //       in_id_areas = area;
  //       in_id_equipments = line;
  //     } else {
  //       nav_level = 'AREA';
  //       in_id_sites = site;
  //       in_id_areas = area;
  //       in_id_equipments = filterLine.toString();
  //     }
  //   } else {
  //     nav_level = 'SITE';
  //     in_id_sites = site;
  //     in_id_areas = filterArea.toString();
  //     in_id_equipments = filterLine.toString();
  //   }
  // } else {
  //   nav_level = 'SITE';
  //   in_id_sites = filterSite.toString();
  //   in_id_areas = filterArea.toString();
  //   in_id_equipments = filterLine.toString();
  // }

  useEffect(() => {
    getOeeScore({
      variables: {
        in_id_enterprise: enterprise,
        in_ids_shifts: `${in_id_shifts}`,
        in_ids_teams: `{${in_id_teams}}`,
        in_id_sites: `{${in_id_sites}}`,
        in_id_equipments: `{${in_id_equipments}}`,
        in_id_areas: `{${in_id_areas}}`,
        in_begin_time: ts_start,
        in_end_time: ts_end,
        is_shift_filtered: true,
        time_grain,
        nav_level,
      }
    });
  }, [site, line, area, ts_start, ts_end, filterShift, filterLine, filterArea, filterSite, filterTeam]);

  useEffect(() => {
    getOeeProgress({
      variables: {
        begin_time: ts_start,
        end_time: ts_end,
        in_id_areas: `{${in_id_areas}}`,
        in_id_enterprise: enterprise,
        time_grain,
        in_id_shifts: `${filterShift.toString()}`,
        is_shift: typeOfView == 'SHIFTS',
        in_ids_team: `{}`,
        is_team: typeOfView == 'TEAMS',
        in_id_sites: `{${in_id_sites}}`,
        in_id_equipments: `{${in_id_equipments}}`,
        nav_level
      }
    });

  }, [site, line, area, ts_start, ts_end, filterShift, filterLine, filterArea, filterSite, typeOfView, filterTeam]);

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: '#333333',
      '&.Mui-selected': {
        color: '#333333',
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#407CCC',
      },
    }),
  );

  const StyledTabs = styled((props) => (
    <TabList {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 60,
      width: '100%',
      backgroundColor: '#407CCC',
    },
  });

  return (
    <Box sx={{ m: { xs: 0, sm: 0, md: 1, xl: 2 } }}>
      <Breadcrumb />
      <Box sx={{ mt: 2, ml: 4 }}>
        <Typography variant="h2"> OEE </Typography>
      </Box>

      <TabContext value={value} sx={{ display: 'flex' }}>

        <Box
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'space-evelyn' : 'space-between',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            alignItems: 'center',
          }}>
          <StyledTabs
            sx={{ mx: !isMobile ? -2 : 0 }}
            onChange={handleChange} aria-label="lab API tabs example">
            <StyledTab value="1"
              sx={{ mr: !isMobile ? -5 : 2 }}
              label={<Typography variant="subtitle2"> {language?.score || 'Score'} </Typography>} />
            <StyledTab value="2"
              label={<Typography variant="subtitle2"> {language?.progress || 'Progress'} </Typography>} />
          </StyledTabs>

          <TypeOfViewSelector />
        </Box>

        <TabPanel value="1">
          {!oeeScoreLoading
            ? <Grid container columns={12} spacing={2}>
              {oeeScoreData?.h_piot_oee_score_with_teams?.map((equipment, index_equipment) => {
                return <OeeScore equipment={equipment} />;
              })}

              {oeeScoreData?.h_piot_oee_score_with_teams?.map((item, index) => {
                return item?.childs?.map((equipment, index_equipment) => {
                  return <OeeScore equipment={equipment} />;
                })
              })}
            </Grid>
            : <LoadingOeeScore />
          }

        </TabPanel>

        <TabPanel value="2">
          {!oeeProgressLoading
            ?
            <Grid container columns={12} spacing={2}>
              {progressData?.h_piot_oee_progress_new2?.map((equipment, index_equipment) => {
                return <OeeProgress data={equipment} />;
              })}
            </Grid>
            : <LoadingOeeScore />
          }
        </TabPanel>

      </TabContext>

    </Box>
  );
}
