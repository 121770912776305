import React, { useContext, useEffect, useState } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { Box, Card, Grid, Paper } from '@mui/material';
import { Typography, } from '@material-ui/core';
import { useQuery, useLazyQuery } from '@apollo/client';
import RelatorioTurno from './components/RelatorioTurno';
import Segurança from './components/Segurança';
import Produção from './components/Produção';
import ConsumoRefugo from './components/ConsumoRefugo';
import ChartProdMonth from './components/ChartProdMonth';
import ChartAggMonth from './components/ChartAggMonth';
import { ReactComponent as Logoblack } from '../../assets/logoblack.svg';
import { EQP_RUNTIME_1DAY, EQP_RUNTIME_1MONTH, EQP_RUNTIME_SHIFT, UNS_EQUIPMENT_CURRENT_JOB, ENTERPRISE } from './queries';
import moment from 'moment';
import api from '../../services/api';

export default function PdfSuzano() {
  let user_language = localStorage.getItem('user_language');
  const { enterprise, } = useContext(VariablesContext);
  const [tags, setTags] = useState([]);

  const getTags = async () => {
    const response = await api.get('/api/tags/37');
    if (response?.data?.data) {
      setTags(response?.data?.data);
    }
  }

  useEffect(() => {
    getTags();
  }, []);

  const [lineL1runtimeDay, setLineL1runtimeDay] = useState([])
  const [lineL2runtimeDay, setLineL2runtimeDay] = useState([])

  const lines = [404, 411];

  const date = new Date();

  let initialOfMonth = moment(date).startOf('month').format('YYYY-MM-DD')
  let initialOfNextMonth = moment(date)?.add(1, 'month').startOf('month').format('YYYY-MM-DD');

  const { data: runtimeDay } = useQuery(EQP_RUNTIME_1DAY, {
    variables: {
      _eq: lines,
      // DE ->
      _gte: initialOfMonth,
      // ATE -> 
      _lt: initialOfNextMonth
    }, fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let respost = data?.equipment_runtime_1day
      setLineL1runtimeDay(respost?.filter((el) => el?.equipment?.id_equipment == 404))
      setLineL2runtimeDay(respost?.filter((el) => el?.equipment?.id_equipment == 411))
    }
  });

  const { data: runtimeMonth } = useQuery(EQP_RUNTIME_1MONTH, {
    variables: {
      _eq: lines,
      // DE ->
      _gte: initialOfMonth,
      // ATE -> 
      _lt: initialOfNextMonth
    }, fetchPolicy: 'no-cache',
  });

  const { data: runtimeShift } = useQuery(EQP_RUNTIME_SHIFT, {
    variables: { _eq1: lines, _eq2: moment(date).subtract(2, 'minutes').format('YYYY-MM-DD') }, fetchPolicy: 'no-cache',
  });

  const { data: unsJob } = useQuery(UNS_EQUIPMENT_CURRENT_JOB, { variables: { _in: lines }, fetchPolicy: 'no-cache' });

  // index - figura da empresa
  let { data: enterpriseData } = useQuery(ENTERPRISE, { variables: { eq: enterprise } });

  return (
    <>
      <Grid container sx={{ backgroundColor: '#F1F3F5' }}>
        <Grid item xs={4} >
          <RelatorioTurno runtimeShift={runtimeShift} />
          {/* TODO: falta passarem as tags */} <Segurança />
          <Produção runtimeShift={runtimeShift} unsJob={unsJob} />
          {/* TODO: falta passarem as tags */} <ConsumoRefugo tags={tags} />
        </Grid>
        <Grid item xs={8}>
          <ChartProdMonth runtimeDay={lineL1runtimeDay} />
          <ChartProdMonth runtimeDay={lineL2runtimeDay} />
          <Box sx={{ display: 'flex', }}>
            {runtimeMonth?.equipment_runtime_1month?.map((el, i) => {
              return (<ChartAggMonth key={i} runtimeMonth={el} />)
            })}
          </Box>
        </Grid>
      </Grid >

      <Box sx={{ gridArea: 'below', height: 50, display: 'flex', justifyContent: 'space-between', mb: 5 }}>
        <Box sx={{ mt: -1.7, ml: 2 }}>
          <img src={enterpriseData?.enterprises?.[0]?.logo_url} width='100px'></img>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mr: 5 }}>
          <Logoblack />
          {/* <Typography variant="h2"> */}
          {/* {user_language === 'pt-BR' ? moment().format('HH:mm') : moment().format('hh:mm a')} */}
          {/* </Typography> */}
        </Box>
      </Box>

    </>
  )
}
