import { gql } from "@apollo/client";

/*export const SET_REPLACE_JOB = gql`
mutation ReplaceJob($id_production_order: Int, $new_id: Int) {
    update_production_orders_runtime(where: {id_production_order: {_eq: $id_production_order}}, _set: {id_production_order: $new_id, recalc_needed: true}) {
      affected_rows
      returning {
        id_production_order
      }
    }
  }
  
`*/


/*
export const SET_REPLACE_JOB = gql`
mutation SwitchJobNumber($id_production_order: Int, $new_id: Int) {
  piot_switch_po_number(args: {in_new_production_order: $new_id, in_production_order_running: $id_production_order}) {
    id_production_order
  }
}

`;*/

export const SET_REPLACE_JOB_RUNTIME = gql`
mutation SetRuntimeOrder($id_production_order: bigint, $new_id: Int) {
  update_production_orders_runtime(
    _set: { id_production_order: $new_id }
    where: { id_production_order_runtime: { _eq: $id_production_order } }
  ) {
    affected_rows
  }
}


`;

export const SET_JOB_RUNTIME_START_END = gql`
mutation SetJobRuntimeStartEnd($id_production_order: bigint, $timeRange: tstzrange) {
  update_production_orders_runtime(
    where: { id_production_order_runtime: { _eq: $id_production_order } }
    _set: { runtime_timerange: $timeRange }
  ) {
    affected_rows
  }
}
`;

export const DELETE_JOB_AVAILABLE = gql`
mutation DeleteJobAvailable($id_production_order: bigint) {
  delete_production_orders(where: {id_production_order: {_eq: $id_production_order}, _and: {status: {_eq: 1}}}) {
    affected_rows
    returning {
      id_order
    }
  }
}

`;
