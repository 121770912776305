import React, { useContext, } from "react";
import { useQuery } from '@apollo/client';
import { VariablesContext } from '../../../Context/VariablesContext';
import { GET_VARIABLES_CONTEXT } from '../../../Context/Query';
import { getLanguage } from '../../../utils/utils';

import { ReactComponent as More } from '../../../assets/More.svg';
import { Box, Divider, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Typography, } from '@material-ui/core';

const language = getLanguage();

export default function area() {
  const { area, setArea, site } = useContext(VariablesContext);
  const { loading, error, data } = useQuery(GET_VARIABLES_CONTEXT);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeArea = (idArea) => {
    setArea(idArea);
    handleClose();
  };

  let text = data?.v_entities_per_user_role?.[0]?.areas.filter(item => item.id_area == area)?.[0]?.nm_area
  let home = location.pathname.toUpperCase().includes('HOME');

  return (
    <>
      <IconButton
        disableRipple={true} color='primary'
        variant="text"
        aria-controls={open ? 'basic-menu' : undefined}
        onClick={handleClick}
      >
        <Typography variant={!home ? 'body1' : 'body2'} color={!home ? '#407CCC' : 'gray'} >
          {area ? text : (home ? language?.choose_area || 'Choose area' :
            <Box sx={{ display: 'flex', alignSelf: 'center'}}>
              <More />
            </Box>
          )}
          {/* {text != undefined ? text : <More/> } */}
        </Typography>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Typography variant="h3" > {language?.select_area || 'Select an area'} </Typography>
        </MenuItem>
        <Divider></Divider>

        {data?.v_entities_per_user_role?.[0]?.areas.filter(item => item.id_site == site).map((area, index_area) => {
          return (
            <MenuItem value={area.id_area} onClick={() => { handleChangeArea(area.id_area); }} >
              {area?.nm_area}
            </MenuItem>
          )
        })}

      </Menu>


    </>
  );
}