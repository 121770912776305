import styled from 'styled-components';

export const TooltipContainer = styled.div`
    box-shadow: -4px 6px 13px rgba(0, 16, 34, 0.2);
    border-radius: 8px;
    background: #fff;
    width: 480px;
    height: 160px;
`;

export const TooltipResponsive = styled.div`
    box-shadow: -4px 6px 13px rgba(0, 16, 34, 0.2);
    border-radius: 8px;
    background: #fff;
    width: ${(props) => props.size}px;
    height: 160px;
`;

export const TooltipColumn = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const TooltipDivider = styled.div`
    width: 1px;
    height: 70px;
    background: #BDBDBD;
`;

export const TooltipItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TooltipTitle = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 12px;
    margin-left: 15px;
    color: #4D4D4D;
    span {
        font-size: 19px;
        color: #4D4D4D;
        opacity: 0.5;
    }
`;
