import React, { useContext } from 'react';
import { Box, Fab, Typography } from '@mui/material'
import { useLazyQuery } from '@apollo/client';
import { TIMELINE } from './queries';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/SwipeableDrawer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getLanguage } from '../../utils/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { VariablesContext } from '../../Context/VariablesContext';

import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Filters from './filters';
import TimeLine from './TimeLine';
import EventsLoading from './eventsLoading';

const drawerBleeding = 66;

const Root = styled('div')(() => ({
  // height: '100%',
  backgroundColor: '#fff',
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: '#fff',
}));

export default function EventsTab() {
  const isLargeDevice = useMediaQuery('(min-width:1200px)');
  const language = getLanguage();
  const { ts_start, ts_end, eventTabsOpen, setEventTabOpen } = useContext(VariablesContext);
  //const [state, setState] = useState(false);
  const [getEvents, { data: timelineData, loading }] = useLazyQuery(TIMELINE, { fetchPolicy: 'no-cache' });

 /*  const toggleDrawer = (newOpen) => () => {
    setState(newOpen);
  }; */

  const updateQuery = (props) => {
    if (props.filter) {
    } else {
      getEvents({
        variables: {
          enterprise: props.enterprise,
          site: `{${props.siteSelected || ''}}`,
          area: `{${props.areaSelected || ''}}`,
          line: `{${props.lineSelected || ''}}`,
          job: props?.job,
          filter_event_types: `{${props.filter_event_types || ''}}`,
          ts_start,
          ts_end
        }

      })
    }
  }

  return (
    <>
      {isLargeDevice ?
        <Fab
          sx={{
            position: 'absolute',
            top: 100,
            right: 0,
            '#tab-text': { display: 'none' },
            overflow: 'hidden',
            width: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            background: '#828282',
            ':hover': { background: '#828282', mr: 0, width: 'auto', '#tab-text': { display: 'block', } }
          }}
          variant="extended"
          size="small"
        >

          <ChevronLeftIcon sx={{ mr: 1, color: '#fff' }} />
          <Typography id='tab-text' variant='button' sx={{ color: '#fff' }} onClick={setEventTabOpen}>
            {language?.events || 'Events'}
          </Typography>

          <Drawer
            anchor='right'
            open={eventTabsOpen}
            variant="persistent"
            sx={{ textTransform: 'none', cursor: 'default' }}
            PaperProps={{
              sx: {
                mt: 10,
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                width: '820px',
              }
            }}
          >
            <Box
              sx={{ width: '100%', height: '48px', background: '#828282', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={setEventTabOpen}
            >
              <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 600 }}>{language?.events || 'Events'} <ArrowForwardIosIcon sx={{ mr: 1, color: '#fff', fontSize: '16px', verticalAlign: 'middle' }} /> </Typography>
            </Box>

            <Filters update={updateQuery} language={language} />
            {
              loading ? <EventsLoading /> : <TimeLine data={timelineData?.h_piot_get_events_timeline_full_with_filter_3} />
            }

          </Drawer>

        </Fab>

        : <Root>
          <CssBaseline />
          <Global
            styles={{
              '.MuiDrawer-root > .MuiPaper-root': {
                // height: `calc(90% - ${drawerBleeding}px)`,
                height: `calc(95%)`,
                overflow: 'visible',
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={eventTabsOpen}
            onClose={setEventTabOpen}
            onOpen={setEventTabOpen}
            swipeAreaWidth={drawerBleeding}
            disableBackdropTransition
            disableSwipeToOpen={false}
            ModalProps={{ keepMounted: true, }} >
            <StyledBox sx={{ top: eventTabsOpen ? 19 : -40, right: 0, left: 0, position: 'absolute', visibility: 'visible', borderTopLeftRadius: 8, borderTopRightRadius: 8, }} >

              <Box sx={{ width: '100%', height: '40px', background: '#828282', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                <Box sx={{ display: 'flex', height: '100%', width: '200px', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 600 }}>
                    {language?.events || 'Events'}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ ml: 1, color: '#fff', fontSize: '16px', verticalAlign: 'middle', transform: `rotate(${eventTabsOpen ? '90' : '-90'}deg)` }} />
                </Box>
              </Box>

            </StyledBox>

            <StyledBox sx={{ px: 2, pb: 2, height: '100%', overflow: 'auto', mt:8 }} >
              <Filters update={updateQuery} language={language} />
              {loading ? <EventsLoading /> : <TimeLine data={timelineData?.h_piot_get_events_timeline_full_with_filter_3} /> }
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      }

    </>
  )
}

