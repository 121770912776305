import React, { useContext, useEffect, useState } from 'react';
import InfoLines from './components/InfoLines';
import Card1 from './components/Card1';
import Card2 from './components/Card2';
import Chart from './components/Chart';
import TableEvent from './components/Table';
import { ReactComponent as Logoblack } from '../../assets/logoblack.svg';

import { VariablesContext } from '../../Context/VariablesContext';
import { Box, Card, } from '@mui/material';
import { Typography, } from '@material-ui/core';

import moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/client';

import {
  UNS_EQUIPMENT_CURRENT_JOB, UNS_EQUIPMENT_CURRENT_SHIFT, UNS_EQUIPMENT_CURRENT_DAY,
  GET_PRODUCTION_CHART, GET_EVENTS, GET_TARGET, UNS_EQUIPMENT_CURRENT_METRICS, ENTERPRISE,
} from './queries';

export default function OverviewSuzano() {
  let user_language = localStorage.getItem('user_language');

  const { enterprise, site, area, line } = useContext(VariablesContext);
  const [arrChartAllLines, setArrChartAllLines] = useState([]);

  const lines = [404, 411];
  const eqp1 = 404
  const eqp2 = 411

  //Linhas para test/desenvolvimento
  // const lines = [426, 433];
  // const eqp1 = 426
  // const eqp2 = 433

  let sumObjectsByKey = (...objs) => Object.values(objs.reduce((a, e) => {
    a[e.time] = a[e.time] || { time: e.time };

    for (const k in e) {
      if (k !== "time") {
        a[e.time][k] = a[e.time][k] ? a[e.time][k] + e[k] : e[k];
      }
    }

    return a;
  }, {}));

  // index - figura da empresa
  let { data: enterpriseData } = useQuery(ENTERPRISE, { variables: { eq: enterprise }, pollInterval: 55000 });

  //BARRA DE CIMA - infoLine
  const { data: unsJob, startPolling: s0 } = useQuery(UNS_EQUIPMENT_CURRENT_JOB, {
    variables: { _in: lines }, pollInterval: 55000, fetchPolicy: 'no-cache'
  });

  //CARD 1
  //infoLine tbm usa unsShift
  const { data: unsShift, startPolling: s2 } = useQuery(UNS_EQUIPMENT_CURRENT_SHIFT, { variables: { in: lines }, pollInterval: 55000, fetchPolicy: 'no-cache', });

  const { data: objetivos, startPolling: s3 } = useQuery(GET_TARGET, {
    variables: { _in: lines }, pollInterval: 55000, fetchPolicy: 'no-cache'
  });

  const { data: eqpsInfoDay, startPolling: s4 } = useQuery(UNS_EQUIPMENT_CURRENT_DAY, { variables: { _in: lines }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: eqpMetrics, startPolling: s5 } = useQuery(UNS_EQUIPMENT_CURRENT_METRICS, { variables: { _in: lines }, pollInterval: 55000, fetchPolicy: 'no-cache', });

  //TABELA
  const { data: eventsData1, startPolling: s6 } = useQuery(GET_EVENTS, { variables: { id: eqp1 }, pollInterval: 55000, fetchPolicy: 'no-cache', });

  const { data: eventsData2, startPolling: s1 } = useQuery(GET_EVENTS, { variables: { id: eqp2 }, pollInterval: 55000, fetchPolicy: 'no-cache', });

  let listaEqp1Eventos = []
  let listaEqp2Eventos = []
  let listaAllEqpEventos = []

  listaEqp1Eventos = eventsData1?.h_piot_overview_i_get_events_3
  listaEqp2Eventos = eventsData2?.h_piot_overview_i_get_events_3

  if (listaEqp1Eventos?.length > 0 && listaEqp2Eventos?.length > 0) {
    listaAllEqpEventos = [...listaEqp1Eventos, ...listaEqp2Eventos]

    listaAllEqpEventos = listaAllEqpEventos?.sort((a, b) => {
      return (a.start > b.start) ? -1 : ((a.start < b.start) ? 1 : 0);
    })
  }

  //GRAFICO
  const [getChartData1, { data: prodDataEqp1 }] = useLazyQuery(GET_PRODUCTION_CHART, {
    onCompleted: (data) => {
      getChartData2({ variables: { id: eqp2 } });
    }, fetchPolicy: 'no-cache'
  });

  const [getChartData2, { data: prodDataEqp2 }] = useLazyQuery(GET_PRODUCTION_CHART, {
    onCompleted: (data) => {
      setArrChartAllLines(sumObjectsByKey(...prodDataEqp1?.h_piot_overview_production_chart, ...data?.h_piot_overview_production_chart));
    },
    fetchPolicy: 'no-cache'
  })
  //const { data: prodDataEqp1, startPolling: s7 } = useQuery(GET_PRODUCTION_CHART, { variables: { id: eqp1 }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  /* const { data: prodDataEqp2, startPolling: s8 } = useQuery(GET_PRODUCTION_CHART, {
    variables: { id: eqp2 }, pollInterval: 55000, fetchPolicy: 'no-cache',
    onCompleted: (prodDataEqp2) => {
      setArrChartAllLines(sumObjectsByKey(...prodDataEqp1?.h_piot_overview_production_chart, ...prodDataEqp2?.h_piot_overview_production_chart));
    
    }
  }); */

  let allEqpsArr12hProduction = arrChartAllLines?.map(e => e.production);

  useEffect(() => {
    s0(55000); s1(55000); s2(55000); s3(55000); s4(55000); s5(55000); s6(55000);
    getChartData1({ variables: { id: eqp1 } });
    let interval = setInterval(() => {
      getChartData1({ variables: { id: eqp1 } });
    }, 55000);

    //recarrega a pagina a cada um dia
    let refreshPage = setInterval(() => {
      window.location.reload();
    }, 86400000);

    return () => {
      clearInterval(interval);
      clearInterval(refreshPage);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F3F5 ',
          flexGrow: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 2,
          gridTemplateRows: 'auto',
          gridTemplateAreas:
            `"infoUp infoUp infoUp infoUp"
            "card1 card2 graph graph"
            "card1 card2 graph graph"
            "tabela tabela tabela tabela "
            "below below below below "`,
        }}>

        <Box sx={{ gridArea: 'infoUp', mt: 1, mr: 1, ml: 1 }}>
          <InfoLines data={unsJob} unsShift={unsShift} />
        </Box>

        <Card sx={{ gridArea: 'card1', ml: 1 }} elevation={0}>
          <Card1
            enterprise={enterprise}
            dataShift={unsShift}
            dataDay={eqpsInfoDay}
          />
        </Card>

        <Card sx={{ gridArea: 'card2', }} elevation={0}>
          <Card2
            enterprise={enterprise}
            dataShift={unsShift}
            dataMaqSpeed={eqpMetrics?.uns_equipment_current_metrics}
            dataDay={eqpsInfoDay}
          />
        </Card>

        <Card sx={{ gridArea: 'graph', mr: 1 }} elevation={0}>
          <Chart
            data={arrChartAllLines}
            dataArr12H={allEqpsArr12hProduction}
            target={objetivos}
          />
        </Card>

        <Box sx={{ gridArea: 'tabela', display: 'flex' }}>
          <TableEvent list={listaEqp1Eventos} line={"L1"} />
          <TableEvent list={listaEqp2Eventos} line={"L2"} />
        </Box>

        <Box sx={{ gridArea: 'below', height: 50, display: 'flex', justifyContent: 'space-between', mb: 5 }}>
          <Box sx={{ mt: -1.7, ml: 2 }}>
            <img src={enterpriseData?.enterprises?.[0]?.logo_url} width='100px'></img>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mr: 5 }}>
            <Logoblack />
            <Typography variant="h2">
              {user_language === 'pt-BR' ? moment().format('HH:mm') : moment().format('hh:mm a')}
            </Typography>
          </Box>
        </Box>

      </Box>
    </>
  )
}
