import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import { getLanguage, roundNumber, userLocale } from '../../../utils/utils';
import { Span, ProgressBar, ProgressItem } from '../styles';
import CardHeader from './CardHeader';


function MainCard({ data }) {
  const language = getLanguage();
  const locale = userLocale();
  let progress = (data?.po_net_production && data?.production_programmed) ?
    data?.po_net_production * 100 / data?.production_programmed : 0;

  let scrap = (data?.curshift_scrap != null || data?.curshift_grosprod != null) ?
    (data?.curshift_grosprod == 0 ? (0).toFixed(0) :
      ((data?.curshift_scrap || 0) * 100 / data?.curshift_grosprod).toFixed(1)) : "";

  let format = 'HH:mm';
  if (locale.isAmPm) format = 'hh:mm a';
  let remaining_time = (data?.job_remaining_time) > 0 ? moment().add(data?.job_remaining_time, 'seconds').format(format) : '-';
  let days_remaining = moment.duration(data?.job_remaining_time, 'seconds').days();

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '330px' },
        height: '219px',
        background: '#FFF',
        boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        mt: 3,
        pl: 2,
        pr: 2,
        pt: 1,
        mr: { xs: 0, sm: '25px' }
      }}
    >
      { /* Header */}
      <CardHeader data={data} />

      { /* Main */}
      <Box
        sx={{
          width: '100%',
          height: '72px',
          background: '#F0F0F0',
          borderRadius: '8px',
          pl: 1,
          pr: 1,
        }}
      >

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Box flexDirection='column' sx={{ display: 'flex', width: '100px' }}>
            <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, mt: '2px', fontWeight: 600, color: '#4D4D4D' }}>{language?.job || "Job"} </Typography>
            <Span>{data?.id_order}</Span>
            <Typography noWrap={true} sx={{ fontSize: { xs: '11px', sm: '13px' }, mt: '-3px', textTransform: 'uppercase', color: '#4D4D4D' }}>{data?.nm_client}</Typography>
          </Box>


          <Box flexDirection='column' sx={{ display: 'flex', width: { xs: '130px', sm: '170px' } }}>
            <Box sx={{ width: { xs: '130px', sm: '170px' }, display: 'flex', mt: '2px', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: { xs: '11px', sm: '13px' } }}>{language?.production || "Production"}</Typography>
              <Typography sx={{ fontSize: { xs: '11px', sm: '13px' } }}> <strong>{roundNumber(data?.po_net_production)}</strong> / {roundNumber(data?.production_programmed)} </Typography>
            </Box>
            <ProgressBar> <ProgressItem width={progress} /> </ProgressBar>
            <Box sx={{ width: '100%', display: 'flex', mt: '6px', justifyContent: 'flex-start' }}>
              <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, fontWeight: 700 }}>
                {language?.projected_end || 'Projected End'}: {data?.job_remaining_time ? remaining_time : '-'}
                {days_remaining > 0 ? <span style={{ color: '#407CCC', fontWeight: 'bold' }} >+{days_remaining}</span> : ''}
              </Typography>
            </Box>
          </Box>

        </Box>

      </Box>

      <Typography sx={{ mt: '10px', color: '#BDBDBD', fontSize: '13px', fontWeight: 700, textTransform: 'uppercase' }}>{language?.current_shift || "Current Shift"}</Typography>

      <Box sx={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'space-between' }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography sx={{ fontSize: { xs: '15', sm: '18px' }, fontWeight: 700, color: '#4D4D4D' }}>{roundNumber(data?.curshift_netprod)}</Typography>
          <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, fontWeight: 400, color: '#4D4D4D' }}>{language?.production || 'Production'} (u)</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography sx={{ fontSize: { xs: '15', sm: '18px' }, fontWeight: 700, color: '#4D4D4D' }}>{scrap}%</Typography>
          <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, fontWeight: 400, color: '#4D4D4D' }}>{language?.scrap || 'Scrap'}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography sx={{ fontSize: { xs: '15', sm: '18px' }, fontWeight: 700, color: '#4D4D4D' }}>{data?.speed || 0}/m</Typography>
          <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, fontWeight: 400, color: '#4D4D4D' }}>{language?.speed || 'Speed'}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography sx={{ fontSize: { xs: '15', sm: '18px' }, fontWeight: 700, color: '#4D4D4D' }}>{(data?.currshift_oee != null) ? (data?.currshift_oee * 100).toFixed(1) : "0"}%</Typography>
          <Typography sx={{ fontSize: { xs: '11px', sm: '13px' }, fontWeight: 400, color: '#4D4D4D' }}>OEE</Typography>
        </Box>

      </Box>

    </Box>
  );
}

export default MainCard;