import React from 'react';
import { ComposedChart, Bar, CartesianGrid, YAxis, XAxis, Tooltip, Cell, Legend, ReferenceLine, Line, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import ScrapPeriodTooltip from './Tooltip';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';

export default function Chart({ data, dateRange, porcentage }) {

  let chartData = data ?? [];

  // average line: sum of net from chartData array
  const average = chartData.reduce((acc, curr) => {
    return acc + (porcentage ? curr.scrap_percentage : curr.scrap);
  }, 0) / chartData.length;

  // console.log(average)
  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

  return (
    <ResponsiveContainer minWidth={1140} height={490}>
      <ComposedChart height={470} margin={{ top: 40, right: 50, left: 16, bottom: 0 }} data={chartData}>
        <CartesianGrid strokeDasharray="1 1" />

        <XAxis dataKey="ts_value_production" tick={<XTick dateRange={dateRange} />} height={60} interval={intervalData} />

        <YAxis tick={porcentage ? <Ytick isPercentage /> : <Ytick />} label={{ position: 'insideLeft' }} axisLine={false} />

        <Tooltip content={<ScrapPeriodTooltip chartData={chartData} dateRange={dateRange} />} />

        <Bar dataKey={porcentage ? 'scrap_percentage' : 'scrap'}
          radius={[5, 5, 0, 0]} isAnimationActive animationDuration={2400} >

          {chartData.map((entry, index) => {
            if (moment(entry.ts_value_production).minutes() !== 0 || moment(entry.ts_value_production).seconds() !== 0) {
              return (
                <Cell key={`cell-${index}`} stroke='#C13939' fill='#ECC3C3'
                  style={{ borderRadius: '10px' }} strokeWidth="3" strokeDasharray="5 3"
                />
              );
            } else {
              return (<Cell key={`cell-${index}`} fill='#C13939' />);
            }
          })
          }
        </Bar>

        {/* porcentage &&
          <Line animationEasing="ease-out" type="linear" strokeWidth="2"
            isAnimationActive animationDuration={2400}
            dot={false} dataKey="scrap_targets" stroke="#27AE60"
          /> */}

        <ReferenceLine y={average} stroke="#828282" isFront strokeWidth={2} strokeDasharray='5 3' />

      </ComposedChart>
    </ResponsiveContainer>
  );
}
