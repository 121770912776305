import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import { Nav, NavSkeleton } from './styles';


const EventsLoading = () => (
  <Grid container>
    <Grid item xs={12} md={4} sx={{ height: {xs: '120px', md:'calc(100vh - 322px)'} }}>
      <Nav>
        <NavSkeleton />
        <NavSkeleton />
        <NavSkeleton />
      </Nav>

    </Grid>


    <Grid item xs={12} md={8} sx={{ maxHeight:'calc(100vh - 322px)', overflowY: 'scroll' }} >

      <div>
        <Timeline sx={{ '.MuiTimelineItem-missingOppositeContent:before': { content: 'none' } }} position='right'>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={100} />
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={200} />
              </Typography>

            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={100} />
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={200} />
              </Typography>

            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={100} />
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                <Skeleton width={200} />
              </Typography>

            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </Grid>

  </Grid>
)

export default EventsLoading;