import { gql } from '@apollo/client';

export const GET_TARGETS = gql`
query getTargets($id_equipment: Int!) {
  production_targets(where: {id_equipment: {_eq: $id_equipment}}) {
    equipment {
      nm_equipment
    }
    id_equipment
    vl_day
    vl_month
    vl_week
    vl_hour
    vl_shift
  }
  scrap_targets(where: {id_equipment: {_eq: $id_equipment}}) {
    vl_day
    id_equipment
  }
}
`;

export const GET_CUSTOM_TARGET_MONTH = gql`
query getCustomTargetMonth($id_equipment: Int, $ts_value: date) {
  equipment_runtime_1month(
    where: {
      id_equipment: { _eq: $id_equipment }
      target: { _is_null: false }
      ts_value: { _gte: $ts_value }
      target_customized: {_eq: true}
    }
    order_by: { ts_value: desc }
  ) {
    target
    ts_value
    equipment {
      nm_equipment
    }
  }
}

`;


export const GET_CUSTOM_TARGET_WEEK = gql`
query getCustomTargetWeek($id_equipment: Int, $ts_value: date) {
  equipment_runtime_1week(
    where: {
      id_equipment: { _eq: $id_equipment }
      target: { _is_null: false }
      ts_value: { _gte: $ts_value }
      target_customized: {_eq: true}
    }
    order_by: { ts_value: desc }
  ) {
    target
    ts_value
    equipment {
      nm_equipment
    }
  }
}

`;

export const GET_CUSTOM_TARGET_DAY = gql`
query getCustomTargetDay($id_equipment: Int, $ts_value: date) {
  equipment_runtime_1day(
    where: {
      id_equipment: { _eq: $id_equipment }
      target: { _is_null: false }
      ts_value: { _gte: $ts_value }
      target_customized: { _eq: true }
    }
    order_by: { ts_value: desc }
  ) {
    target
    ts_value
    equipment {
      nm_equipment
    }
  }
}

`;