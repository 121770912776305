import React, { useState, useEffect, useContext } from 'react';
import { ComposedChart, Bar, CartesianGrid, YAxis, XAxis, Tooltip, Cell, Legend, ReferenceLine, Line, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import ChartLegend from '../../../components/ChartLegend';
import { getLanguage, sortArrayOfObjectsBy } from '../../../utils/utils';
import ShiftOrTeamTooltip from './ShiftOrTeamTooltip';
import { VariablesContext } from '../../../Context/VariablesContext';


function SinglePeriodShiftChart({ data, dateRange, porcentage }) {
  const language = getLanguage();
  let chartData = data ?? [];
  let legendKeys = [];
  let barShifts = [];

  const { typeOfView } = useContext(VariablesContext);

  let shiftOrTeamList = new Array();

  const colors = ['rgba(64, 124, 204, 1)', 'rgba(47, 58, 143, 1)', 'rgba(254, 126, 109, 1)', 'rgba(255, 184, 48, 1)'];
  const inProgressColors = ['rgba(64, 124, 204, 0.5)', 'rgba(47, 58, 143, 0.5)', 'rgba(254, 126, 109, 0.5)', 'rgba(255, 184, 48, 0.5)'];

  if (chartData?.length) {

    let groupBy = '';
    switch (typeOfView) {
      case 'SHIFTS':
        groupBy = `cd_shift`;
        break;
      case 'TEAMS':
        groupBy = `cd_team`;
        break;
    }

    // Creates list of teams/shifts and create legend
    chartData?.map((period) => {
      period?.array_agg?.map((shift) => {
        if (shift?.[groupBy] && shiftOrTeamList?.indexOf(shift?.[groupBy]) < 0) {
          legendKeys.push({ label: `${typeOfView == 'TEAMS' ? (language?.team || 'Team') : (language?.shift || 'Shift') } ${shift?.[groupBy]}`, color: colors[shiftOrTeamList?.length], circle: true });
          shiftOrTeamList.push(shift?.[groupBy]);
        }
      })
    });

    //Known issue: order 
    chartData?.map((ts_value_production, ts_value_production_index) => {
      if (ts_value_production?.array_agg?.length != shiftOrTeamList?.length) {
        const ts_value_production_shifts = ts_value_production?.array_agg?.map((shiftOrTeam) => shiftOrTeam?.[groupBy]);
        shiftOrTeamList?.map((shiftOrTeam) => {
          if (!ts_value_production_shifts?.includes(shiftOrTeam)) {
            let ts_value_production_current_shifts = Array.from(chartData[ts_value_production_index].array_agg);
            if (groupBy == `cd_shift`) {
              ts_value_production_current_shifts?.push({ net: null, scrap: null, target: null, gross: null, cd_shift: shiftOrTeam, id_shift: true, cd_team: null, id_team: null });
            } else {
              ts_value_production_current_shifts?.push({ net: null, scrap: null, target: null, gross: null, cd_shift: null, id_shift: null, cd_team: shiftOrTeam, id_team: true });
            }
            chartData[ts_value_production_index].array_agg = ts_value_production_current_shifts?.sort((a, b) => sortArrayOfObjectsBy(a, b, groupBy));
          }
        }
        )
      }
    });

    barShifts = shiftOrTeamList?.map((item, index) => {
      return (
        <Bar key={item} dataKey={porcentage ? `array_agg[${index}].scrap_percentage` : `array_agg[${index}].scrap`} radius={[5, 5, 0, 0]} isAnimationActive animationDuration={2400} >
          {chartData.map((entry, i) => {
            if (moment(entry.ts_value_production).minutes() !== 0 || moment(entry.ts_value_production).seconds() !== 0) {
              return (
                <Cell key={`cell-${index}`} stroke={colors[index]} fill={inProgressColors[index]}
                  strokeWidth="3" style={{ borderRadius: '10px' }} strokeDasharray="5 3"
                />
              );
            } else {
              return (<Cell key={`cell-${index}`} fill={`${colors[index]}`} />);
            }
          })
          }
        </Bar>
      )
    });
  }


  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

  return (
    <ResponsiveContainer minWidth={1140} height={490}>
      <ComposedChart height={470} margin={{ top: 40, right: 50, left: 16, bottom: 0 }} data={chartData}>
        <CartesianGrid strokeDasharray="1 1" />

        <XAxis dataKey="ts_value_production" tick={<XTick dateRange={dateRange} />} height={60} interval={intervalData} />

        <YAxis tick={ porcentage ? <Ytick isPercentage/> : <Ytick/>} label={{ position: 'insideLeft' }} axisLine={false} />

        <Tooltip content={<ShiftOrTeamTooltip chartData={chartData} dateRange={dateRange} />} />
        {barShifts}

        <Legend content={<ChartLegend />} legendKeys={legendKeys} />

      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SinglePeriodShiftChart;