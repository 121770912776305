import React, { useContext } from 'react';
import { getLanguage, numbertoString } from '../../../utils/utils';
import { Box, Grid, Card, CardContent, Divider, Tooltip, tooltipClasses, } from "@mui/material"
import { Typography, } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { scrapCalc } from '../../../configurations/scrap';
import { VariablesContext } from '../../../Context/VariablesContext';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "none white 0px",
    "& .MuiTooltip-arrow": {
      // top: "-10px !important",
      "&::before": {
        backgroundColor: "white",
      },
    }
  },
}));

export default function AreaCard(area, navigate) {
  const language = getLanguage();
  const numberLine = area?.lines?.length;
  const scrapType = localStorage.getItem('scrap_calc_type');
  const { setLine } = useContext(VariablesContext);

  let line = '';
  if (area?.lines?.length > 1) {
    line = language?.lines || 'lines';
  } else {
    line = language?.line || 'line';
  }

  function defineColor(status) {
    let color = '#bdbdbd';

    if (status === 'stopped') {
      color = '#C13939';
    } else if (status === 'lowSpeed') {
      color = '#ECBC13';
    } else if (status === 'setup') {
      color = '#FD9729';
    } else if (status === 'running') {
      color = '#318F29';
    }
    return color;
  }

  function size(number) {
    let size = 72;

    if (number <= 8) {
      size;
    } else if (number <= 15) {
      size = 56;
    } else if (number <= 24) {
      size = 48;
    } else {
      size = 40;
    }
    return size;
  }

  return (
    <Card sx={{ borderRadius: 1, minHeight: 418 }}>

      <CardContent sx={{ minHeight: 302 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '&:hover': {
            opacity: [0.9, 0.8, 0.7],
            cursor: 'pointer'
          },
        }}
          onClick={() => { navigate('/mission-control') }}
        >
          <Typography variant='h4'> {area.nm_area} </Typography>

          <Typography variant='body2'> {area?.lines?.length} {line} </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          flexShrink: 0,
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          mx: -1,
          mt: 1,
          minHeight: '240px',
        }}>
          {area?.lines?.map((line, index) => {
            return (
              <LightTooltip title={line?.nm_equipment} arrow placement="top" key={index}>
                <Box sx={{
                  backgroundColor: defineColor(line?.status),
                  '&:hover': {
                    opacity: [0.9, 0.8, 0.7],
                    cursor: 'pointer'
                  },
                  height: size(area?.lines?.length),
                  width: size(area?.lines?.length),
                  m: .5
                }}
                  onMouseEnter={() => { setLine(line?.id_equipment) }}
                  onClick={() => { navigate('/total-production') }}
                />
              </LightTooltip>
            )
          })}
        </Box>
      </CardContent>

      <Divider />

      <CardContent sx={{ '&:hover': { opacity: [0.9, 0.8, 0.7], cursor: 'pointer' }, }} onClick={() => { navigate('/mission-control') }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 1, textAlign: 'center' }}>
          <Box>
            <Typography variant='body2'> {language?.production || 'Production'} </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography variant='h4'> {numbertoString(area?.net)} </Typography>
              <Typography variant='body2' style={{ alignSelf: 'center', marginLeft: 3 }}> [u] </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant='body2'> {language?.scrap || 'Scrap'} </Typography>
            <Typography variant='h4'>
              {/* { scrapCalc(scrapType, area?.gross, area?.net) } % */}
              {area?.gross ? scrapCalc(scrapType, area?.gross, area?.net) : 0}%
            </Typography>
          </Box>

          <Box>
            <Typography variant='body2'> OEE </Typography>
            <Typography variant='h4'> {(area?.oee ? area.oee * 100 : 0).toFixed(0)}% </Typography>
          </Box>
        </Box>
      </CardContent>

    </Card>
  );
}
