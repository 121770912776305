import { gql } from '@apollo/client';

export const GET_DWT_REASONS = gql`
query GetDowntimeReasons($id_equipment: Int) {
  equipments(where: {id_equipment: {_eq: $id_equipment}}) {
    downtime_reasons
		nm_equipment
    id_equipment
  }
}

`;
