import React, { useState, useEffect } from 'react';
import { RiDownloadLine } from 'react-icons/ri';
import CsvDownloader from 'react-csv-downloader';
import { dateFormat } from '../../../utils/utils';
import IconButton from '@mui/material/IconButton';

function ExportCsv({ data }) {
    const [csvData, setData] = useState([])
    const year = new Date().getFullYear().toString();
    const month = new Date().getMonth().toString();
    const day = new Date().getDate().toString();
    const hour = new Date().getHours().toString();
    const fileName = day + month + year + "-"+ hour + 'export.csv';

    useEffect(() => {
        setData(data.map((item)=>{
            item.ts_start_formated = dateFormat(item?.ts_start, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
            item.ts_end_formated = dateFormat(item?.ts_end, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
            return item;
        }));
     }, [data]);

    const columns = [
        { id: 'status', displayName: 'Status' },
        { id: 'id_order', displayName: 'ID order' },
        { id: 'nm_client', displayName: 'Client' },
        { id: 'nm_product', displayName: 'Product' },
        { id: 'production_ordered', displayName: 'Order size' },
        { id: 'net_production', displayName: 'Produced' },
        { id: 'production_final', displayName: 'Production Collected' },
        { id: 'scrap', displayName: 'Scrap' },
        { id: 'nm_equipment', displayName: 'Line' },
        { id: 'ts_start_formated', displayName: 'Start' },
        { id: 'ts_end_formated', displayName: 'End' },
        { id: 'total', displayName: 'Duration' },
      ];
  return (
        <CsvDownloader
            separator={","}
            columns={columns}
            datas={csvData}
            filename={fileName}
            meta={true}
        >
            <IconButton>
                <RiDownloadLine />
            </IconButton>
        </CsvDownloader>
  );
}

export default ExportCsv;