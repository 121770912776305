import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, TextField, Button, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { VariablesContext } from '../../../../../Context/VariablesContext';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_CUSTOM_TARGET_DAY, GET_CUSTOM_TARGET_WEEK, GET_CUSTOM_TARGET_MONTH } from '../queries';
import { SET_CUSTOM_TARGET_DAY, SET_CUSTOM_TARGET_WEEK, SET_CUSTOM_TARGET_MONTH } from '../mutations';
import { useSnackbar } from 'notistack';
import { getLanguage, roundNumber } from '../../../../../utils/utils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';

import moment from 'moment';
import CustomTargetTable from './CustomTargetTable';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

function CustomTarget() {
  const { siteOptions, areaOptions, lineOptions, enterprise } = useContext(VariablesContext);
  const [siteSelected, setSiteSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [lineSelected, setLineSelected] = useState(null);
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [period, setPeriod] = useState('DAY');
  const [production, setProduction] = useState(0);
  const [dateValue, setDate] = useState(moment());
  const [minDate, setMinDate] = useState(moment())
  const [open, setOpen] = useState(false);
  const [targetData, setTargetData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const language = getLanguage();

  const [getTargetDay] = useLazyQuery(GET_CUSTOM_TARGET_DAY, { onCompleted: (data) => {
    setTargetData(data?.equipment_runtime_1day);
  }, fetchPolicy: 'no-cache' });

  const [getTargetWeek] = useLazyQuery(GET_CUSTOM_TARGET_WEEK, { onCompleted: (data) => {
    setTargetData(data?.equipment_runtime_1week);
  }, fetchPolicy: 'no-cache' });

  const [getTargetMonth] = useLazyQuery(GET_CUSTOM_TARGET_MONTH, { onCompleted: (data) => {
    setTargetData(data?.equipment_runtime_1month);
  }, fetchPolicy: 'no-cache' });

  const [setTargetDay] = useMutation(SET_CUSTOM_TARGET_DAY, {
    onCompleted: () => {
      getTargetData();
      enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  });

  const [setTargetWeek] = useMutation(SET_CUSTOM_TARGET_WEEK, {
    onCompleted: () => {
      getTargetData();
      enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  });

  const [setTargetMonth] = useMutation(SET_CUSTOM_TARGET_MONTH, {
    onCompleted: () => {
      getTargetData();
      enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  });

  function getTargetData() {
    let currentDate = moment(new Date());
    if (period === 'DAY') {
      getTargetDay({
        variables: {
          id_equipment: lineSelected,
          ts_value: currentDate
        }
      })
    }

    if (period === 'WEEK') {
      getTargetWeek({
        variables: {
          id_equipment: lineSelected,
          ts_value: currentDate
        }
      })
    }

    if (period === 'MONTH') {
      getTargetMonth({
        variables: {
          id_equipment: lineSelected,
          ts_value: currentDate
        }
      })
    }
  }


  useEffect(() => {
    const siteOpt = siteOptions?.map((item) => ({
      value: item?.id_site,
      label: item?.nm_site
    }));
    setSites(siteOpt);

  }, [siteOptions, areaOptions, lineOptions]);

  useEffect(() => {

    if (lineSelected) {
      getTargetData();
    }
    
  },[lineSelected, period]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateTargets = () => {
    getTargetData();
  }


  const resetForm = () => {
    setPeriod('DAY');
    setProduction(0);
    setDate(moment(new Date()));
    if (!buttonDisabled) setButtonDisabled(true);
  };

  const handleSiteChange = (event) => {
    const availableAreas = areaOptions?.filter((item) => item?.id_site === event.target.value);
    if (availableAreas) {
      const areaOpt = availableAreas?.map((item) => ({
        value: item?.id_area,
        label: item?.nm_area
      }));
      setSiteSelected(event?.target.value)
      setAreas(areaOpt);
      setAreaSelected(null);
      setLineSelected(null);
      resetForm();

    }
  };

  const handleAreaChange = (event) => {
    const availableLines = lineOptions?.filter((item) => item?.id_area === event.target.value);
    if (availableLines) {
      const lineOpt = availableLines?.map((item) => ({
        value: item?.id_equipment,
        label: item?.nm_equipment
      }));
      setAreaSelected(event?.target.value);
      setLines(lineOpt);
      setLineSelected(null);
      resetForm();
    }
  };

  const handleLineChange = (event) => {
    setLineSelected(event?.target.value);
    if (buttonDisabled) {
      setButtonDisabled(false);
    }
  };


  const handleProductionChange = (event) => {
    setProduction(Number(event.target.value));
    if (Number(event.target.value) >= 0 && lineSelected !== null) {
      setButtonDisabled(false);
    } else if (!buttonDisabled) {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = () => {

    if (period === 'DAY') {
      setTargetDay({
        variables: {
          target: production,
          ts_value: dateValue,
          id_equipment: lineSelected
        }
      });
    }

    if (period === 'WEEK') {
      setTargetWeek({
        variables: {
          target: production,
          ts_value: dateValue,
          id_equipment: lineSelected
        }
      });
    }

    if (period === 'MONTH') {
      setTargetMonth({
        variables: {
          target: production,
          ts_value: dateValue,
          id_equipment: lineSelected
        }
      });
    }

  }

  function confirmMessage() {
    const line = lineOptions?.filter((item) => item?.id_equipment === lineSelected);
    const lineChanged = line?.[0]?.nm_equipment ?? '';
    let newValue = roundNumber(production);

    let successMsg = language?.confirm_goal_change || "You're about to change the line line_id goal to new_target. Are you sure about this?";
    successMsg = successMsg.replace("line_id", lineChanged?.toString());
    successMsg = successMsg.replace("new_target", newValue?.toString());
    return successMsg;
  }
  
    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
      if (!dateValue) {
        return <PickersDay {...pickersDayProps} />;
      }
  
      const start = moment(dateValue).startOf('week');
      const end = moment(dateValue).endOf('week');
  
      const dayIsBetween = moment(date).isBetween(start, end, null, []);
      const isFirstDay = moment(date).isSame(start, 'day');
      const isLastDay = moment(date).isSame(end, 'day');
  
      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
        />
      );
    };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ color: '#4D4D4D', fontSize: '30px', fontWeight: 'bold', mb: 3 }}>{language?.custom_targets || 'Custom Targets'} </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <FormControl sx={{ width: "30%" }}>
          <InputLabel id="default-target-site-label">Site</InputLabel>
          <Select
            labelId="default-target-site-label"
            id="default-target-site"
            value={siteSelected}
            label="Site"
            onChange={(e) => handleSiteChange(e)}
            sx={{ width: '100%', background: '#fff' }}
          >
            {
              sites?.map((item) => (
                <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <FormControl sx={{ width: "30%" }}>
          <InputLabel id="default-target-area-label">{language?.area || 'Area'}</InputLabel>
          <Select
            labelId="default-target-area-label"
            id="demo-simple-selec2t"
            value={areaSelected}
            label={language?.area || 'Area'}
            onChange={(e) => handleAreaChange(e)}
            sx={{ width: '100%', background: '#fff' }}
          >
            {
              areas?.map((item) => (<MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>))
            }
          </Select>
        </FormControl>

        <FormControl sx={{ width: "30%" }}>
          <InputLabel id="default-target-line-label">{language?.line || 'Line'}</InputLabel>
          <Select
            labelId="default-target-line-label"
            id="demo-simple-sele3ct"
            value={lineSelected}
            label={language?.line || 'Line'}
            onChange={(e) => handleLineChange(e)}
            sx={{ width: '100%', background: '#fff' }}
          >
            {
              lines?.map((item) => (<MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>))
            }
          </Select>
        </FormControl>

      </Box>

      <Box sx={{ mt: '16px' }}>

        <FormControl fullWidth>
          <InputLabel id="default-target-period-label">{language?.period || 'Period'}</InputLabel>
          <Select
            labelId="default-target-period-label"
            id="demo-simple-selects5"
            value={period}
            disabled
            label={language?.period || 'Period'}
            onChange={(e) => { 
              setPeriod(e.target.value);
              if (e.target.value === 'WEEK') {
                let startOfWeek = moment().startOf('week');
                setMinDate(startOfWeek);
                setDate(startOfWeek);
              } else if (e.target.value === 'MONTH') {
                let startOfMonth = moment().startOf('month');
                setMinDate(startOfMonth);
                setDate(startOfMonth);
              } else {
                setMinDate(moment());
                setDate(moment(new Date()));
              }
            }
            }
            sx={{ width: '100%', background: '#fff' }}
          >
            <MenuItem value={'DAY'}>{language?.daily || 'Daily'}</MenuItem>
            {/* <MenuItem value={'WEEK'}>{language?.weekly || 'Weekly'}</MenuItem>
            <MenuItem value={'MONTH'}>{language?.monthly || 'Monthly'}</MenuItem> */}
          </Select>
        </FormControl>
        
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            label={language?.date || 'Date'}
            value={dateValue}
            views={[period === 'MONTH' ? 'month' : 'day']}
            minDate={minDate}
            maxDate={moment().add(1, 'month')}
            onChange={(newValue) => {
              if (period === 'WEEK') {
                let startOfWeek = moment(newValue).startOf('week');
                setDate(startOfWeek);
              } else if (period === 'MONTH') {
                let startOfMonth = moment(newValue).startOf('month');
                setDate(startOfMonth);
              } else {
                setDate(newValue);
              }
            }}
            renderInput={(params) => <TextField sx={{ width: '100%', mt: '16px', background: '#fff' }} {...params} />}
            renderDay={(period === 'WEEK' ) ? renderWeekPickerDay : null}
          />
        </LocalizationProvider>

        <TextField
          sx={{ width: '100%', mt: '16px', background: '#fff' }}
          type="number"
          id="outlined-basic5" label={language?.production || 'Production'} variant="outlined"
          value={production.toString()}
          onChange={(e) => handleProductionChange(e)}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          <Button sx={{ width: '360px', height: '45px', mt: '24px' }}
            disabled={buttonDisabled}
            variant="contained"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => handleClickOpen()}
          >
            {language?.create_goal || 'Create Goal'}
          </Button>

          {lineSelected && <CustomTargetTable data={targetData} id={lineSelected} period={period} update={updateTargets} />}

        </Box>

      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', fontWeight: 400, fontSize: '18px', color: '#4D4D4D' }} id="alert-dialog-description">
            {confirmMessage()}
          </DialogContentText>

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => {
              handleClose();
              handleSubmit();
            }} autoFocus
              variant="contained"
              sx={{ width: '250px', mt: '24px' }}
            >
              {language?.update || 'Update'}
            </Button>
            <Button sx={{ mt: '16px', width: '150px' }} variant='outlined' onClick={handleClose}>{language?.cancel || 'Cancel'}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CustomTarget;