import { gql } from '@apollo/client';


export const TIMELINE = gql`
    query GET_TIMELINE($id_order: Int) {
        h_piot_get_events_timeline_from_po(args: {_id_production_order: $id_order}, limit: 50) {
            cd_category
            cd_machine
            cd_subcategory
            change_over
            duration
            id_enterprise
            id_equipment
            status
            ts_end
            ts_event
            txt_downtime_notes
        }
    }
`;

export const GET_JOB_LIST = gql`query getJobList($nm_equipment: String!) {
  production_orders(where: {status: {_in: [1, 4]}, equipment: {nm_equipment: {_eq: $nm_equipment}}}) {
    id_order
    id_production_order
    product {
      nm_product
    }
    status
    client {
      nm_client
    }
    ts_start
    ts_end
  }
}
`;

export const PRODUCTION_ORDERS_NEW = gql`
query GET_PRODUCTION_ORDERS(
  $ts_end: timestamp
  $ts_start: timestamp
  $id_enterprise: Int
  $areas: String
  $equipments: String
  $shifts: String
  $sites: String
  $teams: String
) {
  h_piot_production_orders_with_runtimes4(
    args: {
      _tsend: $ts_end
      _tsstart: $ts_start
      in_id_enterprise: $id_enterprise
      in_ids_areas: $areas
      in_ids_equipments: $equipments
      in_ids_shifts: $shifts
      in_ids_sites: $sites
      in_ids_teams: $teams
    }
    order_by: { status: asc, ts_start: desc }
  ) {
    gross_production
    id_order
    id_equipment
    id_production_order
    net_production
    nm_client
    nm_equipment
    nm_product
    txt_product
    production_ordered
    production_final
    ts_end
    ts_start
    status
    runtimes
  }
}

`;

export const GET_RUNTIMES = gql`
query GetRuntimes(
  $ts_end: timestamp
  $ts_start: timestamp
  $id_enterprise: Int
  $areas: String
  $equipments: String
  $shifts: String
  $sites: String
  $teams: String
) {
  h_piot_production_orders_runtimes(
    args: {
      _tsend: $ts_end
      _tsstart: $ts_start
      in_id_enterprise: $id_enterprise
      in_ids_areas: $areas
      in_ids_equipments: $equipments
      in_ids_shifts: $shifts
      in_ids_sites: $sites
      in_ids_teams: $teams
    }
    order_by: { status: asc, ts_start: desc }
  ) {
    gross_production
    id_order
    id_production_order
    id_production_order_runtime
    net_production
    nm_client
    nm_equipment
    nm_product
    production_ordered
    status
    ts_end
    ts_start
    id_equipment
  }
}

`;