/**
 * Return scrap porcent %
 * 
 * @author otto
 * @example
 * scrapCalc(type, gross, net)
 * //type come from localStorage
 * //const scrapType = localStorage.getItem('scrap_calc_type');
 * 
 * tipo 1 = (gross-net)/gross; 
 * tipo 2 = (gross-net)/net; 
 * 
 * @returns Int
 */
export function scrapCalc(type, gross, net) {
  // tipo 1 = (gross-net)/gross; 
  // tipo 2 = (gross-net)/net;
  let result = 0

  if (type == 1) {
    result = (((gross - net) / gross) * 100).toFixed(1);
  } else {
    result = (((gross - net) / net) * 100).toFixed(1);
  }
  if (result < 0) return result = 0;
  if (isNaN(result)) return result = 0;
  if (result == "Infinity") return result = 0;
  if (result == "-Infinity") return result = 0;
  if (result == "+Infinity") return result = 0;
  // console.log(result)
  return result
}
