import React, { useContext, useEffect, useState } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { useLazyQuery } from '@apollo/client';
import { getLanguage } from '../../utils/utils';
import { Box, Skeleton, Card } from '@mui/material';
import { Typography, } from '@material-ui/core'
import Breadcrumb from '../../components/Breadcrumb';
import NoData from '../../components/NoData';
import GrainSelector from '../../components/GrainSelector';
import TypeOfViewSelector from '../../components/TypeOfViewSelector';
import Chart from './components/Chart';
import { GET_MACHINE_SPEED_DATA } from './queries';
import ChartShift from './components/ChartShift';
import SelectASite from '../../components/SelectASite';
import InfoDialogMachine from './components/InfoDialog';

export default function MachineSpeed() {
  const language = getLanguage();
  const [isShift, setShift] = useState(false);
  const [isTeam, setTeam] = useState(false);
  const { ts_start, ts_end, enterprise, site, area, line, filterShift, grain, dateRange, filterSite, filterArea, filterLine, filterTeam, typeOfView } = useContext(VariablesContext);

  const [getData, { data, error, loading }] = useLazyQuery(GET_MACHINE_SPEED_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    let shifts = filterShift?.join();
    let teams = filterTeam?.join();
    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];
    let grainSize = grain;
    if (dateRange === 'day' || dateRange === 'today') {
      grainSize = 'HOUR';
    }
    if (dateRange === 'week') {
      grainSize = 'DAY';
    }

    if (shifts) {
      setShift(true);
    } else {
      shifts = '';
      setShift(false);
    }

    if (teams) {
      setTeam(true);
    } else {
      teams = '';
      setTeam(false);
    }

    if (site) {
      if (area) {
        if (line) {
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    } else {
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

    getData({
      variables: {
        group_by_element: typeOfView,
        in_begin_time: ts_start,
        in_end_time: ts_end,
        in_id_areas: `{${in_id_areas.toString()}}`,
        in_id_enterprise: enterprise,
        in_id_equipments: `{${in_id_equipments.toString()}}`,
        in_id_shifts: `${shifts}`,
        in_id_sites: `{${in_id_sites.toString()}}`,
        in_id_teams: `{${teams}}`,
        time_grain: grainSize
      }
    });

  }, [enterprise, site, area, line, ts_start, ts_end, filterShift, filterSite, filterArea, filterLine, grain, filterTeam, typeOfView]);

  // console.log(data?.h_piot_machine_speed)

  return (
    <Box sx={{ pr: 3, pl: 3 }}>
      <Breadcrumb />

      <Box sx={{ m: { xs: 0, sm: 3 }, display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h2'> {language?.machine_speed || 'Machine Speed'} </Typography>
        </Box>
        {site ?
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'center', md: 'space-between' }, mt: { xs: 2, lg: 0 } }}>
            {
              // (dateRange === 'month' || dateRange === 'last month') &&
              <GrainSelector />}
            <Box sx={{ mt: { xs: 3, sm: 0 }, mb: { xs: 1, sm: 0 } }}>
              <TypeOfViewSelector />
            </Box>
            <InfoDialogMachine />
          </Box>
          : <></>
        }

      </Box>

      {
        site ?
          loading
            ? <Skeleton variant='rect' height={600} />
            :
            data?.h_piot_machine_speed?.map((element, index) => {
              return (
                <Card key={index} sx={{ mb: 2, my: 2, mx: 3, display: 'flex', height: '520px', }} elevation={16} >
                  {
                    typeOfView == 'GENERAL'
                      ? <Chart data={element} dateRange={dateRange} />
                      : <ChartShift data={element} dateRange={dateRange} />}
                  {/* ? <Box sx={{ width: '300px', mt: 10 }}>
                        <NoData message={language?.no_data || 'No data'} />
                      </Box> */}
                </Card>
              )
            }
            )
          : <SelectASite />
      }

    </Box >
  );
}