import React, { useState, useContext, useEffect } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Checkbox, Select, Typography, FormControl, MenuItem, InputLabel, OutlinedInput,
} from '@mui/material';


export default function TeamFilter() {
  const { teamOptions, filterTeam, setFilterTeam } = useContext(VariablesContext);
  const language = getLanguage();

  const handleChangee = (event) => {
    const arrayValues = event?.target?.value?.map(x => x);
    setFilterTeam(arrayValues)
  };

  return (
    teamOptions?.length > 0 ?
      <>
        <FormControl sx={{ m: 1, minWidth: 170, }}>
          <InputLabel sx={{ m: filterTeam?.length == 0 ? -1 : 0 }}>
            {filterTeam?.length == 0 ? <FilterAltOutlinedIcon /> : ""}
            {language?.filter_team || 'Filter Team'}
          </InputLabel>
          <Select
            multiple
            value={filterTeam}
            onChange={handleChangee}
            input={
              <OutlinedInput
                sx={{ maxHeight: 40, }}
                label={language?.filter_area || 'Filter Site'} />
            }
            renderValue={
              (selected) => (
                filterTeam?.length >= 1
                  ? filterTeam?.length + " " + `${language.selected || 'Selected'}`
                  : teamOptions.filter(sites => sites.id_team == selected)?.[0]?.cd_team
              )
            }>

            {teamOptions?.length > 0
              ? teamOptions?.map((team, index) => (
                <MenuItem key={team.id_team} value={team.id_team}>
                  <Checkbox sx={{ m: -1, mr: .3 }} checked={filterTeam.includes(team.id_team)} />
                  <Typography variant="body2"> {team.cd_team} </Typography>
                </MenuItem>
              ))
              : language?.no_teams_available || 'No teams available'}

          </Select>
        </FormControl>
      </>
      : <></>
  );
};