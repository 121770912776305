import { gql } from "@apollo/client";

// NEW
export const UNS_EQUIPMENT_CURRENT_JOB = gql`
query UNS_EQUIPMENT_CURRENT_JOB($_eq: Int = 10) {
  uns_equipment_current_job(where: {id_equipment: {_eq: $_eq}}) {
    available_time
    begin_time
    cd_setup
    current_expected_time
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    id_order
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    last_setup_duration
    net_production
    net_production_exec_mode
    nm_client
    nm_product
    nm_product_family
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    production_ordered
    production_programmed
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    setup_begin_time
    setup_end_time
    setup_speed
    setup_target_duration
    speed
    stopped_time
    target
  }
}`;

export const UNS_EQUIPMENT_CURRENT_METRICS = gql`
query uns_equipment_current_metrics($_eq: Int ) {
  uns_equipment_current_metrics(where: {id_equipment: {_eq: $_eq}}) {
    speed
    ideal_speed
  }
}`;

export const EQUIPMENT = gql`
query equipments($_eq: Int) {
  equipments(where: {id_equipment: {_eq: $_eq}}){
    nm_equipment
  }
}`;

export const ENTERPRISE = gql`
query enterprises($eq: Int) {
  enterprises {
    logo_url
    nm_enterprise
  }
}`;

export const EQUIPMENTS_INFOS = gql`
query EQUIPMENTS_INFOS($_eq: Int = 6) {
  equipments(where: {id_parentequipment: {_eq: $_eq}}, order_by: {position: asc}) {
    position
    nm_equipment
    id_equipment
  }
}`;

export const UNS_EQUIPMENT_CURRENT_DAY = gql`
query UNS_EQUIPMENT_CURRENT_DAY($_eq: Int) {
  uns_equipment_current_day(where: {id_equipment: {_eq: $_eq}}) {
    available_time
    begin_time
    elapsed_time
    end_time
    gross_production
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    last_30_days
    net_production
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    speed
    stopped_time
    target
  }
}
`;



// UNS_EQUIPMENT_CURRENT_SHIFT
export const UNS_EQUIPMENT_CURRENT_SHIFT = gql`
query uns_equipment_current_shift($id: Int) {
  uns_equipment_current_shift(where: {id_equipment: {_eq: $id}}) {
    available_time
    begin_time
    duration
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    id_shift
    id_shift_hour
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net_production
    net_production_exec_mode
    next_shift
    next_shift_hour
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    prev1_begin_time
    prev1_duration
    prev1_end_time
    prev1_gross_production
    prev1_id_shift
    prev1_id_shift_hour
    prev1_net_production
    prev1_oee
    prev1_oee_a
    prev1_oee_p
    prev1_oee_q
    prev1_scrap
    prev1_speed
    prev1_target
    prev2_begin_time
    prev2_duration
    prev2_end_time
    prev2_gross_production
    prev2_id_shift
    prev2_id_shift_hour
    prev2_net_production
    prev2_oee
    prev2_oee_a
    prev2_oee_p
    prev2_oee_q
    prev2_scrap
    prev2_speed
    prev2_target
    prev3_begin_time
    prev3_duration
    prev3_end_time
    prev3_gross_production
    prev3_id_shift
    prev3_id_shift_hour
    prev3_net_production
    prev3_oee
    prev3_oee_a
    prev3_oee_p
    prev3_oee_q
    prev3_scrap
    prev3_speed
    prev3_target
    previous_shift
    previous_shift_hour
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    speed
    stopped_time
    target
    equipment {
      position
    }
    }
  }
`;


// OLD
export const OEE_OBJ_MONTH = gql`
query OEE_OBJ_MONTH($_eq: Int) {
  uns_equipment_current_month {
    available_time
    begin_time
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net_production
    net_production_exec_mode
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    speed
    stopped_time
    target
  }
  oee_targets(where: {id_equipment: {_eq: $_eq}}) {
    id_area
    id_enterprise
    vl_day
    vl_month
    vl_shift
    vl_week
  }
}`;

export const AREA_NAME = gql`
query AREA_NAME($_eq: Int) {
  sites(where: {Areas: {Equipments: {id_equipment: {_eq: $_eq}}}}) {
    day_begin
    id_enterprise
    language_tag
    nm_site
    timezone
    week_begin
    week_size
  }
}`;

export const RECORD_12H = gql`
query RECORD_12H($_eq: Int = 10) {
  uns_equipment_current_metrics(where: {id_equipment: {_eq: $_eq}}) {
    downtime_category
    downtime_subcategory
    production_record_shifts
    speed
    status
    status_time
  }
}`;


export const STATUS_EQUIPMENT = gql`
query STATUS_EQUIPMENT($in_ids_equipments: String, $in_id_enterprise: Int, $in_ids_areas: String = "{}", $in_ids_sites: String = "{}") {
  h_piot_get_mission_control_timeline(args: {in_ids_equipments: $in_ids_equipments, in_id_enterprise: $in_id_enterprise, in_ids_areas: $in_ids_areas, in_ids_sites: $in_ids_sites}) {
    id_equipment
    timelinestatus
  }
}`;

export const PROJ_MONTH = gql`
query PROJ_MONTH($_eq: Int) {
  uns_equipment_current_month(where: {equipment: {id_equipment: {_eq: $_eq}}}) {
    available_time
    begin_time
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net_production
    net_production_exec_mode
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    speed
    stopped_time
    target
  }
}`;

export const GET_TARGET = gql`
query GET_TARGET( $_eq1: Int) {
  production_targets(where: {id_equipment: {_eq: $_eq1}}) {
    id_area
    id_enterprise
    id_equipment
    id_site
    vl_day
    vl_month
    vl_week
    vl_shift
    vl_hour
  }
  scrap_targets(where: {id_equipment: {_eq: $_eq1}}) {
    id_area
    id_enterprise
    id_equipment
    id_site
    vl_day
    vl_month
    vl_week
    vl_shift
  }
}`;

export const GET_JOB_INFO = gql`
query GET_JOB_INFO($id: Int) {
    h_piot_overview_i_get_job_info(args: {idequipment: $id}) {
      average_speed
      cd_equipment
      collected_prod
      id_enterprise
      id_equipment
      id_order
      job_production_percentage
      nm_client
      order_size
      production_remaining
      remaining_time
    }
    equipments(where: {id_equipment: {_eq: $id}}){
      nm_equipment
    }
  }  
`;




export const GET_MONTH_PROD = gql`
query GET_MONTH_PRODUCTION($id: Int) {
  uns_equipment_current_month(where: {id_equipment: {_eq: $id}}) {
    available_time
    begin_time
    duration
    elapsed_time
    end_time
    gross_production
    gross_production_exec_mode
    id_shift
    id_shift_hour
    ideal_production
    idle_blocked
    idle_starved
    idle_time
    net_production
    net_production_exec_mode
    next_shift
    next_shift_hour
    oee
    oee_a
    oee_p
    oee_q
    planned_downtime
    prev1_begin_time
    prev1_duration
    prev1_end_time
    prev1_gross_production
    prev1_id_shift
    prev1_id_shift_hour
    prev1_net_production
    prev1_oee
    prev1_oee_a
    prev1_oee_p
    prev1_oee_q
    prev1_scrap
    prev1_speed
    prev1_target
    prev2_begin_time
    prev2_duration
    prev2_end_time
    prev2_gross_production
    prev2_id_shift
    prev2_id_shift_hour
    prev2_net_production
    prev2_oee
    prev2_oee_a
    prev2_oee_p
    prev2_oee_q
    prev2_scrap
    prev2_speed
    prev2_target
    prev3_begin_time
    prev3_duration
    prev3_end_time
    prev3_gross_production
    prev3_id_shift
    prev3_id_shift_hour
    prev3_net_production
    prev3_oee
    prev3_oee_a
    prev3_oee_p
    prev3_oee_q
    prev3_scrap
    prev3_speed
    prev3_target
    previous_shift
    previous_shift_hour
    proportional_ideal_production
    proportional_target
    running_time
    scrap
    scrap_exec_mode
    speed
    stopped_time
    target
    }
  }
`;

export const GET_DAY_PROD = gql`
query GET_DAY_PRODUCTION($id: Int) {
  uns_equipment_current_day(where: {id_equipment: {_eq: $id}}) {
    gross_production
    net_production
    oee
    proportional_target
    scrap
    target
    }
  }
`;

export const GET_PRODUCTION_CHART = gql`
query GET_PRODUCTION_CHART($id:Int) {
    h_piot_overview_production_chart(args: {idequipment: $id}, order_by: {times: asc}) {
      production
      rn
      scrap
      time
      times
    }
  }
`;

export const GET_EVENTS = gql`
query GET_EVENTS($id: Int) {
    h_piot_overview_i_get_events_3(args: {idequipment: $id}) {
      colorcolumn
      duration
      end
      machine
      cd_sector
      notes
      reason
      start
      sub_category
    }
  }
`;