import React, { useContext } from 'react';
import moment from 'moment';
import { LineChart, CartesianGrid, YAxis, XAxis, Tooltip, Line, Legend, ResponsiveContainer } from 'recharts';
import { getLanguage, dateFormat } from '../../../utils/utils';
import XTick from '../../../components/Tick/XTick'
import Ytick from '../../../components/Tick/Ytick'
import { CardContent, Grid, Box, Typography, Card, } from '@material-ui/core'
import CircleIcon from '@mui/icons-material/Circle';
import { VariablesContext } from '../../../Context/VariablesContext';
import useMediaQuery from '@mui/material/useMediaQuery';

const shift_or_team_colours = ['#407CCC', '#FFB830', '#7B84C8', '#FE7E6D'];

const CustomTooltip = ({ label, chartData, shiftOrTeamList }) => {
  const language = getLanguage();
  const { typeOfView } = useContext(VariablesContext);
  let currentDot = chartData?.filter((item) => item?.ts === label);
  currentDot = currentDot?.[0] || {};

  return (
    typeOfView == 'GENERAL' ?
      <>
        <Card >
          <CardContent sx={{ m: -1.5, }}>
            <Typography style={{ textTransform: 'capitalize' }} variant="h4" >
              {dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC' })}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon sx={{ color: '#407CCC', fontSize: 'small', mr: .3 }} />
              <Typography variant="body2" > {language?.total || 'Total'}:  &nbsp; {currentDot?.oee?.toFixed(1)}%</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon sx={{ color: '#F2994A', fontSize: 'small', mr: .3 }} />
              <Typography variant="body2" > {language?.availability || 'Availability'}: &nbsp; {currentDot?.oee_a?.toFixed(1)}%</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon sx={{ color: '#318F29', fontSize: 'small', mr: .3 }} />
              <Typography variant="body2" > {language?.performance || 'Performance'}: &nbsp; {currentDot?.oee_p?.toFixed(1)}% </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon sx={{ color: '#C13939', fontSize: 'small', mr: .3 }} />
              <Typography variant="body2" > {language?.quality || 'Quality'}: &nbsp; {currentDot?.oee_q?.toFixed(1)}% </Typography>
            </Box>
          </CardContent>
        </Card>
      </>
      :
      <>
        <Card >
          <CardContent sx={{ m: -1.5 }}>
            <Typography variant="h4" > {moment(label).utc().format('dddd DD/MM')} </Typography>
            <>
              {Object.keys(currentDot).map((key) => {
                if (key != 'ts' && key != 'null') {
                  return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon sx={{ color: shift_or_team_colours[shiftOrTeamList.indexOf(key)], fontSize: 'small', mr: .3 }} />
                    <Typography variant="body2" > {key}:  &nbsp; {currentDot?.[key]?.toFixed(1)}%</Typography>
                  </Box>
                }
              })}
            </>
          </CardContent>
        </Card>
      </>
  )
};

export default function OeeProgress({ data }) {
  const language = getLanguage();
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });
  const { typeOfView } = useContext(VariablesContext);

  let chartData = data?.oee_progress?.map((item) => (
    {
      ts: item.ts_value_production,
      shift: item.cd_shift,
      team: item.cd_team,
      oee_a: item.oee_a * 100,
      oee_p: item.oee_p * 100,
      oee_q: item.oee_q * 100,
      oee: item.oee * 100
    }));

  // If displaying shifts or teams then agregate the data
  let shiftOrTeamList = [];
  let groupBy = '';
  if (typeOfView != 'GENERAL') {
    switch (typeOfView) {
      case 'SHIFTS':
        groupBy = `shift`;
        break;
      case 'TEAMS':
        groupBy = `team`;
        break;
    }

    chartData = chartData.reduce(
      (previousValue, currentValue) => {
        const i = previousValue.findIndex(findItem => currentValue.ts === findItem.ts);
        if (i > -1) {
          previousValue[i][currentValue?.[groupBy]] = currentValue.oee;
        }
        else {
          previousValue.push(
            {
              ts: currentValue.ts,
              [currentValue?.[groupBy]]: currentValue.oee
            });
        }

        if (currentValue?.[groupBy] && !shiftOrTeamList.includes(currentValue?.[groupBy])) {
          shiftOrTeamList.push(currentValue?.[groupBy]);
        }

        return previousValue;
      },
      []
    );
  }

  let intervalData = 'preserveStart';
  if (chartData?.length < 10) intervalData = 0;
  if (chartData?.length > 10 && chartData?.length <= 33) intervalData = (isMobile ? 5 : 1);

  const chartLines =
    typeOfView == 'GENERAL' ?
      <>
        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_q" stroke="#C13939" />
        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_p" stroke="#318F29" />
        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_a" stroke="#F2994A" />
        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee" stroke="#407CCC " />
      </>
      :
      <>
        {shiftOrTeamList?.map((item, index) => {
          return <Line key={index} type="linear" strokeWidth="2" dot={false} dataKey={item} stroke={shift_or_team_colours[index]} />
        })}
      </>;

  const legend_elements = shiftOrTeamList.reduce(function (result, value, index, array) {
    if (index % 2 === 0)
      result.push(array.slice(index, index + 2));
    return result;
  }, []);

  return (
    <Grid item lg={6} md={12} sm={12} xs={12}>
      <Card elevation={16} height={450} width={500} style={{ borderRadius: 8 }}>
        <Box sx={{ mt: 2, ml: 2, mb: -1 }}>
          <Typography variant="h4" >  {data?.nm_entity} </Typography>
        </Box>

        <CardContent sx={{ width: 500 }}>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              margin={{ top: 10, right: 25, left: -10, bottom: 0 }}
              data={chartData} >
              <CartesianGrid strokeDasharray="3 2" />

              <XAxis dataKey="ts" height={60} tick={<XTick />} interval={intervalData} />
              <YAxis unit='%'
                tick={{ fontSize: '15', fontWeight: 400, fill: '#4D4D4D' }}
                interval={'preserveStartEnd'} axisLine={false}
                label={{ position: 'insideLeft' }} />

              <Tooltip content={<CustomTooltip shiftOrTeamList={shiftOrTeamList} chartData={chartData} />} />

              {chartLines}

            </LineChart>
          </ResponsiveContainer>

          {typeOfView == 'GENERAL' ?
            <Box sx={{ display: { xs: 'grid', sm: 'grid', md: 'flex', lg: 'flex' }, flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', mt: 2, }}>
              <Box sx={{ display: 'flex', mx: { sm: 'auto', md: 0, lg: 0, }, }}>
                <CircleIcon sx={{ color: '#407CCC', fontSize: 'medium', mr: 1, ml: 2 }} />
                <Typography variant="caption" >
                  {language?.oee_total || 'OEE Total'}
                </Typography>

                <CircleIcon sx={{ color: '#F2994A', fontSize: 'medium', mr: 1, ml: 2 }} />
                <Typography variant="caption">
                  {language?.availability || 'Availability'}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', mx: { sm: 'auto', md: 0, lg: 0, } }} >
                <CircleIcon sx={{ color: '#318F29', fontSize: 'medium', mr: 1, ml: 2 }} />
                <Typography variant="caption">
                  {language?.performance || 'Performance'}
                </Typography>

                <CircleIcon sx={{ color: '#C13939', fontSize: 'medium', mr: 1, ml: 2 }} />
                <Typography variant="caption">
                  {language?.quality || 'Quality'}
                </Typography>
              </Box>
            </Box>
            :
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, flexDirection: 'row', justifyContent: 'center', alignContent: 'center', mt: 2, }}>
              {legend_elements?.map((legend_pair, index_legend_pair) => {
                return <>
                  {legend_pair?.map((legend_item, index_legend_item) => {
                    return <>
                      <CircleIcon sx={{ color: shift_or_team_colours[index_legend_pair * 2 + index_legend_item], fontSize: 'medium', mr: 1, ml: 2 }} />
                      <Typography variant="caption" >
                        {legend_item}
                      </Typography>
                    </>
                  })}
                </>
              })
              }
            </Box>}

        </CardContent>
      </Card>
    </Grid>
  );
}
