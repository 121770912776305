import React, { useContext, useEffect, useState } from "react";

import { getLanguage } from '../../utils/utils';
import { useLazyQuery } from '@apollo/client';
import { GET_HOME_DATA } from './Query';
import { VariablesContext } from '../../Context/VariablesContext';

import AreaCard from './components/AreaCard';
import InfoDialog from './components/InfoDialog';
import HomeBreadCrumb from "./components/HomeBreadCrumb";

import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { Box, Grid, Divider, Tab, Tabs, } from "@mui/material"
import { Typography } from '@material-ui/core';
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
    marginTop: -15,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 110,
    width: '100%',
    backgroundColor: '#407CCC',
  },
});

export default function Home() {
  const navigate = useNavigate();
  const language = getLanguage();
  const [goodMorning, setGoodMorning] = useState(
    (moment().hours() > 6) ? (moment().hours() >= 12)
      ? `${language?.good_afternoon || 'Good afternoon'}` :
      `${language?.good_morning || 'Good morning'}` :
      `${language?.good_evening || 'Good evening'}`
  );
  // const [username, setUsername] = useState(localStorage.getItem('piot_username'));
  const { enterprise, site, setSite, setArea } = useContext(VariablesContext);
  const [value, setValue] = useState(site);

  const isTablet = useMediaQuery('(max-width:768px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });

  const [getHomeData, { loading, error, data }] = useLazyQuery(GET_HOME_DATA, {
    onCompleted: (data) => {
      setValue(data?.h_piot_home_uns?.[0]?.sites[0].id_site);
    }
  });

  useEffect(() => {
    if (enterprise) { getHomeData({ variables: { in_id_enterprise: enterprise } }); }
  }, []);

  useEffect(() => {
    if (enterprise) { getHomeData({ variables: { in_id_enterprise: enterprise } }); }
  }, [enterprise]);

  if (site !== value && value !== undefined) { setSite(value); }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid conteiner columns={12} spacing={2}>

      <Grid container spacing={3} sx={{ flexDirection: 'column', }} >
        <Grid item xs={12}>
          {/* Para Computador */}
          <Box sx={{
            alignSelf: "center",
            ml: { xs: 0, sm: 3, md: 4, lg: 7 },
            mt: { xs: 0, sm: 4, md: 2, lg: 3 },
            mb: { xs: 0, sm: -2, md: -1, lg: -1 },
            display: { xs: 'none', sm: 'block' }
          }}>
            <Typography variant="h1"> {goodMorning}! </Typography>
          </Box>

          {/* Para Mobile */}
          <Box sx={{ alignSelf: "center", ml: 3, mt: 3, display: { xs: 'block', sm: 'none', lg: 'none' } }}>
            <Typography variant="h3" > {goodMorning}! </Typography>
          </Box>
        </Grid>

        {/* <Grid item xs={11} md={11} lg={11} sx={{ display: 'grid', alignItems: "center", ml: { xs: 2, lg: 7 }, }} >
            <Box>
              <Typography variant="body2" >
                {language?.select_fields_below_see_detailed || 'Select the fields below to see more detailed information'}
              </Typography>

              <HomeBreadCrumb />
            </Box>
          </Grid> */}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: { xs: 2, sm: 4, md: 3, lg: 7 }, }}>

        {/* Para Computador */}
        <Box sx={{
          display: { xs: 'none', sm: 'block' },
          justifyContent: 'space-between',
          mx: { xs: 0, sm: -.5, md: 1, lg: 0 },
          mt: { xs: 0, sm: 1, md: 0, lg: 0 },
          mb: { xs: 0, sm: 3, md: 2, lg: 2 }
        }}>
          <Typography variant="body1" style={{ color: '#828282' }} > {language?.today_since_shift_1 || 'This is the status of your operation.'} </Typography>
        </Box>

        {/* Para mobile */}
        <Box sx={{
          display: { xs: 'flex', sm: 'none' },
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          mt: .3,
          ml: 1
        }}>
          <Typography variant="subtitle2"> {language?.today_since_shift_1 || 'This is the status of your operation.'} </Typography>
          <InfoDialog />
        </Box>

      </Box>

      <Grid item xs={6} sm={10} lg={10} xl={10} >
        <Box sx={{
          display: { xs: 'block', sm: 'flex' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
        }}>
          <StyledTabs value={value} onChange={handleChange} sx={{ ml: { xs: -4, sm: -4, md: -4, lg: 0 }, }}>
            {data?.h_piot_home_uns?.[0]?.sites?.map((site, index) => {
              return (
                <Tab
                  label={<Typography variant="h4" style={{ color: '#4D4D4D' }} > {site?.nm_site} </Typography>}
                  value={site?.id_site} key={index}
                  sx={{ mt: -1 }}
                />
              )
            })}
          </StyledTabs>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 4 }}> <InfoDialog /> </Box>
        </Box>

        <Box sx={{ ml: { sm: 0, md: 0, lg: 4 } }}>
          {data?.h_piot_home_uns?.[0]?.sites?.filter((site) => (value == site?.id_site))
            .map((site, index) => {
              return (
                <TabPanel key={index} value={site?.id_site} index={site?.id_site} sx={{ ml: 10 }} >

                  <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} sx={{ display: 'flex', }}>
                    {site?.areas?.map((area, index) => {
                      return (
                        <Grid item sx={{ width: 360, minHeight: '418px', mr: { sm: 0, md: 0, lg: 2 }, }}
                          onMouseEnter={() => { setArea(area?.id_area) }}
                        >
                          {AreaCard(area, navigate)}
                        </Grid>
                      )
                    })
                    }
                  </Grid>

                </TabPanel>
              )
            })
          }
        </Box>
      </Grid>

    </Grid >
  );
}
