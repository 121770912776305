import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 410px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15);
  display: flex;
`;

export const ContainerError = styled.div`
  width: 100%;
  height: 410px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlowItem = styled.div`
  width: 238px;
  height: 390px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
`;

export const FlowIssue = styled.div`
  width: 40px;
  height: 350px;
  margin-top: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #C12727;
    position: absolute;
    left: 19px;
    top: 0;
  }
`;

export const FlowHeader = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;

  p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -1px;
  }

  span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #333333;
    opacity: 0.8;
  }
`

export const FlowContent = styled.div`
  width: 100%;
  height: 194px;
`;

export const FlowFooter = styled.div`
  width: 100%;

  div {
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
  }
  p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #333333;
  }
  span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ChartDivider = styled.div`
  width: 1px;
  height: 350px;
  margin-top: 16px;
  background: #828282;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
`;

export const ScrapTooltip = styled.div`
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 130px;
  left: 16px;

  p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
    color: #C12727;
  }
`;