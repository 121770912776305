import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, IconButton, Dialog, DialogContent } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@apollo/client';
import { getLanguage } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';
import { MissionControlInitialQuery } from './queries';

// components
import AreaCard from './components/AreaCard';
import MainCard from './components/MainCard';
import MainCardLarge from './components/MainCardLarge';
import AreaCardExpanded from './components/AreaCardExpanded';
//import MissionControlSkeleton from './components/LoadingSkeleton';
import AreaCardSkeleton from './components/AreaCardSkeleton';
import InfoDialog from './components/InfoDialog';

import Breadcrumb from '../../components/Breadcrumb';
import CardSkeleton from './components/CardSkeleton';

function MissionControl() {
  const isLargeDevice = useMediaQuery('(max-width:1200px)');
  const { enterprise, site, area, line, filterArea, filterLine } = useContext(VariablesContext);
  const language = getLanguage();
  const [equipmentsArray, setEquipmentsArray] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedArea, setExpandedArea] = useState(null);

  const { loading, error, data: linesDataInitial } = useQuery(MissionControlInitialQuery, { variables: { in_id_enterprise: enterprise, in_ids_sites: `{${site ? site : ""}}`, in_ids_equipments: `{${line ? line : filterLine.toString()}}`, in_ids_areas: `{${area ? area : filterArea.toString()}}` }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (expanded) {
      setExpanded(false);
      setExpandedArea(null);
    }
  }, [site, area, line]);

  useEffect(() => {
    if (linesDataInitial) {
      if (linesDataInitial?.h_piot_get_mission_control_uns_3) {
        if (expandedArea) {
          setEquipmentsArray(linesDataInitial?.h_piot_get_mission_control_uns_3?.filter((item) => item.id_area === expandedArea));
        } else {
          setEquipmentsArray(linesDataInitial?.h_piot_get_mission_control_uns_3);
        }
      }
      if (linesDataInitial?.h_piot_get_mission_control_area_uns_2) {
        setAreasData(linesDataInitial?.h_piot_get_mission_control_area_uns_2);
      }
    }
  }, [linesDataInitial]);

  const handleOpenCard = (id) => {
    setExpandedArea(id)
    setExpanded(true);
    setEquipmentsArray(linesDataInitial?.h_piot_get_mission_control_uns_3?.filter((item) => item.id_area === id));
  }

  const handleCloseCard = () => {
    setExpanded(false);
    setExpandedArea(null)
    setEquipmentsArray(linesDataInitial?.h_piot_get_mission_control_uns_3);
  }

  return (
    <Box sx={{ m: 2 }}>
      <Breadcrumb />
      <Grid container sx={{ overflow: 'hidden' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', ml: 3, mt: 1 }}>
          <Typography sx={{ fontSize: { xs: '32px', md: '40px' }, fontWeight: 700, color: '#4D4D4D' }}> {language?.mission_control || 'Mission Control'} </Typography>
          <InfoDialog />
        </Grid>

        <Grid item xs={12} lg={3} xl={2}>
          <Box sx={{
            width: { xs: '100vw', lg: '100%' },
            height: { lg: '100vh' },
            display: 'flex',
            overflowX: { xs: 'auto', lg: 'hidden' },
            overflowY: { xs: 'hidden', lg: 'auto' },
            scrollBehavior: 'smooth',
            flexDirection: { xs: 'row', lg: 'column' },
            borderTop: { xs: '1px solid #E5E5E5', lg: 'none' },
            borderBottom: { xs: '1px solid #E5E5E5', lg: 'none' },
            mb: '10px',
          }}>

            {
              loading ? (
                <>
                  <AreaCardSkeleton />
                  <AreaCardSkeleton />
                </>
              ) :
                (expanded) ? <AreaCardExpanded data={areasData} id={expandedArea} handleClose={handleCloseCard} /> :
                  areasData.map((area, index_area) => <AreaCard data={area} key={index_area} handleOpen={handleOpenCard} />)

            }

          </Box>
        </Grid>

        <Grid item xs={12} lg={9} xl={10} sx={{ p: { xs: 2, lg: 0 }, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap' }}>

          {loading ?
            <Box sx={{ height: '100vh', width: '100%', overflow: 'hidden', display: 'flex', flexWrap: 'wrap' }}>
              <CardSkeleton />
              <CardSkeleton />
            </Box>
            : isLargeDevice ? equipmentsArray.map((line, index_line) => <MainCard data={line} key={index_line} />) :
              <Box sx={{ height: '100vh', width: '100%', overflowX: 'hidden', display: 'flex', flexWrap: 'wrap' }}>
                {
                  equipmentsArray.map((line, index_line) => <MainCardLarge data={line} key={index_line} />)
                }
              </Box>
          }
        </Grid>
      </Grid>
    </Box>
  );
}

export default MissionControl;