import React, { useContext, useEffect, useState } from 'react';
import InfoUpWithOP from './components/InfoUpWithOP';
import InfoUpWithoutOP from './components/InfoUpWithoutOP';
import InfoUpSetUp from './components/InfoUpSetUp';
import Card1 from './components/Card1';
import Card2 from './components/Card2';
import Chart from './components/Chart';
import ChartMontebello from './components/ChartMontbello';
import TableEvent from './components/Table';
import { ReactComponent as Logoblack } from '../../assets/logoblack.svg';

import { VariablesContext } from '../../Context/VariablesContext';
import { Box, Card, } from '@mui/material';
import { Typography, } from '@material-ui/core';

import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  UNS_EQUIPMENT_CURRENT_JOB, EQUIPMENT, UNS_EQUIPMENT_CURRENT_SHIFT, UNS_EQUIPMENT_CURRENT_DAY,
  GET_PRODUCTION_CHART, GET_EVENTS, GET_TARGET, UNS_EQUIPMENT_CURRENT_METRICS, ENTERPRISE,
} from './queries';

export default function Overview() {
  let { lineId } = useParams();
  const { enterprise, site, area, line } = useContext(VariablesContext);
  const [lista, setLista] = useState();

  //recarrega a pagina a cada um dia
  setTimeout(() => {
    window.location.reload()
  }, 86400000);

  let user_language = localStorage.getItem('user_language');
  let enterpriseName = localStorage.getItem("enterprise");

  // index
  let { data: enterpriseData } = useQuery(ENTERPRISE, { variables: { eq: enterprise }, pollInterval: 55000 });

  //BARRA DE CIMA
  const { data: unsJob, startPolling: s0 } = useQuery(UNS_EQUIPMENT_CURRENT_JOB, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: equipment, startPolling: s1 } = useQuery(EQUIPMENT, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  //CARD 1
  const { data: unsShift, startPolling: s2 } = useQuery(UNS_EQUIPMENT_CURRENT_SHIFT, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache', });
  const { data: objetivos, startPolling: s3 } = useQuery(GET_TARGET, { variables: { _eq1: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  const { data: eqpsInfoDay, startPolling: s4 } = useQuery(UNS_EQUIPMENT_CURRENT_DAY, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: eqpMetrics, startPolling: s5 } = useQuery(UNS_EQUIPMENT_CURRENT_METRICS, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache', });

  //TABELA
  const { data: eventsData, startPolling: s6 } = useQuery(GET_EVENTS, {
    variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache',
    onCompleted: (eventsData) => {
      setLista();
      setLista(eventsData);
    }
  });

  //GRAFICO
  const { data: prodData, startPolling: s7 } = useQuery(GET_PRODUCTION_CHART, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  let arr12H = prodData?.h_piot_overview_production_chart?.map(e => e.production);

  useEffect(() => {
    s0(55000); s1(55000); s2(55000); s3(55000); s4(55000); s5(55000); s6(55000); s7(55000);
  }, [])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F3F5 ',
          flexGrow: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 2,
          gridTemplateRows: 'auto',
          gridTemplateAreas:
            `"infoUp infoUp infoUp infoUp"
            "card1 card2 graph graph"
            "card1 card2 graph graph"
            "tabela tabela tabela tabela "
            "below below below below "`,
        }}>

        <Box sx={{ gridArea: 'infoUp', mt: 1, mr: 1, ml: 1 }}>
          {
            unsJob?.uns_equipment_current_job?.[0]?.id_order != null ? (
              unsJob?.uns_equipment_current_job?.[0]?.setup_begin_time != null && unsJob?.uns_equipment_current_job?.[0]?.setup_end_time == null
                ? <InfoUpSetUp job={unsJob} equipment={equipment} />
                : <InfoUpWithOP enterprise={enterprise} data={unsJob} equipment={equipment} />
            ) :
              <InfoUpWithoutOP equipment={equipment} />
          }
        </Box>

        <Card sx={{ gridArea: 'card1', ml: 1 }} elevation={0}>
          <Card1
            enterprise={enterprise}
            dataShift={unsShift} />
        </Card>

        <Card sx={{ gridArea: 'card2', }} elevation={0}>
          <Card2
            enterprise={enterprise}
            equipmentName={equipment?.equipments?.[0]?.nm_equipment}
            dataShift={unsShift}
            dataMaqSpeed={eqpMetrics?.uns_equipment_current_metrics?.[0]}
            dataDay={eqpsInfoDay}
          />
        </Card>

        <Card sx={{ gridArea: 'graph', mr: 1 }} elevation={0}>
          {
            (enterprise == 6 || enterprise == 1 || enterprise == 37)
              ? <ChartMontebello
                data={prodData?.h_piot_overview_production_chart}
                dataArr12H={arr12H}
                target={objetivos} />
              : <Chart
                data={prodData?.h_piot_overview_production_chart}
                dataArr12H={arr12H}
                target={objetivos} />
          }
        </Card>

        <Box sx={{ gridArea: 'tabela', }}>
          <TableEvent
            // data={eventsData?.h_piot_overview_i_get_events_3} 
            list={lista}
          />
        </Box>

        <Box sx={{ gridArea: 'below', height: 50, display: 'flex', justifyContent: 'space-between', mb: 5 }}>
          <Box sx={{ mt: -.8, ml: 2 }}>
            <img src={enterpriseData?.enterprises?.[0]?.logo_url} width='150px'></img>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Logoblack />
            <Typography variant="h2" sx={{ ml: 10, mr: 3, mt: -1.5 }} >
              {user_language === 'pt-BR' ? moment().format('HH:mm') : moment().format('hh:mm a')}
            </Typography>
          </Box>
        </Box>

      </Box>
    </>
  )
}


