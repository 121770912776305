import React from 'react';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Card, Box, Typography as Typography2, } from '@mui/material';
import { Typography, } from '@material-ui/core';
import moment, { now } from 'moment';

export default function InfoUpSetUp({ data, equipment, job, }) {
  const language = getLanguage();
  const dataJob = job?.uns_equipment_current_job?.[0]

  let beginT = moment(dataJob?.setup_begin_time, "YYYYMMDD HH:mm:ss")

  const colorStatus = (tempoFalta) => {
    let color = "#407CCC";
    data?.uns_equipment_current_job?.[0]?.current_expected_time <= 7200 ? color = "#EB5757" : color = "#407CCC";
    return color;
  }
  const timeForm = (num) => {
    const dia = num / 86400;
    const restoDia = num % 86400;

    const hora = restoDia / 3600;
    const restoHora = restoDia % 3600;

    const minutos = restoHora / 60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "")
    )
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, }}>

      <Card sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: 190, width: '15%' }} elevation={0}>
        <Typography variant="h1" >
          {equipment?.equipments?.[0]?.nm_equipment}
        </Typography>
        <Typography variant="h3" style={{ color: '#407CCC', margin: 'auto' }} >
          {language?.last_setup || 'last_setup'}
        </Typography>
        <Typography variant="h3" style={{ margin: "auto" }}>
          {/* {timeForm(30000)} */}
        </Typography>
      </Card>

      <Card sx={{
        display: 'flex', justifyContent: 'space-around', gap: 2, width: '85%', height: 190,
        borderStyle: 'solid',
        borderColor: '#407CCC',
        borderWidth: '10px',
        // border: '5px solid red',
      }} elevation={0}>

        <Box sx={{ ml: 2, display: 'grid', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: '#407CCC' }}>
          <Typography variant="h1" sx={{ mt: 1 }} >
            {language?.changeover || 'CHANGEOVER'}
          </Typography>
          <Typography variant="h3" style={{ margin: "auto" }}>
            {/* {
              (language?.setup || 'SETUP')
              + " "
              + (data?.uns_equipment_current_job?.[0]?.cd_setup && data?.uns_equipment_current_job?.[0]?.cd_setup)
            } */}
            {/* #test */}
          </Typography>
        </Box>


        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, m: 1, }}>
          <Box sx={{ mx: 8, }}>
            <Typography variant="h3" style={{ color: '#407CCC', textAlign: 'center', }}  >
              {language?.next_job || 'Next Job'}
            </Typography>
            <Typography variant="h1"  >
              {dataJob?.id_order}
            </Typography>
          </Box>
          <Box sx={{ mx: 8 }}>
            <Typography variant="h3" style={{ color: '#407CCC', textAlign: 'center' }} >
              {language?.timer || 'Timer'}
            </Typography>
            <Typography variant="h1" >
              {/* 03:30:00 */}
              {/* {moment(dataJob?.setup_begin_time).fromNow()} */}
              {/* {moment(dataJob?.setup_begin_time, 'ss').fromNow()} */}

              {timeForm(moment().diff(moment(dataJob?.setup_begin_time), "seconds"))}
            </Typography>
          </Box>
          {/* <Box sx={{ mx: 8 }}>
            <Typography variant="h3" style={{ color: '#407CCC', textAlign: 'center' }} >
              {language?.goal || 'Goal'}
            </Typography>
            <Typography variant="h1"> */}
          {/* 03:30:00 */}
          {/* {dataJob?.setup_target_duration ?? "-"}
            </Typography>
          </Box> */}
        </Box>

      </Card>

    </Box>
  );
}
