import * as React from 'react';
import { Dialog, IconButton, Box } from '@mui/material';
import { ReactComponent as FullPT } from '../../../assets/Home/InfoHomeFullPT.svg';
import { ReactComponent as MobPT } from '../../../assets/Home/InfoHomeMobPT.svg';

import { ReactComponent as FullEN } from '../../../assets/Home/InfoHomeFullEN.svg';
import { ReactComponent as MobEN } from '../../../assets/Home/InfoHomeMobEN.svg';

import { ReactComponent as FullPO } from '../../../assets/Home/InfoHomeFullPO.svg';
import { ReactComponent as MobPO } from '../../../assets/Home/InfoHomeMobPO.svg';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function InfoDialog() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:425px)', { noSsr: true });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let language = window.localStorage.getItem('user_language');

  return (
    <Box>
      <IconButton onClick={handleClickOpen} >
        <InfoOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <IconButton onClick={handleClose} disableRipple={true} sx={{ justifyContent: 'flex-end' }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ textAlign: 'center', m: 0 }} >

          {
            language === 'pt-BR'
              ? (isMobile ? <MobPT width="310" height="430" alt='LegendaPT' /> : <FullPT width="550" height="430" alt='LegendaPT' />)
              : language === 'en-US'
                ? (isMobile ? <MobEN width="310" height="370" alt='LegendaIng' /> : <FullEN width="600" height="630" alt='LegendaIng' />)
                : (isMobile ? <MobPO width="310" height="370" alt='LegendaPL' /> : <FullPO width="600" height="630" alt='LegendaPL' />)
          }

          {/* // ? (isMobile ? <MobEN width="310" height="370" alt='LegendaIng' /> : <FullEN alt='LegendaIng' />) */}
          {/* // : (isMobile ? <MobPT width="310" height="430" alt='LegendaPT' /> : <FullPT width="550" height="430" alt='LegendaPT' />) */}

        </Box>
      </Dialog>
    </Box>
  );
}
