import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  List, ListItemButton, ListItemIcon, ListItemText, Collapse, ListItem, Box, Typography,
} from '@mui/material';
import { getLanguage } from '../../../utils/utils';

export default function ListaSideBar({ menuData, handleChangeSideBar, isSideBarOpen }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const language = getLanguage();
  let location = useLocation();

  // When the sidebar is closed close all submenus
  useEffect(() => {
    if (!isSideBarOpen) {
      setOpen(false);
    }
  }, [isSideBarOpen]);

  const menuGroupProperties = {
    1: {
      label: language?.["Live"] || "Live",
      icon: "ri-broadcast-line"
    },
    2: {
      label: language?.["operations"] || "Operations",
      icon: "ri-dashboard-3-line"
    },
    3: {
      label: language?.["Overview"] || "Overview",
      icon: "ri-dashboard-line"
    },
    4: {
      label: language?.["reports"] || "Reports",
      icon: "ri-file-copy-2-line"
    }
  }

  //When a submenu is clicked, open the sidebar
  useEffect(() => {
    if (open && !isSideBarOpen) {
      handleChangeSideBar(true);
    }
  }, [open]);
  
  const handleClick = () => {
    setOpen(!open);
  };

  let a = menuData?.menu_items?.map((item, index) => (item?.URL))

  return (
    <>
      <List sx={{ my: -1.8 }}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon
            sx={{ zoom: 2, mr: -3, color: a.includes(location.pathname) ? '#407CCC' : "" }}>
            <i className={menuGroupProperties[menuData?.menu_group?.toString()]?.icon} />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: -2, color: a.includes(location.pathname) ? '#407CCC' : "" }}
            primary={
              <Typography variant="subtitle2" component="div" sx={{ fontWeight: 700, fontSize: 18, }} >
                {menuGroupProperties[menuData?.menu_group?.toString()]?.label}
              </Typography>
            } />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {menuData?.menu_items?.map((item, index) => (
          item?.hidden !== true ?
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link key={index} style={{ textDecoration: 'none',  color:'inherit' }}
                  to={ item?.dataset ? item?.URL + "/" + item?.dataset + "/" + item?.reportId : item?.URL } 
                  onClick={() => {
                    setOpen(false);
                    handleChangeSideBar(false);
                  }} >
                  <ListItem key={index} button sx={{ pl: 9, color: location.pathname == item?.URL ? '#407CCC' : "" }} >
                    <Typography variant="subtitle2" sx={{ fontFamily: "Source Sans Pro", fontWeight: 400, fontSize: 18, zIndex: 1 }}>
                      {language?.[item?.name?.toLowerCase()] || item?.name}
                    </Typography>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            : <></>
        ))}
      </List>
    </>
  );
}