import React from 'react';
import { Box, Skeleton } from '@mui/material';


function SkeletonTable() {
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
        <Skeleton variant='text' width='10%' height='40px' />
      </Box>



    </>
  );
}

export default SkeletonTable;