import React from 'react';
import { Typography, Box } from '@mui/material';
import { Typography as Typography2 } from '@material-ui/core';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export default function Card2({ dataShift, dataDay, dataMaqSpeed, enterprise }) {
  const language = getLanguage();

  let grossEqp1 = dataShift?.uns_equipment_current_shift?.[0]?.gross_production
  let grossEqp2 = dataShift?.uns_equipment_current_shift?.[1]?.gross_production
  let allGross = grossEqp1 + grossEqp2
  // ----------
  let maqSpeed1 = dataMaqSpeed?.find(e => e.id_equipment == 404)?.speed
  let maqSpeed2 = dataMaqSpeed?.find(e => e.id_equipment == 411)?.speed
  // ----------
  let netDayEqp1 = dataDay?.uns_equipment_current_day?.[0]?.net_production
  let netDayEqp2 = dataDay?.uns_equipment_current_day?.[1]?.net_production
  let allNetDay = netDayEqp1 + netDayEqp2

  const howGood = (number, target) => {
    const quanto = (number * 100) / target;

    if (quanto == 99 || quanto == 101 || target === null || target === 0) {
      return <></>
    } else if (quanto > 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowUpwardRoundedIcon fontSize='large' sx={{ color: '#318F29', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#318F29', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"+" + (quanto - 100).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    } else if (quanto < 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowDownwardRoundedIcon fontSize='large' sx={{ color: '#EB5757', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#EB5757', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"-" + (100 - quanto).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, m: 1, height: 430, width: 430 }} >

      <Box >
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, }} >
          {language?.gross_shift_prod || 'Gross SHIFT Production'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {enterprise != 37 ? roundNumber(allGross) : allGross}
          </Typography>

          {/* { howGood(dataShift?.uns_equipment_current_shift?.[0]?.gross_production, dataShift?.uns_equipment_current_shift?.[0]?.target) } */}

        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography2 variant="h4"> Velocidade </Typography2>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: -1 }}>

          <Box sx={{ display: 'flex', }}>
            <Typography2>{dataMaqSpeed?.find(e => e.id_equipment == 404)?.nm_equipment} </Typography2>
            <Typography2 variant="h1" > {maqSpeed1?.toFixed(0) ?? " - "} </Typography2>
            <Typography2 variant="h3" style={{ marginTop: 40, marginLeft: 5 }} >
              {enterprise != 37 ? "un/min" : "m/min"}
            </Typography2>
          </Box>

          <Box sx={{ display: 'flex', }}>
            <Typography2>{dataMaqSpeed?.find(e => e.id_equipment == 411)?.nm_equipment} </Typography2>
            <Typography2 variant="h1"> {maqSpeed2?.toFixed(0) ?? " - "} </Typography2>
            <Typography2 variant="h3" style={{ marginTop: 40, marginLeft: 5 }} >
              {enterprise != 37 ? "un/min" : "m/min"}
            </Typography2>
          </Box>

        </Box>
        {/* {howGood(dataMaqSpeed?.speed, dataMaqSpeed?.ideal_speed)} */}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, mt: 1 }} >
          {language?.daily_prod || 'Daily Production'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {enterprise != 37 ? roundNumber(allNetDay) : allNetDay}
          </Typography>

          {howGood(allNetDay, (dataDay?.uns_equipment_current_day?.[0]?.proportional_target + dataDay?.uns_equipment_current_day?.[1]?.proportional_target))}

        </Box>
      </Box>

    </Box >
  );
}
