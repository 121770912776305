import React, { useContext, useState } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';
import { Button, ButtonGroup } from '@mui/material';
import moment from 'moment';

function GrainSelector() {
  const language = getLanguage();
  const { grain, setGrain, ts_start, dateRange, ts_end } = useContext(VariablesContext);
  let range = moment(ts_end).diff(ts_start, 'days');

  return (
    <>
      <ButtonGroup variant="outlined" sx={{ height: 36, mr: { sm: 3 } }}>

      { (dateRange != 'day' && dateRange != 'today' && dateRange != 'week' && range > 7) &&
        <Button
          onClick={() => setGrain('DAY')}
          sx={{
            backgroundColor: grain == 'DAY' ? '#407CCC' : '',
            color: grain == 'DAY' ? 'white' : '',
            borderRadius: 5,
            width: { xs: 125, sm: 90 },
          }}>
          {language?.daily || 'Daily'}
        </Button>

      } 

      {
        (range > 7) &&
        <Button
          onClick={() => setGrain('WEEK')}
          sx={{
            backgroundColor: grain == 'WEEK' ? '#407CCC' : '',
            color: grain == 'WEEK' ? 'white' : '',
            borderRadius: 5,
            width: { xs: 125, sm: 90 },
          }}>
          {language?.weekly || 'Weekly'}
        </Button>
      }

      {
        (range > 31 ) &&
        <Button
          onClick={() => setGrain('MONTH')}
          sx={{
            backgroundColor: grain == 'MONTH' ? '#407CCC' : '',
            color: grain == 'MONTH' ? 'white' : '',
            borderRadius: 5,
            width: { xs: 125, sm: 90 },
          }}>
          {language?.monthly || 'Monthly'}
        </Button>
      }

      </ButtonGroup>
    </>
  );
}

export default GrainSelector;