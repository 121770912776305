import moment from 'moment-timezone';
import React, { useContext } from 'react';
import Spacer from '../../../components/Spacer';
import { roundNumber, isInvalidNumber, getLanguage, userLocale, dateFormat } from '../../../utils/utils';
import { CardContent, Card, } from '@material-ui/core'
import { VariablesContext } from '../../../Context/VariablesContext';

const CustomTooltip = ({ active, payload, label, chartData, isShift, dateRange }) => {
  const language = getLanguage();
  const locale = userLocale();

  const { typeOfView, timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  let currentDot = chartData?.filter((item) => item?.ts === label);
  if (!active) return null;
  let incomplete = false;

  currentDot = currentDot?.[0] || {};
  if (currentDot?.net_production === null || currentDot?.net_production === 0) return null;

  function validateNumber(number) {
    let validNumber = number
    if (isInvalidNumber(number)) validNumber = 0;
    if (number > 100) validNumber = 100;

    return validNumber;
  }

  let togoal = (currentDot?.net_production) / currentDot?.target * 100;
  let toGoalPeriod = (currentDot?.period_production) / currentDot?.target_period * 100;
  let scrapPercent = currentDot?.scrap_acc * 100 / currentDot.net_production;
  let toGoalColor = 'value-default';
  let toGoalPeriodColor = 'value-default';

  let toGoalPeriodFormat = '';
  let toGoalFormat = '';

  if (togoal >= 100) {
    toGoalColor = 'value-success';
    toGoalFormat = (togoal === NaN || togoal === Infinity) ? '' : togoal.toFixed() + '%' + ' ' + language?.of_goal || 'of goal';
  } else {
    toGoalColor = 'value-danger';
    togoal = Math.abs(togoal);
    toGoalFormat = (togoal === NaN || togoal === Infinity) ? '' : togoal.toFixed() + '%' + ' ' + language?.of_goal || 'of goal';
  }
  if (toGoalPeriod >= 100) {
    toGoalPeriodColor = 'value-success';
    toGoalPeriodFormat = (toGoalPeriod === NaN || toGoalPeriod === Infinity) ? '' : toGoalPeriod.toFixed()  + '%' + ' ' + language?.of_goal || 'of goal';
  } else {
    toGoalPeriodColor = 'value-danger';
    toGoalPeriod = Math.abs(toGoalPeriod);
    toGoalPeriodFormat = (toGoalPeriod === NaN || toGoalPeriod === Infinity) ? '' : toGoalPeriod.toFixed()  + '%' + ' ' + language?.of_goal || 'of goal';
  }

  let displayDate = '';

  if (dateRange === 'day' || dateRange === 'today') {
    displayDate = dateFormat(label, {hour: "2-digit", timeZone: 'CLIENT'});
  } else {
   displayDate = dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC'});
  }

  const Shifts = ({ data }) => {
    return (
      <div className="tooltip-column m-1">
        {data?.filter((item) => item?.net != null)?.map((item, index) => (
          <div className="tooltip-items">
            <span>
              {`${typeOfView == 'TEAMS' ? item?.team : item?.shift}`}
            </span>
            <span className="tooltip-item-value">{roundNumber(item?.net)}</span>
          </div>


        ))}
      </div>
    );
  }

  if (moment(label).minutes() !== 0 && moment(label).seconds() !== 0) {
    incomplete = true;
  }

  return (
    <Card elevation={16} style={{ borderRadius: 8, margin: 24 }}>
      <div className="chart-tooltip">
        <Spacer size={10} isVertical />
        <span className="tooltip-title m-3"> {displayDate}
          <span className='tooltip-incomplete'> {incomplete ? `(${language?.inprogress || 'incomplete'})` : ''} </span>
        </span>
        {
          (isShift)
            ? <Shifts data={currentDot?.shifts} />
            : <div className="tooltip-column m-1">
              <div className="tooltip-items">
                <span>{language?.total_production || 'Total Production'}</span>
                <span className="tooltip-item-value"> {roundNumber(currentDot?.net_production)} </span>
                <span className={`tooltip-value ${toGoalColor}`}>{toGoalFormat}</span>
              </div>
              <div className="tooltip-items">
                <span>{language?.scrap || 'Scrap'}</span>
                <span className="tooltip-item-value"> {scrapPercent?.toFixed()}% </span>
                <span className="tooltip-value value-default">({roundNumber(currentDot?.scrap_acc?.toFixed(1))})</span>
              </div>
              <div className="tooltip-divider" />
              <div className="tooltip-items">
                <span> {language?.period_production || 'Period Production'}</span>
                <span className="tooltip-item-value">{roundNumber(currentDot?.period_production)}</span>
                <span className={`tooltip-value ${toGoalPeriodColor}`}>{toGoalPeriodFormat} </span>
              </div>


            </div>
        }
      </div>
    </Card>
  )
};
export default CustomTooltip;
