import { gql } from '@apollo/client';

export const TOTAL_PRODUCTION_EQUIPMENT = gql`
query GET_TOTAL_PRODUCTION_EQUIPMENT_DAY($ts_start: timestamp, $ts_end: timestamp, $id_enterprise: Int, $id_area: String, $id_site: String, $grain: String, $shifts: String, $teams: String, $id_equipment: String, $partitionby: String) {
  h_piot_total_production_teams_2(args: {in_begin_time: $ts_start, in_end_time: $ts_end, in_id_enterprise: $id_enterprise, in_id_areas: $id_area, in_id_sites: $id_site, time_grain: $grain, in_id_shifts: $shifts, in_id_teams: $teams, in_id_equipments: $id_equipment, partitionby: $partitionby}) {
    gross_production_acc
    net_production_acc
    net_production_incr
    scrap
    scrap_acc
    target
    togoal
    trendline1
    ts
    shift_net_prod
    target_period
  }
}
`;

export const OEE_TOTAL = gql`
  query GetOEETotal($time_grain: String, $nav_level: String, $is_shift_filtered: Boolean = true, $in_ids_shifts: String, $in_id_sites: String, $in_id_equipments: String, $in_id_enterprise: Int, $in_id_areas: String , $in_end_time: timestamptz, $in_begin_time: timestamptz) {
    h_piot_oee_score_full_3(args: {in_begin_time: $in_begin_time, in_end_time: $in_end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_ids_shifts, is_shift_filtered: $is_shift_filtered, nav_level: $nav_level, time_grain: $time_grain}) {
      oee_componentes
    }
  }
`;

export const GET_TARGETS = gql`
query GetTarget(
  $group: String
  $ts_start: timestamptz
  $ts_end: timestamptz
  $in_id_areas: String
  $id_enterprise: Int
  $in_id_equipments: String
  $in_id_shifts: String
  $in_id_sites: String
  $in_id_teams: String
  $nav_level: String
  $time_grain: String
) {
  h_piot_get_targets(
    args: {
      group_by_element: $group
      in_begin_time: $ts_start
      in_end_time: $ts_end
      in_id_areas: $in_id_areas
      in_id_enterprise: $id_enterprise
      in_id_equipments: $in_id_equipments
      in_id_shifts: $in_id_shifts
      in_id_sites: $in_id_sites
      in_id_teams: $in_id_teams
      nav_level: $nav_level
      time_grain: $time_grain
    }
  ) {
    array_agg
    id_enterprise
    target
    ts_value_production
  }
}

`;