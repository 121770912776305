import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Table from './components/Table';
import DowntimeReasonsUpload from './components/DowntimeReasonsUpload.js';
import { Box, Skeleton } from '@mui/material';

import { GET_DWT_REASONS } from './queries'
import SkeletonTable from './components/SkeletonTable';

function DowntimeReasons() {
  const [tableData, setTableData] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const [showDowntimeReasonsUpload, setShowDowntimeReasonsUpload] = useState(false);

  const [getDowntimeReasons, { loading, data, error }] = useLazyQuery(GET_DWT_REASONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let table = [];
      data?.equipments?.[0]?.downtime_reasons?.map((machine, machineIndex) => {
        machine?.categories?.map((category, categoryIndex) => {
          table.push({
            ...category,
            machine: machine?.name?.['en-US']
          }
          )
        })
      });
      setTableData(table);
    }
  });

  useEffect(() => {
    if (selectedLine) {
      getDowntimeReasons({ variables: { id_equipment: selectedLine } });
    }
  }, [selectedLine])

  const toggleCsvUploadModal = () => setShowDowntimeReasonsUpload(!showDowntimeReasonsUpload);

  return (
    <Box>

      <Box sx={{ m: 3 }}>
        {
          showDowntimeReasonsUpload
            ? <DowntimeReasonsUpload isOpen={showDowntimeReasonsUpload} toggleCsvUploadModal={toggleCsvUploadModal} selectedLine={selectedLine} setSelectedLine={setSelectedLine} />
            : <></>
        }
        {
          (loading) ?
            <Box sx={{ width: '100%', height: 700, display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton width='400px' height='85px' />
                <Box sx={{ width: '400px', display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant='text' width='100px' height='50px' />
                  <Skeleton variant='text' width='100px' height='50px' />
                  <Skeleton variant='text' width='100px' height='50px' />
                </Box>

              </Box>
              <Skeleton variant='rectangular' width='100%' height='40px' />
              <SkeletonTable />
            </Box>
            :
            <Table data={tableData} selectedLine={selectedLine} setSelectedLine={setSelectedLine} toggleCsvUploadModal={toggleCsvUploadModal} />
        }

      </Box>

    </Box>
  );
}

export default DowntimeReasons;
