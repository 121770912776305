
import React, { useState } from 'react';
import { getLanguage } from '../../../utils/utils';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
// import PoRegistration from './PoRegistration';

export default function Configuration() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const language = getLanguage();
  const navigate = useNavigate();
  let location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate('/settings/targets');
    //navigate('/settings/users-and-permissions')
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ display: { xs: 'none', sm: 'block' }, mt: -2 }}
      >
        <SettingsIcon sx={{ width: 24, height: 24, color: '#FFFFFF' }} />
      </IconButton>

      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseC}>

        <MenuItem onClick={() => {
          localStorage.setItem('@packiot4:location', location.pathname)
          navigate('/settings/targets') 
        }}>
          {language?.settings || 'Settings'}
        </MenuItem> */}

      {/* <PoRegistration/> */}

      {/* </Menu> */}
    </div>
  );
}
