import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import EventsTab from '../EventsTab';
import { auth } from '../../firebase';
import { Box, } from '@mui/material';

function PageModel(props) {
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();

  const overview = window.location.pathname.split('/')?.[1] == 'overview';

  useEffect(() => {
    setTimeout(() => {
      if (auth?.currentUser) {
        setLogged(true);
        if (window.location.pathname.split('/')?.[1] == '') return navigate("/home");
      }
    }, 1800);
  }, []);

  if (overview || window.location.pathname.split('/')?.[1] == 'settings') {
    return (<>{props.children}</>);
  } else {
    return (
      logged &&
      <>
        <EventsTab />
        <Header />
        <Box>
          <Box sx={{ ml: { sm: 0, md: 8, xl: 8 }, }} >
            <Outlet />
          </Box>

          <Footer />
        </Box>
      </>
    );
  }
}
export default PageModel;
