import React, { } from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { LicenseInfo } from '@mui/x-license-pro';
import './index.css';

import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { ThemeProviderr } from './Context/ThemeContext';
import GlobalStyles from './components/GlobalStyles';

import { useRoutes } from 'react-router-dom';
import routes from './routes';
import './index.css';

library.add(fab, fas)

LicenseInfo.setLicenseKey(
  'f9c575676be09ace73ded8d5ed488cd3T1JERVI6NDE5MDgsRVhQSVJZPTE2ODE1ODAwMDcwMDAsS0VZVkVSU0lPTj0x',
);

function App() {
  const content = useRoutes(routes);

  return (
    <ThemeProviderr value="light">
      <SnackbarProvider TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <GlobalStyles />
          {content}
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProviderr>
  );
}

export default App;
