import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VariablesContext } from '../../../../Context/VariablesContext';
import { delay } from '../../../../utils/utils';
import Table from './components/Table';
import { GET_PRODUCTION_ORDERS, } from './queries'
import { Box, Skeleton } from '@mui/material';
import SkeletonTable from './components/SkeletonTable';
import moment from 'moment';

export default function ProductionOrders() {
  const { filterLine, line, lineOptions } = useContext(VariablesContext);
  const [tableData, setTableData] = useState([]);
  const [dataForDownload, setDataForDownload] = useState([]);
  const language = localStorage.getItem('user_language')

  // let lineIds = lineOptions.map((element, index) => {
  //   return element.id_equipment
  // })
  let lineIds = []

  const memoizedValue = useMemo(() => {
    lineIds = lineOptions.map((element, index) => {
      return element.id_equipment
    })
  }, [lineOptions]);

  const updateData = () => {
    getProductionOrders({ variables: { equipments: lineIds } });
  }

  useEffect(() => {
    updateData();
  }, [lineOptions]);


  const [getProductionOrders, { data, loading, refetch }] = useLazyQuery(GET_PRODUCTION_ORDERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {

      let table = data?.production_orders?.map((item, index) => ({
        id: item?.id_production_order,
        id_production_order: item?.id_production_order,
        id_order: item?.id_order_text,
        nm_client: item?.client?.nm_client,
        cd_product: item?.product?.cd_product,
        nm_product: item?.product?.nm_product,
        order_size: item?.production_ordered,
        nm_site: item?.Sites?.nm_site,
        nm_area: item?.Area?.nm_area,
        nm_equipment: item?.equipment?.nm_equipment,
        added_in: new Date(item?.ts_creation),
      }));
      // console.log(table);
      setTableData(table);

      let tableForDownload = data?.production_orders?.map((item, index) => ({
        id_order: item?.id_order,
        // cd_product: item?.product?.cd_product,
        nm_product: item?.product?.nm_product,
        nm_client: item?.client?.nm_client,
        order_size: item?.production_ordered,
        nm_equipment: item?.equipment?.nm_equipment,
        start_date: item?.ts_start,
        txt_product: item?.product?.txt_product,
        production_programmed: item?.production_programmed,
        product_family: item?.product?.product_family?.nm_product_family,
        txt_production_order_description: item?.txt_production_order_description,
        scrap_target: item?.product?.scrap_target,
        equipment_setup: item?.product?.equipment_setup
      }));
      // console.log(tableForDownload)
      setDataForDownload(tableForDownload)
    }
  });


  return (
    <Box sx={{ m: 4 }}>
      {
        loading
          ? <Box sx={{ width: '100%', height: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Skeleton width='400px' height='85px' />
              <Box sx={{ width: '400px', display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant='text' width='100px' height='50px' />
                <Skeleton variant='text' width='100px' height='50px' />
                <Skeleton variant='text' width='100px' height='50px' />
              </Box>

            </Box>
            <Skeleton variant='rectangular' width='100%' height='40px' />
            <SkeletonTable />

          </Box>
          : <Table data={tableData} update={updateData}
            refetch={refetch} dataDownload={dataForDownload}
          />
      }

    </Box>
  );
}
