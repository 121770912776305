import React from 'react';

import { Box, Typography } from '@mui/material';
import { Typography as Typography2 } from '@material-ui/core';
//import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
//import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

import { getLanguage, roundNumber } from '../../../utils/utils';
//import LightTooltip from '../../../components/LightTooltip';
import { TimeLineItem } from '../styles';

import CardHeader from './CardHeader';
import StatusHistory from './StatusHistory';
import JobProduction from './JobProduction';
import { scrapCalc } from '../../../configurations/scrap';

const colors = {
  running: '#318F29',
  lowSpeed: '#ECBC13',
  changeOver: '#C13939',
  stopped: '#C13939',
  default: '#808080'
}

function MainCardLarge({ data }) {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');
  const showDefaultTimeLine = data?.status_24h?.every(element => element === null);

  let scrap = (data?.curshift_scrap != null || data?.curshift_grosprod != null)
    ? scrapCalc(scrapType, data?.curshift_grosprod, data?.curshift_netprod)
    // (data?.curshift_grosprod == 0 ? (0).toFixed(0) : ((data?.curshift_scrap || 0) * 100 / data?.curshift_grosprod).toFixed(1)) 
    : "";

  // let scrap = (data?.curshift_scrap != null || data?.curshift_grosprod != null) ? (data?.curshift_grosprod == 0 ? (0).toFixed(0) : ((data?.curshift_scrap || 0) * 100 / data?.curshift_grosprod).toFixed(1)) : "";


  return (
    <Box
      sx={{
        width: { lg: '847px', xl: '48%' },
        height: '483px',
        background: '#FFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        pl: 3, pr: 3, pt: 2, pb: 1,
        ml: 2, mt: '8px', mb: 1
      }}
    >
      { /* Header */}
      <CardHeader data={data} />

      { /* Job Production and previous shift */}
      <JobProduction data={data} isLarge />

      { /* Current shift */}
      <Typography sx={{ fontSize: '18px', fontWeight: 700, color: '#4D4D4D', mt: '10px' }}>{language?.current_shift || "Current Shift"}</Typography>
      <Box
        sx={{
          width: '100%', height: '78px', display: 'flex', alignItems: 'center', justifyContent: 'space-around',
          borderBottom: '1px solid #E3E2E7', borderTop: '1px solid #E3E2E7', mt: '10px'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 900 }}> {roundNumber(data?.curshift_netprod)}</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{language?.production || 'Production'} [u]</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}> {scrap}% </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{language?.scrap || 'Scrap'}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}> {data?.speed || 0}/min </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{language?.speed || 'Speed'}[u/min]</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}> {(data?.currshift_oee != null) ? (data?.currshift_oee * 100).toFixed(1) : "0"}% </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>OEE</Typography>
        </Box>

      </Box>

      { /* Last 24h */}
      <Typography sx={{ fontSize: '18px', fontWeight: 700, mt: '10px', color: '#4D4D4D' }}>{language?.last_24_hours || "last 24h"}</Typography>
      <StatusHistory data={data} />

      { /* TimeLine */}
      <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#BDBDBD', textTransform: 'uppercase' }}> {language?.timeline || 'Timeline'} </Typography>
      <Box sx={{ width: '100%', height: '20px', display: 'flex' }}>
        {
          showDefaultTimeLine ? <Box sx={{ width: '100%', bgcolor: colors['default'] }} />
            : data?.status_24h?.map((item, index) => <Box key={index} sx={{ width: 100 / data?.status_24h?.length || 0, bgcolor: colors[item] }} />)
        }
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography2 variant='body1' style={{ color: '#828282' }}>-24h </Typography2>
        <Typography2 variant='body1' style={{ color: '#828282' }}> {language?.current || 'current'}  </Typography2>
      </Box>

    </Box >
  );
}

export default MainCardLarge;