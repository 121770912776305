import React from 'react';
import { Box,Typography, Button } from '@mui/material';
import { getLanguage, roundNumber } from '../../../utils/utils';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { height } from '@mui/system';
import { scrapCalc } from '../../../configurations/scrap';

function AreaCardExpanded({ data, id, handleClose }) {
  const language = getLanguage();
  const areaData = data?.filter(area => area.id_area === id);
  const area = areaData?.[0];
  const scrapType = localStorage.getItem('scrap_calc_type');
  
  let scrapPercent = scrapCalc(scrapType, area?.gross_production, area?.net_production );

  let oeePercent = area?.oee != null ? (area?.oee * 100).toFixed(1) : "";
  let toGoal = (area?.net_production - area?.target) / area?.target * 100;
  let toGoalColor = '#BDBDBD';
  let toGoalProjectedColor = '#BDBDBD';
  let toGoalProjected = (area?.projected_production - area?.target) / area?.target * 100;

  if (toGoal > 0) {
    toGoal = Math.round(toGoal) + "%";
    toGoalColor = '#318F29';
  }
  else if (toGoal < 0) { 
    toGoal = Math.round(toGoal) + "%";
    toGoalColor = '#D32F2F';
  } else {
    toGoal = "0%";
  }

  if (toGoalProjected > 0) {
    toGoalProjected = Math.round(toGoalProjected) + "%";
    toGoalProjectedColor = '#318F29';
  }
  else if (toGoalProjected < 0) {
    toGoalProjected = Math.round(toGoalProjected) + "%";
    toGoalProjectedColor = '#D32F2F';
  } else {
    toGoalProjected = "0%";
  }

  return (
    <Box 
      sx={{ 
        p:2, background: '#FFFFFF', borderRadius: '8px',
        display: 'flex', flexDirection: { xs: 'row', lg: 'column' },
        height: {xs: '284px', lg: '680px'} 
      }}
      >
      <Box sx={{ width: {xs: '360px', lg: '99%'}, height: {xs: '100%', lg: '365px'},  position: 'relative' }}>
        <Typography sx={{ fontSize: '22px', fontWeight: 700, display: {xs: 'flex', lg: 'none'} }}>{language?.current_shift || 'Current Shift'} | {area?.nm_area}</Typography>
        <Typography sx={{ fontSize: '22px', fontWeight: 700, display: {xs: 'none', lg: 'flex'} }}>{language?.current_shift || 'Current Shift'}</Typography>
        <Typography sx={{ fontSize: '21px', fontWeight: 400, display: {xs: 'none', lg: 'flex'} }}> {area?.nm_area}</Typography>
        <Button 
          sx={{ textTransform: 'capitalize', position: 'absolute', top: 0, right: '-15px', display: {xs: 'none', lg: 'flex'} }}
          onClick={() => handleClose()} 
          variant='text' startIcon={<ChevronLeftIcon />}
        >
          {language?.all_areas || 'All areas'}
        </Button>
        <Box sx={{ display: 'flex', pr:3, width: '100%', flexDirection: {xs: 'row', lg: 'column'}, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '20px', mt: '50px', fontWeight: 400 }}>{language?.total_production || "Total Production"}  [u]</Typography>
            <Typography sx={{ fontSize: '40px', fontWeight: 700}}>{roundNumber(area?.net_production)} </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: toGoalColor}}> {toGoal} {language?.of_goal || 'of goal'}</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: {lg: 'space-between'}, flexDirection: {xs: 'column', lg: 'row'}}}>
            <Box sx={{ mt: '10px' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 400}}>{language?.daily_goal || 'Daily Goal'}</Typography>
              <Typography sx={{ fontSize: '24px', fontWeight: 600}}>{roundNumber(area?.target)}</Typography>
            </Box>

            <Box sx={{ mt: '10px' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 400}}>{language?.projected || 'Projected'}</Typography>
              <Typography sx={{ fontSize: '24px', fontWeight: 600}}>{roundNumber(area?.projected_production)}</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: toGoalProjectedColor}}> {toGoalProjected} {language?.of_goal || 'of goal'}</Typography>
            </Box>

          </Box>

        </Box>
      </Box>

      <Box 
        sx={{ 
          display: 'flex', width: {xs: '160px', lg: '99%' },
          flexDirection: 'column', justifyContent: 'center',
          alignItems: 'center',
          borderLeft: {xs: '1px solid #BDBDBD', lg: 'none'},
          borderTop: {xs: 'none', lg: '1px solid #BDBDBD'},
          borderBottom: {xs: 'none', lg: '1px solid #BDBDBD'},
          height: {xs: '100%', lg: '164px'}
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: 400}}>{language?.total_scrap || 'Total Scrap'}</Typography>
        <Typography sx={{ fontSize: '40px', fontWeight: 700}}>{scrapPercent}%</Typography>
        <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#828282'}}>({roundNumber(area?.scrap)})</Typography>
      </Box>

      <Box 
        sx={{ 
          width: {xs: '186px', lg: '99%' },
          display: 'flex', position: 'relative', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center',
          borderLeft: {xs: '1px solid #BDBDBD', lg: 'none'},
          height: {xs: '100%', lg: '140px'}
        }}
      >
        <Button 
          sx={{ textTransform: 'capitalize', position: 'absolute', top: 0, right: 0, display: {xs: 'flex', lg: 'none'} }} 
          onClick={() => handleClose()}  variant='text' 
          startIcon={<ChevronLeftIcon />}
        >
          {language?.all_areas || 'All areas'}
        </Button>

        <Typography sx={{ fontSize: '20px', fontWeight: 400}}>OEE</Typography>
        <Typography sx={{ fontSize: '40px', fontWeight: 700}}>{oeePercent}%</Typography>
        <Typography sx={{ fontSize: '20px', fontWeight: 400, visibility: 'hidden'}}>(37,5k)</Typography>
      </Box>

      
    </Box>
  );
}

export default AreaCardExpanded;