import moment from "moment";

/**
 * @author William
 * @function
 * Uma pequena gambiarra muito util
 * @param {Number} time 
 * @returns 
 */
export function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

/**
 * table search field
 * 
 * @param {string} value 
 * @returns string
 */

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * Return language_pack object
 * @author @william
 * 
 * @example 
 * const language = getLanguage();
 * language?.machine;
 * 
 * @returns Object
 */
export function getLanguage() {
  const storedData = localStorage.getItem('language_packs');

  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch { }
  }

  return null;
}

export function secondsToTimePassed(num) {
  const dia = num / 86400;
  const restoDia = num % 86400;

  const hora = restoDia / 3600;
  const restoHora = restoDia % 3600;

  const minutos = restoHora / 60;

  const restoMinutos = restoHora % 60;

  return (
    (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
    (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
    (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) + "min " : "") +
    (Math.trunc(restoMinutos) !== 0 ? Math.trunc(restoMinutos) + "seg" : "")
  )
};


/**
 * @function
 * @author William lindo
 * @description
 * Return a dateTime format with specific locale
 * 
 * @version 0.1.1
 * @example
 * let date = dateFormat('2023-04-01', {weekDay: 'long', day: '2-digit', month: '2-digit'});
 * console.log(date) // Monday, 04/01
 * 
 * @param {string} date - !Required
 * @param {Object} options !Required
 * @param {string} options.day - "2-digit", "numeric"
 * @param {string} options.weekday - "narrow", "short", "long"
 * @param {string} options.month - "2-digit", "numeric", "narrow", "short", "long"
 * @param {string} options.year - "2-digit", "numeric"
 * @param {string} options.hour - "2-digit", "numeric"
 * @param {string} options.minute - "2-digit", "numeric"
 * @param {string} options.timeZone - "UTC", "CLIENT"
 * 
 * @returns string
 */
export function dateFormat(date, options) {
  const locale = localStorage.getItem('user_language')?.toLowerCase();
  const timezone = localStorage.getItem('timezone');
  let isAmPm = true;
  let dateFormat = '';

  if (!date) return '';

  try {
    if (locale === 'pt-br') isAmPm = false;

    if (options?.timeZone === 'CLIENT') {
      options.timeZone = timezone;
    }

    dateFormat = new Intl.DateTimeFormat(locale, options).format(new Date(date));
    if (!isAmPm && options?.hour && !options.minute) dateFormat += 'h';

  } catch (error) {

  }

  return dateFormat.toLowerCase();
}

/**
 * @function
 * @author William lindo
 * @description
 * save datagrid configuration
 * 
 * @param {string} tableName
 * @param {Object} filter
 * @param {string} filter.filterName - "filter", "rowsPerPage", "columns"
 * @param {any} filter.value
 * 
 * @example
 * onPageSizeChange={(newPageSize) => setDataGridConfig('po', { filterName: 'rowsPerPage', value: newPageSize }) }
 * 
 */
export function setDataGridConfig(tableName, filter) {
  try {
    let config = getDataGridConfig(tableName);

    config[`${filter.filterName}`] = filter.value;

    localStorage.setItem(`@table-filters-${tableName}`, JSON.stringify(config));
  } catch (error) {
    console.log(error)
  }
}

/**
 * @function
 * @author William
 * @description
 * Get table filters stored
 * @param {string} tableName 
 */
export function getDataGridConfig(tableName) {
  let config = {
    filter: [],
    rowsPerPage: 15,
    columns: {}
  };
  try {
    let filters = JSON.parse(localStorage.getItem(`@table-filters-${tableName}`));
    if (filters) config = filters;
  } catch (error) {
    console.log(error);
  }
  return config;
}


/**
 * 
 * Return user locale
 * @author @william
 * 
 * @deprecated
 * @example
 * const locale = userLocale();
 * const dateFormat = moment(date).locale(locale.tag).format('DD/MM');
 * 
 * @returns Object
 */
export function userLocale() {
  const userLanguage = localStorage.getItem('user_language')?.toLowerCase();
  let locale = {
    tag: 'en',
    hour: 'hh a',
    isAmPm: true
  };

  switch (userLanguage) {
    case 'pt-br':
      locale.tag = 'pt-br';
      locale.hour = 'HH';
      locale.isAmPm = false;
      break;

    default:
      break;
  }

  return locale;
}

/**
 * Return <'today', 'week' 'month', 'last month'>
 * @param {string} start 
 * @param {number} diff 
 * 
 * @example
 * dateRange(moment().format('YYYY-MM-DD'), 1)
 * 
 * @returns string
 */
export function dateRange(start, diff) {
  let timeRange = 'custom';
  const startDate = moment(start).format('YYYY-MM-DD');
  const isToday = moment(startDate).isSame(moment().startOf('day'));
  const thisWeek = moment(startDate).isSame(moment().startOf('week'));
  const thisMonth = moment(startDate).isSame(moment().startOf('month'));
  const lastMonth = moment(startDate).isSame(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
  switch (diff) {
    case 0:
      if (isToday) {
        timeRange = 'today';
      } else {
        timeRange = 'day';
      }
      break;
    case 6:
      if (thisWeek) timeRange = 'week';
      break;
    case 27:
      if (thisMonth) timeRange = 'month';
      if (lastMonth) timeRange = 'last month';
      break;
    case 28:
      if (thisMonth) timeRange = 'month';
      if (lastMonth) timeRange = 'last month';
      break;
    case 29:
      if (thisMonth) timeRange = 'month';
      if (lastMonth) timeRange = 'last month';
      break;
    case 30:
      if (thisMonth) timeRange = 'month';
      if (lastMonth) timeRange = 'last month';
      break;
    default:
      timeRange = 'custom';
      break;
  }

  return timeRange;
}

// Seconds to duration string
export function secondsToDurationString(value, precision = "minutes") {
  const language = getLanguage();
  var atrasoString = "NA";
  if (value != null) {
    atrasoString = "";
    if (value > 86400) {//1day
      if (value > 172800) {//2days
        atrasoString = `${Math.floor(value / 86400)} ${language?.days || 'Days'} ${' '}`;
      } else {
        atrasoString = `1 ${language?.day || 'Day'} ${' '} `;
      }
    }
    switch (precision) {
      case 'minutes':
        atrasoString += new Date(value * 1000).toISOString().substr(11, 5);
        break;
      case 'seconds':
        atrasoString += new Date(value * 1000).toISOString().substr(11, 8);
        break;
      default:
        atrasoString += new Date(value * 1000).toISOString().substr(11, 5);
        break;
    }
  }
  return atrasoString
}

/**
 * @function
 * @example
 * // returns true
 * isInvalidNumber(null);
 * @author @william
 * @param  {Number} number
 * @return {Boolean} Return true if some value was expected as a number but is not
 */
export function isInvalidNumber(number) {
  return (
    isNaN(number) ||
    !isFinite(number) ||
    number === Infinity ||
    number === 'Infinity' ||
    number === -Infinity ||
    number === '-Infinity' ||
    number === NaN ||
    number === 'NaN' ||
    number === '∞' ||
    number === '-∞' ||
    number === undefined ||
    number === 'undefined' ||
    number === null ||
    number === 'null'
  );
};


// Round numbers
export function numbertoString(number) {
  let numberString = "";

  if (number >= 1000) {
    numberString += roundNumber(number, true).value;
    numberString += roundNumber(number, true).token;
  }
  else {
    numberString += number?.toFixed(1);
  }
  // else{
  //   numberString+=number.toFixed(0);
  // }
  return numberString;
}

export function roundNumber(number, returnObject, decimalNumber = null) {
  let value = 0;
  let numberNegative = number < 0;
  number = (numberNegative) ? (number * -1) : number;

  let dNumber = (decimalNumber !== null) ? decimalNumber : 2;
  if (number / 1000 < 1) value = float(number, dNumber, dNumber);
  if (number / 1000 > 1 && number / 1000 < 1001) value = float((number / 1000), dNumber, dNumber) + 'K';
  if (number / 1000 > 1000) value = float((number / 1000000), dNumber, dNumber) + 'MM';
  if (returnObject) {
    value = {};
    if (number / 1000 < 1) value.value = number;
    if (number / 1000 > 1 && number / 1000 < 1001) {
      value.value = float((number / 1000), dNumber, dNumber);
      value.token = 'K';
    }
    if (number / 1000 > 1000) {
      value.value = float((number / 1000000), dNumber, dNumber);
      value.token = 'MM';
    }
  }
  if (number == 1000) return '1K';

  return (numberNegative) ? '-' + value : value;
};

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function float(value, min, max) {
  if (!isFloat(value)) return value + '';
  return new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3 }).format(parseFloat(value));
  /* return parseFloat(value)?.toLocaleString('de-DE', {
    maximumFractionDigits: max,
    minimumFractionDigits: min
  }); */
};

export function localText(language_pack, string_key) {
  if (language_pack) {
    if (language_pack.hasOwnProperty(string_key)) {
      return language_pack[string_key];
    }
  }
}

export function convertToDaysHours(value, time) {
  let durationInSeconds = moment.duration(value, time);
  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm:ss');
  }
  return moment.utc(durationInSeconds.valueOf()).format('HH:mm:ss');
};

export function convertToDaysHoursNoSeconds(value, time) {
  let durationInSeconds = moment.duration(value, time);
  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm');
  }
  return moment.utc(durationInSeconds.valueOf()).format('HH:mm');
};

export function getTotalElapsedTime(value, time) {
  let durationInSeconds = moment.duration(value, time);
  let total = '';
  if (durationInSeconds.days() > 0) total = durationInSeconds.days() + 'd ';
  if (durationInSeconds.hours() > 0) total += durationInSeconds.hours() + 'h ';
  if (durationInSeconds.minutes() > 0) total += durationInSeconds.minutes() + 'min';
  return total;

}

export function JSONToCSVConvertor(JSONData, columns = null, ReportTitle, ShowLabel) {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData =
    typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var renamedData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var renamedData = [];

  if (columns) {

    arrData.forEach((new_po) => {

      const renamed_new_po = {};
      const reordered_row = JSON.parse(JSON.stringify(new_po, Object.keys(columns)));
      for (var column in reordered_row) {
        const new_column_name = columns[column];
        if (new_column_name) {
          renamed_new_po[new_column_name] = new_po[column];
        }
      }
      renamedData.push(renamed_new_po);

    });


  } else {
    renamedData = arrData;
  }


  var CSV = "sep=,\r\n";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    //This loop will extract the label from 1st index of on array
    for (var index in renamedData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ",";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < renamedData.length; i++) {
    var row = "";

    const keys = Object.keys(renamedData[i]);

    keys?.forEach((value, index) => {
      if (renamedData[i][value] != null) { row += '"' + String(renamedData[i][value])?.replaceAll('undefined', '') + '"'; } else { row += ''; }
      if (index < keys.length - 1) { row += "," }
    })

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  var fileName = ReportTitle ? ReportTitle !== "" ? ReportTitle.replace(/ /g, "_") + "_" + moment().format('x') : "PackIOT_File" : "PackIOT_File";

  //Initialize file format you want csv or xls
  var uri = "data:text/csv; charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function sortArrayOfObjectsBy(a, b, orderByElement) {
  const asc = orderByElement?.[0] == '-';


  orderByElement = asc ? orderByElement.substring(1) : orderByElement;
  if ((asc && a[orderByElement] < b[orderByElement]) || (!asc && a[orderByElement] > b[orderByElement])) {
    return -1;
  }
  if ((asc && a[orderByElement] > b[orderByElement]) || (!asc && a[orderByElement] < b[orderByElement])) {
    return 1;
  }
  return 0;
}


function getValueFromObjectPath(obj, path) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  var a = path.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return o;
}
