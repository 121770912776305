import React from 'react';

import { roundNumber } from '../../utils/utils';

const Ytick = (props) => {
  let value = roundNumber(props.payload.value);
  
  if (value < 0) value = 0;
  
  let valueWithoutLastElement = value.toString().slice(0, -1);
  return (
    <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
      <tspan x={(props.x)} dx={(props?.isSpeed?"0px":'')} dy={(props?.isSpeed?"0px":"4px")} textAnchor="end">
        {props?.removeLastLetter ? valueWithoutLastElement : value }
        {props?.isPercentage ? '%' : ''}
        <tspan dy="18px" dx="-50px" >
          {props?.isSpeed && value!==0 ? ' un/min' : ''}
        </tspan>
      </tspan>
    </text>
  );
}
export default Ytick;
