import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment-timezone';
import { roundNumber, isInvalidNumber, getLanguage, userLocale, dateFormat } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';

import { TooltipContainer, TooltipColumn, TooltipDivider, TooltipItems, TooltipTitle } from '../styles';
import { scrapCalc } from '../../../configurations/scrap';

const SinglePeriodTooltip = ({ active, payload, label, chartData, dateRange }) => {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');
  const { grain, timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  let currentDot = chartData?.filter((item) => item?.ts_value_production === label);
  if (!active) return null;
  currentDot = currentDot?.[0] || {};
  if (currentDot?.net === null) return null;
  if (moment(currentDot?.ts_value_production).isAfter(moment(new Date()))) return null;

  let showIncomplete = false;
  
  let displayDate = '';

  if (dateRange === 'day' || dateRange === 'today') {
    displayDate = dateFormat(label, {hour: "2-digit", timeZone: 'CLIENT'});
  } else {
   displayDate = dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC'});
  }

  if (moment(currentDot.ts_value_production).minutes() !== 0 || moment(currentDot.ts_value_production).seconds() !== 0) {
    showIncomplete = true;
  }

  function validateNumber(number) {
    let validNumber = number
    if (isInvalidNumber(number)) validNumber = 0;
    if (number > 100) validNumber = 100;

    return validNumber;
  }
  let toGoalPercentColor = '#318F29';
  let toGoalColor = '#4D4D4D';
  let toGoal = 0;
  let toGoalPercentText = language?.of_goal || 'of goal';
  let toGoalPercentFormat = '';
  let toGoalPercent = (currentDot?.net - currentDot?.target) / currentDot?.target * 100;
  let periodProduction = roundNumber(currentDot?.net);
  let scrap = roundNumber(currentDot?.scrap.toFixed(1));
  let scrapPercent = scrapCalc(scrapType, currentDot?.gross, currentDot?.net)

  if (currentDot?.net <= currentDot?.target) {
    toGoal = roundNumber(currentDot?.target - currentDot?.net);
  } else {
    toGoal = roundNumber(currentDot?.net - currentDot?.target);
    toGoalColor = '#318F29';
    toGoal = `+${toGoal}`;
  }

  if (toGoalPercent >= 0) {
    if (isFinite(toGoalPercent)) {
      toGoalPercentFormat = '+' + toGoalPercent.toFixed() + '% ' + toGoalPercentText;
    }
  } else {
    if (!isNaN(toGoalPercent)) {
      toGoalPercentColor = '#C12727';
      toGoalPercentFormat = toGoalPercent.toFixed() + '% ' + toGoalPercentText;
    }
    
  }

  return (
    <TooltipContainer >
      <Box sx={{ display: 'flex', height: '50px', width: '100%' }}>
        <TooltipTitle>
          {displayDate}
          <span> {showIncomplete ? `(${language?.inprogress || 'In progress'})` : ''} </span>
        </TooltipTitle>

      </Box>

      <TooltipColumn>
        <TooltipItems>
          <Typography sx={{ color: '#4D4D4D' }}>{language?.period_production || 'Period Production'}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {periodProduction} </Typography>
          <Typography sx={{ visibility: 'hidden', fontWeight: 'bold', fontSize: '16px', color: '#318F29' }}> 30</Typography>
        </TooltipItems>

        <TooltipItems>
          <Typography sx={{ color: '#4D4D4D' }} >{language?.scrap || 'Scrap'}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {scrapPercent}% </Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#4D4D4D', opacity: 0.5 }}>({scrap})</Typography>
        </TooltipItems>

        <TooltipDivider />

        <TooltipItems>
          <Typography sx={{ color: '#4D4D4D' }} >{language?.to_goal || 'To Goal'}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: toGoalColor }}> {toGoal} </Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: toGoalPercentColor }}> {toGoalPercentFormat}  </Typography>
        </TooltipItems>
      </TooltipColumn>

    </TooltipContainer>
  )

};
export default SinglePeriodTooltip;
