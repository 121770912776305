import React, { useState, useEffect, useRef } from "react";

import { RiInformationLine } from "react-icons/ri";

import { Box, Grid, IconButton, Button, Link } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@material-ui/core';


export default function MissionControl35() {

    return (
      <>
        <Grid container >

            <Box sx={{
              width: { xs: '100vw', lg: '100%' },
              height: { lg: 'calc(100vh - 300px)' },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 5
            }}>
              <ErrorOutlineIcon />
            <Box sx={{
              mt: 2, display: 'flex', alignItems: 'center', flexDirection: 'column',}}>
              <Typography variant="h4">Ops!</Typography>
              <Typography variant="subtitle1">Essa página migrou para:</Typography>
              <Typography variant="subtitle1" color={'blue'}>http://camargo.packiot.com</Typography>
              <Typography variant="subtitle1">Clique no botão abaixo para conferir.</Typography>
              </ Box>
            <Link href='http://camargo.packiot.com' variant="subtitle1" color='#407CCC'>
              <Button sx={{ bgcolor: "blue", color: 'white', marginTop: 2 }} onClick={() => { }}>Ir para nova página</Button>
            </Link>
              

            </Box>
        </Grid>
      </>
    );
}