import styled from "styled-components";

export const SuportLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  
  &:hover {
    color: #fff;
  }
`;