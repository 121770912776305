import React, { useContext, useState } from "react";
import { useQuery } from '@apollo/client';
import { VariablesContext } from '../../../Context/VariablesContext';
import { GET_VARIABLES_CONTEXT } from '../../../Context/Query';
import { getLanguage } from '../../../utils/utils';

import { ReactComponent as More } from '../../../assets/More.svg';
import { Box, Divider, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Typography, } from '@material-ui/core';

const language = getLanguage();

export default function Site() {
  const { site, setSite, setEnterprise } = useContext(VariablesContext);
  const { data:set } = useQuery(GET_VARIABLES_CONTEXT);

  // console.log(data)
  // console.log(set?.v_entities_per_user_role?.[0])
  let ableSites = set?.v_entities_per_user_role?.[0]

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const validation = (data) => {
    if (site == undefined) {
      if (data?.sites.length === 1) {
        setSite(data?.sites[0].id_site);
      }
    }
  };
  validation(ableSites);

  const handleChangeSite = (idSite) => {
    setSite(idSite);
    handleClose();
  };

  let text = ableSites?.sites.filter(item => item.id_site == site)?.[0]?.nm_site
  let home = location.pathname.toUpperCase().includes('HOME');

  return (
    <>
      <IconButton
        disableRipple={true} color='primary'
        variant="text"
        aria-controls={open ? 'basic-menu' : undefined}
        onClick={handleClick}
      >
        <Typography variant={!home ? 'body1' : 'body2'} color={!home ? '#407CCC' : 'gray'}>
          {site ? text : (home ? language?.choose_site || 'Choose site' :
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <More />
            </Box>)}
        </Typography>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Typography variant="h3" > {language?.select_site || 'Select a site'} </Typography>
        </MenuItem>
        <Divider></Divider>

        {ableSites?.sites?.map((site, index_site) => {
          return (
            <MenuItem value={site?.id_site} onClick={() => { handleChangeSite(site?.id_site); }} >
              {site?.nm_site}
            </MenuItem>
          )
        })}

      </Menu>
    </>
  );
}