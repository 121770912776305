import styled from 'styled-components';

export const Span = styled.span`
font-weight: 700;
font-size: 20px;
color: #4D4D4D;
`;
export const AreaSpan = styled.span`
font-weight: 400;
font-size: 16px;
`;

export const ProgressBar = styled.div`
display: flex;
width: 100%;
height: 11px;
background: #FFFFFF;
border-radius: 10px;
`;

export const ProgressItem = styled.span`
  width: ${props => props.width}%;
  background: #407CCC;
  height: 11px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  ${(props) => {
    if (props.width >= 99) return 'border-top-right-radius: 10px; border-bottom-right-radius: 10px;'
  }}
`
export const TimeLineItem = styled.div`
  width: ${(props) => props.size}%;
  background: ${(props) => props.color};
  height: 20px;
  margin-top: 10px;
`;
