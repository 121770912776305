import { gql } from '@apollo/client';

export const GET_PRODUCTION_FLOW = gql`
query GetProductionFlow(
  $ts_start: timestamptz
  $ts_end: timestamptz
  $id_enterprise: Int
  $id_area: String
  $id_site: String
  $grain: String
  $shifts: String
  $id_equipment: String
  $teams: String
) {
  h_piot_production_flow(
    args: {
      in_begin_time: $ts_start
      in_end_time: $ts_end
      in_id_enterprise: $id_enterprise
      in_id_areas: $id_area
      in_id_sites: $id_site
      time_grain: $grain
      in_id_shifts: $shifts
      in_id_equipments: $id_equipment
      in_id_teams: $teams
    }
  ) {
    flexible_position
    nm_equipment
    production_flow
    total_scrap
  }
}

`;

