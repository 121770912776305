import React, { useState } from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, } from '@mui/material';
import { Typography } from '@material-ui/core'
import ExportCsv from './ExportCsv';
import { getLanguage } from '../../../../../utils/utils';

import DialogDelete from './DialogDelete';
import PoRegistration from './PoRegistration';

function CustomToolbar({ data, selectedIDs, update, dataDownload }) {
  const language = getLanguage();
  const [value, setValue] = useState(selectedIDs);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  // console.log(data)

  const handleAddPOButton = () => {
    // console.log(selectedIDs)
    // console.log('clicou em add'); 
    setOpenUpload(!openUpload);
  }

  const handleDeleteButton = () => {
    // console.log(selectedIDs)
    setOpen(true);
  }

  return (
    <>
      <DialogDelete isOpen={open} selectedIDs={selectedIDs} update={update} handleClose={() => { setOpen(false) }} />
      <PoRegistration isOpen={openUpload} handleClickOpen={handleAddPOButton} update={update} />

      <GridToolbarContainer sx={{ mb: 1, }} >
        <Box sx={{ display: { xs: 'block', lg: 'flex' }, justifyContent: 'space-between', mb: 1, width: '100%' }}>

          <Typography variant='h3' >
            {language?.available_production_orders || 'Available Production Orders'}
          </Typography>

          <Box sx={{ mt: { xs: 2, lg: 0 }, display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', lg: 'flex-end' }, }} >

            <ExportCsv dataDownload={dataDownload} />

            <GridToolbarColumnsButton sx={{ bgcolor: '#EAEAED', height: '40px', width: '140px', borderRadius: '8px', color: '#333333', mr: 2 }} />

            {selectedIDs.length === 0
              ? <Button variant="contained" sx={{ height: '40px', }} onClick={() => { handleAddPOButton() }} >
                <AddCircleOutlineOutlinedIcon sx={{ fontSize: 'large', mr: 1 }} />
                {language?.new_production_order || 'New Production Order'}
              </Button>
              : <Button variant="contained" color="error" sx={{ height: '40px', mr: 2, }} onClick={() => { handleDeleteButton() }}>
                <DeleteOutlinedIcon sx={{ fontSize: 'large', mr: 1 }} />
                {language?.delete_production_orders || 'Delete Production Orders'}
              </Button>}

          </Box>
        </Box>

      </GridToolbarContainer>
    </>
  );
}

export default CustomToolbar;