import React from 'react';
import { Box, Typography } from '@mui/material';

import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
//import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import moment from 'moment';

import { getLanguage, roundNumber, userLocale } from '../../../utils/utils';
import { Span, ProgressBar, ProgressItem } from '../styles';


function JobProduction({ data, isLarge }) {
  const language = getLanguage();
  const locale = userLocale();
  let progress = (data?.po_net_production && data?.production_programmed) ? data?.po_net_production * 100 / data?.production_programmed : 0;
  let client = data?.nm_client;
  if (client?.length > 15) {
    client = client.substring(0, 12) + '...';
  }
  let format = 'HH:mm';
  if (locale.isAmPm) format = 'hh:mm a';
  let remaining_time = (data?.job_remaining_time) > 0 ? moment().add(data?.job_remaining_time, 'seconds').format(format) : '-';
  let days_remaining = moment.duration(data?.job_remaining_time, 'seconds').days();

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
      <Box
        sx={{
          width: { lg: '570px', xl: '70%' },
          height: '72px',
          background: '#F0F0F0',
          borderRadius: '8px',
          pl: 1,
          pr: 1,
        }}
      >

        <Box sx={{ width: '100%', display: 'flex', }}>
          <Box flexDirection='column' sx={{ display: 'flex', width: '200px' }}>
            <Typography sx={{ fontSize: '13px', mt: '2px', fontWeight: 600, color: '#4D4D4D' }}>{language?.job || "Job"} </Typography>
            <Span> {data?.id_order} </Span>
            <Typography sx={{ fontSize: '13px', mt: '-3px', textTransform: 'uppercase', color: '#4D4D4D' }}>{client}</Typography>
          </Box>


          <Box flexDirection='column' sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', mt: '2px', justifyContent: 'space-between' }}>
              <Typography>{language?.production || "Production"}</Typography>
              <Typography><strong>{roundNumber(data?.po_net_production)}</strong> / {roundNumber(data?.production_programmed)}</Typography>
            </Box>
            <ProgressBar> <ProgressItem width={progress} /> </ProgressBar>
            <Box sx={{ width: '100%', display: 'flex', mt: '6px', justifyContent: 'center' }}>
              {
                data?.job_remaining_time > 0
                  ?
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {language?.projected_end || 'Projected End'}: {data?.job_remaining_time ? remaining_time : '-'}
                    {days_remaining > 0 ? <span style={{ color: '#407CCC', fontWeight: 'bold' }} >+{days_remaining}</span> : ''}
                  </Typography>
                  : <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {language?.ended || 'Ended'}
                  </Typography>
              }
            </Box>
          </Box>

        </Box>

      </Box>

      <Box sx={{ width: { lg: '178px', xl: '28%' }, display: 'flex', flexDirection: 'column', }}>
        <Typography sx={{ color: '#BDBDBD', fontSize: '16px', fontWeight: 700, textTransform: 'uppercase' }}>
          {language?.previous_shifts || "Previous shifts"}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 700, display: 'flex', alignItems: 'center' }}>
              {roundNumber(data?.prev1shift_netprod) || '0'} {/* <ArrowUpwardRoundedIcon sx={{ color: '#318F29', fontSize: '20px', fontWeight: 700 }} /> */}
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.prev1_shift_name}</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 700, display: 'flex', alignItems: 'center' }}>
              {roundNumber(data?.prev2shift_netprod) || '0'} { /*<ArrowUpwardRoundedIcon sx={{ color: '#318F29', fontSize: '20px', fontWeight: 700 }} /> */}
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.prev2_shift_name}</Typography>
          </Box>

        </Box>

      </Box>

    </Box>
  );
}

export default JobProduction;