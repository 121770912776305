import React, { useContext, useState } from "react";
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';
import { Button, Menu, MenuItem, IconButton, } from '@mui/material';
import { Typography } from '@material-ui/core'
import moment from 'moment';

const language = getLanguage();

export default function PeriodTime() {
  const { setRange, setTs_start, setTs_end } = useContext(VariablesContext);
  const [selectedRange, setSelectedRange] = useState(language?.choose_period || 'Choose Period');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const today_start = moment().startOf('day').format('YYYY-MM-DD HH:mm')
  const today_end = moment().endOf('day').format('YYYY-MM-DD HH:mm')
  const weekStart = moment().startOf('week').format('YYYY-MM-DD HH:mm');
  const weekEnd = moment().endOf('week').format('YYYY-MM-DD HH:mm');
  const monthStart = moment().startOf('month').format('YYYY-MM-DD HH:mm')
  const monthEnd = moment().endOf('month').format('YYYY-MM-DD HH:mm')

  const handleRangeSelection = (e) => {
    switch (e) {
      case "today":
        setSelectedRange(language?.today || 'Today');
        setTs_start(today_start);
        setTs_end(today_end);
        setRange('today')
        break;
      case "thisWeek":
        setSelectedRange(language?.this_week || 'This week');
        setTs_start(weekStart);
        setTs_end(weekEnd);
        setRange('week')
        break;
      case "thisMonth":
        setSelectedRange(language?.this_month || 'This Month');
        setTs_start(monthStart);
        setTs_end(monthEnd);
        setRange('month')
        break;
    }
  }

  return (
    <>
      <IconButton
        disableRipple={true}
        variant="text"
        aria-controls={open ? 'basic-menu' : undefined}
        onClick={handleClick}
      >
        <Typography variant="body2" color='primary' >
          { selectedRange ? selectedRange : (language?.choose_period || 'Choose period') }
        </Typography>
      </IconButton>
      
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <MenuItem onClick={ () => { handleRangeSelection("today"); handleClose() } }>
          {(language?.taday || 'Today')}
        </MenuItem>
        <MenuItem onClick={ () => { handleRangeSelection("thisWeek"); handleClose() } }>
          {(language?.this_week || 'This week')}
        </MenuItem>
        <MenuItem onClick={ () => { handleRangeSelection("thisMonth"); handleClose() } }>
          {(language?.this_month || 'This Month')}
        </MenuItem>
      </Menu>
    </>
  );
}
