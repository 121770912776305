import React, { useContext, useEffect, useState } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { useLazyQuery } from '@apollo/client';
import { getLanguage } from '../../utils/utils';
import { Box, Skeleton, Card } from '@mui/material';
import { Typography, } from '@material-ui/core'
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilter from '../../components/EntityFilters';
import NoData from '../../components/NoData';
import GrainSelector from '../../components/GrainSelector';
import TypeOfViewSelector from '../../components/TypeOfViewSelector';
import SinglePeriodChart from './components/Chart';
import { GET_SINGLE_PERIOD_DATA, GET_TARGETS  } from './queries';
import SinglePeriodShiftChart from './components/ChartShift';
import SelectASite from '../../components/SelectASite';

import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export default function SinglePeriod() {
  const language = getLanguage();
  const [isShift, setShift] = useState(false);
  const [isTeam, setTeam] = useState(false);
  const { ts_start, ts_end, enterprise, site, area, line, filterShift, grain, dateRange, filterSite, filterArea, filterLine, filterTeam, typeOfView } = useContext(VariablesContext);
  const [getProductionData, { data, error, loading }] = useLazyQuery(GET_SINGLE_PERIOD_DATA, {
    fetchPolicy: 'no-cache',
  });
  const [getTarget, { data:targetData, loading: targetLoading }] = useLazyQuery(GET_TARGETS, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let shifts = filterShift?.join();
    let teams = filterTeam?.join();
    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];
    let grainSize = grain;
    let nav_level = 'EQUIPMENT';
    if (dateRange === 'day' || dateRange === 'today') {
      grainSize = 'HOUR';
    }
    if (dateRange === 'week') {
      grainSize = 'DAY';
    }

    if (shifts) {
      setShift(true);
    } else {
      shifts = '';
      setShift(false);
    }

    if (teams) {
      setTeam(true);
    } else {
      teams = '';
      setTeam(false);
    }

    if (site) {
      if (area) {
        if (line) {
          nav_level = 'EQUIPMENT';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          nav_level = 'AREA';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        nav_level = 'SITE';
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    } else {
      nav_level = 'SITE';
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

    getProductionData({
      variables: {
        id_enterprise: enterprise,
        shifts: `${shifts}`,
        sites: `{${in_id_sites.toString()}}`,
        equipments: `{${in_id_equipments.toString()}}`,
        areas: `{${in_id_areas.toString()}}`,
        teams: `{${teams}}`,
        ts_start,
        ts_end,
        grain: grainSize,
        group_by_element: typeOfView
      }
    });

    getTarget({
      variables: {
        id_enterprise: enterprise,
        in_id_shifts: `${shifts}`,
        in_id_teams: `{${teams}}`,
        in_id_sites: `{${in_id_sites.toString()}}`,
        in_id_equipments: `{${in_id_equipments.toString()}}`,
        in_id_areas: `{${in_id_areas.toString()}}`,
        ts_start: ts_start,
        ts_end: ts_end,
        time_grain: grainSize,
        nav_level,
        group: 'SHIFTS'
      }
    })

  }, [enterprise, site, area, line, ts_start, ts_end, filterShift, filterSite, filterArea, filterLine, grain, filterTeam, typeOfView]);

  return (
    <Box sx={{ pr: 3, pl: 3 }}>
      <Breadcrumb />

      <Box sx={{ m: { xs: 0, sm: 3 }, display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h2'> {language?.single_period || 'Single Period'} </Typography>
          {site ? <EntityFilter hideSector hideTeam /> : <></>}
        </Box>
        {site ?
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'center', md: 'space-between' }, mt: { xs: 2, lg: 0 } }}>
            <TypeOfViewSelector />
            <Box sx={{ mt: { xs: 4, sm: 0 } }}>
              {
                // (dateRange === 'month' || dateRange === 'last month') && <GrainSelector />
                <GrainSelector />
              }
            </Box>
          </Box>
          : <></>}
      </Box>

      {site ?
        loading ? <Skeleton variant='rect' height={600} /> :
          <Card sx={{
            mb: 2, my: 4, display: 'flex',
            // flexDirection: 'column',
            overflowX: { xs: 'scroll', md: 'visible' },
            height: '600px', width: '100%'
          }} elevation={16} >

            {
              (data?.h_piot_single_period_with_teams_3?.length) ?

                (typeOfView != 'GENERAL'
                  ? <SinglePeriodShiftChart data={data?.h_piot_single_period_with_teams_3} dateRange={dateRange} />
                  : <SinglePeriodChart data={data?.h_piot_single_period_with_teams_3} target={targetData?.h_piot_get_targets} dateRange={dateRange} />)

                : <Box sx={{ width: '300px', mt: 10 }}>
                  <NoData message={language?.no_data || 'No data'} />
                </Box>
            }

            {typeOfView == 'GENERAL' &&
              <Box sx={{
                display: 'flex',
                justifyContent: { xs: 'space-around', sm: 'center' },
                alignItems: 'center', flexWrap: 'wrap',
                mt: 2,
                ml: { xs: 2, sm: '150px', md: '220px', lg: '410px', xl: '650px' },
                position: 'absolute',
                mt: '520px'
              }}>
                <Box sx={{ display: 'flex', }}>
                  <CircleIcon sx={{ color: '#407CCC', fontSize: 20, mr: 1 }} />
                  <Typography variant="body2"> {language?.quantity_produced || 'Quantity Produced'} </Typography>
                </Box>
                <Box sx={{ display: 'flex', mx: 2 }}>
                  <MoreHorizIcon sx={{ mr: 1 }} />
                  <Typography variant="body2"> {language?.average || 'Average'} </Typography>
                </Box>
                <Box sx={{ display: 'flex', }}>
                  <HorizontalRuleIcon sx={{ color: '#318F29', fontSize: 30, mr: 1 }} />
                  <Typography variant="body2" > {language?.production_goal || 'Production Goal'} </Typography>
                </Box>
              </Box>}

          </Card>
        : <SelectASite />
      }

    </Box >
  );
}