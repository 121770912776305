import React, { useState } from 'react';
import { Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Button, Box, Skeleton  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { getLanguage } from '../../../utils/utils';
import JobList from './JobList';
import { GET_JOB_LIST } from '../queries';
import { useQuery } from '@apollo/client';

function ModalEdit({ params, isOpen, handleClose, handleChange }) {
    const language = getLanguage();
    const [search, setSearch] = useState('');
    const [jobSelected, setJob] = useState({});

    const { data, loading, error } = useQuery(GET_JOB_LIST, 
        { 
            variables: { nm_equipment: params?.row?.nm_equipment },
            fetchPolicy: 'cache-first',
            skip: (params?.row?.nm_equipment) ? false : true
        }
    )

    const inputHandler = (e) => {
        setSearch(e.target.value.toLowerCase());
      };

    const handleSelect = (item) => {
        setJob(item);
    }


  return (
    <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle sx={{ pl: 2, pt: 3 }}>
            <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: '22px', color: '#4D4D4D', width: '90%' }}>
               {language?.select_job_replace || 'Select a po to replace'} {params?.row?.id_order}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pr:2, pl:2, width: {xs: '100%', md: 492}, height: 350, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
            <TextField sx={
                { width: '100%', mt: 2, height: '38px', '& .MuiOutlinedInput-input': { height: '10px' }, '& .MuiInputLabel-root': { top: '-5px' } }
            } 
            id="outlined-search" label={`${language?.search_job || "Search a production order"}`} type="search"
                onChange={inputHandler}
                InputProps={{
                    endAdornment: <InputAdornment position="end"> <SearchIcon /> </InputAdornment>,
                }}
            />
                              
            {
                (loading) ? 
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                    <Skeleton sx={{ mt: 1 }} width="100%" variant="rectangular" />
                    <Skeleton sx={{ mt: 1 }} width="100%" variant="rectangular" />
                    <Skeleton sx={{ mt: 1 }} width="100%" variant="rectangular" />
                    <Skeleton sx={{ mt: 1 }} width="100%" variant="rectangular" />

                </Box> 
                
                : <JobList data={data?.production_orders} search={search} handleSelect={handleSelect} /> 
            }
        </DialogContent>

        <Divider />

        <DialogActions sx={{ p:2, justifyContent: {xs: 'center', sm: 'flex-end'}  }}>
            <Button 
                sx={{ mr: {xs: 0, sm: '24px'}, borderRadius:2, px:3, border: '2px solid #407CCC', color: '#000', background: '#FFF', '&:hover' : { background: '#EAEAED' } }}
                variant="contained" disableElevation onClick={handleClose }
                size="small"
            >
                {language?.cancel || 'Cancel'}
            </Button>
                        
            <Button sx={{ borderRadius:2, px:4 }} size="small" variant="contained" onClick={() => handleChange(jobSelected)}> 
                {language?.edit || 'Edit'}
            </Button>
        </DialogActions>
    </Dialog>
  );
}

export default ModalEdit;