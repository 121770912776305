import React, {useContext} from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment-timezone';
import { roundNumber, getLanguage, dateFormat } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';

import { TooltipContainer, TooltipColumn, TooltipItems, TooltipTitle } from '../styles';
import { scrapCalc } from '../../../configurations/scrap';

const ScrapPeriodTooltip = ({ active, payload, label, chartData, dateRange }) => {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');
  const { timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  let currentDot = chartData?.filter((item) => item?.ts_value_production === label);
  if (!active) return null;
  currentDot = currentDot?.[0] || {};
  if (currentDot?.net === null) return null;
  if (moment(currentDot?.ts_value_production).isAfter(moment(new Date()))) return null;

  let showIncomplete = false;
  let displayDate = '';

  if (dateRange === 'day' || dateRange === 'today') {
    displayDate = dateFormat(label, {hour: "2-digit", timeZone: 'CLIENT'});
  } else {
   displayDate = dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC'});
  }

  if (moment(currentDot.ts_value_production).minutes() !== 0 || moment(currentDot.ts_value_production).seconds() !== 0) {
    showIncomplete = true;
  }

  let toGoal = 0;
  let periodProduction = roundNumber(currentDot?.net);
  let scrapPercent = scrapCalc(scrapType, currentDot?.gross, currentDot?.net)

  if (currentDot?.net <= currentDot?.target) {
    toGoal = roundNumber(currentDot?.target - currentDot?.net);
  } else {
    toGoal = roundNumber(currentDot?.net - currentDot?.target);
    toGoal = `+${toGoal}`;
  }

  return (
    <TooltipContainer >
      <Box sx={{ display: 'flex', height: '50px', width: '100%' }}>
        <TooltipTitle>
          {displayDate}
          <span> {showIncomplete ? `(${language?.inprogress || 'In progress'})` : ''} </span>
        </TooltipTitle>

      </Box>

      <TooltipColumn>
        <TooltipItems>
          <Typography sx={{ color: '#4D4D4D' }} >{language?.scrap || 'Scrap'}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {scrapPercent}% </Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#4D4D4D', opacity: 0.5 }}>({roundNumber(currentDot?.scrap?.toFixed(1))})</Typography>
        </TooltipItems>

        <TooltipItems>
          <Typography sx={{ color: '#4D4D4D' }}>{language?.period_production || 'Period Production'}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {periodProduction} </Typography>
          {/* <Typography sx={{ visibility: 'hidden', fontWeight: 'bold', fontSize: '16px', color: '#318F29' }}> 30</Typography> */}
        </TooltipItems>
      </TooltipColumn>

    </TooltipContainer>
  )

};
export default ScrapPeriodTooltip;
