import React ,{ useEffect, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { VariablesContext } from '../Context/VariablesContext';
import { Box, } from '@mui/material';

const AuthGuard = (props) => {
  const { children } = props;
  const { initialValues, } = useContext(VariablesContext);
  let location = useLocation();

  const logged = localStorage.getItem('@packiot4:token');

  useEffect(() => {
    initialValues();
  }, []);

  if ( !logged  ) {
    return <Navigate to='/login' />;
  }

  return (
    <Box sx={{backgroundColor:"#f1f3f5", minHeight: '100%' }}>
      { location.pathname.includes('verview') ? <Outlet /> : children }
    </Box>
  );
};

export default AuthGuard;
