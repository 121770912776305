import React, { useContext } from 'react';
import { Typography, Box, Card } from '@mui/material';
import moment from 'moment-timezone';
import { roundNumber, getLanguage, userLocale, dateFormat } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';

const Tooltipp = ({ active, payload, label, chartData, dateRange }) => {
  const language = getLanguage();
  const { timezone } = useContext(VariablesContext);
  timezone ? moment.tz.setDefault(timezone) : null;

  let currentDot = chartData?.filter((item) => item?.net === label);
  if (!active) return null;
  currentDot = currentDot?.[0] || {};
  if (currentDot?.net === null) return null;
  if (moment(currentDot?.net).isAfter(moment(new Date()))) return null;

  let displayDate = '';

  if (dateRange === 'day' || dateRange === 'today') {
    displayDate = dateFormat(label, {hour: "2-digit", timeZone: 'CLIENT'});
  } else {
   displayDate = dateFormat(label, { weekday: "long", month: "2-digit", day: "2-digit", timeZone: 'UTC'});
  }

  let value = roundNumber(payload[0].value.toFixed(1));

  return (
    <Card sx={{ display: 'flex',flexDirection:'column', alignContent: 'center', justifyContent:'center',
     height: '150%', width: '110%', px: 1, py: 2
     }}>
      <Typography sx={{ color: '#4D4D4D', ml:1, fontWeight: 'bold', textTransform: 'capitalize' }}> {displayDate}  </Typography>
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#407CCC', mx:'auto'}}>{language?.avg || 'Avg'}: {value} un/min </Typography>
    </Card>
  )

};
export default Tooltipp;
