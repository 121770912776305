import { gql } from '@apollo/client';

export const GET_PRODUCTION_ORDERS = gql`
query GET_PRODUCTION_ORDERS($equipments: [Int!]) {
  production_orders(where: {equipment: {id_equipment:{_in: $equipments}}, status: {_eq: 1}}, order_by: {ts_creation: desc}) {
    available_time
    conversion_factor
    equipment_setup
    gross_production
    id_equipment_executed
    id_order
    id_order_text
    id_product
    id_user_operator
    ideal_production
    ideal_production_speed
    id_production_order
    net_production
    oee
    oee_availability
    oee_performance
    oee_quality
    planned_downtime
    production_final
    production_ordered
    production_real
    qt_stops
    running_time
    speed
    stopped_time
    ts_creation
    ts_end
    ts_end_tz
    ts_start
    ts_start_tz
    txt_production_order_description
    txt_production_order_notes
    production_programmed
    Sites {
      nm_site
    }
    Area {
      nm_area
    }
    client {
      nm_client
    }
    equipment {
      nm_equipment
    }
    product {
      nm_product
      cd_product
      txt_product
      scrap_target
      equipment_setup
      product_family {
        id_enterprise
        nm_product_family
        id_product_family
      }
    }
  }
}
`;

export const DELETE_PO = gql`
mutation MyMutation($id_production_order: [bigint!] ) {
  delete_production_orders(where: {id_production_order: {_in: $id_production_order}}) {
    affected_rows
  }
}
`;
