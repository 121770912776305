import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';
import Fab from '@mui/material/Fab';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import './style.css'

import api from '../../services/api';

export default function ReportsPowerBI(props) {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [error, setError] = useState(false);

  let { dataset, reportId } = useParams();

  let temp_URL = "";
  let temp_token = "";

  useEffect(() => {
    if (reportId) {
      api.post('/getEmbedToken', { reportId: reportId })
        .then((response) => {
          setEmbedUrl(response?.data?.embedUrl?.[0]?.embedUrl)
          setToken(response?.data?.accessToken)
          temp_URL = response?.data?.embedUrl?.[0]?.embedUrl;
          temp_token = response?.data?.accessToken;
        })
        .catch((error) => {
          setError(true)
        })
    }
  }, [reportId]);

  if (token != null && embedUrl != null) {
    const embedConfig = {
      type: 'report',   // Supported types: report, dashboard, tile, visual and qna

      id: reportId,
      embedUrl: embedUrl,
      accessToken: token,

      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          },
          pageNavigation: {
            visible: true
          }
        },
        background: models.BackgroundType.Transparent,
        navContentPaneEnabled: false
      }
    }
    return (
      <div class="container">
        <div class="row">
          <div class="col-12">
            {embedUrl ? <PowerBIEmbed
              embedConfig={embedConfig}
              eventHandlers={
                new Map([
                  ['loaded', function () { console.log('Report loaded'); }],
                  ['rendered', function () { console.log('Report rendered'); }],
                  ['error', function (event) { console.log(event.detail); }]
                ])
              }
              cssClassName={"embedded-report-container"}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            /> : <></>}
          </div>
        </div>
        <Fab color="primary" aria-label="refreshDataset"
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16
          }
          }
          onClick={() => {
            api.post('/refreshDataset', { reportId: reportId, dataset: dataset })
          }}>
          <AutorenewIcon />
        </Fab>
      </div>
    );
  }
  else {
    return <Loading />
  }
}