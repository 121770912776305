import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@material-ui/core';

import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/749302-select.json';

function SelectASite() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            //preserveAspectRatio: "xMaxYMax slice"
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100vh' }}>
            <Lottie options={defaultOptions} height={320} width={320} />
            <Box sx={{ pr: 3, pl: 3, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ color: '#407CCC', textAlign: 'center' }} variant='h3'>Please select a site to see this content!</Typography>
            </Box>
        </Box>
    );
}

export default SelectASite;