import React, { } from 'react';
import { TooltipDowntimeReasons as Tooltip } from './TooltipDowntimeReasons';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Card, CardContent, } from '@mui/material';
import { Typography, } from '@material-ui/core';

export default function Resume({ data, translate }) {
  const size = 2000

  function groupBy(array, f) {
    let groups = {};
    array?.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  let dado = data?.h_piot_get_downtimes_per_category?.[0]?.downtimes_per_category;

  let totalTime = dado?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);

  // DADOS DO CHANGEOVERS
  const changeOversList = dado?.filter(element => element?.change_over === true);

  let totalTimeChangeOvers = changeOversList?.map(element => element?.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccChangeovers = changeOversList?.map(element => element?.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgChangeovers = Math.round(totalTimeChangeOvers / OccChangeovers)
  let tooltipListaChangeOver = changeOversList;
  tooltipListaChangeOver?.forEach(element => { element.name = element.cd_category });

  // DADOS DE LISTA PLANEJADA
  const plannedList = dado?.filter(element => element.planned_downtime === true && element?.change_over === false);

  let totalTimePlanned = plannedList?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccPlanned = plannedList?.map(element => element.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgPlanned = Math.round(totalTimePlanned / OccPlanned)
  let tooltipListaPlanned = plannedList;
  tooltipListaPlanned?.forEach(element => { element.name = element.cd_category });

  // // LISTA SEM REPETIÇÃO DE DADOS PLANEJADOS
  // let listPlannedClean = [];
  // var projectIguaisPlanejados = groupBy(tooltipListaPlanned, function(item) {
  //   return [item.cd_category];
  //   }).map((data) => {
  //   return {
  //     duration_total: data.reduce((total, valor) => total + valor.duration_total, 0),
  //     num_occurence: data.reduce((total, valor) => total + valor.num_occurence, 0),
  //     avg_time: data.reduce((total, valor) => total + valor.avg_time, 0),
  //     itens: data
  //   };
  //  });

  //  for (var i in projectIguaisPlanejados) {
  //   let projetosPlanejados = {
  //     cd_category: projectIguaisPlanejados[i].itens[0].cd_category ,
  //     duration_total: projectIguaisPlanejados[i].duration_total,
  //     num_occurence: projectIguaisPlanejados[i].num_occurence,
  //     avg_time: projectIguaisPlanejados[i].avg_time,
  //   }
  //   listPlannedClean.push(projetosPlanejados);
  // }

  // DADOS DE LISTA NÃO PLANEJADA

  const unplannedList = dado?.filter(element => element.planned_downtime === false && element?.change_over === false);

  let totalTimeUnplanned = unplannedList?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccUnplanned = unplannedList?.map(element => element.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgUnplanned = Math.round(totalTimeUnplanned / OccUnplanned)
  let tooltipListaUnplanned = unplannedList;
  tooltipListaUnplanned?.forEach(element => { element.name = element.cd_category });

  // // LISTA SEM REPETIÇÃO DE DADOS NÃO PLANEJADOS
  let listUnplannedClean = [];
  var projectIguaisUnplanned = groupBy(tooltipListaUnplanned, function (item) {
    return [item.cd_category];
  }).map((data) => {
    return {
      duration_total: data.reduce((total, valor) => total + valor.duration_total, 0),
      num_occurence: data.reduce((total, valor) => total + valor.num_occurence, 0),
      avg_time: data.reduce((total, valor) => total + valor.avg_time, 0),
      itens: data
    };
  });

  for (var i in projectIguaisUnplanned) {
    let projetosUnplanneds = {
      cd_category: projectIguaisUnplanned[i].itens[0].cd_category,
      duration_total: projectIguaisUnplanned[i].duration_total,
      num_occurence: projectIguaisUnplanned[i].num_occurence,
      avg_time: projectIguaisUnplanned[i].avg_time,
    }
    listUnplannedClean.push(projetosUnplanneds);
  }

  // DADOS DO GERAIS

  const planned = totalTimePlanned ? (totalTimePlanned / totalTime) * 100 : 0;
  const unplanned = totalTimeUnplanned ? (totalTimeUnplanned / totalTime) * 100 : 0;
  const changeovers = totalTimeChangeOvers ? (totalTimeChangeOvers / totalTime) * 100 : 0;

  return (
    <Card>
      <Box sx={{ mt: 2, ml: 3, mb: -1 }}>
        <Typography variant="h4"> {translate?.downtime_ranking ?? `Downtime's Ranking`} </Typography>
      </Box>
      <CardContent >
        <Box sx={{ maxWidth: size, minHeight: 43, display: 'flex', alignItems: 'center' }}>

          {Math.round(planned) !== 0 ?
            <Tooltip
              translate={translate}
              name={translate?.planned || "Planned"}
              totalTime={totalTimePlanned}
              occ={OccPlanned}
              avg={AvgPlanned}
              // lista={listPlannedClean}
              lista={tooltipListaPlanned}
            >
              <Box sx={{
                display: 'grid',
                width: isNaN(planned) || Math.round(planned) === 0 ? 0
                  : Math.round(planned) <= 10 ? 10 * size
                    : Math.round(planned) * size,
              }} >
                <Box sx={{ mx: 'auto' }} >
                  <Typography variant="subtitle2" >
                    {planned.toFixed(1)}%
                  </Typography>
                </Box>
                <Box sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor: '#0984E3',
                  borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
                }}> </Box>
              </Box>
            </Tooltip>
            : <></>}

          {Math.round(unplanned) !== 0 ?
            <Tooltip
              translate={translate}
              name={translate?.unplanned || "Unplanned"}
              totalTime={totalTimeUnplanned}
              occ={OccUnplanned}
              avg={AvgUnplanned}
              // lista={listUnplannedClean}
              // lista={tooltipListaUnplanned}
              lista={unplannedList}
              placement="bottom-start"
            >
              <Box sx={{
                display: 'grid',
                width: isNaN(unplanned) || Math.round(unplanned) === 0 ? 0
                  : Math.round(unplanned) <= 10 ? 10 * size
                    : Math.round(unplanned) * size,
              }} >
                <Box sx={{ mx: 'auto' }} >
                  <Typography variant="subtitle2">
                    {unplanned.toFixed(1)}%
                  </Typography>
                </Box>

                <Box
                  sx={{
                    height: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center', backgroundColor: '#EB5757',
                    borderBottomRightRadius: Math.round(changeovers) === 0 ? 10 : 0,
                    borderTopRightRadius: Math.round(changeovers) === 0 ? 10 : 0,
                    borderTopLeftRadius: Math.round(planned) === 0 ? 10 : 0,
                    borderBottomLeftRadius: Math.round(planned) === 0 ? 10 : 0,
                  }}> </Box>
              </Box>
            </Tooltip>
            : <></>}

          {Math.round(changeovers) !== 0 ?
            <Tooltip
              translate={translate}
              name={translate?.change_overs || "Change Overs"}
              totalTime={totalTimeChangeOvers}
              occ={OccChangeovers}
              avg={AvgChangeovers}
              lista={tooltipListaChangeOver}
            >
              <Box sx={{
                display: 'grid',
                width: isNaN(changeovers) || Math.round(changeovers) === 0 ? 0
                  : Math.round(changeovers) <= 10 ? 10 * size
                    : Math.round(changeovers) * size,
              }} >
                <Box sx={{ mx: 'auto' }} >
                  <Typography variant="subtitle2">
                    {Math.round(changeovers) !== 0 ? changeovers.toFixed(1) + "%" : ""}
                  </Typography>
                </Box>
                <Box sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor: '#E9CD3D',
                  borderTopRightRadius: 10, borderBottomRightRadius: 10,
                }}> </Box>
              </Box>
            </Tooltip>
            : <></>}

        </Box>
      </CardContent>

      <CardContent sx={{ display: 'flex', justifyContent: 'space-around', mt: -.8, flexWrap: 'wrap' }}>

        {Math.round(planned) !== 0 &&
          <Box sx={{ display: 'flex' }}>
            <CircleIcon sx={{ color: '#0984E3', }} />
            <Typography color="text.secondary" variant="body2"> {translate?.planned || "Planned"} </Typography>
          </Box>}

        {Math.round(unplanned) !== 0 &&
          <Box sx={{ display: 'flex' }}>
            <CircleIcon sx={{ color: '#EB5757' }} />
            <Typography color="text.secondary" variant="body2"> {translate?.unplanned || "Unplanned"} </Typography>
          </Box>
        }

        {Math.round(changeovers) !== 0 &&
          <Box sx={{ display: 'flex' }}>
            <CircleIcon sx={{ color: '#E9CD3D' }} />
            <Typography color="text.secondary" variant="body2"> {translate?.change_over || "Changeovers"} </Typography>
          </Box>
        }

      </CardContent>

    </Card>
  );
}
