import { roundNumber } from '../../../utils/utils';
import CircleIcon from '@mui/icons-material/Circle';
import { Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ComposedChart, LabelList } from 'recharts';
import { Box, Paper } from '@mui/material';
import { Typography } from '@material-ui/core';

const DataFormater = (number) => {
  return roundNumber(number)
}
export default function ChartAggMonth({ runtimeMonth }) {
  const data = [runtimeMonth];

  return (
    <Paper sx={{ display: 'grid', flexGrow: 1, mr: 1, mt: 1, }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
        <Typography variant="h4"> {data?.[0]?.equipment?.nm_equipment} | Planejado X Realizado </Typography>
      </Box>

      <ResponsiveContainer aspect={5} width="100%" height="100%">
        <ComposedChart
          layout="vertical" width={500} height={100} data={data}
          margin={{ top: 20, right: 35, left: -30, bottom: 0 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" tickFormatter={DataFormater} fontSize={20} fontWeight={700} fontFamily={'Source Sans Pro'} />
          <YAxis dataKey="ts_value" type="category" scale="band" />
          <Bar dataKey="target" fill="#00BE62" barSize={15} radius={[0, 50, 50, 0]} isAnimationActive={false}>
            <LabelList offset={5} dataKey="target" position="right"
              fontSize={20} fontWeight={700} fontFamily={'Source Sans Pro'} />
          </Bar>
          <Bar dataKey="net" fill="#1F608A" barSize={15} radius={[0, 50, 50, 0]} isAnimationActive={false}>
            <LabelList offset={5} dataKey="net" position="right"
              fontSize={20} fontWeight={700} fontFamily={'Source Sans Pro'} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }}>
        <Box style={{ display: 'flex', alignItems: 'center', }} >
          <CircleIcon sx={{ color: '#00BE62' }} />
          &nbsp;&nbsp;
          <Typography variant="body1"> Planejado ACM </Typography>
        </Box>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Box style={{ display: 'flex', alignItems: 'center', }} >
          <CircleIcon sx={{ color: '#1F608A' }} />
          &nbsp;&nbsp;
          <Typography variant="body1" > Realizado ACM </Typography>
        </Box>
      </Box>

    </Paper >
  );
}
