import React, { createContext, useState, useEffect, } from "react"
import { useNavigate } from 'react-router-dom';
import { client } from "../services/graphqlConnection";
import { sendPasswordResetEmail, signInWithEmailAndPassword, onAuthStateChanged, signOut, confirmPasswordReset, verifyPasswordResetCode, OAuthProvider, signInWithPopup } from "firebase/auth";
import api from '../services/api';
import { auth } from '../firebase'

const provider = new OAuthProvider('microsoft.com');

provider.setCustomParameters({
  prompt: "consent",
  // Target specific email with login hint.
  login_hint: 'user@enterprise.com',
  // "the tenant id provided by outlook",
  tenant: 'a7109315-9727-4adf-97ad-4849bb63edcb',
})

const AuthContext = createContext();

function AuthProvider(props) {
  const { children } = props;
  const navigate = useNavigate();
  const [userError, setUserError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [sendEmail, setSendEmail] = useState(null);
  const [logged, setLogged] = useState(null);
  const [currentUser, updateCurrentUser] = useState({ stsTokenManager: { accessToken: '' } });

  useEffect(() => {
    auth.onAuthStateChanged((currentState) => {
      if (currentState) {
        updateCurrentUser({ ...currentState.toJSON(), state: auth.currentUser });
        localStorage.setItem("id_user_firebase", auth?.currentUser?.uid);
        auth?.currentUser?.getIdToken().then((token) => { localStorage.setItem('@packiot4:token', token); });
      }

      if (!currentState) {
        if (client) { client.resetStore() }
      }

      setLogged(true);
    });
  }, [auth]);

  onAuthStateChanged(auth, (currentUser) => {
    updateCurrentUser(currentUser);
    auth?.currentUser?.getIdToken().then((token) => { localStorage.setItem('@packiot4:token', token); });
  });

  const getDataOthersUsers = async (accessToken, tokenSts) => {
    await api.post('/api/users/external', {
      externalAcessToken: accessToken,
      firebaseAcessToken: tokenSts
    })
      .then((res) => {
        console.log(res.data)
        if (res.data == "already-exists") {
          setLogged(true)
          navigate('/home');
          location.reload();
        }
      })
      .catch((err) => {
        console.log(err)
      })
  };

  // Login pela Microsoft AC
  const othersAuth = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        localStorage.setItem('@packiot4:token', accessToken);

        getDataOthersUsers(accessToken, result?.user?.stsTokenManager?.accessToken);
      })
      .catch((error) => {
        console.log(error)
        // Handle error.
      });
  }


  const login = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        setLogged(true);
        // if (urlParams.redirect) {
        //   navigate(`/${urlParams.redirect}`);
        // } else {
        navigate('/home');
        // }
        localStorage.setItem('@packiot4:token', response.user.accessToken);
      })
      .catch(error => {
        if (error?.code === 'auth/user-not-found') {
          setUserError(true);
        } else {
          setUserError(false);
        }
        if (error?.code === 'auth/wrong-password') {
          setPassError(true);
        } else {
          setPassError(false);
        }
        console.error("Error signing in with password and email", error);
      });

  };

  const logout = () => {
    signOut(auth).then(() => {
      console.log("LOG OUT");
      setLogged(false);
      localStorage.clear();
    }).catch((error) => {
      // An error happened.
    });
  }

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then((value) => setSendEmail('sent'))
      .catch((error) => {
        setSendEmail('error');
      })
  }

  const updatePassword = async (code, password) => {
    const update = await confirmPasswordReset(auth, code, password).then(() => true)
      .catch((error) => { console.log(error); return false; });

    return update;
  }

  const verifyCode = async (code) => {
    const isValid = await verifyPasswordResetCode(auth, code)
      .then(() => true)
      .catch((error) => { console.log(error); return false; })

    return isValid;
  }

  const value = {
    logged: logged,
    user: currentUser,
    userError,
    passError,
    logout,
    login,
    resetPassword,
    sendEmail,
    updatePassword,
    verifyCode,
    othersAuth,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
