import React, { useState, useEffect, useContext } from 'react';
import { getLanguage } from '../../../../utils/utils';
import { VariablesContext } from '../../../../Context/VariablesContext'
import { AuthContext } from '../../../../Context/AuthContext'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Grid, Box, Typography, } from '@material-ui/core'
import { Button, Tab, Tabs, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api'

import TableUser from './components/Users/tableUser';
import TablePermissions from './components/Permissions/tablePermissions';
import ModalCreateUser from './components/Users/modalCreateUser';
import ModalCreateRole from './components/Permissions/modalCreateRole';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
    marginTop: -15,
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 90,
    width: '90%',
    backgroundColor: '#407CCC',
  },
});

export default function UsersAndPermissions() {
  const language = getLanguage();
  const { enterprise, } = useContext(VariablesContext);
  const idEnterprise = localStorage.getItem("id_enterprise");
  const user = useContext(AuthContext);
  const token = localStorage.getItem("@packiot4:token")
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openCreateRole, setOpenCreateRole] = useState(false);
  const [value, setValue] = useState(1);
  const [dataUser, setDataUser] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const [pages, setPages] = useState([]);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function getUsers() {
    try {
      const response = await api.get('/api/admin/users');
      if (response) setDataUser(response.data);
    } catch (error) {
      console.error(error);
      setError(true);
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  }

  async function getRoles() {
    try {
      const response = await api.get('/api/admin/user-roles');
      if (response) {
        setDataRole(response.data);
      }
    } catch (error) {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  }

  
  const getPages = async () => {
    try {
      const response = await api.get('/api/admin/pages');
      if (response.data) {
        setPages(response.data);
      } 
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${language?.an_error_occured || 'Oops! an error occured'}`, { variant: 'error' });
    }
  }

  const handleCreateUser = async (userInfo) => {
    try {
      const response = await api.post('/api/admin/users', {
        email: userInfo.email,
        name: userInfo.userName,
        language: localStorage.getItem('user_language'),
        user_role: userInfo.role,
        password: userInfo.password
      });

      if (response) {
        enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
        updateData();
      }
    } catch (error) {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  }

  const handleCreateRole = async (role_info) => {
    console.log(role_info);
    try {
      const response = await api.post('/api/admin/user-roles', role_info);
      if (response) {
        enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
        getRoles();
      }
    } catch (error) {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    }
  }

  const updateData = () => {
    getUsers();
    getRoles();
  }
  
  
  useEffect(() => {
    getUsers();
    getRoles();
    getPages();

  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpenAddUser = () => {
    if (dataRole?.length) {
      setOpenCreateUser(!openCreateUser);
    } else {
      enqueueSnackbar(`${language?.create_role_first || 'You need to create a role first'}`, { variant: 'warning' })
    }
  };
  const handleClickOpenAddRole = () => {
    setOpenCreateRole(!openCreateRole);
  };

  return (
    <>
      <Box sx={{ mt: 3, mx: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'> {language?.user_and_permission || 'Users and Permissions'} </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'space-between' }, alignItems: 'center' }}>
        <StyledTabs value={value} onChange={handleChange} sx={{ ml: 1 }}>
          <Tab label={<Typography variant="h4" style={{ color: '#4D4D4D', textTransform: 'capitalize' }}> {language?.users || 'Users'} </Typography>} value={1} />
          <Tab label={<Typography variant="h4" style={{ color: '#4D4D4D', textTransform: 'capitalize' }}> {language?.permissions || 'Permissions'} </Typography>} value={2} />
        </StyledTabs>

        {value == 1
          ? <Button variant="contained" sx={{ height: '40px', textTransform: 'capitalize', mr: '30px' }} onClick={() => handleClickOpenAddUser()} >
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: 'medium', mr: 1 }} />
            <Typography variant="body1" > {language?.create_user || 'Create User'} </Typography>
          </Button>
          : <Button variant="contained" sx={{ height: '40px', textTransform: 'capitalize', mr: '30px' }} onClick={() => handleClickOpenAddRole()} >
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: 'medium', mr: 1 }} />
            <Typography variant="body1" > {language?.create_role || 'Create Role'} </Typography>
          </Button>}
      </Box>

      <ModalCreateUser 
        isOpen={openCreateUser}
        handleCreateUser={handleCreateUser}
        handleClose={() => { setOpenCreateUser(!openCreateUser) }}
        params={dataRole}
      />
      <ModalCreateRole 
        pages={pages}
        handleCreateRole={handleCreateRole} 
        isOpen={openCreateRole} 
        handleClose={() => { setOpenCreateRole(!openCreateRole) }} 
      />

      <Box >
        <TabPanel value={value} index={1} >
          <TableUser lista={dataUser} update={updateData} roles={dataRole} />
        </TabPanel>

        <TabPanel value={value}  index={2} >
          <TablePermissions update={updateData} pages={pages}  lista={dataRole} />
        </TabPanel>
      </Box>
    </>
  );
}
