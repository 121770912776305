import axios from 'axios';
import { auth } from '../firebase';

const api = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_URL || 'https://api4.packiot.com/'
  baseURL: 'https://api4.packiot.com/'
  // baseURL: 'http://localhost:3333/'
});

api.interceptors.request.use(
  async (config) => {
    if (!auth?.currentUser?.getIdToken()) await delay(2500);
    let token = await auth?.currentUser?.getIdToken().then((token) => token);
    if (token) {
      config.headers = {
        ...config.headers,
        token: `${token}`,
        uid: localStorage.getItem('id_user_firebase')
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

export default api;