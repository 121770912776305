import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { getLanguage, getDataGridConfig, setDataGridConfig } from '../../../../../utils/utils';
import { Box } from '@mui/material';

import CustomToolbar from './CustomToolbar';
import moment from 'moment';

function PoTable({ data, update, refetch, dataDownload }) {
  const language = getLanguage();
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const dataGridConfig = getDataGridConfig('po-settings');

  const columns = [
    {
      field: 'id_order',
      headerName: `${language?.order_id || 'Order ID'}`,
      flex: 1,
      minWidth: 110,
    },
    {
      field: 'nm_client',
      headerName: `${language?.client || 'Client'}`,
      flex: 1,
      minWidth: 150,
    }, {
      field: 'nm_product',
      headerName: `${language?.product || 'Product'}`,
      flex: 1,
      minWidth: 150,
    }, {
      field: 'cd_product',
      headerName: `${language?.cd_product || 'Product Code'}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'order_size',
      headerName: `${language?.order_size || 'Order Size'}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'nm_site',
      headerName: `${language?.site || 'Site'}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'nm_area',
      headerName: `${language?.area || 'Area'}`,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'nm_equipment',
      headerName: `${language?.line || 'Line'}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'added_in',
      headerName: `${language?.added_in || 'Added In'}`,
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => value && moment(value).format('DD/MM/YY HH:mm'),

    },
    // {
    //   field: 'total',
    //   headerName: `${language?.edit || 'Edit'}`,
    //   editable: false,
    //   flex: 1,
    //   minWidth: 150,
    // },

  ];

  useEffect(() => {

    if (data?.length) {
      const filteredData = data?.filter((item) => item?.status !== 1);
      setRows(filteredData);
    }

  }, [data])

  return (
    <>
      <Box sx={{ height: 740, width: '100%', }}>
        <DataGridPro
          sx={{ border: 'none' }}
          components={{ Toolbar: CustomToolbar }}
          rows={rows}
          columns={columns}
          onColumnVisibilityModelChange={(newModel) =>
            setDataGridConfig('po-settings', { filterName: 'columns', value: newModel })
          }
          pagination
          initialState={{
            pagination: {
              pageSize: dataGridConfig?.rowsPerPage
            },
            filter: {
              filterModel: {
                items: dataGridConfig?.filter,
              },
            },
            columns: {
              columnVisibilityModel: dataGridConfig?.columns
            }
          }}
          rowsPerPageOptions={[5, 10, 15, 50, 100]}
          onPageSizeChange={(newPageSize) => setDataGridConfig('po-settings', { filterName: 'rowsPerPage', value: newPageSize })}
          onFilterModelChange={(e) => setDataGridConfig('po-settings', { filterName: 'filter', value: e.items })}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const objs = rows?.filter((item) => ids?.includes(item?.id));
            setSelectedIDs(objs)
          }}
          componentsProps={{
            toolbar: {
              data: data,
              dataDownload: dataDownload,
              selectedIDs: selectedIDs,
              update: update,
            },

          }}
          localeText={{
            columnsPanelTextFieldLabel: `${language?.find_column || 'Find column'} `,
            columnsPanelTextFieldPlaceholder: `${language?.column_title || 'Column title'}`,
            columnsPanelShowAllButton: `${language?.show_all || 'Show all'}`,
            columnsPanelHideAllButton: `${language?.hide_all || 'Hide all'}`,
            toolbarColumns: `${language?.columns || 'Columns'}`,
          }}
        />
      </Box>
    </>
  );
}

export default PoTable;
