import React, { useEffect, useState, } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import LightTooltip from '../../../../../../components/LightTooltip';
import { Grid, Box, Typography, IconButton } from '@material-ui/core'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalDeleteRole from './modalDeleteRole';
import ModalEditRole from './modalEditRole';
import { getLanguage } from '../../../../../../utils/utils';

export default function TablePermissions({ lista, pages, update }) {
  const language = getLanguage();
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rowsData = lista?.map((item) => ({
      id: item.id_user_role,
      nm_user_role: item.nm_user_role,
      permissions: item.permissions
    }));
    setRows(rowsData)
  }, [lista]);

  const columns = [
    { field: 'nm_user_role', headerName: `${language?.role || 'Role'}`, flex:1 },
    {
      field: 'edit', headerName: `${language?.edit || 'Edit'}`, flex:1,
      renderCell: (params) => (
        <LightTooltip title={`${language?.edit || 'Edit'}`} placement='top'>
          <IconButton onClick={() => handleClickOpenEdit(params)} sx={{ cursor: 'unset' }}>
            <EditOutlinedIcon />
          </IconButton>
        </LightTooltip>
      )
    },
    {
      field: 'delete', headerName: `${language?.delete || 'Delete'}`, flex:1,
      renderCell: (params) => (
        <LightTooltip title={`${language?.delete || 'Delete'}`} placement='top'>
          <IconButton onClick={() => handleClickOpenDelete(params)} sx={{ cursor: 'unset' }}>
            <DeleteOutlineIcon />
          </IconButton>
        </LightTooltip>
      )
    },
  ];

  const handleClickOpenEdit = (params) => {
    setOpenEdit(!openEdit);
    setDataModal(params?.row);
  };

  const handleClickOpenDelete = (params) => {
    setOpenDelete(!openDelete);
    setDataModal(params?.row);
  };

  return (
    <>
      <ModalDeleteRole params={dataModal} update={update} isOpen={openDelete} handleClose={() => { setOpenDelete(!openDelete) }} />
      <ModalEditRole pages={pages} update={update} params={dataModal} isOpen={openEdit} handleClose={() => { setOpenEdit(!openEdit) }} />
      {/* <Box sx={{ height: '90%', width: '95%', mx: 'auto' }} > */}
        <Box sx={{ height: 740, width: '100%', }}>
        <DataGrid
          sx={{ border: 'none' }}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}
