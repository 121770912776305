import React, { useContext, } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { useQuery } from '@apollo/client';
import { GET_VARIABLES_CONTEXT } from '../../../Context/Query';
import { getLanguage } from '../../../utils/utils';

import { ReactComponent as More } from '../../../assets/More.svg';
import { Box, Divider, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Typography, } from '@material-ui/core';

const language = getLanguage();

export default function Line() {
  const { area, line, setLine } = useContext(VariablesContext);
  const { loading, error, data } = useQuery(GET_VARIABLES_CONTEXT);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLine = (idLine) => {
    setLine(idLine);
    handleClose();
  };

  let text = data?.v_entities_per_user_role?.[0]?.equipments.filter(item => item.id_equipment == line)?.[0]?.nm_equipment
  let home = location.pathname.toUpperCase().includes('HOME');
  let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  let temp = data?.v_entities_per_user_role?.[0]?.equipments?.filter(item => item.id_area == area);
  let sortItems = temp?.sort((a, b) => collator.compare(a.nm_equipment, b.nm_equipment));

  return (
    <>
      <IconButton
        disableRipple={true} color='primary'
        variant="text"
        aria-controls={open ? 'basic-menu' : undefined}
        onClick={handleClick}
      >
        <Typography variant={!home ? 'body1' : 'body2'} color={!home ? '#407CCC' : 'gray'}>
          {/* { text != undefined ? text : <More/> } */}
          {line ? text : (home ? language?.choose_line || 'Choose line' :
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <More />
            </Box>
          )}
        </Typography>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Typography variant="h3" > {language?.select_line || 'Select a line'} </Typography>
        </MenuItem>
        <Divider></Divider>

        {sortItems?.map((equipment, index_equipment) => {
          return (
            <MenuItem value={equipment.id_equipment} onClick={() => { handleChangeLine(equipment.id_equipment); }} >
              {equipment.nm_equipment}
            </MenuItem>
          )
        })}

      </Menu>

    </>
  );
}
