import React, { useState, useEffect } from 'react';
import { getLanguage, roundNumber } from '../../../utils/utils';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Cell, Label, Line, ComposedChart, LabelList } from 'recharts';
import { Box, Paper } from '@mui/material';
import { Typography } from '@material-ui/core';

export default function ChartProdMonth({ runtimeDay }) {
  const prodMonth = runtimeDay

  const language = getLanguage();
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });

  const DataFormater = (number) => {
    return roundNumber(number)
  }

  const CustomizedLabel = () => ({
    render() {
      const { x, y, value } = this.props;
      Number(value);
      let numb = value?.toString();
      return (
        <foreignObject
          x={x - (numb?.length * 5)}
          y={y - 25}
          width={numb?.length * 9}
          height="160"
          fontSize='14' fontWeight={700} fontFamily='Source Sans Pro'
        >
          <Box
            sx={{ backgroundColor: '#00BE62', display: 'flex', justifyContent: 'center', borderRadius: 1, color: '#FEFEFE' }}>
            <text>
              {value}
            </text>
          </Box >
        </foreignObject >
      )
    }
  });

  return (
    <Paper sx={{ mr: 1, mt: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
        <Typography variant="h4" >
          {prodMonth?.[0]?.equipment?.nm_equipment} | Acompanhamento da Produção de {language?.[month]}
        </Typography >
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          <CircleOutlinedIcon sx={{ color: '#00BE62', }} />
          &nbsp;
          <Typography variant="h4">Planejado Dia </Typography>
          &nbsp;&nbsp;
          <CircleIcon sx={{ color: '#1F608A', }} />
          &nbsp;
          <Typography variant="h4" > Realizado </Typography>
        </Box>
      </Box >

      <ResponsiveContainer aspect={2.8} width="100%" height="100%" >
        <ComposedChart
          width={1400} height={540} data={runtimeDay} barGap={0}
          margin={{ top: 30, right: 25, left: 5, bottom: 5 }}
          isAnimationActive={false}
        >

          <CartesianGrid strokeDasharray="1 1" vertical={false} />

          <XAxis dataKey="ts_value"
            tickFormatter={(el) => el && el?.slice(-2)}
            tick={{ stroke: '#2D3748', strokeWidth: .8, fontSize: '20' }} />

          <YAxis
            tickFormatter={DataFormater}
            yAxisId="left" orientation="left" stroke="#2D3748"
            tick={{ strokeWidth: .8, fontSize: '20', fontWeight: 700 }}>
          </YAxis>

          <Bar yAxisId="left" barSize={20} dataKey="net" radius={[25, 40, 0, 0]} fill={'#1F608A'}
            label={{
              fill: 'white', fontSize: 20, angle: 90,
              fontWeight: 700, fontFamily: 'Source Sans Pro'
            }} />

          <Line
            yAxisId='left' dataKey="target" stroke="#00BE62" strokeWidth={3} isFront={true} ifOverflow="extendDomain" isAnimationActive={false}
            label={<CustomizedLabel />} dot={true}
          />
        </ComposedChart>
      </ResponsiveContainer>

    </Paper >
  );
}
