import React, { useState, } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import LightTooltip from '../../../../../../components/LightTooltip';
import { Grid, Box, Typography, IconButton } from '@material-ui/core'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getLanguage, getDataGridConfig, setDataGridConfig } from '../../../../../../utils/utils';
import ModalDeleteUser from './modalDeleteUser';
import ModalEditUser from './modalEditUser';

export default function TableUser({ lista, roles, update }) {
  const language = getLanguage();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataModal, setDataModal] = useState();
  const dataGridConfig = getDataGridConfig('users');

  // id: 0, name: 'Rodolfino Junior', login: 'rodolfo@mail.com', role: 'Admin'
  const columns = [
    { field: 'user_name', headerName: `${language?.name || 'Name'}`, minWidth: 300 },
    { field: 'user_email', headerName: `Login`, minWidth: 300 },
    { field: 'nm_user_role', headerName: `${language?.role || 'Role'}`, minWidth: 200 },
    {
      field: 'edit', headerName: `${language?.edit || 'Edit'}`, minWidth: 150,
      renderCell: (params) => (
        <LightTooltip title={`${language?.edit || 'Edit'}`} placement='top'>
          <IconButton onClick={() => handleClickOpenEdit(params)} sx={{ cursor: 'unset' }}>
            <EditOutlinedIcon />
          </IconButton>
        </LightTooltip>
      )
    },
    {
      field: 'delete', headerName: `${language?.delete || 'Delete'}`, minWidth: 100,
      renderCell: (params) => (
        <LightTooltip title={`${language?.delete || 'Delete'}`} placement='top'>
          <IconButton onClick={() => handleClickOpenDelete(params)} sx={{ cursor: 'unset' }}>
            <DeleteOutlineIcon />
          </IconButton>
        </LightTooltip>
      )
    },
  ];

  const handleClickOpenEdit = (params) => {
    setOpenEdit(!openEdit);
    setDataModal(params?.row);
  };

  const handleClickOpenDelete = (params) => {
    setOpenDelete(!openDelete);
    setDataModal(params?.row);
  };

  return (
    <>
      <ModalDeleteUser update={update} params={dataModal} isOpen={openDelete} handleClose={() => { setOpenDelete(!openDelete) }} />
      <ModalEditUser roles={roles} update={update} params={dataModal} isOpen={openEdit} handleClose={() => { setOpenEdit(!openEdit) }} />

      <Box sx={{ height: 740, width: '100%', }}>
        <DataGrid
          sx={{ border: 'none' }}
          getRowId={(e)=> e.id_user }
          rows={lista}
          columns={columns}
          initialState={ {
            pagination: {
              pageSize: 50
            },
            filter: {
              filterModel: {
                items: dataGridConfig?.filter,
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 50, 100]}
          onFilterModelChange={(e) => setDataGridConfig('users', {filterName: 'filter', value: e.items}) }
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}
