import { Box } from '@mui/material';
import React from 'react';
import { Container, FlowItem, FlowContent, FlowHeader, FlowFooter, ChartDivider, ScrapTooltip, FlowIssue } from '../styles';
import { roundNumber, convertToDaysHours } from '../../../utils/utils';
import LightTooltip from '../../../components/LightTooltip';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { scrapCalc } from '../../../configurations/scrap'

function calculateSize(net, gross, reference) {
  let x = 0;
  let y = 0;

  x = gross / reference;
  y = net / reference;

  if (x > 1) x = 1;
  if (y > 1) y = 1;

  if (x < 0) x = 0;
  if (y < 0) y = 0;

  return { x, y }
}

function Flow({ data, language }) {
  const scrapType = localStorage.getItem("scrap_calc_type")
  const referenceValue = data?.[0]?.gross;
  let flowIssue = null;

  const FlowItems = (data)?.map((item, index, array) => {
    let scrap = item?.gross - item?.net;

    let x = calculateSize(item?.net, item?.gross, referenceValue)?.x;
    let y = calculateSize(item?.net, item?.gross, referenceValue)?.y;

    if (index > 0) {
      let previous = array[index - 1]?.net;
      let current = item?.net;
      if (current > previous) {
        flowIssue = `${language.flow_sensor_issue || 'Data may be unreliable due to problems with sensors or line configuration'}`;
      } else {
        flowIssue = null;
      }
    }

    return (
      <>
        {
          flowIssue &&
          <FlowIssue>
            <LightTooltip title={flowIssue} placement='top'>
              <Box sx={{ background: '#fff', height: '20px', zIndex: 1 }}>
                <ErrorOutlineOutlinedIcon sx={{ color: '#C12727' }} />
              </Box>
            </LightTooltip>
          </FlowIssue>
        }
        {flowIssue && <ChartDivider />}
        <FlowItem>

          <FlowHeader>
            <p>{item?.nm_machine}</p>
            <span>{language?.stopped_time || 'Stopped Time'}: {convertToDaysHours(item?.stopped_time ?? 0, 'seconds')}</span>
          </FlowHeader>

          <FlowContent>
            <Box
              sx={{
                background: 'linear-gradient(180deg, #407CCC 0%, rgba(65, 117, 245, 0.500772) 49.92%, rgba(65, 117, 245, 0) 100%)',
                width: '100%', height: '100%', position: 'relative',
                clipPath: `polygon( 0% calc(100% * (1 - ${x})), 100% calc(100% * (1 - ${y})), 100% 100%, 0% 100% )`
              }}
            />
            <ScrapTooltip>
              <p>{language?.scrap || 'Scrap'}</p>
              <span> {scrapCalc(scrapType, item?.gross, item?.net)} % </span>
            </ScrapTooltip>
          </FlowContent>

          <FlowFooter>
            <div>
              <p>{language?.production || 'Production'}</p>
              <span style={{ color: '#407CCC' }}> {roundNumber(item?.net)} </span>
            </div>
            <div>
              <p>{language?.scrap || 'Scrap'}</p>
              <span style={{ color: '#C12727' }}> {roundNumber(scrap)} </span>
            </div>
          </FlowFooter>

        </FlowItem>
        <ChartDivider />
      </>

    )
  });

  return (
    <Container>
      <Box sx={{ display: 'flex', width: '100%', height: '400px', overflowX: 'auto' }}>
        {FlowItems}
      </Box>
    </Container>
  );
}

export default Flow;