import React from 'react';
import { Box } from '@mui/material';
import DefaultTarget from './components/DefaultTarget';
import CustomTarget from './components/CustomTargets';

function Targets() {
  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} sx={{ p: 3 }}>
      <Box gridColumn="span 6">
        <DefaultTarget />
      </Box>
      <Box gridColumn="span 6">
          <CustomTarget />
      </Box>  
    </Box>
  );
}

export default Targets;