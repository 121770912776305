import { gql } from '@apollo/client';

export const GET_DOWNTIME_REASONS = gql`
query GET_DOWNTIME_REASONS($_eq: Int) {
  equipments(where: {id_equipment: {_eq: $_eq}}) {
    downtime_reasons
  }
}
`;

export const DOWNTIME_QUERIES = gql`
query GET_DOWNTIME_DATA($in_id_enterprise: Int, $in_ids_equipments: String = "{}", $in_ids_sectors: String = "{}", $in_ids_shifts: String = "{}", $in_ids_areas: String = "{}", $in_ids_sites: String = "{}", $_tsstart: timestamp, $_tsend: timestamp, $in_ids_teams: String = "{}", $sector_view: Boolean = false, $microstops_view: Boolean = false) {
  h_piot_get_downtimes_resumo(args: {in_id_enterprise: $in_id_enterprise, _tsend: $_tsend, _tsstart: $_tsstart, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_shifts: $in_ids_shifts, in_ids_sites: $in_ids_sites, in_ids_teams: $in_ids_teams}) {
    available_time
    duration_justified
    duration_microstops
    duration_planned
    duration_total
    duration_unplanned
  }
  h_piot_get_downtimes_per_category(args: {in_id_enterprise: $in_id_enterprise, _tsend: $_tsend, _tsstart: $_tsstart, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_shifts: $in_ids_shifts, in_ids_sites: $in_ids_sites, in_ids_teams: $in_ids_teams}) {
    downtimes_per_category
    id_enterprise
  }
  h_piot_get_downtimes_events:h_piot_get_downtimes_events_2(args: {in_id_enterprise: $in_id_enterprise, in_ids_sites: $in_ids_sites, in_ids_equipments: $in_ids_equipments, in_ids_sectors: $in_ids_sectors, in_ids_areas: $in_ids_areas, _tsend: $_tsend, _tsstart: $_tsstart, microstops_view: $microstops_view}) {
    nm_equipment
    sector
    id_equipment_event
    id_shift
    id_sector
    cd_category
    cd_machine
    cd_shift
    cd_subcategory
    duration
    id_enterprise
    id_equipment
    id_order
    ts_end
    ts_event
    txt_category
    txt_downtime_notes
    txt_subcategory
    manual_event
  }
}
`;

export const SHOULD_DISPLAY_EVENT_COLLUMN = gql`
query SHOULD_DISPLAY_EVENT_COLLUMN {
  equipments_aggregate(where: 
    { _and: [{event_should_be_displayed: {_eq: true}}, {_or:[{tp_equipment: {_eq: 2}}, {tp_equipment: {_eq: 1}}]}]}) {
    aggregate {
      count
    }
  }
}
`;