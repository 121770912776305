import React from 'react';
import { Box, Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { roundNumber, } from '../../../utils/utils.js';

export default function Produção({ runtimeShift, unsJob }) {
  const shifts = runtimeShift?.equipment_runtime_shift;
  const lineL1shifts = shifts?.filter((el) => el?.equipment?.id_equipment == 404)
  const lineL2shifts = shifts?.filter((el) => el?.equipment?.id_equipment == 411)

  return (
    <Paper sx={{ m: 1 }}>
      <Grid container spacing={1} sx={{ m: 1, pb: 2 }}>
        <Grid item xs={12} >
          <Typography variant="h4"> Produção </Typography>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          &nbsp;
          <Typography variant="body1" > Acumulado Dia </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Acumulado Dia L1</Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Acumulado Dia L2</Typography>
          <Typography variant="body1" style={{ marginTop: 14 }}> Acumulado Turno </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Acumulado Turno L1</Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Acumulado Turno L2</Typography>
          &nbsp;
          <Typography variant="body1" style={{ marginTop: 14 }}> Corrida Atual </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Quantidade Planejada </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Quantidade Produzida </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}> Saldo a Produzir </Typography>

        </Grid>

        <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>

          <Typography variant="body1" style={{ fontSize: 16, color: '#828282' }}> TOTAL </Typography>
          {/* Acumulado Dia */}
          <Typography variant="body2"> {shifts?.reduce((a, b) => a + b.net, 0)} </Typography>
          {/* Acumulado Dia L1 */}
          <Typography variant="body2"> {lineL1shifts?.reduce((a, b) => a + b.net, 0)} </Typography>
          {/* Acumulado Dia L2 */}
          <Typography variant="body2"> {lineL2shifts?.reduce((a, b) => a + b.net, 0)} </Typography>
          {/* Acumulado Turno */}
          <Typography variant="body2" style={{ marginTop: 5 }}> {lineL1shifts?.at(-1)?.net + lineL2shifts?.at(-1)?.net} </Typography>
          {/* Acumulado Turno L1 */}
          <Typography variant="body2"> {lineL1shifts?.at(-1)?.net} </Typography>
          {/* Acumulado Turno L2 */}
          <Typography variant="body2"> {lineL2shifts?.at(-1)?.net} </Typography>

          <Typography variant="body1" style={{ marginTop: 10, color: '#828282' }}> LINHA 1</Typography>
          {/* Corrida Atual L1*/}
          <Typography variant="body2">{unsJob?.uns_equipment_current_job?.[0]?.nm_product} </Typography>
          {/* Quantidade Planejada L1 */}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[0]?.production_programmed} </Typography>
          {/* Quantidade Produzida L1*/}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[0]?.net_production} </Typography>
          {/* Saldo a Produzir L1*/}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[0]?.production_programmed - unsJob?.uns_equipment_current_job?.[0]?.net_production} </Typography>

        </Grid>

        <Grid item xs={3} sx={{ display: 'grid', justifyContent: 'center' }}>

          <Typography variant="body1" style={{ fontSize: 16, color: '#828282' }}> BASE 64 </Typography>
          {/* Acumulado Dia */}
          <Typography variant="body2"> - </Typography>
          {/* Acumulado Dia L1 */}
          <Typography variant="body2"> - </Typography>
          {/* Acumulado Dia L2 */}
          <Typography variant="body2"> - </Typography>
          {/* Acumulado Turno */}
          <Typography variant="body2" style={{ marginTop: 10 }}> - </Typography>
          {/* Acumulado Turno L1 */}
          <Typography variant="body2"> - </Typography>
          {/* Acumulado Turno L2 */}
          <Typography variant="body2"> - </Typography>

          <Typography variant="body1" style={{ marginTop: 10, marginBottom: 10, color: '#828282' }}> LINHA 2</Typography>
          {/* Corrida Atual L2*/}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[1]?.nm_product} </Typography>
          {/* Quantidade Planejada L2 */}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[1]?.production_programmed}</Typography>
          {/* Quantidade Produzida L2*/}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[1]?.net_production} </Typography>
          {/* Saldo a Produzir L2*/}
          <Typography variant="body2"> {unsJob?.uns_equipment_current_job?.[1]?.production_programmed - unsJob?.uns_equipment_current_job?.[1]?.net_production} </Typography>
        </Grid>

      </Grid >
    </Paper >
  );
}



