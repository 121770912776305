import React , { useContext, useState, } from 'react';
import {
  Box, Menu, MenuItem, ListItemIcon, Divider, IconButton, Typography, Tooltip,
  Avatar,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { getLanguage } from '../../../utils/utils';
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import {VariablesContext} from '../../../Context/VariablesContext'
import { AuthContext } from '../../../Context/AuthContext'

export default function User() {
  const {clearContext} = useContext(VariablesContext);
  const { logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const language = getLanguage();
  const history = useNavigate();
  const [username, setUsername] = useState(localStorage.getItem('piot_username'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () =>  {
    history("/login");
    logout();
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2, mt:-2}}
      >
        <Avatar sx={{ width: 24, height: 24, }}>
          {username?.[0]}
        </Avatar>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {/* <MenuItem>
           Profile
        </MenuItem>
        <Divider /> */}
        
        <MenuItem onClick={handleLogout}>
          <ListItemIcon> <Logout fontSize="small" /> </ListItemIcon>
          Logout
        </MenuItem>

      </Menu>
    </>
  );
}
