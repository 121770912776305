import React from 'react';
import { Typography, Box } from '@mui/material';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { scrapCalc } from '../../../configurations/scrap';

export default function Card1({ dataShift, enterprise }) {
  const language = getLanguage();
  const scrapType = localStorage.getItem('scrap_calc_type');

  const howGood = (number, target) => {
    const quanto = (number * 100) / target;

    if (quanto == 99 || quanto == 101 || target === null || target === 0) {
      return <></>
    } else if (quanto > 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowUpwardRoundedIcon fontSize='large' sx={{ color: '#318F29', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#318F29', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"+" + (quanto - 100).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    } else if (quanto < 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <ArrowDownwardRoundedIcon fontSize='large' sx={{ color: '#EB5757', alignSelf: 'center', }} />
          <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#EB5757', }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"-" + (100 - quanto).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 1 }} >
              {language?.of_goal || 'of goal'}
            </Typography>
          </Box>
        </Box>
      )
    }
  };

  //Projetado para o final do turno - ULTIMO CAMPO
  let shiftNetPerSecond = dataShift?.uns_equipment_current_shift?.[0]?.duration * dataShift?.uns_equipment_current_shift?.[0]?.net_production;
  let elapsedTime = dataShift?.uns_equipment_current_shift?.[0]?.elapsed_time;
  let projectedTurno = shiftNetPerSecond / elapsedTime;

  // ----------
  let grossShift = dataShift?.uns_equipment_current_shift?.[0]?.gross_production;
  let netShift = dataShift?.uns_equipment_current_shift?.[0]?.net_production;

  // ----------
  //proportional_target no vindo do banco não está correto o calculo
  let targetOfShift = dataShift?.uns_equipment_current_shift?.[0]?.target;
  let duration = dataShift?.uns_equipment_current_shift?.[0]?.duration;
  let targetPorSegundo = targetOfShift / duration
  let proportionalTarget = targetPorSegundo * elapsedTime
  // let proportional_target = dataShift?.uns_equipment_current_shift?.[0]?.proportional_target

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, m: 1, height: 430, width: 430 }} >

      <Box >
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, }} >
          {language?.shift_production || 'SHIFT Production'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {
              enterprise != 37 ?
                roundNumber(dataShift?.uns_equipment_current_shift?.[0]?.net_production)
                : dataShift?.uns_equipment_current_shift?.[0]?.net_production
            }
          </Typography>

          {howGood(dataShift?.uns_equipment_current_shift?.[0]?.net_production, proportionalTarget)}

        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, mt: 1 }} >
          {language?.shift_scrap || 'SHIFT Scrap'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {scrapCalc(scrapType, grossShift, netShift) ?? "-"} %
          </Typography>

          {enterprise !== 37 ? <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', color: '#EB5757', ml: 1 }}>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {roundNumber(dataShift?.uns_equipment_current_shift?.[0]?.scrap)}
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 700, fontSize: 16, ml: 3 }} >
              {enterprise != 37 ? 'units' : 'm'}
            </Typography>
          </Box> : <></>}

        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 24, mt: 1 }} >
          {language?.shift_projected || 'Projected at end of SHIFT'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{ fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {enterprise != 37 ? roundNumber(projectedTurno.toFixed(0)) : projectedTurno.toFixed(0)}
          </Typography>

          {/* VER daonde que vai ser retirado os dados */}
          {/* { howGood(dataShift?.uns_equipment_current_shift?.[0]?.net_production, dataShift?.uns_equipment_current_shift?.[0]?.target) } */}

        </Box>
      </Box>

    </Box >
  );
}
