import React from 'react';
import Logo from '../../assets/Logo_Black_Transparent_Background 2.png';
import PackiotImage from '../../assets/Logo_Black_Transparent_Background 1.png'
// import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, } from '@material-ui/core'

function Footer() {
  const year = new Date().getFullYear();
  // const isTablet = useMediaQuery('(max-width:768px)', { noSsr: true });

  return (
    <Box sx={{ height: 75, display: 'flex', alignItems: 'flex-start', backgroud: 'trasparent', justifyContent: 'center', color: "#BDBDBD" }}>
      <img src={Logo} />
      <img src={PackiotImage} />
      <p>&nbsp;</p>
      <p>&copy; {year} - All rights reserved</p>
    </Box>
  )

};

export default Footer;