import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Grid, TextField, Select, 
  InputLabel, InputAdornment, FormHelperText, OutlinedInput, IconButton, FormControl,
   MenuItem, 
   DialogTitle} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@material-ui/core';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { getLanguage } from '../../../../../../utils/utils';
import { useSnackbar } from 'notistack';

function LinearProgressWithLabel(props) {
  let passwordStrenght = 'weak';
  let color = '#C13939'

  if (props.value <= 50) {
    passwordStrenght = 'weak';
    color = '#C13939'
  } else if (props.value <= 75) { 
    passwordStrenght = 'medium';
    color = '#ECBC13'
  } else {
    passwordStrenght = 'strong';
    color = '#318F29'
  }
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ backgroundColor: `#E3E2E7`, color: `${color}` }} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{passwordStrenght}</Typography>
      </Box>
    </Box>
  );
}

export default function ModalCreateUser({ isOpen, params, handleClose, handleCreateUser }) {
  const language = getLanguage();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassError, setConfirmPassError] = useState({ error: false, message: '' });
  const [passError, setPassError] = useState({ error: false, message: '' });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [progress, setProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let pass = password;
    let upperLetter = new RegExp(/[A-Z]/gm);
    let lowerLetter = new RegExp(/[a-z]/g);
    let number = new RegExp(/(?=.*\d)/g);

    let strength = 0;

    if (upperLetter.test(pass)) {
      strength += 25;
    }

    if (lowerLetter.test(pass)) {
      strength += 25;
    }

    if (number.test(pass)) {
      strength += 25;
    }

    if (pass.length >= 8) {
      strength += 25;
    }

    setProgress(strength);

  }, [password]);

  function resetForm() {
    setPassword('');
    setEmail('');
    setProgress(0);
    setConfirmPassword('');
    setRole(null);
    setUserName('');
  }

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  }

  const handlePasswordChange = (event) => {
    if (passError.error) setPassError({ error: false, message: '' });
    setPassword(event.target.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (event) => {
    if (confirmPassError.error) setConfirmPassError({ error: false, message: '' });
    setConfirmPassword(event.target.value);

  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (password.length < 6) {
      enqueueSnackbar('Weak password', { variant: 'error' });
      return;
    }
    if (confirmPassword === password) {
      let validEmail = true;
      if (!email.includes('@')) validEmail = false;
      if (email === '') validEmail = false;
      if (validEmail) {
          if (role && userName !== '' && password !== '') {
            handleCreateUser({
              userName,
              email,
              password,
              role
            });
            handleClose();
            resetForm();
          } else {
            enqueueSnackbar('Fill in the remaining fields', { variant: 'error' });    
          }
        
      } else {
        enqueueSnackbar('Invalid Email', { variant: 'error' });
      } 
       
    } else {
      setConfirmPassError({ error: true, message: 'Password does not match!' })
    }
    
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'}>
      <DialogTitle>
        <Typography variant="h4" style={{ color: '#4D4D4D' }}> {language?.create_user || "Create New User"} </Typography>
      </DialogTitle>

      <DialogContent sx={{ pr: 3, pl: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

        <Grid container spacing={2}>

          <Grid item xs={12} md={6}>

            <TextField
              sx={{ width: '100%', mt: '5px', background: '#fff' }}
              type="text"
              id="create-user-name" label={language?.name || 'Name'} variant="outlined"
              value={userName}
              onChange={(e) => handleNameChange(e)}
            />

          </Grid>

          <Grid item xs={12} md={6}>

          <TextField
              sx={{ width: '100%', mt: '5px', background: '#fff' }}
              type="text"
              id="create-user-email" label={language?.email || 'Email'} variant="outlined"
              value={email}
              onChange={(e) => handleEmailChange(e)}
            />

          </Grid>

          <Grid item xs={12}>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="create-user-role-label">{language?.role || 'Role'}</InputLabel>
              <Select
                labelId="create-user-role-label"
                id="create-user-role-select"
                value={role}
                label={language?.role || 'role'}
                onChange={(e) => handleRoleChange(e)}
                sx={{ width: '100%', background: '#fff' }}
              >
                {
                  params.map((item) => (
                  <MenuItem key={item?.id_user_role} value={item?.id_user_role}>{item?.nm_user_role}</MenuItem>
                  )
                )
                }
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              error={passError.error}
              fullWidth variant="outlined"
              sx={{ mt: '5px' }}
            >
              <InputLabel htmlFor="outlined-adornment-password">{language?.password || 'Password'}*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={`${language?.password || 'Password'}`}
              />
              
                <FormHelperText sx={{ ml: 0 }}>
                  <LinearProgressWithLabel value={progress} />
                </FormHelperText>
            </FormControl>

          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              error={confirmPassError.error}
              fullWidth variant="outlined"
              sx={{ mt: '5px' }}
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">{language?.confirm_password || 'Confirm Password'}*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={`${language?.confirm_password || 'Confirm Password'}`}
              />
              {
                confirmPassError.error &&
                <FormHelperText sx={{ ml: 0 }}>
                  {confirmPassError.message}
                </FormHelperText>
              }
            </FormControl>

          </Grid>

        </Grid>

      </DialogContent>

      <DialogActions sx={{ mt: '10px', p: 3, borderTop: '1px solid #E3E2E7' }}>
        <Button
          sx={{ width: 132, height: 42, textTransform: 'capitalize', borderRadius: 2, border: '2px solid #407CCC', color: '#000', background: '#FFF', '&:hover': { background: '#EAEAED' } }}
          variant="contained" disableElevation onClick={handleClose}
          size="small"
        >
          <Typography variant='body1'> {language?.cancel || 'Cancel'} </Typography>
        </Button>

        <Button sx={{ borderRadius: 2, textTransform: 'capitalize',  width: 132, height: 42 }}
          size="small" variant="contained" onClick={handleSubmit}
        >
          <Typography variant='body1'> {language?.create || 'Create'} </Typography>
        </Button>

      </DialogActions>

    </Dialog>
  );
}