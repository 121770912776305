import React, { useState, useEffect, useContext } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

import { Button, ButtonGroup } from '@mui/material';

function TypeOfViewSelector(props) {
  const { enterprise, site, area, line, visualizationLevel, teamOptions, setTypeOfView, typeOfView } = useContext(VariablesContext);
  const language = getLanguage();

  return (
    <ButtonGroup variant="outlined" sx={{ height: 36, mr: { sm: 3 } }}>
      <Button
        onClick={() => setTypeOfView('GENERAL')}
        sx={{
          backgroundColor: typeOfView == 'GENERAL' ? '#407CCC' : '',
          color: typeOfView == 'GENERAL' ? 'white' : '',
          borderRadius: 5,
          width: { xs: 125, sm: 90 },
        }}>
        {language?.general || 'General'}
      </Button>

      <Button
        onClick={() => setTypeOfView('SHIFTS')}
        sx={{
          backgroundColor: typeOfView == 'SHIFTS' ? '#407CCC' : '',
          color: typeOfView == 'SHIFTS' ? 'white' : '',
          borderRadius: 5,
          width: { xs: 125, sm: 90 },
        }}>
        {language?.shifts || 'Shifts'}
      </Button>

      {teamOptions.length > 0 && <Button
        onClick={() => setTypeOfView('TEAMS')}
        sx={{
          backgroundColor: typeOfView == 'TEAMS' ? '#407CCC' : '',
          color: typeOfView == 'TEAMS' ? 'white' : '',
          borderRadius: 5,
          width: { xs: 125, sm: 90 },
        }}>
        {language?.teams || 'Teams'}
      </Button>
      }

    </ButtonGroup>
  );
}

export default TypeOfViewSelector;
